import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Breadcrumbs, Loader } from '../../library';

import Applications from './applications';

import { searchAgreementApplications, multisignAgreements } from '../../../actions';

const MainAgreements = () => {
    const dispatch = useDispatch();
    const [ selectedApps, setSelectedApps ] = useState([]);
    const loaded = useSelector( state => state.matching.agreement.loaded );
    let apps = useSelector( state => state.matching.agreement.apps );
    const counter = useSelector( state => state.matching.agreement.counter );

    useEffect( () => {
        dispatch( searchAgreementApplications('', 1 ) );
    }, [] );
    
    const sign_ids = [];
    const sing_apps = selectedApps.map( application => {
        console.log('[1]', application );
        let ids = [
            application.reporting_review.grunt_agreement.final_budget.sign_id,
            application.reporting_review.grunt_agreement.final_protocol.sign_id,
            application.reporting_review.grunt_agreement.main_file.sign_id,
        ].filter( item => item !== null );

        ids.forEach( sign_id => sign_ids.push( sign_id ));

        return application._id;
    });

    const multisignAgreementsCallback = () => {
        dispatch( multisignAgreements(sing_apps, setSelectedApps) );
    };

    return(
        <> 
            <Breadcrumbs
                items={[
                    { name: 'Мультипідпис', link: `/agreements` },
                    { name: 'Грантові угоди', link: `/agreements/main` },
                ]}
            />
            <h1 className="page-title"> Завантажені грантові угоди, які потребують підпису </h1>

            {
                loaded ? 
                (
                    <Applications 
                        type="grunt_agreement"
                        data={selectedApps}
                        handler={setSelectedApps}
                        loaded={loaded}
                        apps={apps}
                        counter={counter}
                        sign_ids={sign_ids}
                        signCallback={multisignAgreementsCallback}
                    />
                ) : 
                (
                    <Loader />
                ) 
            }
            
        </>
    )


}


export default MainAgreements;