import axios from '../../helpers/private.axios';

import { notify } from '../index';

export const ALL_EVAL_GROUPS_REQ = "ALL_EVAL_GROUPS_REQ";
export const ALL_EVAL_GROUPS_RES = "ALL_EVAL_GROUPS_RES";
export const ALL_EVAL_GROUPS_ERR = "ALL_EVAL_GROUPS_ERR";

export const CREATE_GROUP_REQ = "CREATE_GROUP_REQ";
export const CREATE_GROUP_RES = "CREATE_GROUP_RES";
export const CREATE_GROUP_ERR = "CREATE_GROUP_ERR";

export const GET_SINGLE_GROUP_REQ = "GET_SINGLE_GROUP_REQ";
export const GET_SINGLE_GROUP_RES = "GET_SINGLE_GROUP_RES";
export const GET_SINGLE_GROUP_ERR = "GET_SINGLE_GROUP_RES";


export const getAllGroups = () => (dispatch) => {
    dispatch({ type: ALL_EVAL_GROUPS_REQ })

	axios.get(`/api/evaluation/groups`)
	.then(
		res => {
			console.log('req', res );
			dispatch({
				type: ALL_EVAL_GROUPS_RES,
				payload: res.data.data.groups
			})
		}
    ).catch( err => {
		dispatch({
			type: ALL_EVAL_GROUPS_ERR,
			errors: err
		})
	});
}

export const createGroup = ( data, methodology_id, history ) => (dispatch) => {
	dispatch({ type: CREATE_GROUP_REQ });

	let body = {
		...data,
		methodology_id
	}

	axios.post(`/api/evaluation/groups/store`,
		body
	)
	.then(
		res => {
			console.log('req', res );
			dispatch({
				type: CREATE_GROUP_RES,
				payload: res.data.data.groups
			});
			
			dispatch(
				notify(
					"Група успішно створена", "success"
				)
			);
			history.push(`/admin/evaluation/methodology/${methodology_id}`);
		}
    ).catch( err => {
		dispatch({
			type: CREATE_GROUP_RES,
			errors: err
		})
	});
} 

export const getSingleGroup = (id, loadData ) => (dispatch) => {
	dispatch({ type: GET_SINGLE_GROUP_REQ })
	axios.get(`/api/evaluation/groups/${id}`)
	.then(
		res => {
			console.log('res', res, res.data.data);
			dispatch({
				type: GET_SINGLE_GROUP_RES,
				payload: res.data.data
			});

			if( loadData ){
				loadData( res.data.data.group );
			}
		}
    ).catch( err => {
		dispatch({
			type: ALL_EVAL_GROUPS_ERR,
			errors: err
		})
	});
}


export const UPDATE_SINGE_GROUP_REQ = "UPDATE_SINGE_GROUP_REQ";
export const UPDATE_SINGE_GROUP_RES = "UPDATE_SINGE_GROUP_RES";
export const UPDATE_SINGE_GROUP_ERR = "UPDATE_SINGE_GROUP_ERR";

export const updateGroup = ( data, id, history ) => (dispatch) => {
    
	dispatch({ type: UPDATE_SINGE_GROUP_REQ });
	axios.post(`/api/evaluation/groups/${id}/update`,
        data
	)
	.then(
		res => {
			dispatch({
				type: UPDATE_SINGE_GROUP_RES,
				payload: res.data.data.metodology
			});
			
			dispatch(
				notify(
					"Група успішно оновленна", "success"
				)
			);
		}
    ).catch( err => {
		dispatch({
			type: UPDATE_SINGE_GROUP_ERR,
			errors: err
		})
	});
} 