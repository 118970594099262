import React, { useEffect, useState } from 'react'
import { Switch, Route, useHistory, useParams } from 'react-router-dom';

import { TabsNav, Loader, Breadcrumbs } from '../../../library';

import { DocumentDetails } from './details';
import { DocumentFinInfo } from './finInfo';
import { ApplicantInfo } from './AppInfo';
import { FinishDocument } from './FinishDocument';
import { GenInfo } from './GenInfo';


import { getSingleDocument } from '../../../../actions';


export const SingleDocument = () => {
    const params = useParams();
    const history = useHistory();

    const [ { loaded, document, template, application }, setDocument ] = useState({
        loaded: false,
        document: null,
        template: null,
        application: null,
    });

    useEffect(() => {
        getSingleDocument( params.document_id )
            .then( res => {
                setDocument({
                    loaded: true,
                    document: res.data.data.document,
                    template: res.data.data.template,
                    application: res.data.data.application,
                })
            })
    }, [])


    const updateDocument = ( document ) => {
        setDocument({
            loaded: true,
            document: document,
            template: template,
            application: application
        });
    }

    if( !loaded ){
        return <Loader />
    }

    return (
        <>  
            <Breadcrumbs items={[
                {name: 'Документи', link: `/documents` },
                {name: document.title, link: `#` },
            ]}/>
            <TabsNav 
                route={`/documents/${document._id}`}
                tabs={[
                    { slug: '', title: 'Інформація'},
                    { slug: 'fin-info', title: 'Фінансова інформація'},
                    { slug: 'gen-info', title: 'Генеративна інформація'},
                    { slug: 'details', title: 'Реквізити'},
                    { slug: 'finish', title: 'Відправити на підпис'},
                ]}
            />
            <Switch>
                <Route exact path="/documents/:document_id" render={ ( params ) => ( 
                        <DocumentDetails {...params} 
                            document={document} 
                            template={template} 
                            setDocument={updateDocument}
                            application={application}
                        />
                    )} 
                />

                <Route exact path="/documents/:document_id/fin-info" render={ ( params ) => (
                        <DocumentFinInfo {...params} 
                            document={document} 
                            template={template} 
                            application={application}
                        />
                    )} 
                />

                <Route exact path="/documents/:document_id/gen-info" render={ ( params ) => (
                        <GenInfo {...params} 
                            document={document} 
                            template={template} 
                            application={application}
                            setDocument={updateDocument}
                        />
                    )} 
                />

                <Route exact path="/documents/:document_id/details" render={ ( params ) => (
                        <ApplicantInfo {...params} 
                            document={document} 
                            template={template} 
                            application={application}
                            setDocument={updateDocument}
                        />
                    )} 
                />

                <Route exact path="/documents/:document_id/finish" render={ ( params ) => (
                        <FinishDocument {...params} 
                            document={document} 
                            setDocument={updateDocument}
                            template={template} 
                            application={application}
                        />
                    )} 
                />
            </Switch>
        </>
    )

}