import axios from '../../helpers/private.axios'
import config from '../../config'
import { notify } from './notify.actions'

export const GET_AGREEMENT_APPS_REQ = "GET_AGREEMENT_APPS_REQ";
export const GET_AGREEMENT_APPS_RES = "GET_AGREEMENT_APPS_RES";


export const searchAgreementApplications = ( query = '', agreement = 1 ) => (dispatch, getState) => {
    dispatch({ type: GET_AGREEMENT_APPS_REQ })

    axios.post(`/api/catalogs/search`, {
        query: query,
        agreement: agreement
    })
    .then(
        res => {
            dispatch({
                type: GET_AGREEMENT_APPS_RES,
                payload: res.data.data.applications,
                counter: res.data.data.applications.length
            });
        }
    )

}