const messages = ( max_rank ) => {

    let messagesLib = {
        "0" : {
            message: "Оцініть пункт", className: "text-gray"
        }
    };

    if( max_rank === 5){
        messagesLib = {
            ...messagesLib,
            "5": { message: "Дуже добре", className: "text-green"},
            "4": { message: "Добре", className: "text-green"  },
            "3": { message: "Посередньо", className: "text-yellow"  },
            "2": { message: "Нижче середнього", className: "text-red"  },
            "1": { message: "Слабко", className: "text-red"  },
            "0": { message: "Немає оцінки", className: "text-grey"},
        }
    }

    if( max_rank === 3 ){
        messagesLib = {
            ...messagesLib,
            "3": { message: "Добре", className: "text-green"  },
            "2": { message: "Посередньо", className: "text-yellow"  },
            "1": { message: "Слабко", className: "text-red"  },
            "0": { message: "Немає оцінки", className: "text-grey"},
        }
    }

    return messagesLib;
}

export default messages;