import React, { useState, useEffect } from 'react';



export const AgreementFields = ({ fields, templateData, handleTemplateData }) => {


    const inputHandler = name => e => {
        handleTemplateData( name, e.target.value );
    }

    return(
        <div className="documents__aditional">

            <h3> Поля які не тягнуться з заявки і які необхідно заповнити </h3>
            <div className="form-container">
            {
                fields.map( (field, index ) => {
                    return(
                        <label className="form-label" key={ index }>
                            <div className="form-label-text">
                                { field.label }
                            </div>
                            <div className="form-label-input">
                                <input 
                                    className="form-input"
                                    type="text" 
                                    name={ field.name }
                                    placeholder={ field.name }  
                                    onChange={ inputHandler(field.name) }  
                                    required
                                />
                            </div>
                        </label>
                    )
                })
            }
            </div>


        </div>
    )
}