import React from 'react'
import { NavLink } from 'react-router-dom';


export const ExpertsPageHeader = () => {

    return(
        <header className="tabs__container">
            <NavLink exact className="tabs__item" to="/ed/experts"> Дійсні експерти </NavLink>
            <NavLink exact className="tabs__item" to="/ed/experts/unassigned"> Нерозподілені експерти </NavLink>
        </header>
    );

}

export const SingleExpertHeader = ({ user }) => {
    return(
        <header className="tabs__container">
            <NavLink exact className="tabs__item" to={`/ed/experts/${user._id}/applications`}> Заявки </NavLink>
            <NavLink exact className="tabs__item" to={`/ed/experts/${user._id}/info`}> Інформація </NavLink>
            <NavLink exact className="tabs__item" to={`/ed/experts/${user._id}/vote`}> Проголосувати за експерта </NavLink>
        </header>
    );
}


export default ExpertsPageHeader;