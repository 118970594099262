import React, { Component } from 'react'
import { Redirect, Link } from 'react-router-dom'
import { connect } from 'react-redux'

import { Button } from '../../library'
import { restorePass, restorePassClean } from '../../../actions'

class Restore extends Component {
  state = {
    email: ''
  }

  handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value

    this.props.restoreClean()

    this.setState({
      [name]: value
    })
  }

  componentWillUnmount() {
    this.props.restoreClean()
  }

  submitForm = (e) => {
    e.preventDefault()
    const { email } = this.state

    setTimeout(() => this.props.restoreClean(), 2000)
    this.props.recoverPass(email)
  }

  render(){
    const { loading, error, success } = this.props
    const { handleChange, submitForm } = this

    if (success) return <Redirect to="/restore/success"/>
    return(
      <div className="login-container">

        <p className="login-recovery-title">Відновлення паролю</p>
        <p className="login-recovery-subtitle">Ваша пошта, на яку зареєстровано аккаунт</p>
        <form
          className="login-form"
          onSubmit={submitForm}
        >
          <label className={ !error ? "login-label" : "login-label error"}>
            <div className="login-label-input">
              <input
                type="email"
                required
                className="login-input"
                name="email"
                onChange={handleChange}
                placeholder="Н-д, IvanFranko@gmail.com"
              />
            </div>
          </label>
          {
            error && (
                <div className="login-label">
                  <div className="login-label-text"></div>
                  <div className="login-label-input text-red">
                    { error }
                  </div>
                </div>
              )
          }

          <div className="login-label">
            <div className="login-label-text"></div>
            <div className="login-label-input">
              <Button
                text="Надіслати інструкції по відновленню паролю"
                ltext="Надсилаються інструкції по відновленню паролю"
                loading={loading}
                size="large"
              />
            </div>
          </div>
        </form>
        <div className="login-footer">
          <button className="login-footer-info">Український Культурний Фонд</button>
          <Link className="login-footer-recovery" to="/">Форма входу у систему</Link>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  success: state.users.restore.success,
  error: state.users.restore.error,
  loading: state.users.restore.loading
})

const mapDispatchToProps = (dispatch) => ({
  recoverPass: ( email ) => {
    dispatch( restorePass(email) )
  },
  restoreClean: () => {
    dispatch( restorePassClean() )
  }
})



export default connect(mapStateToProps, mapDispatchToProps)(Restore)
