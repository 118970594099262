import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Uploading from './uploading';
import Scaned from './scaned';
import Additional from './additional';
import Root from './root';
import GenerateNewAgreement from './GenerateAgreement/generate_new';

import DocumentsList from './GenerateAgreement/agreement_list';

const Agreement = () => {
    return(
        <div>
            <Switch>
                <Route exact path="/rp/application/:id/agreement/" component={ Root }/>
                <Route exact path="/rp/application/:id/agreement/upload" component={ Uploading }/>
                <Route exact path="/rp/application/:id/agreement/scaned" component={ Scaned }/>
                <Route exact path="/rp/application/:id/agreement/additional" component={ Additional }/>
                <Route exact path="/rp/application/:id/agreement/list" component={ DocumentsList }/>
                <Route exact path="/rp/application/:id/agreement/generate" component={ GenerateNewAgreement }/>
            </Switch>
        </div>
    );
}


export default Agreement;