import React from 'react'
import { TabsNav } from '../../../library'


const ExpertSingleHead = ({ id, review }) => {

  console.log( 'st', review.status.id === 3)

  let headerNavDisable = [
    { slug: 'info', title: 'Загальна інформація' },
    { slug: 'rate', title: 'Оцінювання заявки', disabled: review.status.id <= 0 ? true : false },
    { slug: 'protocol', title: 'Протокол заявки', disabled: review.status.id !== 3 ? true : false},
  ];

  return (
    <TabsNav
      route="/expert"
      tabs={ headerNavDisable }
      location={ id }
    />
  )
}

export default ExpertSingleHead