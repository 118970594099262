import {
  GET_ALL_COUNCIL_REQUEST,
  GET_ALL_COUNCIL_SUCCESS,
  GET_ALL_COUNCIL_ERROR,
  CLEAR_COUNCIL,

  CREATE_COUNCIL_SUCCESS,
} from '../../actions'

const contestsInitialState = {
  data: null,
  loading: false,
  loaded: false,
  errors: []
};

const Councils = ( state = contestsInitialState, action) => {
  switch( action.type ){
    case GET_ALL_COUNCIL_REQUEST:
      return {
        ...state,
      loading: true
      }

    case GET_ALL_COUNCIL_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload
      }

    case CREATE_COUNCIL_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload
      }

    case GET_ALL_COUNCIL_ERROR:
      return {
        ...state,
        error: action.error,
      }

    case CLEAR_COUNCIL:
      return {
        data: [],
        loading: false,
        loaded: false,
        errors: []
      }

    default:
      return state;
  }
}

export default Councils;
