import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Sidebar from '../../common/SideBar'
import Notify from '../../common/Notify'

import { Layout, Breadcrumbs, Loader } from '../../library'

import { Feed } from './feed';
import { Templates } from './templates/templates';
import { CreateTemplate } from './templates/create';
import { EditTemplate } from './templates/edit';
import { SingleDocument } from './single_document';
import './documents.scss';

const Documents = () => (
    <>
    <Sidebar />
    <Layout>
        <Switch>
            <Route exact path="/documents" component={Feed} />
            <Route exact path="/documents/templates" component={Templates} />
            <Route exact path="/documents/templates/:id" component={EditTemplate} />

            <Route exact path="/documents/create" component={CreateTemplate} />
            <Route path="/documents/:document_id" component={SingleDocument} />

        </Switch>
        <Notify />
    </Layout>
    </>
);

export default Documents;