import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';

import { uploadExpertDocuments } from '../../../../actions';

import { getExpertDocuments } from './getExpertDocuments';
import { InlineSpinner } from '../../../library';

import { pdf_icon } from '../images';

const FileSelector = ({ document, handler }) => {
    const { name, required, label, outside_link, filename, link } = document
    return(
        <label className="expForm__item file">
                <div className="expForm__label"> 
                    { label }
                    {
                        required && (
                            <span className="required">*</span>
                        )
                    }
                    {
                        outside_link && (
                            <div>
                                Шаблон 
                                <a href={outside_link} target="_blank" className="text-link">
                                    доступний за посиланням
                                </a>
                            </div>
                        )
                    }
                    
                </div>
                <div className="expForm__container">
                    <label className="project-app-grand-file">
                        {
                            filename ? filename : 'Натисніть, щоб обрати файл'
                        }
                        <input
                            type="file" 
                            name={name}
                            className="expForm__hidden"
                            onChange={handler}
                            accept=".pdf"
                            required={required}
                            tabIndex="1"
                            disabled={false}   
                        />
                    </label>
                    {
                        link && (
                            <a href={link} target="_blank" className="expForm__link text-link">

                                <img src={pdf_icon} width="32" style={{ marginRight: '5px' }} />
                                <span>Завантажити файл</span>
                            </a>
                        )
                    }    
                </div>
            </label>
    )

}



const ExpertDocuments = () => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.user)
    const inUpdaiting =  useSelector(state => state.users.singleUser.updating );

    const [ documents, setDocuments ] = useState( getExpertDocuments( user ) );

    console.log('[inUpdaiting]', inUpdaiting );

    const fileChanger = ( e ) => {
        let name = e.target.name;
        let file = e.target.files[0];

        console.log('file', name, file  );

        if( file ){
            let updatedDocs = documents.map( doc => {
                if( doc.name === name ){
                    doc.filename = file.name;
                    doc.file = file;
                }
                return doc;
            });
    
            setDocuments( updatedDocs );
        }


    }

    const sendForm = (e) => {
        e.preventDefault();
        dispatch(
            uploadExpertDocuments(
                user._id,
                documents
            )
        )


    }



    return(
        <>
            <form className="expForm" onSubmit={sendForm}>
                {
                    documents.map( (document, index) => {
                        return (
                            <FileSelector
                                key={index}
                                document={document}
                                handler={fileChanger}
                            />
                        )
                    })
                }

                <footer className="expForm__item file">
                    <div className="expForm__label"></div>
                    <div className="expForm__container">
                        <button className={ inUpdaiting ? "direction__btn disabled" : "direction__btn"}> Зберегти дані </button>
                        {
                            inUpdaiting && (
                            <div style={{ padding: "15px 10px"}}> 
                                <InlineSpinner />
                                <span style={{ paddingLeft: "15px"}}>Файли завантажуються, будь ласка, зачекайте </span>
                            </div>
                            )
                        }
                    </div>
                </footer>
            </form>

        </>
    )

}

export default ExpertDocuments;