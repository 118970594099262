import React from 'react'
import moment from 'moment';

const ApplicationSelect = ({ applications, changeSectionHandler, section_name, schema, section, changeStatus, statuses, subsection, changeSubsectionStatus, subsectionStatus }) => {
    return(
        <>
            <label className="ed-stats__label">
                <div  className="ed-stats__title"> Оберіть етап </div>
                <select
                    className="ed-stats__input"
                    onChange={changeSectionHandler}
                    value={section_name}
                    required
                > 
                    <option value="" disabled={ section_name !== "" }> Оберіть етап </option>
                    {
                        schema.map( (item, i) => {
                            return(
                                <option key={i} value={item.name}>
                                    { item.label }
                                </option>
                            );
                        })
                    }
                </select>

            </label>

            {
                section.subqueries && (
                    <label className="ed-stats__label">
                        <div className="ed-stats__title"> Оберіть статус </div>
                        <select
                            className="ed-stats__input"
                            onChange={changeStatus( section.subqueries )}
                            value={statuses}
                            required
                        > 
                            <option value=""  disabled={ statuses !== "" } > Оберіть статус </option>
                            {
                                section.subqueries.map( (item, i) => {
                                    return(
                                        <option key={i} value={item.status}>
                                            { item.label }
                                        </option>
                                    ); 
                                })
                            }
                        </select>
                    </label>
                )
            }

            {
                subsection.substatuses && (
                    <label className="ed-stats__label">
                        <div className="ed-stats__title"> Оберіть підстатус </div>
                        <select
                            className="ed-stats__input"
                            onChange={changeSubsectionStatus}
                            value={subsectionStatus}
                            required
                        > 
                            <option value="" disabled={ subsectionStatus !== "" }> Оберіть підстатус </option>
                            {
                                subsection.substatuses.map( (item, i) => {
                                    let addMessage = '';
                                    let disabled = false;
                                    if( statuses === 5 && item.status === -1 && applications.length > 1 ){
                                        addMessage = '(Можна обрати лише коли обрано 1 заявку)'
                                        disabled = true;
                                    }

                                    return(
                                        <option key={i} value={item.status} disabled={disabled}>
                                            {`${item.label} ${addMessage}`}
                                        </option>
                                    ); 
                                })
                            }
                        </select>
                    </label>
                )
            }

            {
                subsectionStatus == -1 && applications.length === 1 && (
                    <label className="ed-stats__label">
                        <div className="ed-stats__title"> Оберіть які додаткові угоди видалити </div>
                        <div>
                            {
                                applications[0].reporting_review.grunt_agreement.additionalFiles.length > 0 && (
                                    applications[0].reporting_review.grunt_agreement.additionalFiles.map( agreement => {
                                        return(
                                            <label>
                                                <input type="checkbox" value={agreement.id}/>
                                                <span> Додаткова угода від { moment(agreement.created_at).format('lll') } </span>
                                            </label>
                                        )

                                    })
                                )
                            }
                        </div>
                    </label>
                )
            }
        </>
    )
}

export default ApplicationSelect;