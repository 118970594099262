import axios from '../../helpers/private.axios'
import config from '../../config'

export const GET_SINGLE_FIELD_REQUEST = 'GET_SINGLE_FIELD_REQUEST'
export const GET_SINGLE_FIELD_SUCCESS = 'GET_SINGLE_FIELD_SUCCESS'
export const GET_SINGLE_FIELD_ERROR = 'GET_SINGLE_FIELD_ERROR'

export const GET_ALL_FIELDS_REQ = 'GET_ALL_FIELDS_REQ'
export const GET_ALL_FIELDS_RES = 'GET_ALL_FIELDS_RES'
export const GET_ALL_FIELDS_ERR = 'GET_ALL_FIELDS_ERR'


const CULTURAL_SECTORS = "5f75c51baa00c7169c1ffab2";
const KEYWORDS = "5f75db79c3276c5ffb2a1482";

export const getSingleField = ( type ) => (dispatch) => {
	dispatch({ type: GET_SINGLE_FIELD_REQUEST, field: type });

	let key = '';
	if( type === "keywords"){
		key = KEYWORDS;
	}
	if( type === "culture"){
		key = CULTURAL_SECTORS;
	}

	axios.get(`${config.apiPath}/api/catalogs/field/${key}`)
	.then( res => {
		dispatch({
		type: GET_SINGLE_FIELD_SUCCESS,
		payload: res.data.data.field,
		field: type
		});
	}).catch( error => {
		dispatch({
			type: GET_SINGLE_FIELD_ERROR,
			payload: error
		})
	})
}


export const getAllFields = () => dispatch => {
	dispatch({ type: GET_ALL_FIELDS_REQ });
	axios.get(`/api/common/fields/`)
	.then( res => {
		dispatch({
			type: GET_ALL_FIELDS_RES,
			payload: res.data.data
		});
	}).catch( error => {
		dispatch({
			type: GET_ALL_FIELDS_ERR,
			payload: error
		})
	})
}