import React from 'react'
import { connect } from 'react-redux'

import { logout } from '../../../actions'

import logo from './icons/sup_icons_SUP_LOGO.svg'
import altLogo from './icons/sup_icons_EA.svg'

const Unassigned = ({ logout }) => {
  return(
		<div className="wrap login">
      <div className="login-logo">
        <img src={logo} alt="logo"/>
      </div>
      <div className="login-wrapper unassigned">
        <div>
          Дякуємо за реєстрацію у <b>Системі Управління Проектами Українського Культурного Фонду.</b>
        </div>
        <div>
          Будь ласка, зачекайте, поки адміністратор системи розподілить вашу роль і надасть вам доступи.
        </div>
        <br/>
        <div>
          Після цього, замість цієї сторінки ви побачите свою робочу сторінку.
        </div>
        <button
          className="login-button loading"
          onClick={ logout }
        >
            Вийти з аккаунту
        </button>
        <div className="login-footer centred">
          <button className="login-footer-info">Український Культурний Фонд</button>
        </div>
      </div>
      <div className="login-agency">
        <a href="https://elt.agency/" className="cw_link">
        <span>Система розроблена </span>
        <img className="copywrite" src={altLogo} alt="Element Agency"/>
        </a>
      </div>
		</div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  logout: () => {
    dispatch( logout() )
  }
})

export default connect(null, mapDispatchToProps)(Unassigned)
