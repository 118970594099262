import React from 'react'

export const OfflineProcedure = ({ procedure }) => {
    return (
        <>
            <div className="project-app-single-repeat-info">
                <div className="project-app-single-repeat-info-value">Глава комісії</div>
                <div className="project-app-single-repeat-info-title">{ procedure.commission.head.name }</div>
            </div>
            <div className="project-app-single-repeat-info">
                <div className="project-app-single-repeat-info-value">Члени комісії</div>
                <div className="project-app-single-repeat-info-title">
                    { procedure.commission.members.map( item => item.name ).join(', ') }
                </div>
            </div>
            <div className="project-app-single-repeat-info">
                <div className="project-app-single-repeat-info-value">Секретар</div>
                <div className="project-app-single-repeat-info-title">{ procedure.commission.secretary.name }</div>
            </div>
        </>
    );
}

export default OfflineProcedure;