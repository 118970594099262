import React from 'react';

export const Round = ({status}) => {
    let color = '';
	switch( status ){
		case 1:
			color = '#3EB29D';
			break;

		case -1:
			color = '#CE5151';
			break;

		default:
			color = '#9D9D9C';	
	}

	let styles = {
		borderRadius: '20px',
		height: '20px',
		width: '20px',
		background: color
	}
	return (
		<div style={styles}></div>
	);
}

export default Round;
