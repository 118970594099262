import React from 'react'
import PropTypes from 'prop-types';


import SignerController from '../../../helpers/signer.plugin';

import './style.scss';
import key from './icons/key.svg';
import loader from './icons/dual_loader.svg';

export class Signer extends React.Component{

    static propTypes = {
        ids: PropTypes.array,
        statusChangerCallback: PropTypes.func.isRequired
    }

    static defaultProps = {
        afterEach: null
    }

    state = {
        inited: false,
        loading: false
    }

    button = React.createRef();

    componentDidMount(){
        this.signer = new SignerController();
        let signerProcess = this.signer.init();
        signerProcess.then( (res) => {
            this.setState({ inited: true });
            console.log( signerProcess );
        });
    }

    setPopupCoords = ( node ) => {
        const coords = node.getBoundingClientRect();
        this.signer.setCoords(
            coords.width,
            coords.top,
            coords.left
        );
    }

    startSignerHandler = () => {
        const { statusChangerCallback, afterEach } = this.props;
        this.setState({ loading: true });
        // console.log('signer fn:', statusChangerCallback, 'ids', this.props.ids );
        this.signer.signItems( this.props.ids, null, statusChangerCallback, afterEach );
        this.setPopupCoords( this.button.current );
    }


    render = () => {
        const { button, startSignerHandler } = this;
        const { inited, loading } = this.state;
        const { message } = this.props;

        return(
            <div className="signer__wrapper">
                {
                    inited ? 
                    (
                        <>
                        {
                            !loading ? (
                                <button 
                                    type="button"
                                    className="signer__button"
                                    onClick={startSignerHandler}
                                    ref={button}
                                >
                                    <span> { message ? message : "Підписати за допомогою ЕЦП"}</span>
                                    <img className="signer__icon" src={key} alt="key"/>
                                </button>
                            ) : (
                                <button 
                                    type="button"
                                    className="signer__button"
                                    disabled={true}
                                    ref={button}
                                >
                                    <span> Зачекайте, поки документи будуть підписані </span>
                                    <img className="signer__icon" src={loader} alt="key"/>
                                </button>
                            ) 
                        }
                        </>
                    ) : 
                    (
                        <button 
                            type="button"
                            className="signer__button">
                            <span>Підключаємось до серверу електроного підпису</span>
                            <img className="signer__icon" src={key} alt="key"/>
                        </button>
                    )
                }
            </div>
        )
    }
}