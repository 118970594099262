import React from 'react'
import { connect } from 'react-redux'

import { Loader, Breadcrumbs } from '../../../../library'

import RenderRoles from './renderRoles'
import RenderPrograms from './renderPrograms'
import RenderFields from './renderFields'

import {
  getSingleUser,
  getAllCouncil,
  getRootPrograms,
  getRootSeasons,
  getSingleField,
  closeUserNotify,
  clearSingleUserData,
  updateSingleUser
} from '../../../../../actions'

class AssignUser extends React.PureComponent {
  state = {
    _id: null,
    name: '',
    email: '',
    role: null,
    council_id: null,
    main_program: '',
    programs_score_ids: [],
    keywords: [],
    max_application_limit: null,
    cultural_sector: null,
    position: '',
    fix_fields: false
  }

  static getDerivedStateFromProps(props, state){
    const { singleUser, fields } = props;
    // console.log('- --------user gdsfp', singleUser, props );

    if ( singleUser && singleUser._id !== state._id) {
      return singleUser
    }

    let existedKeywords = null;
    if( singleUser && fields.keywords_loaded ){
      
      existedKeywords = singleUser.keywords.filter( user_keyword => {
        return fields.keywords.options.options.find( item => {
          if( item.archive ){
            return false;
          }
          return item.value === user_keyword;
        });
      });

      if( state.keywords.length !== singleUser.keywords.length){
        // console.log('update 2. returned', state, 'state', state.keywords.length, 'props', singleUser.keywords.length );
        return state;
      }

      if( singleUser.keywords.length !== existedKeywords.length && !state.fix_fields ){
        // console.log('update 1', state.keywords,  singleUser.keywords.length, existedKeywords.length );
        let newState = {
          ...state,
          keywords: existedKeywords,
          fix_fields: true
        }
        // console.log('up1. return:', newState );
        return newState;
      }

    

      return state;
    }



    return state
  }

  componentDidMount(){
    this.props.getUser( this.props.match.params.userid )
    this.props.getAllCouncil()

    this.props.getDataRootPrograms()
    this.props.getDataSingleField('keywords')
    this.props.getDataSingleField('culture')
  }

  handleChange = (e) => {
    const name = e.target.name
    let value = e.target.value

    this.setState({
      [name]: value
    })
  }

  componentWillUnmount() {
    this.props.clearUserData()
  }

  handleProgramsChange = (e) => {
    let value = e.target.value;
    
    if( e.target.name === "programs_score_ids"){
      let userPrograms = [...this.state.programs_score_ids ]
      let isAlreadyHaveProgram = this.state.programs_score_ids.some( existed_field => existed_field === value)
  
      if( isAlreadyHaveProgram ){
        userPrograms = userPrograms.filter( item => item !== value )
      } else {
        userPrograms.push(value)
      }
      this.setState({
        programs_score_ids: userPrograms
      })
    }

    if( e.target.name === "main_program" ){
      this.setState({ main_program: value });
    }

  }

  handleKeywordsChange = (e) => {
    let value = e.target.value

    let userKeywords = [...this.state.keywords ]
    let isAlreadyHaveField = this.state.keywords.some( existed_field => existed_field === value)

    if( isAlreadyHaveField ){
      userKeywords = userKeywords.filter( item => item !== value )
    } else {
      if(userKeywords.length >= 5) return
      userKeywords.push(value)
    }

    this.setState({
      keywords: userKeywords
    })
  }

  sendEditRequest = (e) => {
    e.preventDefault()
    const { _id, name, email, role, council_id, programs_score_ids, keywords, max_application_limit, cultural_sector, main_program, position } = this.state

    this.props.editUser( _id, { name, email, role, council_id, programs_score_ids, keywords, cultural_sector, position, max_application_limit, main_program }, this.props.history)
  }

  render(){
    const { singleUser, councils, seasons, fields } = this.props
    const { handleChange, sendEditRequest, handleProgramsChange, handleKeywordsChange } = this
    let { name, email, role, council_id, programs_score_ids, keywords, max_application_limit, cultural_sector, main_program, position } = this.state

    if( !singleUser ){
      return(
        <Loader/>
      )
    } else {
      return(
        <div className="innerWrap">
          <Breadcrumbs items={[
            {name:'Адмiнiстративна панель', link: '/admin'},
            {name:'Користувачі', link: '/admin/users'},
            {name: singleUser.name, link: `/admin/users/${singleUser._id}`},
          ]}/>
          <header className="page-header">
            <h1 className="page-title">Налаштувати користувача: {singleUser.name}</h1>
          </header>

          <form className="form-container" onSubmit={sendEditRequest}>

            <label className="form-label">
              <div className="form-label-text">
                <span>Ім'я користвача</span>
              </div>
              <div className="form-label-input">
                <input
                  type="text"
                  className="form-input"
                  name="name"
                  onChange={handleChange}
                  value={name}
                  placeholder="Н-д, Іван Франко"
                />
              </div>
            </label>
            
            <label className="form-label">
              <div className="form-label-text">
                <span> Посада </span>
              </div>
              <div className="form-label-input">
                <input
                  type="text"
                  className="form-input"
                  name="position"
                  onChange={handleChange}
                  value={position}
                  placeholder="Н-д, Іван Франко"
                />
              </div>
            </label>

            <label className="form-label">
              <div className="form-label-text">
                <span>Пошта</span>
              </div>
              <div className="form-label-input">
                <input
                  type="email"
                  className="form-input"
                  name="email"
                  onChange={handleChange}
                  value={email}
                  placeholder="Н-д, ivan.franko@gmail.com"
                />
              </div>
            </label>

            <div className="form-label">
              <div className="form-label-text">
                <span>Роль</span>
              </div>

              <div className="form-label-input">
                <RenderRoles role={role} handleChange={ handleChange }/>
              </div>
            </div>

            {
              (role === 'expert') && (
                <>
                  {
                    councils && (
                      <label className="form-label">
                        <div className="form-label-text">
                          <span>Група</span>
                        </div>
                        <div className="form-label-input">
                          <select
                            value={council_id === null ? '' : council_id}
                            onChange={handleChange}
                            name="council_id"
                            className="form-input">
                              <option value="null">Не вибрано жодної експертної ради</option>
                              {
                                councils.map( (council, key) => (
                                  <option key={key} value={council._id}>{council.title}</option>
                                ))
                              }
                          </select>
                        </div>
                      </label>
                    )
                  }
                  {
                    // Основний культурний сектор
                    fields.culture_loaded && (
                      <label className="form-label">
                        <div className="form-label-text">
                          <span>Основний культурний сектор</span>
                        </div>
                        <div className="form-label-input">
                          <select
                            value={cultural_sector === null ? '' : cultural_sector}
                            onChange={handleChange}
                            name="cultural_sector"
                            className="form-input">
                              <option value="null">Не вибрано жодного сектору</option>
                              {
                                fields.culture.options.options.map( (culture, key) => {
                                
                                  let name = culture.name;

                                  if( singleUser.fields ){
                                    if( singleUser.fields.expected_cultural_sector === culture.value ){
                                      name = `${name} (Бажаний сектор)`;
                                    }
                                  }

                                  return (
                                    <option key={key} value={culture.value}>
                                      { name }
                                    </option>
                                  )
                                })
                              }
                          </select>
                        </div>
                      </label>
                    )
                  }
                  {
                    fields.keywords_loaded && (
                      <div className="form-label">
                        <div className="form-label-text">
                          <span>Ключовi слова ({keywords.length})</span>
                        </div>
                        <div className="form-label-input">
                          <RenderFields
                            name="keywords"
                            fields={fields.keywords.options.options}
                            currentfields={keywords}
                            handleChange={handleKeywordsChange}
                            showExpected={true}
                            user={singleUser}
                          />
                        </div>
                      </div>
                    )
                  }
                  <hr/>
                  {
                    seasons && (
                      <div className="form-label">
                        <div className="form-label-text">
                          <span>Програми якi оцiнює експерт</span>
                        </div>
                        <div className="form-label-input">
                          <RenderPrograms
                            seasons={seasons}
                            mainProgram={main_program}
                            currentPrograms={programs_score_ids}
                            handleChange={handleProgramsChange}
                            showExpected={true}
                            user={singleUser}
                          />
                        </div>
                      </div>
                    )
                  }
                  <label className="form-label">
                    <div className="form-label-text">
                      <span>Макс. заявок</span>
                    </div>
                    <div className="form-label-input">
                      <input
                        type="number"
                        className="form-input"
                        name="max_application_limit"
                        onChange={handleChange}
                        value={max_application_limit}
                        placeholder="0"
                      />
                    </div>
                  </label>
                </>
              )
            }

            <div className="form-label">
              <div className="form-label-text"></div>
              <div className="form-label-input">
                <button
                  className="form-button"
                  type="submit"
                >
                  Налаштувати!
                </button>
              </div>
            </div>
          </form>
        </div>
      )
    }

  }
}

const mapStateToProps = (state) => ({
  singleUser: state.users.singleUser.data,
  notification: state.users.singleUser.notification,

  councils: state.users.allCouncils.data,
  seasons: state.programs.seasons.data,
  fields: state.common.fields,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getUser: (_id) => {
    dispatch( getSingleUser(_id) )
  },
  clearUserData: () => {
    dispatch( clearSingleUserData() )
  },
  getAllCouncil: () => {
    dispatch( getAllCouncil() )
  },
  editUser: (_id, data ) => {
    dispatch( updateSingleUser(_id, data, ownProps.history ) )
  },
  closeNotification: () => {
    dispatch({ type: closeUserNotify() })
  },
  getDataRootPrograms: () => {
    // dispatch( getRootPrograms() );
    dispatch( getRootSeasons() );
  },
  getDataSingleField: ( type ) => {
    dispatch( getSingleField( type ) )
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(AssignUser)