import React from 'react';
import { connect } from 'react-redux';

import { Loader, Input, Table, Row, Cell } from '../../../../library';

import { getAllUsers, createNegotiationProcedure } from '../../../../../actions';

import { roles } from '../../../Admin/Users/UserList/roles';


const UserItem = React.memo( ({ user, onChange, displayRole, head, secretary, members }) => {
	const used = [ 
		head,
		secretary,
		...members
	];
	let alreadyUsed = used.some( item => item === user._id );
	return(
		<Row key={user._id}>
			<Cell type="large">{user.name}</Cell>
			{/* <Cell type="large">{user.email}</Cell> */}
			<Cell type="medium">
				{ displayRole(user.role) }
			</Cell>
			<Cell type="small"> 
				<input 
					name="head" 
					type="radio"
					checked={ user._id === head } 
					onChange={ onChange }
					value={ user._id }
					disabled={alreadyUsed}
				/> 
			</Cell>
			<Cell type="small"> 
				<input 
					name="members" 
					type="checkbox" 
					checked={ members.some( memb => memb === user._id)}
					onChange={ onChange }
					value={ user._id }
					disabled={ alreadyUsed && !members.some( memb => memb === user._id) }
				/> 
			</Cell>
			<Cell type="small"> 
				<input 
					name="secretary" 
					type="radio" 
					checked={ user._id === secretary}
					onChange={ onChange }
					value={ user._id }
					disabled={alreadyUsed}
				/> 
			</Cell>
		</Row>	
	)
});


class CreateNegotiation extends React.Component {
	state = {
		type: "offline",
		head: '',
		members: [],
		secretary: null,

		query: '',
		filtredList: [],
	}

	componentDidMount(){
		this.props.getUsers();
	}

	displayRole = (role) => {
		const obj = roles.find( item => item.title === role)
		return obj.name
	}
	
	createProcedure = () => {
		const { application } = this.props;
		const { type, head, members, secretary } = this.state;
		this.props.createProcedure( application._id, type, head, members, secretary);
	}

	changeType = ( e ) => {
		this.setState({
			type: e.target.value
		})
	}

	changeUsers = ( e ) => {
		const name = e.target.name;
		let value = e.target.value;

		if( name === "members"){

			let alreadyAdded = this.state.members.some( item => item === value );
			if( alreadyAdded ){
				value = [...this.state.members].filter( item => item !== value );
			} else {
				if( this.state.members.length < 3){
					value = [...this.state.members, value ];
				} else {
					value = this.state.members;
				}
			}
			this.setState({
				[ name ] : value
			});
			
		} else {
			this.setState({
				[ name ] : value
			})
		}
	}

	findLocalUser = ( e ) => {
		let query = e.target.value.toLowerCase();
		let filtred = this.props.users.filter( user => user.name.toLowerCase().indexOf( query ) !== -1 );
		this.setState({ 
			filtredList: filtred,
			query
		});
	}


	render() {
		const { users, users_loaded, create_req } = this.props
		const { type, head, secretary, members, query, filtredList } = this.state;
		const { changeType, changeUsers, findLocalUser, displayRole, createProcedure } = this;
		if( !users_loaded ){
			return (
				<Loader />
			);
		}
		console.log('create_req', create_req);
		let filtredUsers = filtredList.length !== 0 ? filtredList : users;

		return (
			<div className="project-app-discuss">
			<div className="expert-solution-form project-app-reporting-wrap">
				<div className="project-app-reporting-wrap-radio">
				<div className="expert-solution-form-text">Проводиться:</div>
					<label className="expert-solution-form-label-wrap">
						<input
							className="expert-solution-form-input"
							type="radio"
							value="online"
							name="procedure_type"
							checked={ type === "online"}
							onChange={changeType}
						/>
						<span className="expert-solution-form-label">Онлайн</span>
					</label>
					<label className="expert-solution-form-label-wrap">
						<input
							className="expert-solution-form-input"
							type="radio"
							value="offline"
							name="procedure_type"
							checked={ type === "offline"}
							onChange={changeType}
						/>
						<span className="expert-solution-form-label">Офлайн</span>
					</label>
					<button 
						className={ create_req ? "application-expert_apply-btn disabled" : "application-expert_apply-btn" }
						onClick={createProcedure}
					> 
						{
							create_req ? "Створюємо процедуру..." : "Створити процедуру"
						}
					</button>
				</div>
			</div>

			{
				type === 'offline' && (
					<>
						<div className="project-app-discuss-search">
							<Input 
								onChange={findLocalUser}
								wrapClass="project-app-discuss-search-input" 
								type="search" 
								value={query}
							/>
						</div>
						<Table>
							<Row type="head">
								<Cell type="large"> Користувач </Cell>
								{/* <Cell type="large"> Пошта </Cell> */}
								<Cell type="medium"> Роль </Cell>
								<Cell type="small"> Голова комісії </Cell>
								<Cell type="small"> Члени комісії </Cell>
								<Cell type="small"> Секретар </Cell>
							</Row>
							{
								filtredUsers.map( user => {
									return (
										<UserItem 
											key={user._id}
											user={user}
											onChange={changeUsers}
											displayRole={displayRole}
											head={head}
											secretary={secretary}
											members={members}

										/>
									);
								})
							}
						</Table>
					</>
				)
			}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	application: state.applications.info.data,
	users_loaded: state.users.allUsers.loaded,
	create_req: state.matching.negotiation.create_req,
	users: state.users.allUsers.data.filter( user => user.role !== 'unassigned' && user.role !== 'expert')
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	getUsers: () => {
		dispatch( getAllUsers() );
	},
	createProcedure: ( application, type, head, members, secretary ) => {
		dispatch( createNegotiationProcedure( application, type, head, members, secretary, ownProps.history ) );
	}
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateNegotiation);