import React from 'react'

export const RadioTextData = ({ value }) => (
    <>
        <div>
            <b>{ value.status === "0" ? "Ні" : "Так"}</b>
        </div>
        {
            value.status === "1" && (
                <div style={{ whiteSpace: "pre-wrap"}}>
                    {value.value}
                </div>
            )
        }
    </>
)

