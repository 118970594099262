import {
    GET_DIRECTION_APPS_REQ,
    GET_DIRECTION_APPS_RES
} from '../../actions'

const appsInitialState = {
    error: null,
    apps: [],
    counter: 0,
    loaded: false
}

const tech_review_reducer = ( state = appsInitialState, action) => {
    switch( action.type ){
        case GET_DIRECTION_APPS_REQ:
            return {
                ...state,
                loaded: false,
            }

        case GET_DIRECTION_APPS_RES:
            return {
                ...state,
                apps: action.payload,
                counter: action.counter,
                loaded: true,
            }

        default:
            return state
    }
}

export default tech_review_reducer;