import { createStore, applyMiddleware, compose } from "redux"

import reducer from '../reducers'

/*
    Middlewares:
*/
import thunk from 'redux-thunk'


import { loadState, saveState } from '../helpers/storeLocalData';

/*
    Dev-tools:
*/
const composeEnhancers = typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose
const middleware = applyMiddleware( thunk )

const persistedState = loadState('state');
let store
if( persistedState !== undefined ){
    store = createStore( reducer, persistedState, composeEnhancers(middleware) )
} else {
    store = createStore( reducer, composeEnhancers(middleware) )
}

store.subscribe(() => {
    let stored = store.getState();
    if( stored.auth.token !== null){
        saveState({
            auth: {
                token: stored.auth.token,
                user: stored.auth.user
            }
        }, 'state')
    }
});

export default store