import {
  DISPLAY_NOTIFY,
  CLEAR_NOTIFY,
  HIDE_NOTIFY
} from '../../actions'

const contestsInitialState = {
  list: []
}

const notify = ( state = contestsInitialState, action) => {
  switch( action.type ){
    case DISPLAY_NOTIFY:
      return {
        list: [ ...state.list, action.payload ]
      }
    case HIDE_NOTIFY:

      let newData = [...state.list];
      newData.shift();

      return {
        list: newData
      }
    case CLEAR_NOTIFY:
      return state
    default:
      return state
  }
}

export default notify