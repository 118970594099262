import React from 'react'
import { pdf_icon, xls_icon } from './icons';

const DocumentList = ({ documents, except }) => {
    return(
        <div className="expert_documents">
        {
            documents && documents.map( doc => {
                
                const extension = doc.extension.toLowerCase();

                if( except ){
                    if( except.some( item => doc.prefix === item) ){
                        return null;
                    }
                }

                let title = doc.title;

                // Is file additional file to negotiation procedure
                if( title === null ){
                    title = `Додатковий файл (${doc.name})`
                }


                return(
                <a
                className="application__documents-item"
                key={doc.absolute_path}
                href={doc.absolute_path}
                rel="noopener noreferrer"
                target="_blank"
                >
                    <img
                    className="application__documents-icon"
                    src={ extension === 'pdf' ? pdf_icon : xls_icon}
                    alt="format icon"
                    />
                    { title }
                </a>
                )
            })
        }
        </div>
    )
};


export default DocumentList;