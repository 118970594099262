import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import classnames from 'classnames';
import moment from 'moment';

import { Loader, USwitch, DocumentLink, Signer } from '../../../library';

import { 
    getApplicationDocuments,
    createValidationProtocol,
    changeApplicationStatus,
    clearDocumentsData,
} from '../../../../actions';


const STATIC_QUESTIONS = [
    {
        name: "fields",
        label: "Поля заявки",
        left: "З помилкою",
        right: "Вірні"
    },
    {
        name: "lot_priorities",
        label: "Cпівпадіння пріоритетам лоту",
        left: "Не співпадає",
        right: "Співпадає"
    },
    {
        name: "estimate_compliance",
        label: "Відповідність кошторису",
        left: "Не відповідає",
        right: "Відповідає"
    }
];

const STATUS_DICTIONARY = {
    valid_single: "Вірний",
    invalid_single: "З помилкою",
    valid_multiple: "Вірні",
    invalid_multiple: "З помилкою"
}

const VALIDITY_MESSAGES = {
    valid: "Буде створено протокол з позитивним результатом",
    invalid: "Буде створено протокол з негативним результатом",
    direction: "Заявка буде відправлена на дирекцію"
};

const getValidityMessage = ( valid, direction ) => {
    if( direction ){
        return VALIDITY_MESSAGES.direction;
    }   
    return VALIDITY_MESSAGES[ valid ? 'valid' : 'invalid'];
}

const AppDocuments = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const textarea = useRef(null);
    const applicationData = useSelector( state => state.applications.info );
    
    const [ data, setData ] = useState({
        docs: [],
		fields: false,
		lot_priorities: false,
		estimate_compliance: false,
		need_direction: false,
        validity: false,
    });

    const staticFileldsHandler = ({ target }) => {
        let value = target.value;
        if( target.type === "checkbox"){
            value = target.checked;
        }
        setData({ ...data,[ target.name ] : value });
    }
    
    const documentsHandler = ({ target: { name }}) => {
        let res = data.docs.map( doc => {
            if( doc.prefix === name ){
                doc.validation = !doc.validation;
            }
            return doc;
        });
        setData({ ...data, docs: res });
    }

    const mergeDocumentsWithFiles = ( documents, files ) => {  
        const result = documents.map( contest_document => {

            const file = files.find( file => file.prefix === contest_document.prefix );
            if( file ){
                contest_document.validation = file.validation;
            } else {
                contest_document.validation = false;
            }

            return contest_document;
        });
        setData({ ...data, docs : result });
    }

    const CreateProtocol = ( e ) => {
        e.preventDefault();
        if( textarea.current.value === ""){
            textarea.current.focus();
            return;
        }

        const { docs, fields, lot_priorities, estimate_compliance, need_direction } = data;
        dispatch( createValidationProtocol( params.id, docs, fields, textarea.current.value, need_direction, lot_priorities, estimate_compliance ) );
    }

    const FinalizeApplication = ( e ) => {
        dispatch( changeApplicationStatus(  params.id , 'tech_review') );
    }

    const checkValidity = () => {
        if( data.need_direction ){
            return 'direction';
        }
        const isDataValid = [ data.fields, data.lot_priorities, data.estimate_compliance ].every( item => item === true );
        const isDocumentsValid = data.docs.every( document => document.validation === true);
        return isDataValid && isDocumentsValid;
    }

    useEffect(() => {
        console.log( applicationData.loaded, applicationData.documents  );
        if( applicationData.loaded ){
            if( applicationData.documents && data.docs.length === 0 ){
                mergeDocumentsWithFiles( applicationData.documents, applicationData.data.tech_review.validated_documents );
            }

            const application = applicationData.data;
            if( application.tech_review.status !== 0 ){
                setData({
                    ...data,
                    fields: application.tech_review.validated_fields,
                    estimate_compliance: application.tech_review.estimate_compliance,
                    lot_priorities: application.tech_review.lot_priorities,
                    need_direction: application.tech_review.lot_priorities,
                    docs: application.tech_review.validated_documents
                });
            }
        }
    }, [ applicationData.loaded ])

    if( !applicationData.loaded ){
        return <Loader />
    }
    
    const application = applicationData.data;
    let validity = checkValidity();

    return (
        <div key={data.sended}>
            <div className="adoc__container">
                <div className="adoc__documents">
                    <h2 className="adoc__subtitle"> Валідація: </h2>
                    <ul className="adoc__list">
                    {
                        STATIC_QUESTIONS.map( (question, index) => {
                            if( data[question.name] === undefined ){
                                return null;
                            }
                            return(
                                <li key={index} className="adoc__item">
                                    <div className="adoc__item--label">
                                        { question.label }
                                    </div>
                                    <div className="adoc__item--validation">
                                        <USwitch
                                            name={question.name}
                                            handler={staticFileldsHandler}
                                            value={data[question.name]}
                                            left={question.left}
                                            right={question.right}
                                            disabled={ application.tech_review.status !== 0 }
                                        />
                                    </div>
                                </li>
                            );
                        })
                    }
                    {
                        applicationData.documents.map( ({ prefix, title, path, absolute_path, validation }, index) => {
                            return (
                                <li key={index} className="adoc__item">
                                    <div className="adoc__item--label">
                                        <a href={absolute_path} className="link" target="_blank" rel="noopener noreferrer">
                                            { title }
                                        </a>
                                    </div>
                                    <div className="adoc__item--validation">
                                        <USwitch
                                            name={prefix}
                                            handler={documentsHandler}
                                            value={validation}
                                            left={STATUS_DICTIONARY.invalid_single}
                                            right={STATUS_DICTIONARY.valid_single}
                                            disabled={ application.tech_review.status !== 0 }
                                        />
                                    </div>
                                </li>
                            );
                        })
                    }
                    </ul>
                </div>
                <form className="adoc__form" onSubmit={ application.tech_review.status === 0 ? CreateProtocol : () => {} }>
                    <h2> Рішення </h2>
                    <label className="adoc__checkbox">
                        <input 
                            type="checkbox" 
                            name="need_direction" 
                            onClick={staticFileldsHandler}
                            disabled={ application.tech_review.status !== 0 }

                            defaultChecked={  application.tech_review.status === -3 }
                        />
                        <div>
                            Заявка потребує рішення дирекції
                        </div>
                    </label>

                    <div>
                        Коментар <i>(Буде надіслано заявнику)</i>
                    </div>

                    {
                        application.tech_review.status === 0 ?
                        (
                            <textarea 
                                className="adoc__textarea"
                                rows="10" 
                                placeholder="Рішення технічного відбору"
                                ref={textarea}
                                defaultValue={applicationData.validate_message}
                            ></textarea>
                        ):
                        (
                            <p className="adoc__comment">
                                {application.tech_review.validate_message}
                                {application.tech_review.direction_message}
                            </p>
                        )
                    }
                    
                    <div className="adoc__footer">
                        {
                            application.tech_review.status === 2 || application.tech_review.status === -2 || application.tech_review.status === -3 ?
                            <div className="adoc__result">
                                <span>
                                    Заявку було провалідовано <i>{ moment( application.tech_review.user.updated_at ).format('lll') }</i> 
                                    <br />
                                    Провів валідацію: <b>{ application.tech_review.user.name }</b>
                                    
                                </span>
                                <a href={ applicationData.tech_review_protocol.fileUrl } className="link" target="_blank" rel="noopener noreferrer">
                                    Протокол
                                </a>
                            </div>
                            :
                            (
                                application.tech_review.status === 0 ? 
                                (
                                    <>
                                        <button 
                                            type="submit"
                                            className={ classnames('adoc__btn', { valid: validity }, { invalid: !validity }, { direction: data.need_direction }) }
                                        >
                                            Створити протокол заявки
                                        </button>
                                    
                                        <div className={ classnames('adoc__validity', { valid: validity }, { invalid: !validity }, { direction: data.need_direction }) }>
                                            { getValidityMessage( validity, data.need_direction ) }
                                        </div>
                                    </>
                                ) : (
                                    <Signer 
                                        ids={[ applicationData.tech_review_protocol.sign_id ]}
                                        statusChangerCallback={FinalizeApplication}
                                    />
                                )
                            )
                        }
                    </div>
                </form>
            </div>
        </div>
    );
}



export default AppDocuments;