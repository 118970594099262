import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import CreateEditor from '../editor/config';
import { VaribalesConfigurator } from '../editor/variables_configurator';

import { updateDocumentTemplate, getSingleDocumentTemplate, deleteDocumentTemplate, notify } from '../../../../actions';
import { Breadcrumbs } from '../../../library';

export const EditTemplate = () => {
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const inputNode = useRef(null);
    const editorNode = useRef(null);
    const [ editorValue, setEditorValue ] = useState({});
    const [ editorInited, setEditorInited ] = useState(false);

    const [ errorMessage, setErrorMessage ] = useState("");
    
    const [ documentValues, setDocumentValue ] = useState({ 
        title: "", 
        comment: "",
        variables: [],
        template: {}
    });

    const [ variables, setVariables ] = useState([
        {
            name: "sum_number",
            mode: "finance",
            label: "Сума гранту числом",
        },
        {
            name: "sum_text",
            mode: "finance",
            label: "Сума гранту прописом",
        },
        {
            name: "scholarship_fin_string",
            mode: "finance",
            label: "Сума стипендії та порядок розрахунків. Умови",
        },
        {
            name: "scholarship_fin_total",
            mode: "finance",
            label: "Сума стипендії та порядок розрахунків. Сума Стипендії",
        },
        {
            name: "full_applicant_name",
            mode: "applicant",
            label: "ПІБ у називному відмінку (тільки стипендії)"
        },
    ]);

    useEffect( () => {

        getSingleDocumentTemplate( params.id )
            .then( res => {
                const { title, comment, template, variables } = res.data.data.template;
                setDocumentValue({
                    title,
                    comment,
                });
                
                setVariables( variables );

                if( !editorInited ){
                    setEditorInited( true );
                    setEditorValue( template );
                    CreateEditor( editorNode.current, template, setEditorValue )
                }

            })
            .catch( err => {
                if( err.response.status === 404 ||  err.response.status === 500  ){
                    dispatch( notify( err.response.data.message, 'error' ) );
                    history.push('/documents/templates');
                }
            })
    }, [ editorInited, editorValue ])


    const handleDocumentValues = ( e ) => {
        let { name, value } = e.target;
        setDocumentValue({
            ...documentValues,
            [name]: value
        });
    }

    const deleteTemplate = () => {

        if( window.confirm('Ви впевнені, що хочете видалити цей шаблон?') ){

            deleteDocumentTemplate( params.id )
                .then( () => {
                    dispatch( notify('Шаблон видалено', 'success' ) );
                    history.push('/documents/templates');
                })   
        }
    }


    const updateTemplate = () => {
        if( documentValues.title === ""){
            setErrorMessage("Не вказано назву документа");
            return;
        }

        setErrorMessage("");    
        
        dispatch( 
            updateDocumentTemplate(
                params.id,    
                { 
                    title: documentValues.title,
                    comment: documentValues.comment,
                    template: typeof(editorValue) === "string" ? JSON.parse(editorValue) : editorValue,
                    variables: variables
                }, 
                history
            )
        );
        console.log('[Data to store]', editorValue, variables, documentValues );
    }
    

    return(
        <>  

            <Breadcrumbs items={[
                {name: 'Документи', link: `/documents` },
                {name: 'Шаблони', link: `/documents/templates` },
                {name: 'Редагування шаблону', link: `#` },
            ]}/>

            <header className="documents__header">
                <h1> Редагування шаблону {documentValues.title} </h1>

                <div className="documents__actions">
                    <button className="documents__delete" onClick={deleteTemplate}> Видалити шаблон </button>
                    <button className="documents__store" onClick={updateTemplate}> Оновити шаблон </button>
                </div>
            </header>

            {
                errorMessage && (
                    <div className="documents__error">
                        { errorMessage }
                    </div>
                ) 
            }

            <div className="documents__row documents__row--vertacal">
                <label className="documents__label">
                    <p className="documents__labelText"> Назва документу <span className="required">*</span></p>
                    <input 
                        className="documents__variablesInput" 
                        name="title"
                        value={documentValues.title}
                        onChange={handleDocumentValues}  
                    />
                </label>

                <label className="documents__label">
                    <p className="documents__labelText"> Опис/нотатка до документу (Не обовʼязково) </p>
                    <textarea 
                        className="documents__variablesInput"  
                        rows="5" 
                        name="comment"   
                        value={documentValues.comment}
                        onChange={handleDocumentValues}  
                    />
                </label>
            </div>

            <textarea hidden style={{ width: "100%", fontSize: "15px"}} ref={inputNode} rows="5" value={JSON.stringify(editorValue)} onChange={ e => {} } />

            <div className="documents__wrapper">
                <div className="documents__editor">
                    <div ref={editorNode}></div>
                </div>  
                <VaribalesConfigurator
                    variables={variables} 
                    setVariables={setVariables}
                    setErrorMessage={setErrorMessage}
                />
            </div>

        </>
    );

}
