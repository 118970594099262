
import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import { Button, Input } from '../../library'
import { restorePassHash, restorePassClean } from '../../../actions'

class RestoreHash extends Component {
  state = {
    password: ''
  }

  handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value

    this.props.restoreClean()

    this.setState({
      [name]: value
    })
  }

  componentDidMount() {
    this.props.restoreClean()
  }

  submitForm = (e) => {
    e.preventDefault()
    const { password } = this.state
    const hash = this.props.match.params.hash

    this.props.recoverPass(password, hash)
  }

  render(){
    const { done, loading, error } = this.props
    const { handleChange, submitForm } = this
    const { password } = this.state

    if (done) {
      return (<Redirect to="/restore/done"/>)
    } else {
      return(
        <div className="login-container">

          <p className="login-recovery-title">Відновлення паролю</p>
          <p className="login-recovery-subtitle">
            Введіть ваш новий пароль i запам'ятайте його
          </p>
          <br/>
          <form
            className="login-form"
            onSubmit={submitForm}
          >
            <Input
              type="password"
              placeholder="Введiть пароль"
              name="password"
              onChange={handleChange}
              value={password}
              required
              />
            {
              error && (
                  <div className="login-label">
                    <div className="login-label-text"></div>
                    <div className="login-label-input text-red">
                      { error }
                    </div>
                  </div>
                )
            }

            <div className="login-label">
              <div className="login-label-text"></div>
              <div className="login-label-input">
                <Button
                  text="Зберегти пароль"
                  ltext="Редагування паролю"
                  loading={loading}
                  size="large"
                />
              </div>
            </div>
          </form>
          <div className="login-footer">
            <button className="login-footer-info">Український Культурний Фонд</button>
            <Link className="login-footer-recovery" to="/">Форма входу у систему</Link>
          </div>
        </div>
      )
    }
  }
}

const mapStateToProps = (state) => ({
  done: state.users.restore.done,
  error: state.users.restore.error,
  loading: state.users.restore.loading
})

const mapDispatchToProps = (dispatch) => ({
  recoverPass: ( password, hash ) => {
    dispatch( restorePassHash(password, hash) )
  },
  restoreClean: () => {
    dispatch( restorePassClean() )
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(RestoreHash)