import React, { useEffect } from 'react'
import moment from 'moment';
import { Link, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Layout, Breadcrumbs, Table, Row, Cell, Loader } from '../../../library';
import { getExpertsBySeason, getRootSeasons, getSingleField } from '../../../../actions';

import { getLabelForValue } from '../../../../helpers/getLabelForValue';

export const SingleSeason = () => {

    const { season_id } = useParams();
    const { loaded, data } = useSelector( state => state.expert.seasons );
    const seasons = useSelector( state => state.programs.seasons );
    const { culture, culture_loaded } = useSelector( state => state.common.fields );
    const dispatch = useDispatch();

    useEffect( () => {
        dispatch( getExpertsBySeason( season_id ) );
        dispatch( getRootSeasons() );
        dispatch( getSingleField('culture') );

    }, [])

    if( !loaded ){
        return <Loader />
    }

    const { season, count, experts } = data;

    console.log('[season_id]', season_id );

    return(
        <Layout>
            <Breadcrumbs
                items={[
                    { name: "Експертний відділ", link: `/ed/` },
                    { name: "Експерти", link: `/ed/experts`},
                    { name: "Сезони", link: `/ed/experts/seasons`},
                    { name: season.title, link: `#`}
                ]}
            />
            <h1 className="page-title"> Експерти які були зареєстовані\оновлені у сезоні «{season.title}» </h1>
            
            <Table>
                <Row type="head">
                    <Cell type="large"> Експерт </Cell>
                    <Cell type="medium"> Оновлено  </Cell>
                    <Cell type="medium"> Сектор  </Cell>
                    <Cell type="medium"> Прогарма </Cell>
                </Row>

                { 
                    experts.map( (user) => {

                    return(
                        <Row 
                            key={ user._id }   
                        >
                            <Cell type="large"> 
                                <Link className="nav_link" to={`/ed/experts/${user._id}`}  >
                                    { user.name }
                                </Link>
                            </Cell>
                            <Cell type="medium">{ moment(user.updated_at).format('lll') }</Cell>
                            <Cell type="medium">
                                {
                                    user.fields && (
                                        <>
                                            { culture_loaded && getLabelForValue( user.fields.expected_cultural_sector, 'culture', culture.options.options )  }
                                        </>
                                    )
                                }
                            </Cell>
                            <Cell type="medium">
                                {
                                    user.fields && (
                                        <>
                                            { seasons.loaded && getLabelForValue( user.fields.expected_programs_main, 'program', seasons.data )  }
                                        </>
                                    )
                                }
                            </Cell>                                      
                        </Row>
                    )
                })}
                
            </Table>


        </Layout>
    );
}


export default SingleSeason;