import React, { Component } from 'react'
import { connect } from 'react-redux';

import { makeCriticalErrorLog, clearErrorLog } from '../../../actions';
import { Layout } from '../../library';
import Sidebar from '../SideBar';

import './GlobalErrorPage.scss';
import soop from './soop_inner.svg';


class GlobalErrorBoundary extends Component {
    state = { 
        hasError: false 
    };
    
    static getDerivedStateFromError(error) {
        // Обновить состояние с тем, чтобы следующий рендер показал запасной UI.
        return { 
            hasError: true,
            message: error
        };
    }
    
    componentWillUnmount(){
        this.props.clearLog();
    }
    
    componentDidCatch(error, errorInfo) {

        if( process.env.NODE_ENV === 'production' ){
            // this.props.makeLog( error, errorInfo );
        }
    }
    
    render() {
        if (this.state.hasError) {
            // Можно отрендерить запасной UI произвольного вида
            return (
                <>
                    <Sidebar/> 
                    <Layout>
                        <h1 className="error_title"> Oops... Перепрошуємо, здається сталась помилка! </h1>
                        <p className="error_message">
                            Якщо ви бачите цю сторінку - значить в системі сталась помилка і тепер наші розробники про неї знають!
                            Оновіть цю сторінку та сробуйте ще.
                        </p>

                        <div className="error__soup">
                            <img src={soop} alt="soop" className="error__soup-body"/>
                        </div>
                    </Layout>
                </>
            )
        }
        return this.props.children; 
    }
}

const mapStateToProps = (state) => ({
    loggedError: state.common.error
})

const mapDispatchToProps = ( dispatch, ownProps ) => ({
    makeLog: ( error, errorInfo ) => {
        dispatch( makeCriticalErrorLog( error, errorInfo, window.location.href ) );
    },
    clearLog: () => {
        dispatch( clearErrorLog() );
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(GlobalErrorBoundary);
