import {
    GET_SINGLE_GROUP_REQ,
    GET_SINGLE_GROUP_RES,
	GET_SINGLE_GROUP_ERR,
	UPDATE_SINGE_GROUP_REQ,
	UPDATE_SINGE_GROUP_RES
} from '../../../actions';

const usersInitialState = {
    errors: [],
    loading: false,
    loaded: false,
	data: null,
	
	create_req: false,
	message: ''
}

const single_reducer = ( state = usersInitialState, action) => {
    switch( action.type ){

		case UPDATE_SINGE_GROUP_REQ:
			return {
				...state,
				create_req: true
			}

		case UPDATE_SINGE_GROUP_RES:
			return {
				...state,
				create_req: false
			}


		case GET_SINGLE_GROUP_REQ:
			return {
				...state,
				loaded: false,
				loading: true
			}

		case GET_SINGLE_GROUP_RES:
			return {
				...state,
				loading: false,
				loaded: true,
				data: action.payload
			}

		case GET_SINGLE_GROUP_ERR:
			return {
				...state,
				error: action.errors
			}



		default:
			return state
		}
}

export default single_reducer;