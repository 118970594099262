import React, { Component, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Button, StatusHandler } from '../../../library';

import { finalize_expert_part, save_final_comment } from '../../../../actions';


const ExpertItemReview = ({ number, item, isRemoved = false }) => {

    let initState = false;
    if( number === 1){
        initState = true;
    }
    const { review, score } = item;
    const [ isOpen, changeOpen ] = useState( initState );

    const openData = () => {
        console.log('isOPne', isOpen );
        changeOpen( !isOpen );
    }
    
    // const isRemoved = number === 1 || number === 5;
    // const isRemoved = false;

    return(
        <div className={ isRemoved ? "finExp__item removed" : "finExp__item"}>
            <header className="finExp__itemHeader" onClick={openData}>
                <h3 className="finExp__itemTitle"> 
                { number }. {review.expert.name} ({score}) 
                {
                    isRemoved && <span className="finExp__norank"> - Не враховується</span>
                }
                </h3>
                <button type="button">
                    { isOpen ? 'Закрити' : 'Відкрити'}
                </button>
            </header>
            {
                isOpen && (
                    <p className="finExp__review">{ item.review.expert_message}</p>
                )
            }
        </div>
    )
}

const ExpertAppFinal = () => {


    let { id } = useParams();
    const dispatch = useDispatch();
    const data = useSelector( state => state.applications.info.data );
    const reviews = useSelector( state => state.applications.info.reviews.active );

    const [ message, setMessage ] = useState( data.expert_review.final_message );
    
    const changeHandler = e => {
        setMessage( e.target.value );
    }

    const finSuccess = () => {
        dispatch( finalize_expert_part( id, 3 ) );
    }

    const finFail = () => {
        dispatch( finalize_expert_part( id, -2 ) );
    }

    const saveComment = () => {
        dispatch( save_final_comment( id, message ) )
    }

    let total_score = 0;
    if( reviews.length === 3 ){
        total_score = reviews.reduce( ( sum, current, index ) => {
            return sum + current.score;
        }, 0 );
    } else {
        total_score = reviews.reduce( ( sum, current, index ) => {
            // Uncomment code below for 5 experts:
            if( index === 0 || index === 4){
                return sum;
            }
            return sum + current.score;
        }, 0 );
    }

    console.log('r', reviews, total_score);

    return(
        <>
            <h1> 
                Фіналізація експертної частини заявки (
                <StatusHandler
                    type="expert_division_application"
                    statusId={ data.expert_review.status}
                />)
            </h1>

            <div className="finExp">

                <div className="finExp__body">

                    <div className="finExp__message">
                        <div className="direction__label">
                            <div className="finExp__itemHeader">
                                <div className="direction__message"> Загальний зведений коментар </div>
                                <button className="direction__btn" onClick={saveComment}> Зберегти коментар </button>
                            </div>
                            <textarea 
                                rows="16"
                                className="application__textarea"
                                name="text_protocol_short"
                                value={message}
                                onChange={changeHandler}
                                onBlur={saveComment}
                            ></textarea>
                        </div>
                        <footer className="buttons__container">
                            <Button 
                                color="green"
                                text="Фіналізувати заявку"
                                handler={finSuccess}
                                disabled={data.expert_review.status !== 1 && data.expert_review.status !== 0 }
                            />
                        </footer>
                    </div>

                    <div className="finExp__experts">
                        {
                            reviews.map( (item, index) => {
                                return(
                                    <ExpertItemReview 
                                        number={index + 1}
                                        key={item.review._id}
                                        item={item}
                                        isRemoved={ reviews.length !== 3 && ( index === 0 || index === 4 ) }
                                    />
                                )
                            })
                        }
                        <hr />
                        <div>
                            Результат заявки:  <b>{ total_score }</b>
                        </div>
                    </div>


                </div>

            </div>
        </>
    )

}

export default ExpertAppFinal;