import axios from '../../../helpers/private.axios'

import { notify } from '../../common/notify.actions';

export const UPDATE_DOCUMENT_TEMPLATE_REQ = "UPDATE_DOCUMENT_TEMPLATE_REQ";

export const updateDocumentTemplate  = ( id, data, history ) => (dispatch) => {


    dispatch({ type: UPDATE_DOCUMENT_TEMPLATE_REQ });

    axios.post(`/api/documents/templates/${id}`, data )
        .then ( res => {
            console.log('res', res );
            dispatch( notify('Шаблон оновлено','success') );
            // history.push(`/documents/templates`);
        })

    console.log( data );

}