import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { validateSlug } from '../../../../helpers/slug_validator';

import { getAllFields } from '../../../../actions';


const INIT_STATE_VARIABLE = {
    name: "",
    mode: "generate",
    field: "",
    label: "",
};


export const VaribalesConfigurator = ({ variables, setVariables, setErrorMessage }) => {
    const dispatch = useDispatch();
    const { fieldsAndGroups, fields, count } = useSelector( state => state.common.fields.all );


    const [ variable, setVariable ] = useState(INIT_STATE_VARIABLE) 


    useEffect(() => {
        dispatch( getAllFields() );
    }, [])


    const handleVariable = ( e ) => {
        let { name, value } = e.target;

        if( name === "name"){
            value = validateSlug( value );
        }

        if( name === "field"){
            let field = getFieldName( value );
            setVariable({
                ...variable,
                label: field.label,
                [name]: value
            });
            return;
        }

        setVariable({
            ...variable,
            [name]: value
        });
    }

    const getFieldName = ( name ) => {
        let field = fields.find( item => {
            return item.name === name;
        } );

        // console.log( field );
        if( !field ){
            return {
                label: "Поле з таким неймом не знайдене",
            };
        }
        return field;
    }

    const addNewVariable = () => {

        if( variable.name === ""){

            setErrorMessage("Назва змінної обовязкова");
            return;
        }

        setErrorMessage("");
        setVariables([ ...variables, variable ]);
        setVariable(INIT_STATE_VARIABLE);
    }

    const removeVariable = (name) => _ => {
        setVariables(
            variables.filter( variable => variable.name !== name )
        );
    }


    return(
        <div className="documents__vairables">
            <h2 className="documents__title"> Додати змінну </h2>
            <div className="documents__row documents__row--vertacal">
                
                <label className="documents__label">
                    <p className="documents__labelText ">
                        { variable.mode === "generate" && "Цей тип змінної буде запропоновано заповнити у момент генерації документа"}
                        { variable.mode === "application" && "Цей тип змінної буде автоматично підтягнути з заявки, до якої генерується документ"}
                    </p>
                    <select 
                        className="documents__variablesInput" 
                        name="mode" 
                        onChange={ handleVariable } 
                        value={variable.mode}    
                    >
                        <option value="generate">Заповнюється при генерації</option>
                        <option value="application">Підтягується з заявки</option>
                    </select>
                </label>
                
                <label className="documents__label">
                    <p className="documents__labelText">
                        Ця змінна буде використовуватись у документі зліва, для того, щоб при генерації додати динамічне значення через 
                        конструкцію <b>[{variable.name}]</b>
                    </p>
                    <input 
                        className="documents__variablesInput" 
                        placeholder='Назва змінної, латинкою без пробілів' 
                        onChange={ handleVariable } 
                        name="name"   
                        value={variable.name}
                    />
                </label>


                { variable.mode === "generate" && (
                    <label className="documents__label">
                        <p className="documents__labelText">
                            Цей текст буде виводитись при генерації документу, щоб підказати що саме сюди заповнювати
                        </p>
                        <input 
                            className="documents__variablesInput" 
                            placeholder='Коментар/Лейбл який буде показуватись при заповнені' 
                            onChange={ handleVariable } 
                            name="label"   
                            value={variable.label}
                        />
                    </label>
                )}

                {
                    variable.mode === "application" && (
                        <select className="documents__variablesInput" onChange={handleVariable} name="field">
                            <option value="">Оберіть повʼязане поле</option>
                            {
                                fieldsAndGroups && fieldsAndGroups.map( group => {
                                    if( group.fields.length > 0 ){
                                        return(
                                            <optgroup label={`${group.title} ${ group.admin_comment ? `(${group.admin_comment})` : '' }`} key={group._id}>
                                                {
                                                    group.fields.map( field => (
                                                        <option 
                                                            key={field._id} 
                                                            value={field.name}
                                                            data-label={field.label}
                                                        >{field.label}</option>
                                                    ))
                                                }
                                            </optgroup>
                                        )
                                    }
                                    return null;
                                })
                            }
                        </select>
                    )
                }

                <button onClick={addNewVariable} className="documents__cta" type="button"> Додати змінну </button>
            </div>

            <h2 className="documents__title"> Змінні документа</h2>
            {
                variables && variables.map( (variable, index) => {

                    return(
                        <div className="documents__row documents__row--variable" key={ variable.name + index }>
                            <div className="documents__cell documents__cell--grouped">
                                <div className="documents__cell documents__cell--name">[{ variable.name }]</div>
                                <div className="documents__cell documents__cell--vertical">
                                    { variable.mode === "generate" && "Заповнюється при генерації"}
                                    { variable.mode === "application" && "Підтягується з заявки"}

                                    { variable.mode === "application" && (
                                        <div className="documents__labelText">
                                            { getFieldName( variable.field ).label }
                                        </div>
                                    )}    
                                    { variable.mode === "generate" && (
                                        <div className="documents__labelText">
                                            { variable.label }
                                        </div>
                                    )} 

                                    { ( variable.mode === "finance" ||  variable.mode === "applicant" )  && (
                                        <div className="documents__labelText">
                                            { variable.label }
                                        </div>
                                    )}
                                    
                                </div>
                            </div>

                            {
                                ( variable.name !== "sum_number" && variable.name !== "sum_text" && variable.name !== "name_in_genitive_case" && variable.name !== "works_by_document" && variable.name !== "scholarship_fin_string" && variable.name !== "scholarship_fin_total" && variable.name !== "full_applicant_name") && (
                                    <div className="documents__cell">
                                        <button className="documents__removeVariable" onClick={ removeVariable( variable.name ) }>Delete</button>
                                    </div>
                                )
                            }

                        </div>
                    )
                })
            }
        </div>
    );

}