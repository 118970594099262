import React from 'react'
import { Switch, Route } from 'react-router-dom'

import AllApp from './RateMain/AllApp';
import App from './App';

import Sidebar from '../../common/SideBar'
import Notify from '../../common/Notify'

const Expert = () => (
  <>
    <Sidebar />
    <Switch>
      <Route exact path="/expert" component={AllApp} />
      <Route path="/expert/:id" component={App} />
    </Switch>
    <Notify />
  </>
)

export default Expert