import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { 
    Layout, Breadcrumbs, Loader,
    StatusHandler, Empty, Skeleton,
    Table, Row, Cell
} from '../../../library';

import Filters from './filter_element';
import HookFilters from './hook_filters';

import NegotiationCircle from './negotiation_status_circle';
import {
    getReportingApplicationsStats,
    clearNegotiationData,
    setNegotiationFilters
} from '../../../../actions';

import { loadState } from '../../../../helpers/storeLocalData';

import debounce from '../../../../helpers/debounce';


class ReportingStats extends Component {

    ref = React.createRef();

    componentDidMount = () => {
        
        const preloadedData = loadState('negotiation_filters');
        this.props.setFiltersAction( preloadedData );
        this.props.getNegotiationApps( false );
        this.deboundedOnload = debounce( this.handleOnScroll, 300 );
        window.addEventListener('scroll',  this.deboundedOnload );
    }   

    componentWillUnmount = () => {
        window.removeEventListener('scroll',  this.deboundedOnload );
        this.props.clearNegotiationData();
    }

    componentDidUpdate = (prevProps) => {
        console.log( JSON.stringify(this.props.filters), JSON.stringify(prevProps.filters) );
        if( JSON.stringify(this.props.filters) !== JSON.stringify(prevProps.filters) && this.props.data.length === 0 ){
            this.props.getNegotiationApps( false );    
        }
    }

    loadMore = () => {
        const { total, offset, loading } = this.props;
        if( offset <= total ){
            if( !loading ){
                this.props.getNegotiationApps(true);
            }
        }
    }

    handleOnScroll = () => {
        const { loading } = this.props;
        if( window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight ){
            if( !loading ){
                this.loadMore();
            }
        }
    }

    render = () => {
        const { loading, loaded, offset, data, total } = this.props;

        return(
            <Layout
                className="projects-division"
                paddingTop="20px"
            >
                <Breadcrumbs items={[
                    { name: 'Погодження', link: '/rp/programs' },
                    { name: `Статистика`, link: `/rp/stats/` },
                ]} />

                {/* <Filters /> */}
                <HookFilters />

                <div ref={this.ref}>
                    <Table>
                        <Row>
                            <Cell type="small"> # </Cell>
                            <Cell type="medium"> Статус оцінки / заявки </Cell>
                            <Cell type="extra-large"> Переговорні процедури </Cell>
                        </Row>

                        {
                            !loaded ? 
                            <Loader/> : 
                            (
                                <>
                                {
                                    data.length > 0 ? 
                                    (
                                        <>
                                            {
                                                data.map( (item, index) => {
                                                    return(
                                                        <Row key={ item.application.spec_id }>
                                                            <Cell type="small">
                                                                <Link to={`/rp/application/${item.application._id}`} className="link">
                                                                    {`${index+1}) `}
                                                                    { item.application.spec_id }
                                                                </Link>
                                                            </Cell>
                                                            <Cell type="medium">
                                                                {
                                                                    item.type === "" && (
                                                                        <StatusHandler 
                                                                            adaptiveType={true} 
                                                                            adaptiveData={item.application.reporting_review} 
                                                                            statusId={item.application.reporting_review.status}
                                                                        />
                                                                    )
                                                                }
                                                                {       
                                                                    item.type === "negotiation" && (
                                                                        <>
                                                                            {
                                                                                item.negotiations.length > 0 && ( item.application.reporting_review.status === 0 || item.application.reporting_review.status === 1 ) ? (
                                                                                    <StatusHandler type="reporting.neotiation" statusId={item.negotiations[ item.negotiations.length - 1 ].status } />
                                                                                ) : (
                                                                                    <StatusHandler type="reporting_review" statusId={item.application.reporting_review.status}/>
                                                                                )
                                                                            }
                                                                        </>
                                                                    )
                                                                }
                                                                {
                                                                    item.type === "direction" && <StatusHandler type="reporting.direction" statusId={item.application.reporting_review.direction.status} />
                                                                }
                                                                {
                                                                    item.type === "supervisory_board" && <StatusHandler type="reporting.supervisory_board" statusId={item.application.reporting_review.supervisory_board.status} />
                                                                }
                                                                {
                                                                    item.type === "grunt_agreement" && <StatusHandler type="reporting.grunt_agreement" statusId={item.application.reporting_review.grunt_agreement.status} />
                                                                }
                                                                {
                                                                    item.type === "reporting" && <StatusHandler type="reporting.reporting" statusId={item.application.reporting_review.reporting.status} />
                                                                }
                                                            </Cell>
                                                            <Cell type="extra-large">
                                                                {   
                                                                    item.negotiations.length > 0 && (
                                                                        <div className="ed-stats__circles-container">
                                                                            {
                                                                                item.negotiations.map( negotiation => {
                                                                                    return(
                                                                                        <NegotiationCircle
                                                                                            key={ negotiation._id  } 
                                                                                            application_id={ item.application._id }
                                                                                            negotiation={ negotiation }

                                                                                        />
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    )
                                                                }
                                                            </Cell>
                                                        </Row>
                                                    )
                                                })
                                            }
                                            {
                                                loading && (
                                                    <>
                                                        <Row>
                                                            <Skeleton />
                                                        </Row>
                                                        <Row>
                                                            <Skeleton />
                                                        </Row>
                                                    </>
                                                )
                                            }
                                        </>
                                    ) : (
                                        <Empty text="Заявок по таким фільтрам немає" />
                                    ) 
                                }

                                </>
                            )
                        }


                        {
                            offset <= total ? 
                            (
                                !loading && ( 
                                    <button className="row__button" onClick={this.loadMore}> Завантажити ще </button> 
                                )
                            ) :
                            <Row>
                                <Cell type="huge"> Ви вже завантажили усі дані по цьому фільтру </Cell>
                            </Row>
                        }

                    </Table>
                </div>
            </Layout>
        )
    }
}

const mapStateToProps = (state) => ({
    filters: state.matching.stats.filters,
    loaded: state.matching.stats.loaded,
    loading: state.matching.stats.loading,
    data: state.matching.stats.data,
    total: state.matching.stats.total_with_filter,
    offset: state.matching.stats.offset,
});

const mapDispatchToProps = (dispatch) => ({
    getNegotiationApps: ( chunk, type ) => {
        dispatch( getReportingApplicationsStats(chunk, type) );
    },
    setFiltersAction: ( filters ) => {
        dispatch( setNegotiationFilters( filters ) );
    },
    clearNegotiationData: () => {
        dispatch( clearNegotiationData() );
    }
})


export default connect(mapStateToProps, mapDispatchToProps)(ReportingStats);