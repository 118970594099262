import axios from '../../helpers/private.axios';
import { notify } from '../common/notify.actions';

export const GET_ALL_APP_NEGOTIATION_REQ = "GET_ALL_APP_NEGOTIATION_REQ";
export const GET_ALL_APP_NEGOTIATION_RES = "GET_ALL_APP_NEGOTIATION_RES";

export const getAllApplicationNegotiations = ( id ) => (dispatch) => {
    dispatch({ type: GET_ALL_APP_NEGOTIATION_REQ });

    axios.get(`/api/application/${id}/procedures`)
        .then( res => {
            dispatch({ type: GET_ALL_APP_NEGOTIATION_RES, payload: res.data.data });
        })
} 


export const CREATE_PROCEDURE_REQ = "CREATE_PROCEDURE_REQ";
export const CREATE_PROCEDURE_RES = "CREATE_PROCEDURE_RES";
export const CREATE_PROCEDURE_ERR = "CREATE_PROCEDURE_ERR";

export const createNegotiationProcedure = ( application, type, head, members, secretary, history ) => dispatch => {
    dispatch({ type: CREATE_PROCEDURE_REQ });
    axios.post(`/api/reporting/procedure/create`, {
        "application_id" : application,
        "type": type,
        "commission": {
            "head": head,
            "secretary": secretary,
            "members": members
        }
    })
    .then( res => {
        dispatch({ type: CREATE_PROCEDURE_RES });
        dispatch( 
            notify( res.data.data.message, "success" )
        );
        history.push(`/rp/application/${application}/procedures/`);
    })
    .catch( error => {
        console.log('err', error.response );
        dispatch( notify( error.response.data.data.message,  "error") );
    });
} 

export const GET_SINGLE_PROCEDURE_REQ = "GET_SINGLE_PROCEDURE_REQ";
export const GET_SINGLE_PROCEDURE_RES = "GET_SINGLE_PROCEDURE_RES";
export const GET_SINGLE_PROCEDURE_ERR = "GET_SINGLE_PROCEDURE_ERR";

export const getSingleNegotiationProcedure = ( prodcedure_id ) => ( dispatch ) => {
    dispatch({ type: GET_SINGLE_PROCEDURE_REQ });
    axios.get(`/api/reporting/procedure/${prodcedure_id}`)
        .then( res => {
            dispatch({
                type: GET_SINGLE_PROCEDURE_RES,
                payload: res.data.data
            });
        });
}   

export const CHANGE_PROCEDURE_STATUS_REQ = "CHANGE_PROCEDURE_STATUS_REQ";
export const CHANGE_PROCEDURE_STATUS_RES = "CHANGE_PROCEDURE_STATUS_RES";
export const CHANGE_PROCEDURE_STATUS_ERR = "CHANGE_PROCEDURE_STATUS_ERR";

export const changeNegotiationProcedureStatus = ( prodcedure_id, status, message ) => (dispatch) => {
    dispatch({ type: CHANGE_PROCEDURE_STATUS_REQ });
    axios.post(`/api/reporting/procedure/${prodcedure_id}/status`, {
        status,
        message
    })
        .then( res => {
            dispatch({ type: CHANGE_PROCEDURE_STATUS_RES  });
            dispatch( getSingleNegotiationProcedure(prodcedure_id) );
        });
}

export const SEND_PROCEDURE_FEEDBACK_REQ = "SEND_PROCEDURE_FEEDBACK_REQ";
export const SEND_PROCEDURE_FEEDBACK_RES = "SEND_PROCEDURE_FEEDBACK_RES";
export const SEND_PROCEDURE_FEEDBACK_ERR = "SEND_PROCEDURE_FEEDBACK_ERR";

export const sendNegotiationRequest = ( prodcedure_id, type, status, message, history ) => (dispatch) => {
    dispatch({ type: SEND_PROCEDURE_FEEDBACK_REQ});

    axios.post(`/api/reporting/procedure/${prodcedure_id}/change`, {
        status,
        message,
        type
    })
    .then( res => {
        dispatch( getSingleNegotiationProcedure(prodcedure_id) );
        dispatch( 
            notify( res.data.data.message, "success" )
        );
        console.log( res.data.data );
        history.push(`/rp/application/${res.data.data.procedure.application_id}/estimate`);
    })
    .catch( error => {
        dispatch( notify( error.response.data.data.message,  "error") );
    });
}


export const UPLOAD_PROCEDURE_FILE_REQ = "UPLOAD_PROCEDURE_FILE_REQ";
export const UPLOAD_PROCEDURE_FILE_RES = "UPLOAD_PROCEDURE_FILE_RES";
export const UPLOAD_PROCEDURE_FILE_ERROR = "UPLOAD_PROCEDURE_FILE_ERROR";

export const uploadNegotiationFile = ( prodcedure_id, file ) => (dispatch) => {

    const formData = new FormData();
    formData.append('file', file );    

    dispatch({ type: UPLOAD_PROCEDURE_FILE_REQ });

    axios.post(`/api/reporting/procedure/${prodcedure_id}/uploading`, formData )
        .then( res => {
            console.log( 'resp', res );
            dispatch( getSingleNegotiationProcedure(prodcedure_id) );
        })
        .catch( err => {
            dispatch( notify( "Сталась помилка при завантаженні файлу",  "error") );
        })

}


export const removeNegotiationFile = ( file_id, prodcedure_id ) => ( dispatch ) => {

    axios.post(`/api/reporting/remove-attach/${file_id}`)
        .then( res => {
            dispatch( getSingleNegotiationProcedure( prodcedure_id) );
        })

}

export const REQ_GENERATE_REPORTING_FILE = "REQ_GENERATE_REPORTING_FILE";
export const RES_GENERATE_REPORTING_FILE = "RES_GENERATE_REPORTING_FILE";

export const generateNegotiationReporting = ( application_id ) => ( dispatch ) => {
    dispatch({ type: REQ_GENERATE_REPORTING_FILE });
    axios.post(`/api/reporting/stats/${application_id}/generate-negotiation-report/`)
        .then( res => {
            console.log('res', res );
            dispatch({ 
                type: RES_GENERATE_REPORTING_FILE,
                payload: res.data.data.file.publicPath
            })
        });
}
