import uniqid from 'uniqid';

export class FinBlock {

	static get toolbox() {
		return {
		title: 'Реквізити',
		icon: `<svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<line x1="0.5" y1="0.5" x2="0.5" y2="15.5" stroke="black" stroke-linecap="round"/>
				<line x1="23.5" y1="0.5" x2="23.5" y2="15.5" stroke="black" stroke-linecap="round"/>
				<line x1="4.5" y1="4.5" x2="19.5" y2="4.5" stroke="black" stroke-linecap="round"/>
				<line x1="4.5" y1="11.5" x2="19.5" y2="11.5" stroke="black" stroke-linecap="round"/>
				<path d="M4.5 11.5V14M19.5 11.5V14" stroke="black"/>
				<path d="M4.5 4.5V2M19.5 4.5V2" stroke="black"/>
				</svg>`
		};
	}

	constructor({ data, api }){

		this.api = api;
		this.data = data;
		this.nodes = {
			wrapper: null,
			body: null
		};

		this.render = this.render.bind(this);
	}


	render(){

		let node;
		if (this.nodes.wrapper) {
			node = this.nodes.wrapper;
		} else {
			this.nodes.wrapper = document.createElement('div');
			node = this.nodes.wrapper;
		}

		const { type = "full" } = this.data;

		let id = uniqid();

		node.innerHTML = `
			<div class="fin_details">
				<div class="fin_details__content">
					<h2>Блок реквізитів.</h2>
					<p>
						Блок реквізитів АВТОМАТИЧНО заповнюється при генерації документа
					</p>

					<div class="fin_details__type">
						<label>
							<input type="radio" name="${id}_fin_type_name" class="_type" value="full" ${ type === "full" ? 'checked' : ""} />
							<div>Повний</div>
						</label>
						<label>
							<input type="radio" name="${id}_fin_type_name" class="_type" value="short" ${ type === "short" ? 'checked' : ""} />
							<div>Короткий</div>
						</label>
					</div>
				</div>
			</div>
		`;

		return node;
	}


	save(node) {
		console.log( node.querySelector('._type:checked') );
		return {
			fin_data: true,
			type: node.querySelector('._type:checked').value
		}
	}
	
}