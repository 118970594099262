import React, { useState, useEffect } from 'react'
import axios from 'axios';

let REGISTER_SITE_URL = "https://ucf.in.ua/api/generate-unassigned-table";

const GenerateRegister = ({ type }) => {

    if( type === "expert"){
        REGISTER_SITE_URL =  "https://ucf.in.ua/api/generate-unassigned-table/expert";
    }

    const [ register, setRegister ] = useState( null );
    const [ isFetching, setFetching ] = useState( false );

    const generateRegister = () => {

        setFetching( true );
        axios.post( REGISTER_SITE_URL )
            .then( res => {
                console.log('res', res.data );
                setRegister( res.data  );
            });
    }

    return(
        <>				
            {
                register === null ? (
                    <button
                        className={`negotiation__button ${ isFetching && 'loading' }`}
                        onClick={ generateRegister }
                    > 
                        {
                            isFetching ? 'Файл генерується...' : 'Згенерувати реєстр'
                        }
                    </button>
                ) : (
                    <a 
                        href={register.url}
                        target="_blank" rel="noopener noreferrer"  
                        className="negotiation__button b-link"   
                    >
                        Завантажити реєстр
                    </a>
                )
            }
        </>
    )

};

export default GenerateRegister;