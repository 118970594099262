import React from 'react'

export const OptionsData = ({ options, value }) => {
    if( !options ){
        return(<b>Options not loaded</b>)
    }
    // console.log('options', options, 'value', value );
    const optionsValues = options.options;
    if( !optionsValues ){
        return(<b>Options not loaded</b>)
    } else {
        if( Array.isArray(value) ){
            // Handle checkbox:
            const findedOpt = optionsValues.filter( opt => value.some( val => val === opt.value ));
            if( findedOpt ){
                return(
                    <ul className="dataSort__list">
                        {
                            findedOpt.map( opt => (<li key={opt.name}><b>{opt.name}</b></li>))
                        }
                    </ul>
                )
            }
        } else {
            // Handle radio and select:
            const findedOpt = optionsValues.find( opt => value === opt.value );
            if( findedOpt ){
                return(
                    <b>
                        {findedOpt.name}
                    </b>
                );
            } 
        }
    }
    return null;
    
}