import React from 'react'
import { Link } from 'react-router-dom'

import './button.scss'
import './spinner.scss'

export const Button = ({ handler, loading, text, ltext, size, color, type, disabled, to, children }) => {
  // color: .green .red .disabled
  // size: .small .medium .large

  if (type === 'link') return (
    <Link to={ to ? to : '/'}>
      <button className="custom-button link">{children}</button>
    </Link>
  )

  return (
    <>
      {
        !loading ?
          <button
            className={`
              custom-button
              ${size ? size : ''} ${color ? color : ''} ${disabled ? 'disabled' : ''}
            `}
            onClick={ disabled ? (e) => { e.preventDefault() } : handler }
            type={ handler ? 'button' : 'submit' }
          >
            { text ? text : "Пiдтветдити" }
          </button> :
          <button
            className={`
              custom-button disabled loading
              ${size ? size : ''}
              ${color ? color : ''}
            `}
            onClick={(e) => { e.preventDefault() }}
            disabled={disabled}
            type={`button`}
          >
            <span>{ ltext ? ltext : "Вiдправляеться" }</span>
            <svg className="spinner" viewBox="0 0 50 50">
              <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
            </svg>
          </button>
      }
    </>
  )
}

export const ButtonWrap = (props) => (
  <div className="custom-button-wrap">
    { props.children }
  </div>
)