import React from 'react'

import './style.scss';

export const USwitch = ({ value, name, handler, disabled = false, left, right }) => {
    return(
        <div className="switch__wrap">
            <div className="switch__message">
                {left}
            </div>
            <label className="switch">
                <input 
                    className="switch__input" 
                    type="checkbox" 
                    onChange={handler}
                    name={name}
                    checked={value}
                    disabled={disabled}
                />
                <span className="switch__slider round"></span>
            </label>
            <div className="switch__message">
                {right}
            </div>
        </div>
    )
}

