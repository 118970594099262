import {
    MAKE_ERROR_LOG_REQ,
    MAKE_ERROR_LOG_RES,
    CLEAR_ERROR_LOG
} from '../../actions'

const errorsInitialState = {
    data: null,
    loading: false,
}

const errors = ( state = errorsInitialState, action) => {
    switch( action.type ){
        case MAKE_ERROR_LOG_REQ:
            return {
                ...state,
                loading: true
            }
        case MAKE_ERROR_LOG_RES:
            return {
                loading: false,
                data: action.payload
            }

        case CLEAR_ERROR_LOG:
            return errorsInitialState

        default:
            return state;
    }
}

export default errors;