import React from 'react'

import './styles/styles.scss'

export const Layout = ({
  className,
  children,
  breadcrumbs,
  header,
  title,
  sidebar,
  notify,
  paddingTop
}) => {
  return (
    <div className={`main-layout ${className}`}>
      { sidebar && <div className="content-header-sidebar">{ sidebar }</div> }
      <div
        className="content"
        style={{
          paddingLeft: !sidebar ? 'calc(240px + 35px)' : '0px',
          // paddingRight: !notify ? 'calc(240px + 35px)' : '0px',
          paddingTop: paddingTop ? paddingTop : '60px'
        }}
      >
        { breadcrumbs }
        { header }
        <div className="content-header">
          { title && <div className="content-header-title">{ title }</div> }
        </div>
        <div className="content-body">
          { children }
        </div>
      </div>
    </div>
  )
}