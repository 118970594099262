
/*
    <option value="reporting.neotiation"> Переговорна процедура </option>
    <option value="reporting.direction"> Рішення дирекції </option>
    <option value="reporting.supervisory_board"> Наглядова рада </option>
    <option value="reporting.grunt_agreement"> Грантова угода </option>
    <option value="reporting.reporting"> Звітність </option>
    <option value="reporting.act"> Акт виконаного проекту </option>

*/

const filterStatuses = {
    negotiation: {
        isActive: true,
        label: "Статус переговорної процедури",
        statuses: [
            {
                id: "",
                label: "Усі статуси"
            },
            {
                id: "-2",
                label: "Потребує доопрацювання, підписана"
            },
            {
                id: "-1",
                label: "Потребує доопрацювання, не підписана"
            },
            {
                id: "0",
                label: "Не розпочата"
            },
            {
                id: "1",
                label: "Триває переговорна процедура"
            },
            {
                id: "2",
                label: "Заявник вніс зміни"
            },
            {
                id: "3",
                label: "Завершена, не підписана"
            },
            {
                id: "4",
                label: "Завершена, підписана"
            },
        ]
    },
    direction: {
        isActive: true,
        label: "Статус дирекції", 
        statuses: [
            {
                id: "0",
                label: "Рішення немає"
            },
            {
                id: "1",
                label: "Затверджено"
            },
            {
                id: "-1",
                label: "Не затверджено"
            },
        ]
    },
    supervisory_board:{
        isActive: true,
        label: "Статус наглядової ради", 
        statuses: [
            {
                id: "0",
                label: "Рішення немає"
            },
            {
                id: "1",
                label: "Затверджено"
            },
            {
                id: "-1",
                label: "Не затверджено"
            },
            {
                id: "2",
                label: "Не потребує рішення"
            }
        ]
    },
    grunt_agreement: {
        isActive: true,
        label: "Статус грантової угоди", 
        statuses: [
            {
                id: "0",
                label: "Робота не розпочата"
            },
            {
                id: "1",
                label: "Завантажена угода"
            },
            {
                id: "2",
                label: "Підписана директором"
            },
            {
                id: "3",
                label: "Підписана заявником"
            },
            {
                id: "4",
                label: "Підписана заявником і директором"
            },
            {
                id: "5",
                label: "Завантажено скановану угоду"
            }
        ]
    },
    additional_agreement: {
        isActive: true,
        label: "Додаткові угоди", 
        statuses: [
            {
                id: '1',
                label: "Завантажено додаткову угоду"
            },
            {
                id: '2',
                label: "Підписана директором"
            },
            {
                id: '3',
                label: "Підписана заявником і директором"
            },
        ]
    },
    reporting: {
        isActive: true,
        label: "Звітність",                    
        statuses: [
            {
                id: '',
                label: 'Оберіть статус'
            },
            {
                id: '0',
                label: 'Звітність ще не розпочата'
            },
            {
                id: '1',
                label: "Розпочато подачу звітності"
            },
            {
                id: '2',
                label: "Надісладно звітність"
            },
            {
                id: '3',
                label: "Звітність прийнято"
            },
        ]
    },
    act: {
        isActive: true,
        label: "Акт виконаного проекту",                    
        statuses: [
            {
                id: '',
                label: 'Оберіть статус'
            },
            {
                id: '0',
                label: 'Акт не завантажено'
            },
            {
                id: '1',
                label: "Акт завантажено"
            },
            {
                id: '2',
                label: "Акт підписано директором"
            },
            {
                id: '3',
                label: "Акт підписано директором і заявником"
            },
        ]
    }
};


const getFilterStatuses = ( type ) => {

    const allowedTypes = [
        'all', 'negotiation', 'direction', 'supervisory_board', 'grunt_agreement', 'additional_agreement', 'reporting', 'act'
    ];
    if( allowedTypes.some( allowed => allowed === type ) ){
        return filterStatuses[ type ];
    } else {
        console.error('You set not a valid reporting status');
    }
}


export default getFilterStatuses;