import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';

import RenderPrograms from '../../Admin/Users/UserList/renderPrograms';
import RenderFields from '../../Admin/Users/UserList/renderFields';

import { updateSingleUser, getSingleField, getRootSeasons } from '../../../../actions';

// const filtredFields = fields.filter( item => item.archive !== "1");
// const filtredCurrent = currentfields.filter( item => {
//   return filtredFields.some( ff => ff.value === item );
// });
// console.log( fields, filtredFields );

// console.log('[f]', filtredCurrent, currentfields  );

const ExpertForm = () => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.user)

    const keywords = useSelector( state => {
        if( state.common.fields.keywords_loaded  ){
            console.log(state.common.fields)
            return state.common.fields.keywords;
        }
        return null;
    });
    const culture = useSelector( state => {
        if( state.common.fields.culture_loaded  ){
            return state.common.fields.culture;
        }
        return null;
    });

    const seasons = useSelector( state => {
        let seasons_data = state.programs.seasons;
        if( seasons_data.loaded ){
            return seasons_data.data.filter( item => item.season.status === "active");
        }
        return null;
    });
    
    
    let filterdKeywordOptions = [];
    if( keywords ){
        filterdKeywordOptions = keywords.options.options.filter( item => item.archive !== "1");
    }


    useEffect(() => {
        dispatch( getSingleField('keywords') );
        dispatch( getSingleField('culture') );
        dispatch( getRootSeasons() );
    }, [])



    console.log(user);
    let initData = {};
    if( user.fields ){
        initData = {
            name: user.fields.name || '',
            phone: user.fields.phone || '',
            address: user.fields.address || '',
            workplace: user.fields.workplace || '',
            position: user.fields.position || '',
            education: user.fields.education || '',
            facebook_link: user.fields.facebook_link || '',
            prof_expirience: user.fields.prof_expirience || false,
            activity_last_years: user.fields.activity_last_years || '',
            project_managment: user.fields.project_managment || '',
            maiking_budget: user.fields.maiking_budget || false,
            budget_info: user.fields.budget_info || '',
            rating_expirience: user.fields.rating_expirience || false,
            rating: user.fields.rating || '',
            work_days: user.fields.work_days || 0,

            expected_cultural_sector: user.fields.expected_cultural_sector || null,
            expected_keywords: user.fields.expected_keywords || [],
            expected_programs_main: user.fields.expected_programs_main || "",
            expected_programs_secondary: user.fields.expected_programs_secondary || []
        };
    } else {
        initData = {
            name: '',
            phone: '',
            address: '',
            workplace:'',
            position: '',
            education: '',
            facebook_link: '',
            prof_expirience: false,
            activity_last_years: '',
            project_managment: '',
            maiking_budget: false,
            budget_info: '',
            rating_expirience: false,
            rating: '',
            work_days: 0,

            expected_cultural_sector: null,
            expected_keywords: [],
            expected_programs_main: "",
            expected_programs_secondary: []
        };
    }

    

    let [ fields, setFields ] = useState(initData);


    const textHandler = ( e ) => {
        let name = e.target.name;
        let value = e.target.value;

        setFields({
            ...fields,
            [name]: value
        });
    }

    const radioHandler = (e) => {
        let name = e.target.name;
        setFields({
            ...fields,
            [name]: !fields[ name ]
        });
    }

    const handleKeywords = (e) => {
        let value = e.target.value

        let filterExpected = fields.expected_keywords.filter( expected => {
            return filterdKeywordOptions.some( ff => ff.value === expected );
        })

        let userKeywords = [ ...filterExpected ];

        let isAlreadyHaveField = userKeywords.some( existed_field => existed_field === value)
    
        if( isAlreadyHaveField ){
            userKeywords = userKeywords.filter( item => item !== value )
        } else {
            if(userKeywords.length >= 5) return
            userKeywords.push(value)
        }
        
        setFields({
            ...fields,
            expected_keywords: userKeywords
        });

    }

    const handleProgramsChange = (e) => {
        let value = e.target.value;
        
        if( e.target.name === "programs_score_ids"){
            let userPrograms = [ ...fields.expected_programs_secondary ]
            let isAlreadyHaveProgram = fields.expected_programs_secondary.some( existed_field => existed_field === value)
            if( isAlreadyHaveProgram ){
                userPrograms = userPrograms.filter( item => item !== value )
            } else {
                userPrograms.push(value)
            }

            setFields({
                ...fields,
                expected_programs_secondary: userPrograms
            });
        }
    
        if( e.target.name === "main_program" ){
            setFields({
                ...fields,
                expected_programs_main: value
            });
        }

    }


    const sendForm = (e) => {
        e.preventDefault();

        dispatch( 
            updateSingleUser( 
                user._id, 
                {
                    fields: fields
                }
            ) 
        );
    }



    return(
        <>
            <form className="expForm" onSubmit={sendForm}>

                <label className="expForm__item">
                    <div className="expForm__label"> ПІБ</div>
                    <div className="expForm__container">
                        <input 
                            className="form-input" 
                            type="text" 
                            name="name"
                            onChange={textHandler}
                            value={fields.name}
                        />
                    </div>
                </label>

                <label className="expForm__item">
                    <div className="expForm__label">  Контактний номер телефону </div>
                    <div className="expForm__container">
                        <input 
                            className="form-input" 
                            type="text" 
                            name="phone"
                            onChange={textHandler}
                            value={fields.phone}
                        />
                    </div>
                </label>

                <label className="expForm__item">
                    <div className="expForm__label">  Місце проживання (місто, країна) </div>
                    <div className="expForm__container">
                        <input 
                            className="form-input" 
                            type="text" 
                            name="address"
                            onChange={textHandler}
                            value={fields.address}
                        />
                    </div>
                </label>
                
                <label className="expForm__item">
                    <div className="expForm__label">  Місце роботи </div>
                    <div className="expForm__container">
                        <input 
                            className="form-input" 
                            type="text" 
                            name="workplace"
                            onChange={textHandler}
                            value={fields.workplace}
                        />
                    </div>
                </label>

                <label className="expForm__item">
                    <div className="expForm__label">  Посада </div>
                    <div className="expForm__container">
                        <input 
                            className="form-input" 
                            type="text" 
                            name="position"
                            onChange={textHandler}
                            value={fields.position}
                        />
                    </div>
                </label>

                <label className="expForm__item">
                    <div className="expForm__label">  Освіта </div>
                    <div className="expForm__container">
                        <input 
                            className="form-input" 
                            type="text" 
                            name="education"
                            onChange={textHandler}
                            value={fields.education}
                        />
                    </div>
                </label>

                <label className="expForm__item">
                    <div className="expForm__label">  Посилання на профіль у Facebook </div>
                    <div className="expForm__container">
                        <input 
                            className="form-input" 
                            type="text" 
                            name="facebook_link"
                            onChange={textHandler}
                            value={fields.facebook_link}
                        />
                    </div>
                </label>


                <div className="expForm__item">
                    <div className="expForm__label">  Чи маєте Ви не менше трьох років досвіду професійної діяльності у відповідному секторі?  </div>
                    <div className="expForm__container radio">
                        <label className="expForm__radioItem">
                            <input type="radio" onChange={radioHandler} name="prof_expirience" checked={ fields.prof_expirience }/>
                            <span> Так </span>
                        </label>
                        <label className="expForm__radioItem">
                            <input type="radio" onChange={radioHandler} name="prof_expirience" checked={ !fields.prof_expirience }/>
                            <span> Ні </span>
                        </label>
                    </div>
                </div>

                <label className="expForm__item">
                    <div className="expForm__label">  Надайте інформацію про Вашу роботу протягом останніх трьох років (не більше 10-ти речень). У разі наявності, надайте активні лінки, які підтверджують профільну діяльність.</div>
                    <div className="expForm__container">
                        <textarea className="form-input" rows="15" name="activity_last_years" onChange={textHandler} value={fields.activity_last_years} />
                    </div>
                </label>

                <label className="expForm__item">
                    <div className="expForm__label">  Чи маєте Ви досвід управління проектами,  не менше трьох років?</div>
                    <div className="expForm__container">
                        <textarea className="form-input" rows="15" name="project_managment" onChange={textHandler} value={fields.project_managment} />
                    </div>
                </label>

                <div className="expForm__item">
                    <div className="expForm__label"> Чи маєте Ви досвід бюджетування (фінансового менеджменту, складання бюджету проектів) не менше трьох років? </div>
                    <div className="expForm__container radio">
                        <label className="expForm__radioItem">
                            <input type="radio" onChange={radioHandler} name="maiking_budget" checked={ fields.maiking_budget } />
                            <span> Так </span>
                        </label>
                        <label className="expForm__radioItem">
                            <input type="radio" onChange={radioHandler} name="maiking_budget" checked={ !fields.maiking_budget }/>
                            <span> Ні </span>
                        </label>
                    </div>
                </div>

                <label className="expForm__item">
                    <div className="expForm__label"> Надайте інформацію про Ваш досвід бюджетування протягом останніх трьох років (не більше 10-ти речень - розгорнута відповідь) </div>
                    <div className="expForm__container">
                        <textarea className="form-input" rows="15" name="budget_info" onChange={textHandler} value={fields.budget_info} />
                    </div>
                </label>

                <div className="expForm__item">
                    <div className="expForm__label"> Чи має кандидат досвід оцінки проектів у культурно-мистецькій сфері (участь в експертних групах, радах, комісіях з присудження премій, журі тощо) не менше трьох років? </div>
                    <div className="expForm__container radio">
                        <label className="expForm__radioItem">
                            <input type="radio" onChange={radioHandler} name="rating_expirience" checked={ fields.rating_expirience } />
                            <span> Так </span>
                        </label>
                        <label className="expForm__radioItem">
                            <input type="radio" onChange={radioHandler} name="rating_expirience" checked={ !fields.rating_expirience }/>
                            <span> Ні </span>
                        </label>
                    </div>
                </div>

                <label className="expForm__item">
                    <div className="expForm__label"> Надайте інформацію про Ваш досвід оцінки проектів у культурно-мистецькій сфері (участь в експертних групах, радах, комісіях з присудження премій, журі тощо) протягом останніх трьох років (не більше 10-ти речень). </div>
                    <div className="expForm__container">
                    <textarea className="form-input" rows="15" name="rating" onChange={textHandler} value={fields.rating} />
                    </div>
                </label>

                <label className="expForm__item">
                    <div className="expForm__label">  Скільки повних робочів днів Ви зможете приділити оцінюванню? </div>
                    <div className="expForm__container">
                        <input 
                            className="form-input" 
                            type="text" 
                            name="work_days"
                            onChange={textHandler}
                            value={fields.work_days}
                        />
                    </div>
                </label>

                {/* Expected  */}
                {
                    culture && (
                        <label className="expForm__item">
                            <div className="expForm__label">  Основний культурний сектор </div>
                            <div className="expForm__container">
                                <select
                                    value={ fields.expected_cultural_sector === null ? '' : fields.expected_cultural_sector}
                                    onChange={textHandler}
                                    name="expected_cultural_sector"
                                    className="form-input">
                                        <option value=""> Оберіть культурний сектор </option>
                                    {
                                        culture.options.options.map( (culture_item, key) => {
                                            if( culture_item.archive != "1"){
                                                return(
                                                    <option key={key} value={culture_item.value}>{culture_item.name}</option>
                                                );
                                            }
                                        })
                                    }
                                </select>
                            </div>
                        </label>
                    )
                }

                {
                    keywords && (
                        <label className="expForm__item">
                            <div className="expForm__label"> Ключові слова </div>
                            <div className="expForm__container">
                                <RenderFields
                                    fields={filterdKeywordOptions}
                                    name="expected_keywords"
                                    currentfields={fields.expected_keywords}
                                    handleChange={handleKeywords}
                                />
                            </div>
                        </label>
                    )
                }


                {
                    seasons && (
                        <div className="expForm__item">
                            <div className="expForm__label"> Програма </div>
                            <div className="expForm__container">
                                <div className="form-label-input">
                                    <RenderPrograms
                                        seasons={seasons}
                                        mainProgram={fields.expected_programs_main}
                                        currentPrograms={fields.expected_programs_secondary}
                                        handleChange={handleProgramsChange}
                                    />
                                </div>
                            </div>
                        </div>
                    )
                }
                        

                <footer className="expForm__item">
                    <div className="expForm__label"></div>
                    <div className="expForm__container">
                        <button className="direction__btn"> Зберегти дані </button>
                    </div>
                </footer>

            </form>


        </>
    )

}

export default ExpertForm;