import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { Table, Row, Cell } from '../../../../library/Table'
import { Loader, Breadcrumbs, Empty } from '../../../../library'

import { getAllCouncil, clearCouncil } from '../../../../../actions'

class CouncilList extends Component {
  componentDidMount(){
    this.props.getAllCouncil()
  }

  componentWillUnmount(){
    this.props.clearCouncil()
  }

  render(){
    const { loading, data } = this.props

    if( loading ){
      return <Loader/>
    } else {
      return(
        <div className="innerWrap">
          <Breadcrumbs items={[
            {name:'Адмiн панель', link: '/admin'},
            {name:'Користувачі', link: '/admin/users'},
            {name:'Список груп', link:'/admin/users/council'}
          ]}/>
          <header className="page-header">
            <h1 className="page-title">Користувачі</h1>
            <Link className="button button-yellow" to="/admin/users/council/new"> Нова група </Link>
          </header>
          <Table>
            <Row type="head">
              <Cell type="large">Назва групи</Cell>
              <Cell type="large">Кількість експертів</Cell>
            </Row>
            {
              data && data.length ?
              data.map( (council, key) => (
                <Row
                  key={key}
                  type="link"
                  to={`/admin/users/council/${council._id}`}
                >
                  <Cell type="large">{council.title}</Cell>
                  {/* Кількість заявок на обробці експерта */}
                  <Cell type="large">123</Cell>
                </Row>
              )) : <Empty/>
            }

          </Table>

        </div>
      )
    }

  }
}

const mapStateToProps = (state) => ({
  data: state.users.allCouncils.data,
  loading: state.users.allCouncils.loading,
})

const mapDispatchToProps = (dispatch) => ({
  getAllCouncil: () => {
    dispatch( getAllCouncil() )
  },
  clearCouncil: () => {
    dispatch( clearCouncil() )
  }
})


export default connect(mapStateToProps, mapDispatchToProps)(CouncilList)
