import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom';

import { Layout } from '../../library';
import Sidebar from '../../common/SideBar';
import Notify from '../../common/Notify';

import MainPage from './main';
import TechReviewDirection from './tech_review';
import CreateTechReviewDirection from './tech_review/create';
import SingleTechDirection from './tech_review/single';

import ExpertsDirection from './experts';
import CreateExpertsDirection from './experts/create';
import SingleExpertDirection from './experts/single';


import './styles/direction.scss';

const Temp = () => {
    return(<h1>Temporary route</h1>)
}

const DirectionRoot = () => {
    return(
        <>
            <Sidebar/> 
            <Layout>
                <Switch>
                    <Route exact path="/dr" component={MainPage}/>
                    <Route exact path="/dr/tech_review" component={TechReviewDirection} />
                    <Route exact path="/dr/tech_review/create" component={CreateTechReviewDirection} />
                    <Route exact path="/dr/tech_review/:direction_id" component={SingleTechDirection} />
                    

                    <Route exact path="/dr/experts" component={ ExpertsDirection } />
                    <Route exact path="/dr/experts/create/:type" component={ CreateExpertsDirection } />
                    <Route exact path="/dr/experts/:direction_id" component={SingleExpertDirection} />

                </Switch>
                <Notify /> 
            </Layout>
        </>
    )

}


export default DirectionRoot;