import React from 'react'

import EmptyIcon from './icons/empty_icon.svg'
import './styles/styles.scss'

export const Empty = ({ text, size }) => {
  let objSize = {}

  if (size === 'small') {
    objSize = {
      margin: '20px',
      height: '80px',
      fontSize: '1.4rem',
      display: 'flex',
      alignItems: 'center'
    }
  }
  if (size === 'medium') {
    objSize = {
      margin: '35px',
      height: '150px',
      fontSize: '1.6rem'
    }
  }
  return (
    <div className="empty" style={objSize}   >
      <div className="empty-image">
        <img src={EmptyIcon} alt="Немає об'єктів"/>
      </div>
      <p className="empty-description">
        { text ? text : "У цьому конкурсі, ще немає Заявок..."}
      </p>
    </div>
  )
}