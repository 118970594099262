import React, { useEffect } from 'react'
import { Route, Switch, Redirect, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';


import { Layout, Loader, TabsNav } from '../../../library'

import AppInfo from './AppInfo'
import AppDocuments from './AppDocuments'
import AppHead from './AppHead';

import { clearApplicationInfo, getProjectApplication } from '../../../../actions'


const ProjectApp = () => {

	const dispatch = useDispatch();
    const params = useParams();
	const { loaded, breadcrumbs, data } = useSelector( state => state.applications.info );

	useEffect(() => {
		dispatch( getProjectApplication(  params.id ) );
	}, []);

	if( !loaded ){
        return <Loader />;
    }

	return(
		<Layout
			className="projects-division"
			paddingTop="20px"
			header={ 
				<TabsNav 
					route="/pd/application" 
					tabs={[
						{slug: 'info', title: 'Інформація'},
						{slug: 'review', title: 'Оцінка'}
					]} 
					location={ data._id } 
				/> 
			}
		>
			<AppHead
				name={data.user.name}
				spec_id={data.spec_id}
				status={data.tech_review.status}
				created_at={ data.created_at }
				breadcrumbs={breadcrumbs}
			/>
			<Switch>
				<Redirect exact from="/pd/application/:id/" to="/pd/application/:id/info"/>
				<Route exact path="/pd/application/:id/info" component={ AppInfo }/>
				<Route exact path="/pd/application/:id/review" component={ AppDocuments }/>
			</Switch>
		</Layout>
	);


}


/*
class ProjectApp2 extends React.Component {
	componentDidMount() {
		this.props.getApplication(this.props.match.params.id)
	}

	componentWillUnmount() {
		this.props.clearApplication()
	}

	render(){
		const { data, breadcrumbs, loading } = this.props
		const { id } = this.props.match.params

		// const headerNav = 

	//   <AppHead
	//   name={data.user.name}
	//   spec_id={data.spec_id}
	//   status={data.tech_review.status}
	//   created_at={ data.created_at }
	//   breadcrumbs={breadcrumbs}
	// />
	// <Loader/>

		// return (
		// 	<>
				
		// 	</>
		// )
	}
}
*/

const mapStateToProps = (state) => ({
	data: state.applications.info.data,
	breadcrumbs: state.applications.info.breadcrumbs,
	loading: state.applications.info.loading
})

const mapDispatchToProps = (dispatch) => ({
	getApplication: (id) => {
		dispatch(getProjectApplication(id))
	},
	clearApplication: () => {
		dispatch(clearApplicationInfo())
	}
})

// export default connect(mapStateToProps, mapDispatchToProps)(ProjectApp)

export default ProjectApp;