import {
	ALL_EVAL_GROUPS_REQ,
	ALL_EVAL_GROUPS_RES,
	ALL_EVAL_GROUPS_ERR,
} from '../../../actions';

const usersInitialState = {
    errors: [],
    loading: false,
    loaded: false,
	data: [],

	create_req: false,
	message: ''
}

const groups_reducer = ( state = usersInitialState, action) => {
    switch( action.type ){
		case ALL_EVAL_GROUPS_REQ:
			return {
				...state,
				loaded: false,
				loading: true
			}

		case ALL_EVAL_GROUPS_RES:
			return {
				...state,
				loading: false,
				loaded: true,
				data: action.payload
			}

		case ALL_EVAL_GROUPS_ERR:
			return {
				...state,
				error: action.errors
			}

		default:
			return state
		}
}

export default groups_reducer;