import React, { useState } from 'react'
import  { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Breadcrumbs } from '../../../library';
import { getSchemaByType } from './rollbacks_schemas/getSchemaByType';
import SearchApplication from './search_application';

import ApplicationSelect from './additional_forms/application_select';
import ReviewSelect from './additional_forms/review_select';

import { applicationsRollback } from '../../../../actions';

const CreateRollback = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [ rollType, setRollType ] = useState("");
    const [ schema, setSchema ] = useState( null );
    const [ applications, setApplications ] = useState([]);
    const [ section_name, changeSectionName ] = useState('');
    const [ section, changeSection ] = useState('');

    const [ subsection, changeSubsection ] = useState('');
    const [ subsectionStatus, changeSubStatus ] = useState('');

    const [ disabled, setDisabled ] = useState( false );


    const changeType = ( e ) => {
        let type = e.target.value;
        let schema = getSchemaByType( type );

        setRollType( type );
        setSchema( schema );
        console.log('[schema]', schema );
    }
    
    const changeSectionHandler = e => {
        let section_name = e.target.value;
        let section = schema.find( item => item.name === section_name );

        changeSectionName( section_name );
        changeSection( section );

        console.log('[SN]', section_name, section );
    }

    const changeStatus = ( subsections ) => (e) => {
        let status = e.target.value;
        let subsection = subsections.find( item => item.status === Number(status) );
        console.log('[v]', subsections, subsection );
        changeSubsection( subsection );
    }
    
    const changeSubsectionStatus = ( e ) => {
        changeSubStatus( e.target.value );
    }

    const sendDataToRollback = (e) => {
        e.preventDefault();

        const app_ids = applications.map( item => item._id );

        if( rollType === "application"){
            dispatch(
                applicationsRollback(
                    {
                        type: rollType,
                        application_ids: app_ids,
                        section: section_name,
                        status: subsection.status,
                        subsection: subsection,
                        subsection_status: subsectionStatus
                    },
                    history
                )
            )
        }

        if( rollType === "expert_review"){
            dispatch();
        }
        

    }


    console.log('[ROLLBACK SCHEMA]', {
        type: rollType,
        application_ids: applications.map( item => item._id ),
        section: section_name,
        status: subsection.status,
        subsection: subsection,
        subsection_status: subsectionStatus
    });


    return(
        <div className="rollbacks">
            <Breadcrumbs items={[
                {name: 'Адмінка', link: `/admin` },
                {name: 'Відкот-машина', link: `/admin/rollback` },
                {name: 'Новий відкот', link: `/admin/rollback/create` },
            ]}/>

            <h1 className="page-title"> Новий відкот </h1>

            <form onSubmit={sendDataToRollback}>

                <label className="ed-stats__label">
                    <div  className="ed-stats__title"> Оберіть, що відкочуємо </div>
                    <select
                        className="ed-stats__input"
                        value={rollType}
                        onChange={changeType}
                        required
                    >   
                        <option value="" disabled={ rollType !== null }>Тип відкоту</option>
                        <option value="application"> Заявку </option>
                        <option value="expert_review"> Відгук експерта </option>
                        <option value="negotiation_procedure" disabled={true}> Переговорна процедура </option>
                        <option value="application_reporting" disabled={true}> Звітність </option>
                    </select>
                </label>

                {
                    rollType !== "" && (
                        <>
                            <SearchApplication 
                                type={ rollType }
                                setApplications={setApplications}
                                applications={applications}
                            />
                            {
                                schema !== null && (
                                    <div>
                                        {
                                            rollType === "application" && (
                                                <ApplicationSelect 

                                                    applications={applications}

                                                    changeSectionHandler={changeSectionHandler}
                                                    section_name={section_name}
                                                    schema={schema}
                                                    section={section}

                                                    statuses={subsection.status}
                                                    changeStatus={changeStatus}

                                                    subsection={subsection}
                                                    changeSubsectionStatus={changeSubsectionStatus}
                                                    subsectionStatus={subsectionStatus}
                                                />
                                            )
                                        }
                                        {
                                            rollType === "expert_review" && (
                                                <ReviewSelect 
                                                    changeSectionHandler={changeSectionHandler}
                                                    section_name={section_name}
                                                    schema={schema}
                                                    section={section}
                                                />
                                            )
                                        }
                                    </div>
                                )
                            }
                        </>
                    )
                }


                <button 
                    className={ disabled ? "direction__btn disabled" : "direction__btn"}
                    disabled={disabled}    
                > Відкотити </button>
            </form>

        </div>
    );
}

export default CreateRollback;