import React from 'react'
import { Breadcrumbs, StatusHandler } from '../../../library'

const AppHead=({ spec_id, breadcrumbs, reviews, application }) => (
  <>
    <Breadcrumbs items={[
        { name: 'Погодження', link: '/rp/programs' },
        { name: `${breadcrumbs.program.title}`, link: `/rp/programs/${breadcrumbs.program._id}` },
          breadcrumbs.lot !== undefined && breadcrumbs.lot !== null ?
          { name: `${breadcrumbs.lot.title}`, link: `/rp/lots/${breadcrumbs.lot._id}` } :
          null,
        { name: `${breadcrumbs.contest.title}`, link: `/rp/contests/${breadcrumbs.contest._id}` },
        { name: `${spec_id}`, link: `/rp/programs` }
      ]}
    />
    <header className="application__header">
      <div className="header-item">
        <h1 className="page-title">{ spec_id }</h1>
      </div>
      <div>
        <div className="header-item">
          <div className="header-item__name">
            Загальний бал заявки
          </div>
          <div className="header-item__value">
            <span className="text-gray">
                {
                  reviews.active.reduce( (sum, current) => {
                    return sum + current.score;
                  }, 0 )
                }
            </span>
          </div>
          <div className="header-item"> 
              <StatusHandler 
                adaptiveType={true} 
                adaptiveData={application.reporting_review} 
                statusId={application.reporting_review.status} 
            />
          </div>
        </div>
      </div>
    </header>
  </>
);

export default AppHead