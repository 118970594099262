import {
    GET_APPLICATION_REPORTING_REQ,
    GET_APPLICATION_REPORTING_RES,
	GET_SINGLE_REPORTING_PROCEDURE_REQ,
	GET_SINGLE_REPORTING_PROCEDURE_RES

} from '../../actions'

const usersInitialState = {
    procedures: null,
	loaded: false,
	creating: false,

	procedure: null,
	loaded_procedure: false,
	procedure_files: []
}

const childs = ( state = usersInitialState, action) => {
    switch( action.type ){

		case GET_APPLICATION_REPORTING_REQ:
			return {
				...state,
				loaded: false
			}

		case GET_APPLICATION_REPORTING_RES:
			return {
				...state,
				loaded: true,
				procedures: action.payload
			}

		case GET_SINGLE_REPORTING_PROCEDURE_REQ:
			return {
				...state,
				loaded_procedure: false
			}

		case GET_SINGLE_REPORTING_PROCEDURE_RES:
			return {
				...state,
				loaded_procedure: true,
				procedure: action.payload,
				procedure_files: action.files
			}

		default:
			return state
    }
}

export default childs