import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table, Row, Cell, Loader, Signer, RoleChecker, Round } from '../../library';

import { searchAgreementApplications, setAgreementSigned } from '../../../actions';



let throttlingTimer = null;

const ApplicationRow = React.memo(({ app, handler, selected, full, type }) => {
    const checked = selected.some( selected_app => selected_app._id === app._id );
    
    let allSigned = [];
    let isSigned = false;

    if( type === "grunt_agreement" || type === "additional_agreement"){
        allSigned = useSelector( state => state.matching.agreement.signed_ids );
        isSigned = allSigned.some( signed => {
            return signed.sign_id === app.reporting_review.grunt_agreement.main_file.sign_id ;
        }); 
    } else if( type === "act") {
        isSigned = false;
    }

    console.log('[full]', full );
    console.log('[type]', type );


    return(
        <Row key={app._id}>
            <label className="direction__row">
                <Cell className="id">
                    <input 
                        type="checkbox"
                        value={app._id}
                        onChange={handler}
                        checked={checked}
                        data-spec={ app.spec_id }
                    />
                </Cell>
                <Cell className="large">
                    <div className="agreement__line">
                    { app.spec_id }
                    {
                        full && type === "grunt_agreement" && (
                            <>
                                { app.reporting_review.grunt_agreement.status === 1 && (<span className="text-green"> (Завантажено угоду) </span>) }
                            </>
                        )
                    }
                    
                    {
                        full === undefined && (
                            <>
                                {
                                    type === "grunt_agreement" && ( <Round status={ isSigned ? 1 : 0 } /> )
                                }
                                {
                                    type === "additional_agreement" && (
                                        <>
                                            {
                                                app.reporting_review.grunt_agreement.additionalFiles.map( (item, index) => {
                                                    const isSigned = allSigned.some( signed => signed.sign_id === item.sign_id );
                                                    return ( <Round key={index} status={ isSigned ? 1 : 0 } /> )
                                                })
                                            }
                                        </>
                                    )
                                }
                                {
                                    type === "act" && ( <Round status={ isSigned ? 1 : 0 } /> )
                                }
                            </>
                        )
                    }
                    </div>
                </Cell>
                {
                    full && (
                        <>
                            { 
                                type === "additional_agreement" && (
                                    <Cell className="large">
                                        <Link to={`/rp/application/${app._id}/agreement/additional`} className="text-link">
                                            Переглянути додаткові угоди
                                            {
                                                app.reporting_review.grunt_agreement.additionalFiles && (
                                                    ` (${app.reporting_review.grunt_agreement.additionalFiles.length})`
                                                )
                                            }
                                        </Link>
                                    </Cell>
                                )
                            }
                            { 
                                type === "grunt_agreement" && (
                                    <>
                                        <Cell className="large">
                                            <Link to={`/rp/application/${app._id}/agreement/upload`} className="text-link">
                                                Переглянути угоду
                                            </Link>
                                        </Cell>
                                        <Cell className="large">
                                            <span style={{color: '#eee'}}>{ app.reporting_review.grunt_agreement.main_file.sign_id }</span>
                                        </Cell>
                                    </>
                                )
                            }
                            { 
                                type === "act" && (
                                    <Cell className="large">
                                        <Link to={`/rp/application/${app._id}/act`} className="text-link">
                                            Переглянути акт
                                        </Link>
                                    </Cell>
                                )
                            }
                        </>
                    )
                }
            </label>
        </Row>
    );
});

const Applications = React.memo(({ 
    data = [], 
    handler = () => {}, 
    counter,
    type,
    apps,
    loaded,
    sign_ids,
    signCallback,
}) => {
    const dispatch = useDispatch();
    const [ search, setSearch ] = useState('');

    const selectedApps = data;
    const setSelected = handler;
    const user = useSelector( state => state.auth.user );

    const handleSearch = (e) => {
        let value = e.target.value;
        setSearch( value );
        if( value.length >= 4){
            clearTimeout( throttlingTimer );
            throttlingTimer = setTimeout( () => {
                if( type === "additional_agreement"){
                    dispatch( searchAgreementApplications( value, 'additional' ) );
                }
                if( type === "grunt_agreement"){
                    dispatch( searchAgreementApplications( value ) );
                }
            }, 700 );
        }
        if( value === ""){
            if( type === "additional_agreement"){
                dispatch( searchAgreementApplications( value, 'additional' ) );
            }
            if( type === "grunt_agreement"){
                dispatch( searchAgreementApplications( value ) );
            }
        }
    }

    const checkApplication = (e) => {
        let value = e.target.value;
        const app = apps.find( app => app._id === value );
        const isChecked = selectedApps.some( app => app._id === value );   
        if( isChecked ){
            setSelected( selectedApps.filter( app => app._id !== value ) );
        } else {
            setSelected( [...selectedApps, app ]);
        }
    } 

    console.log('sign_ids', sign_ids );


    return(
        <div className="direction__block">
            <h2 className="direction__subtitle"> Заявки { counter } </h2>
            <input 
                className="direction__search" 
                placeholder="Введіть мінімум 4 символи ід для пошуку"
                value={search} 
                onChange={handleSearch}    
            />
            <div className="direction__container">
                <div className="direction__block users">
                {
                    loaded ?
                    (
                        <Table>
                            {
                                apps.length > 0 ? 
                                (
                                    <>
                                        {
                                            apps.map( app => 
                                                <ApplicationRow 
                                                    type={type}
                                                    key={app._id} 
                                                    app={app} 
                                                    handler={checkApplication} 
                                                    selected={selectedApps} 
                                                    full={true}
                                                />
                                            )
                                        } 
                                    </>
                                ) : (
                                    <Row>
                                        Угод, які потребують підпису - немає
                                    </Row>
                                )
                            }
                        </Table>
                    )
                    : 
                    (
                        <Table>
                            <Row>
                                <Cell>
                                {
                                    search.length >= 4 ? 'Шукаємо заявки' : 'Введіть для пошуку'
                                }
                                </Cell>
                            </Row>
                        </Table>
                    )
                }
                </div>
                {
                    selectedApps.length > 0 && (
                        <div className="direction__block users selected">
                            <h2 className="direction__subtitle"> Обрані {selectedApps.length} </h2>
                            <Table>
                            {
                                selectedApps.map( app => 
                                    <ApplicationRow 
                                        type={type}
                                        key={app._id} 
                                        app={app} 
                                        handler={checkApplication} 
                                        selected={selectedApps}
                                    />
                                )
                            }
                            </Table>

                            <RoleChecker 
                                allowedRoles={['chief', 'admin']} 
                                user={user}
                                message="Тільки директор може підписати грантову угоду"
                            >
                                <Signer 
                                    ids={sign_ids}
                                    statusChangerCallback={ signCallback }
                                    afterEach={setAgreementSigned}
                                />
                            </RoleChecker>
                        </div>  
                    )
                }
            
            
            </div>
                
        </div>
    )


});

export default Applications;
