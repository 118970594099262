import React, { useState } from 'react'
import { connect } from 'react-redux';

import { RoleChecker } from '../../../../library';
import { uploadAgreement, uploadFile, endAgreement, signGrunt, deleteSignedAgreement } from '../../../../../actions';
import { pdf_icon, xls_icon } from '../../icons';

const Scaned = ({
    app, documents, uploading, loaded, user,
    uploadFileAction, endAgreementAction, signGruntReq, deleteScaned
}) => {

    const [ scaned, setScaned ] = useState( null );

    const handleScanedAgreement = (e) => {
        if( e.target.files.length > 0){
            uploadFileAction( e.target.files[0],  'scaned_agreement');
            setScaned( e.target.files[0] );
        }
    }

    if( app.reporting_review.grunt_agreement.status < 4 ){
        return(
            <div className="additional_info text">
                Скановану угоду можна завантажити лише після завантаження та підпису основної угоди
            </div>
        );
    }

    return(
        <div>
            <div className="project-app-grand-wrap scaned">
                {
                    app.reporting_review.grunt_agreement.status === 4 && (
                            <form 
                                className="project-app-grand"
                                onSubmit={ () => {}}
                            >
                                <div className="project-app-grand-title">Сканована версія угоди</div>
        
                                    <label className="project-app-grand-file">
                                        Натисніть, щоб обрати файл
                                        <input
                                            hidden
                                            type="file" 
                                            name="scaned_agreement"
                                            className="hidden"
                                            onChange={handleScanedAgreement}
                                            accept=".xls, .xlsx, .pdf"
                                            required
                                            tabIndex="1"
                                        />
                                    </label>   
                                    <button 
                                        style={{  marginTop: '10px' }}
                                        className={ !uploading ? "project-app-grand-button" : "project-app-grand-button disabled"}
                                        disabled={uploading}    
                                    >
                                        {
                                            uploading ? "Угода завантажується" : "Завантажити угоду"
                                        }
                                    </button>
                            </form>
                    )   
                }
                {
                    app.reporting_review.grunt_agreement.status === 5 && (
                        <div>
                            <div className="project-app-grand-title">Сканована версія угоди</div>
                            <div className="expert_documents">
                                <a className="application__documents-item" href={app.reporting_review.grunt_agreement.scaned_agreement.url} target="_blank" rel="noopener noreferrer">
                                    <img  className="application__documents-icon" src={pdf_icon} alt="format icon"/>
                                    <span> Сканована угода { app.spec_id } </span>
                                </a>
                            </div>
                            <div className="">
                                <RoleChecker 
                                    allowedRoles={['admin']} 
                                    user={user}
                                    message="Тільки адміністратор може видалити скановану угоду"
                                >
                                    <button 
                                        style={{  marginTop: '10px' }}
                                        className="documents__deleteConfirm button button-decline"
                                        onClick={deleteScaned}
                                    >
                                        Видалити угоду
                                    </button>
                                </RoleChecker>
                                
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
}


const mapStateToProps = (state) => ({
    user: state.auth.user,
    app: state.applications.info.data,
    documents: state.applications.info.documents,
    loading: state.applications.info.loading,
    loaded: state.applications.info.loaded,
    uploading: state.matching.uploading.uploading_grunt
});


const mapDispatchToProps = (dispatch, ownProps ) => ({
    uploadReq: ( formData ) => {
        dispatch( uploadAgreement( 
            ownProps.match.params.id, 
            formData,
            ownProps.history
        ));
    },

    uploadFileAction: ( file, type, additional_id ) => {
        dispatch( uploadFile( 
            ownProps.match.params.id, 
            file, type, additional_id,
        ));
    },

    endAgreementAction: () => {
        dispatch(
            endAgreement( ownProps.match.params.id, ownProps.history )
        );
    },

    signGruntReq: () => {
        console.log( "GRUNT_SIGN ");
        dispatch(
            signGrunt(  ownProps.match.params.id, ownProps.history )
        );
    },

    deleteScaned: () => {
        dispatch(
            deleteSignedAgreement( ownProps.match.params.id )
        );
    }

});

export default connect( mapStateToProps, mapDispatchToProps )( Scaned );