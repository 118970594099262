import React from 'react'
import { Switch, Route } from 'react-router-dom'

import AdminMain from './AdminMain'
import ProgramsRoot from '../../common/Programs/ProgramsRoot'
import ProgramsChilds from '../../common/Programs/ProgramsChilds'
import ProgramsLots from '../../common/Programs/ProgramsLots'
import SeasonsRoot from '../../common/Programs/SeasonsRoot'
import Users from './Users'

import LotsRegister from './LotsRegister'
import Sidebar from '../../common/SideBar'
import Notify from '../../common/Notify'
import Evaluation from './Evaluation'
import Rollback from './Rollback-Machine';

import DivisionContext from '../../../helpers/DivisionContext'

import './styles/styles.scss'

const ExpertsDivision = () => (
  <DivisionContext.Provider
    value={{
      type: 'admin',
      route: 'admin',
      title: 'Адмiнiстративна панель'
    }}>
    <Sidebar/>
		<Switch>
      <Route exact path="/admin" component={ AdminMain }/>
      <Route path="/admin/users" component={ Users }/>
      <Route path="/admin/evaluation" component={Evaluation} />
      <Route path="/admin/rollback" component={Rollback} />
      
      <Route exact path="/admin/programs" component={ ProgramsRoot }/>

      <Route exact path="/admin/seasons/:seasonid" component={ ProgramsRoot }/>
			<Route exaxt path="/admin/seasons" component={SeasonsRoot}/>

			<Route exact path="/admin/programs/:progid" component={ ProgramsChilds }/>
      <Route exact path="/admin/lots/:id" component={ ProgramsLots }/>
      <Route exact path="/admin/contests/:id" component={ LotsRegister }/>

      

		</Switch>
    <Notify/>
	</DivisionContext.Provider>
)

export default ExpertsDivision