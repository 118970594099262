import React from 'react'
import { connect } from 'react-redux';

import { signalConflict, signExpertProtocol } from '../../../../actions';

import { Signer } from '../../../library';


const VariantsOfConflict = [
	{
		name: "Конфлікт інтересів",
		value: "-3"
	},
	{
		value: "-4",
		name: "Брак часу"
	},
	{
		value: "-5",
		name: "Невідповідний профіль"
	},
	{
		name: "Інша причина",
		value: "-1"
	}
]

class ExpertSingleConflict extends React.Component {
	state = {
		message: '',
		type_id: '-3',
		reason: '',
		error: null
	}

	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		});
	}

	handleSubmit = (e) => {
		e.preventDefault();
		const { data } = this.props;

		if( this.state.message.length < 100){
			this.setState({
				error: "Довжина коментаря повинна бути мінімум 100 символів"
			})
		} else {
			this.props.signalConflictAct(
				data.review._id,
				{
					message: this.state.message,
					type_id: this.state.type_id
				}
			);
		}
	}

	signCallback = () => {
		const { data } = this.props;
		const { reason } = this.state;

		this.props.signProtocol(
			data.review._id,
			this.state.type_id,
			reason
		)
	}

	render () {
		const { message, type_id, reason, error } = this.state;
		const { handleSubmit } = this;
		const { data } = this.props;

		const disabled = data.review.protocol_id !== undefined;

		console.log('[type_id]', type_id, type_id == -1 );

		return (
		<div className="expert-solution-form">
			<h1 className="expert-solution-form-title">
				Генерація протоколу відмови від заявки. { !disabled ? "Крок 1" : "Крок 2"}
			</h1>
			<form className="expert-solution-form-form" onSubmit={handleSubmit} disabled={disabled}>
				{
					disabled && 
					(
						<>
							<div className="expert-solution-form-text">Тип відмови:</div>
							{
								VariantsOfConflict.map( variant => {

									const isChecked = data.review.status.id === 0 ?  type_id === variant.value ? true : false : data.review.status.id == variant.value

									return(
										<label className="expert-solution-form-label-wrap">
											<input
												className="expert-solution-form-input"
												type="radio"
												value={variant.value}
												name="type_id"
												checked={  isChecked }
												onChange={ this.handleChange }
												disabled={ data.review.status.id !== 0}
											/>
											<span className="expert-solution-form-label">{ variant.name } </span>
										</label>
									);

								})
							}
							
							
							{
								type_id == -1 && (
									<label>
										<input 
											type="text"
											className="form-input"
											name="reason"
											onChange={ this.handleChange }
											value={reason}
										/>
									</label>
								)
							}
						</>
					)
				}
				
				{
					disabled ? (
						<div className="additional_info">							
							<a href={data.protocol.fileUrl} target="_blank" rel="noopener noreferrer" className="application__documents-protocol">
								Завантажити протокол відмови
							</a>
							<br />
							<i>Причина відмови: </i> 
							<span> { data.review.status.message } </span> 
						</div>
					) : (
						<div className="expert-solution__form">
							<div className="expert-solution__form-main">
								<div style={{ textAlign: 'left'}} className={ error ? "expert-solution-form-text text-red" : "expert-solution-form-text" }>
									Напишіть, будь ласка, розгорнутий коментар, чого ви не можете оцінити цю заявку: (мінімум 100 символів: { message.length } / 100 )
								</div>
								<textarea
									rows="12"
									className="expert-solution-form-textarea"
									name="message"
									value={ disabled ? data.review.status.message : message }
									onChange={this.handleChange}
									disabled={disabled}
									required={true}
									minLength="100"
								/>
								<button className="expert-solution-form-submit" type="submit">Створити протокол відмови</button>
							</div>
							<div className="expert-solution__form-info">
								<p>
								Викладіть, будь ласка, ситуацію, в якій у вас виник реальний чи потенційний конфлікт інтересу, що впливає на об'єктивність оцінювання вами даного проекту. При цьому, будь ласка, зазначте чи вчинялися дії та чи приймалися рішення в умовах реального конфлікту інтересів.
								</p>
								<p>
								Нагадуємо, що відповідно до Порядку повідомлення про конфлікту інтересів (затверджено рішенням Наглядової ради Українського культурного фонду №49 від 31.07.2019) визначено:
								</p>
								<ul>
									<li>
										<b>потенційний конфлікт інтересів</b> -  це наявність у особи приватного інтересу у сфері, в якій вона виконує свої службові чи представницькі повноваження, що може вплинути на об’єктивність чи неупередженість прийняття нею рішень або на вчинення чи невчинення дій під час виконання зазначених повноважень;
									</li>
									<li>
										<b>реальний конфлікт інтересів</b> -  це суперечність між приватним інтересом особи та її службовими чи представницькими повноваженнями,  що впливає на об’єктивність або неупередженість прийняття рішень, або на вчинення чи невчинення дій під час виконання зазначених повноважень.  
									</li>
								</ul>
								<p>
								Викладена інформація тут вважається офіційним повідомленням про реальний/потенційний конфлікт інтересів на підстави якого Дирекція приймає рішення у відповідності до Порядку повідомлення про конфлікт інтересів.
								</p>
							</div>
						</div>
					) 
				}


				{
					disabled && (
						<>
							{
								data.review.status.id !== -3 && data.review.status.id !== -1 ?
								(
									<Signer
										ids={[data.protocol.sign_id]}
										statusChangerCallback={ this.signCallback }
									/>
								) : null 
							}
						</>
					)
				}
			</form>
		</div>
		)
	}
}

const mapDispatchToProps = ( dispatch ) => ({
	signalConflictAct: ( review_id, body ) => {
		dispatch( signalConflict(review_id, body) )
	},
	signProtocol: ( review_id, type_id, reason ) => {
		dispatch( signExpertProtocol( review_id, type_id, reason ) );
	}
});

const mapStateToProps = ( state ) => ({
	data: state.expert.single.data,
});

export default connect(mapStateToProps, mapDispatchToProps)(ExpertSingleConflict);