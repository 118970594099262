import axios from '../../helpers/private.axios'
import config from '../../config'

import { GET_INFO_USER_RES } from '../auth/login.actions';

export const GET_ALL_USERS_REQUEST = 'GET_ALL_USERS_REQUEST'
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS'
export const GET_ALL_USERS_ERROR = 'GET_ALL_USERS_ERROR'

export const GET_SINGLE_USERS_REQUEST = 'GET_SINGLE_USERS_REQUEST'
export const GET_SINGLE_USERS_SUCCESS = 'GET_SINGLE_USERS_SUCCESS'
export const GET_SINGLE_USERS_ERROR = 'GET_SINGLE_USERS_ERROR'

export const GET_CURRENT_USER_REQ = "GET_CURRENT_USER_REQ";
export const GET_CURRENT_USER_RES = "GET_CURRENT_USER_RES";
export const GET_CURRENT_USER_ERR = "GET_CURRENT_USER_ERR";

export const getCurrentUser = () => dispatch => {
  dispatch({ type: GET_CURRENT_USER_REQ });
  axios.get(`/api/users/me`)
  .then( res => {
    // console.log('user getted', res);
    dispatch({ type: GET_INFO_USER_RES, payload: res.data.data });
  })
  .catch( err => {
    dispatch({ type: GET_CURRENT_USER_ERR, error: err });
  })
}


export const getAllUsers = () => (dispatch) => {
  dispatch({ type: GET_ALL_USERS_REQUEST })

  axios.get(`${config.apiPath}/api/users`).then(
    users => {
      dispatch({
        type: GET_ALL_USERS_SUCCESS,
        users: users.data.data.users.data,
        count: users.data.data.users.totalCount,
      })
    }
  )
}

export const getSingleUser = (id) => (dispatch) => {
  dispatch({ type: GET_SINGLE_USERS_REQUEST })

  axios.get(`${config.apiPath}/api/users/${id}`)
  .then( user => {
    dispatch({
      type: GET_SINGLE_USERS_SUCCESS,
      payload: user.data.data.user
    })
  })
}