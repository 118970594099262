import React, { Component } from 'react'
import { Switch, Route, NavLink } from 'react-router-dom'

import UsersList from './UserList'
import Councils from './Councils'
import NewCouncil from './Councils/newCouncil'
import SingleCouncil from './Councils/singleCouncil'
import SingleUser from './UserList/editUser'

import { Layout } from '../../../library'

import './styles/users.scss'

class UsersContainer extends Component {
  render(){
    return(
      <div>
        <Layout
          className="projects-division"
          header={
            <header className="tabs__container">
              <NavLink className="tabs__item" to="/admin/users" exact> Користувачі </NavLink>
              <NavLink className="tabs__item" to="/admin/users/council"> Групи користувачів </NavLink>
            </header>
          }
        >
          <Switch>
            <Route path="/admin/users" exact component={UsersList}/>
            <Route path="/admin/users/council" exact component={Councils}/>
            <Route path="/admin/users/:userid" exact component={SingleUser}/>
            <Route path="/admin/users/council/new" exact component={NewCouncil}/>
            <Route path="/admin/users/council/:councilid" exact component={SingleCouncil}/>
          </Switch>
        </Layout>
      </div>
    )
  }
}

export default UsersContainer
