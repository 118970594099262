import React from 'react'
import './style.scss';

const reporting_map = [
    {
        id: -3,
        name: 'reporting_review'
    },
    {
        id: -2,
        name: 'reporting_review'
    },
    {
        id: 0, // Пройдено технічний та екпертний відбір
        name: 'reporting_review'
    },
    {
        id: 1, // Тривають переговорні процедури
        name: 'reporting.neotiation'
    },
    {
        id: 2, // Пройдено переговорну процедуру
        name: 'reporting.neotiation'
    },
    {
        id: 3, // Отримано рішення дирекції
        name: 'reporting.direction',
        key: 'direction'
    },
    {
        id: 4, //  Отримано рішення наглядової ради
        name: 'reporting.supervisory_board',
        key: 'supervisory_board'
    },
    {
        id: 5, // Грантова угода
        name: 'reporting.grunt_agreement',
        key: 'grunt_agreement'
    },
    {
        id: 6, //  Звітування
        name: 'Процес звітування',
        key: 'reporting'
    },
    {
        id: 7, // Акт виконаного проекту
        name: 'Пройдено процедуру звітності',
        key: 'completed_act'
    },
]


export const StatusHandler = ({ statusId, type, adaptiveType = false, adaptiveData }) => {
    let statusType = type;
    let statusLibrary = []
    let status = Number( statusId );
    
    if( adaptiveType ){
        let adaptive = reporting_map.find( status => status.id === statusId)
        console.log('[ad]', adaptive, statusType );
        if( adaptive ){
            statusType = adaptive.name;

            if( adaptive.key === "reporting"){
                statusType = "reporting.reporting";
            }

            if( adaptive.key === "completed_act"){
                statusType = "reporting.act";
            }

            if( adaptive.key ){
                status = adaptiveData[ adaptive.key ].status
            }
        }
    }


    if (statusType === "tech_review" ) {
        statusLibrary = [
            { id: 0, status: "Не оброблено", className: "text-gray"},
            { id: 1, status: "Схвалено, не підписано", className: "text-gray"},
            { id: -1, status: "Відхилено, не підписано", className: "text-gray"},
            { id: 2, status: "Схвалено, підписано", className: "text-green"},
            { id: -2, status: "Відхилено, підписано", className: "text-red"},
            { id: -3, status: "Не можу виставити оцінку", className: "text-yellow"},
            { id: -4, status: "Подано аппеляцію", className: "text-yellow"},


        ];
    }
    if (statusType === "expert_review") {
        statusLibrary = [
            { id: -3, status: "Конфлікт інтересів", className: "text-red"},
            { id: -2, status: "Звільнений з заявки", className: "text-red"},
            { id: -1, status: "Не можу оцінити заявку", className: "text-red"},
            { id: 0, status: "Не оброблено", className: "text-gray"},
            { id: 1, status: "Триває опрацювання", className: "text-gray"},
            { id: 2, status: "Відправлено на валідацію", className: "text-gray"},
            { id: 3, status: "Відгук схвалений", className: "text-green"},
            { id: 4, status: "Потрібне доопрацювання", className: "text-red"},
            { id: 5, status: "Завершена", className: "text-green"},
            { id: 6, status: "Попередньо погоджено", className: "text-orange"},
        ];
    }

    if( statusType === "expert_feedback"){
        statusLibrary = [
            { id: 0, status: 'Не опрацьовано', className: "text-gray"},
            { id: 1, status: 'Завершено', className: "text-green"}
        ];
    }

    if( statusType === "expert_division_application"){
        statusLibrary = [
            { id: -2, status: 'Відхилено', className: "text-red"},
            { id: -1, status: 'Відхилено, не підписано', className: "text-gray"},
            { id: 0, status: 'Не обробленно', className: "text-gray"},
            { id: 1, status: 'Розподілено експертів', className: "text-gray"},
            { id: 2, status: 'Схвалено, не підписано', className: "text-gray"},
            { id: 3, status: 'Схвалено', className: "text-green"},
        ];
    }

    if( statusType === "reporting_review"){
        statusLibrary = [
            { id: -3, status: " Заявка була відхилена і підписана", className: "text-red" },
            { id: -2, status: " Заявка була відхилена під час переговорів", className: "text-gray" },
            { id: 0, status: 'Пройдено технічний та екпертний відбір', className: "text-gray"},
            { id: 1, status: 'Тривають переговорні процедури', className: "text-gray"},
            { id: 2, status: 'Успішно пройдено переговорну процедуру', className: "text-gray"},
            { id: 3, status: 'Отримано рішення дирекції', className: "text-gray"},
            { id: 4, status: 'Отримано рішення наглядової ради', className: "text-gray"},
            { id: 5, status: 'Підписання грантової угоди', className: "text-gray"},
            { id: 6, status: 'Триває звітування', className: "text-gray"},
            { id: 7, status: 'Акт виконаного проекту', className: "text-green"},
        ];
    }

    if( statusType === "reporting.neotiation"){
        statusLibrary = [
            { id: -2, status: "Потребує доопрацювання, підписана", className: "text-red" },
            { id: -1, status: "Потребує доопрацювання, не підписана", className: "text-gray"},
            { id: 0, status: "Не розпочата", className: "text-gray"},
            { id: 1, status: "Триває переговорна процедура", className: "text-gray"},
            { id: 2, status: "Заявник вніс зміни", className: "text-gray"},
            { id: 3, status: "Завершена, не підписана", className: "text-gray"},
            { id: 4, status: "Завершена, підписана", className: "text-green"},
        ]
    }

    if( statusType === "reporting.direction"){
        statusLibrary = [
            { id: -1, status: "Заявку відхилено" , className: "text-red"},
            { id: 0, status: "Немає рішення" , className: "text-gray"},
            { id: 1, status: "Заявку схвалено" , className: "text-green"}
        ]
    }

    if( statusType === "reporting.supervisory_board"){
        statusLibrary = [
            { id: -1, status: "Заявку відхилено" , className: "text-red"},
            { id: 0, status: "Немає рішення" , className: "text-gray"},
            { id: 1, status: "Заявку схвалено" , className: "text-green"},
            { id: 2, status: "Не потребує рішення" , className: "text-green"}
        ]
    }

    if( statusType === "reporting.grunt_agreement"){
        statusLibrary = [
            { id: 0, status: "Угода не завантажена" , className: "text-gray"},
            { id: 1, status: "Завантажено угоду" , className: "text-gray"},
            { id: 2, status: "Угода підписана директором", className: "text-green" },
            { id: 3, status: "Угода підписана заявником", className: "text-green" },
            { id: 4, status: "Угода підписана директором і заявником", className: "text-green" },
            { id: 5, status: "Завантажено скановану угоду", className: "text-green" },
        ];
    }
    
    if( statusType === "reporting.reporting"){
        statusLibrary = [
            { id: -1, status: "Звітність відправлена на доопрацювання" , className: "text-red"},
            { id: 0, status: "Звітність ще не подана" , className: "text-gray"},
            { id: 1, status: "Триває прийом звітності" , className: "text-gray"},
            { id: 2, status: "Заявник подав звітність" , className: "text-green"},
            { id: 3, status: "Звітність була схвалена" , className: "text-green"},
        ]
    }

    if( statusType === "repoting.user"){
        statusLibrary = [
            { id: -1, status: "Потребує доопрацювання" , className: "text-red"},
            { id: 0, status: "Фідбеку немає" , className: "text-gray"},
            { id: 1, status: "Звітність вірна" , className: "text-green"}
        ]
    }

    if( statusType === "reporting.act"){
        statusLibrary = [
            { id: 0, status: "Акт не завантажено" , className: "text-gray"},
            { id: 1, status: "Акт завантажено" , className: "text-gray"},
            { id: 2, status: "Акт підписано директором" , className: "text-green"},
            { id: 3, status: "Акт підписано директором і заявником" , className: "text-green"}
        ];
    }
    
    const StatusObj = statusLibrary.find( item => item.id === status );
    
    if( StatusObj !== undefined){
        return(
            <span className={`statusHandler ${StatusObj.className}`}>
                { StatusObj.status }
            </span>
        )
    } else {
        return(
            <span> Статус заявки не розпізнано </span>
        )
    }
};

export default StatusHandler