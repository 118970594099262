import React, { useState, useEffect }  from 'react'
import moment from 'moment';

import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Breadcrumbs, Loader, Signer, DocumentLink, SignerList } from '../../../library';

import { getSingleDirection, updateSingleDirection, finishDirection, notify } from '../../../../actions';
import { pdf_icon } from '../icons';

import SingleAppRow from './single_application_block';

const SingleDirection = () => {
    let { direction_id } = useParams();
    const dispatch = useDispatch();

    const [ messages, setDirMessage ] = useState({
        text_protocol: '',
        text_protocol_short: '',
        direction_date: ''
    });

    const [ direction_apps, setDirectionApps ] = useState( [] );

    const loaded = useSelector( state => state.direction.single.loaded );
    let direction = useSelector( state => state.direction.single.data );

    const setDirectionMessages = ( direction ) => {
        setDirMessage({
            text_protocol: direction.text_protocol,
            text_protocol_short: direction.text_protocol_short,
            direction_date: direction.direction_date
        })
    }

    useEffect( () => {
        dispatch( getSingleDirection( direction_id, setDirectionMessages, setDirectionApps ) );
    }, [ direction_id ] );


    const updateDirection = () => { 
        console.log('[Update direction]');

        const body = {
            text_protocol: messages.text_protocol,
            text_protocol_short: messages.text_protocol_short, 
            results: direction_apps,
            direction_date: messages.direction_date
        };

        dispatch( updateSingleDirection( direction._id, body ) );
    }

    const changeHandler = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setDirMessage({ ...messages, [name] : value });
    }

    const changeRadio = ( app_id ) => ( e ) => {
        console.log('[ch]', e.target.checked, e.target.value );
        let updatedApps = direction_apps.map( d_app => {
            if( d_app._id === app_id ){
                d_app.status = Number(e.target.value);
            }
            return d_app;
        });
        console.log('updatedApps', updatedApps)
        setDirectionApps( updatedApps );
    }

    const finishDirectionAction = () => {

        const body = {
            text_protocol: messages.text_protocol,
            text_protocol_short: messages.text_protocol_short,
            results: direction_apps,
            direction_date: messages.direction_date
        };

        dispatch( finishDirection(direction_id, body) );
    }

    const getDirection = () => {
        dispatch( getSingleDirection( direction_id, setDirMessage, setDirectionApps ) );
        dispatch( notify('Протокол успішно підписано', 'success') )
    }

    if( !loaded ){
        return <Loader />
    }
    
    const enableFinish = direction_apps.every( res => {
        return res.status !== 0;
    });
    console.log('[enableFinish]', enableFinish );


    return(
        <>
            <Breadcrumbs
                items={[
                    {name: 'Дирекція', link: `/dr` },
                    {name: 'Технічний відбір', link: `/dr/tech_review`},
                    {name: `Дирекція від ${moment(direction.created_at ).format('lll')} ` , link: `/dr/tech_review/`},
                ]}
            />
            <h1>{`Дирекція від ${moment(direction.created_at ).format('lll')} `}</h1>
            
            <div className="text">
                Склад дирекції: 
                {
                    direction.users.map( (user, index) => {
                        return (
                            <b className="text" key={user._id}>
                                {user.name}
                                {index !== direction.users.length - 1 && ','}
                            </b>
                        )
                    })
                }
            </div>

            <div className="d-single__body">
                <div className="d-single bold">
                    <div className="d-single__top">
                        <div className="d-single__item id"> # </div>
                        <div className="d-single__item spec_id">
                            ІД
                        </div>
                        <div className="d-single__item spec_id">
                            Статус
                        </div>
                        <div className="d-single__item actions">
                            Дії
                        </div>
                        <div className="d-single__item large">
                            Назва проекту
                        </div>
                        <div className="d-single__item large">
                            Заявник
                        </div>
                    </div>
                </div>
            
                {
                    direction.applications.map( (application, index) => {
                        return(
                            <SingleAppRow 
                                key={application._id} 
                                index={ index + 1 }
                                application={application}
                                direction_apps={direction_apps}
                                changeRadio={changeRadio}
                                disabled={direction.status === 1}
                            />
                        );
                    })
                }
            </div>

            <div className="direction__column">

                <label className="direction__label">
                    <div className="direction__message"> 
                        Дата проведення дирекції
                        <span className="text-gray"> (Текстом для протоколу, н-д: «12 січня 2021»)</span>
                        <span className="required">*</span>
                    </div>
                    <input 
                        className={ direction.status === 1 ? "application__textarea disabled" : "application__textarea"}
                        name="direction_date"
                        defaultValue={messages.direction_date}
                        onBlur={changeHandler}
                        disabled={direction.status === 1}
                    ></input>
                </label>


                <label className="direction__label">
                    <div className="direction__message"> Вступ <span className="required">*</span> </div>
                    <textarea 
                        rows="8"
                        className={ direction.status === 1 ? "application__textarea disabled" : "application__textarea"}
                        name="text_protocol_short"
                        defaultValue={messages.text_protocol_short}
                        onBlur={changeHandler}
                        disabled={direction.status === 1}
                    ></textarea>
                </label>

                <label className="direction__label">
                    <div className="direction__message">
                        <span>Рішення для протоколу</span>
                        <span className="required">*</span>
                    </div> 
                    <textarea 
                        rows="24"
                        className={ direction.status === 1 ? "application__textarea disabled" : "application__textarea"}
                        name="text_protocol"
                        defaultValue={messages.text_protocol}
                        onBlur={changeHandler}
                        disabled={direction.status === 1}
                    ></textarea>
                </label>
                {
                    direction.status === 1 && (
                        <div className="direction__footer">
                            <div className="application__label">
                                <div className="direction__message"> Підписати рішення </div>
                                <div className="direction__signer">
                                    <Signer 
                                        ids={[ direction.protocol.sign_id ]}
                                        statusChangerCallback={getDirection}
                                    />
                                    <br></br>
                                    <DocumentLink 
                                        url={direction.protocol.fileUrl}
                                        icon="pdf"
                                        text="Протокол дирекції"
                                    />
                                </div>
                            </div>
                            {
                                direction.protocol && (
                                    <div className="direction__footerItem">
                                        <SignerList sign_id={direction.protocol.sign_id} />
                                    </div>
                                )
                            }
                        </div>
                    )
                }
            </div>
            <footer className="direction__comment">
                {
                    direction.status !== 1 ? (
                        <>
                            <button className="direction__btn" onClick={updateDirection}> Зберегти дирекцію </button>
                            <button 
                                className="direction__btn finish"
                                onClick={finishDirectionAction}  
                            > Прийняти рішення </button>
                        </>
                    ) : (
                        <>
                            <button disabled className="direction__btn disabled"> Зберегти дирекцію </button>
                            <button disabled className="direction__btn disabled"> Прийняти рішення </button>
                        </>
                    )
                }
            </footer>
        </>
    )

}

export default SingleDirection;