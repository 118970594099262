import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import closeLock from './icons/close-lock.svg'
import openLock from './icons/open-lock.svg'

import { getUserToken, resetError } from '../../../actions'

class Login extends Component {
  state = {
    login: '',
    password: '',
    showPass: false
  }

  componentDidMount = () => {
    // this.props.resetAuthError()
  }

  handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value

    if (this.props.auth_error) {
      this.props.resetAuthError()
    }

    this.setState({
      [name]: value
    })
  }

  showPassword = (e) => {
    e.preventDefault()
    this.setState({ showPass: !this.state.showPass })
  }

  submitForm = (e) => {
    e.preventDefault()
    const { login, password } = this.state
    const { login_progress } = this.props

    if( !login_progress ){
      this.props.loginUser({
        login,
        password
      })
    }
  }

  render() {
    const { auth_error, login_progress } = this.props
    const { showPass } = this.state
    const { handleChange, submitForm, showPassword } = this

    return (
      <div className="login-container">
        <form
          className="login-form"
          onSubmit={submitForm}
        >
          <label className="login-label">
            <div className="login-label-text">
              <span>Пошта</span>
            </div>
            <div className="login-label-input">
              <input
                type="email"
                className={ !auth_error ? 'login-input' : 'login-input input-error' }
                name="login"
                onChange={handleChange}
                placeholder="Н-д, IvanFranko@gmail.com"
                required
              />
            </div>
          </label>
          <label className="login-label">
            <div className="login-label-text">
              <span>Пароль</span>
            </div>
            <div className="login-label-input">
              <input
                type={!showPass ? "password" : "text"}
                className={ !auth_error ? 'login-input' : 'login-input input-error' }
                name="password"
                onChange={handleChange}
                placeholder="********"
                required
              />
              <button
                type="button"
                className="login-showPassword"
                onClick={showPassword}
              >
                <img
                  src={!showPass ? closeLock : openLock}
                  alt={!showPass ? 'Показати пароль' : 'Сховати пароль'}
                  title={!showPass ? 'Показати пароль' : 'Сховати пароль'}
                />
              </button>
            </div>
          </label>
          {
            auth_error !== null &&
            (
              <div className="login-label">
                <div className="login-label-text"></div>
                <div className="login-label-input text-red">
                  {auth_error.message}
                </div>
              </div>
            )
          }

          <div className="login-label">
            <div className="login-label-text"></div>
            <div className="login-label-input">
              <button
                className={!login_progress ? "login-button" : "login-button loading"}
                type="submit"
              >
                Увійти
                {
                  login_progress && !auth_error &&
                    (
                      <svg className="spinner" viewBox="0 0 50 50">
                        <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                      </svg>
                    )
                }
              </button>
            </div>
          </div>
        </form>
        <div className="login-footer">
          <button className="login-footer-info">Український Культурний Фонд</button>
          <Link className="login-footer-recovery" to="/restore">Відновлення паролю</Link>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    login_progress: state.auth.loadingToken,
    auth_error: state.auth.error
  }
}

const mapDispatchToProps = (dispatch) => ({
  resetAuthError: () => {
    dispatch(resetError())
  },
  loginUser: (dataUser) => {
    dispatch(getUserToken(dataUser))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
