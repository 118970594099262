import { combineReducers } from 'redux'

import single from './app_single.reducer';
import all from './app_all.reducer';
import ranking from './ranking.reducer';
import feedbacks from './feedbacks';
import stats from './expert_stats.reducer';
import users from './expert_lists';
import singleExpert from './single_user';
import seasons from './seasons.reducer';

const expert = combineReducers({
    single,
    all,
    ranking,
    feedbacks,
    stats,
    users,
    singleExpert,
    seasons
})

export default expert