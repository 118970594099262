import {
  GET_SINGLE_APP_FOR_EXPERT_REQ,
  GET_SINGLE_APP_FOR_EXPERT_RES,
  GET_SINGLE_APP_FOR_EXPERT_ERR,
  UPDATE_EXPERT_REVIEW,
  CLEAR_EXPERT_APP_SINGLE
} from '../../actions'

const usersInitialState = {
  error: null,
  loading: false,
  loaded: false,
  data: null
}

const single = (state = usersInitialState, action) => {
  switch (action.type) {
    case GET_SINGLE_APP_FOR_EXPERT_REQ:
      return ({
        ...state,
        loaded: false,
        loading: true
      })
    case GET_SINGLE_APP_FOR_EXPERT_RES:
      return ({
        ...state,
        loading: false,
        loaded: true,
        data: action.payload
      })
    case GET_SINGLE_APP_FOR_EXPERT_ERR:
      return ({
        ...state,
        error: action.payload
      })
    case UPDATE_EXPERT_REVIEW:
      return({
        ...state,
        data: {
          ...state.data,
          review: action.payload
        }
      })

    case CLEAR_EXPERT_APP_SINGLE:
      return state
    default:
      return state
  }
}

export default single