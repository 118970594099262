import {
    GET_APPLICATION_DOCUMENTS_REQ,
    GET_APPLICATION_DOCUMENTS_RES,
    CLEAR_APPLICATION_DOCUMENTS
} from '../../actions';

const initialState = {
    error: null,
    loading: false,
    loaded: false,
    data: null
}

const documents = ( state = initialState, action) => {
    switch( action.type ){
        case GET_APPLICATION_DOCUMENTS_REQ:
            return({
                ...state,
                loading: true
            });
        
        case GET_APPLICATION_DOCUMENTS_RES:
                return({
                    ...state,
                    loading: false,
                    loaded: true,
                    data: action.payload
                });

        case CLEAR_APPLICATION_DOCUMENTS:
            return initialState;

        default:
            return state
    }
}

export default documents;