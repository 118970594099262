import React from 'react'
import { connect } from 'react-redux'

import { Loader, DataSort } from '../../../library'
import { Collapse, Panel } from '../../../library/Collapse'

import { getApplicationInfo, clearApplicationInfo } from '../../../../actions'

import DocumentsList from '../../../common/DocumentList';


const AppDetails = ({ data, documents, loading }) => {
  console.log('data', data, documents );
  if (data && !loading) {
    return (
      <>
        <Collapse>
          <Panel title="Документи" opened="true">
            <DocumentsList documents={documents} />
          </Panel>
          {
            data.data.fields.map( (group, key) => {
              if( group !== null ){
                return(
                    <Panel
                      key={key}
                      title={group.title}
                      opened={ key === 0 }
                    >
                      {
                        group.fields.map( (subfield, subkey) => {
                          return (
                            <DataSort key={subkey} subfields={group.subfields} {...subfield}/>
                          )
                        })
                      }
                    </Panel>
                  )
                } else { return null }
              })
          }
        </Collapse>
      </>
    )
  } else {
    return <Loader/>
  }
}


const mapStateToProps = (state) => ({
  data: state.applications.info.data,
  documents: state.applications.info.documents,
  loading: state.applications.info.loading
})

const mapDispatchToProps = (dispatch) => ({
  getApplication: (id) => {
    dispatch(getApplicationInfo(id))
  },
  clearApplication: () => {
    dispatch(clearApplicationInfo())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(AppDetails)