const negotiation_rollback_schema = [
    {
        label: "Не оброблено",
        name: "assigned",
        status: 0
    },
    {
        label: "Заявник вніс зміни",
        name: "assigned",
        status: 0
    },
    {
        label: "Прибрати коментар співробітника",
        name: "need_to_validate",
        status: 2
    },
    {
        label: "Потрібне доопрацювання",
        name: "need_to_rework",
        status: 4
    }
];

export default negotiation_rollback_schema;