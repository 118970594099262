const getFieldValue = ( value, options, multiple ) => {
    if( multiple ){
        let result = options.filter( opt => {
            return value.some( val => opt.value === val );
        }).map( item => item.name );
        return result.join(',');
    }
    let finded = options.find( opt => opt.value === value );
    if( finded ){
        return finded.name;
    } else {
        return `${finded}. (Поле було змінено або видалено)`;
    }

} 

export default getFieldValue;