import React from 'react'
import { connect } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'

import RootRoutes from '../../root_routes'

const filterRoutesByRoleAndType = ( type, role = null ) => {
    let renderRoutes = null
    // Filter by Type:
    const typesRoutes = RootRoutes.filter( route => route.type === type )
    if( role !== null ){
        // Filter by role
        renderRoutes = typesRoutes.filter( route => {
            if (route.roles === undefined) {
                return false
            } else {
                return route.roles.some(route_role => route_role === role)
            }
        })
    } else {
        renderRoutes = typesRoutes
    }
    return renderRoutes;
}


const RoleWrapper = ({ auth, isAuthenticated }) => {
    let routes = [];
    let redirectPath = "/";

    if( isAuthenticated ){

        routes = filterRoutesByRoleAndType('private', auth.user.role );

        // console.log('auth.user.role', auth.user.role);
        if( auth.user.role === "unassigned"){
            redirectPath = "/";
        } else {
            // console.log('gere', routes);
            let redirectRoute = routes.find( route => {
                if( route.mainFor !== undefined ){
                    return route.mainFor.some( item => {
                        // console.log('MAIN', item, auth.user.role );
                        return item === auth.user.role;
                    });
                } else {
                    return false;
                }
            });
            // console.log('red', redirectRoute)
            if( redirectRoute !== null ){
                redirectPath = redirectRoute.path;
            }
        }
    } else {
        routes = filterRoutesByRoleAndType('public', null )
    }

    // console.log('- - - - - - - - ' );

    return (
        <>
            <Switch>
                {
                    routes.map((route, key) => {
                        return (
                            <Route key={key} {...route} />
                        )
                    })
                }
                <Redirect to={redirectPath} />
            </Switch>
        </>
    )
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    isAuthenticated: state.auth.user !== null
})

export default connect(mapStateToProps)(RoleWrapper)

