import React, { useEffect } from 'react'
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Breadcrumbs, Table, Row, Cell, Button } from '../../../library';

import { getAllUnassignedUsers } from '../../../../actions';


const Commision = () => {
    const dispatch = useDispatch();

    useEffect( () => {
        dispatch( getAllUnassignedUsers('final') );

    }, [] );
    const loaded = useSelector( state => state.direction.list.loaded );
    let users = useSelector( state => state.users.unassigned.data );

    let current_user_role = useSelector( state => state.auth.user.role )

    return(
        <>
            <Breadcrumbs
                items={[
                    { name: "Експертний відділ", link: `/ed/` },
                    { name: "Експерти", link: `/ed/experts`},
                    { name: "Відбір експертів", link: "/ed/experts/selection"},
                    { name: "Експерти які пройшли відбір", link: "/ed/experts/selection/commission"},
                ]}
            />
            <header className="direction__header">
                <h1 className="page-title"> Експерти які пройшли відбір </h1>
            </header>
                
            <Table>
                <Row type="head">
                    <Cell type="id">#</Cell>
                    <Cell type="large"> Розділ </Cell>
                    <Cell type="medium"> Роль </Cell>
                </Row>

                {
                    users.map( (user, index)   => {
                        
                        return(
                            <Row 
                                key={user._id}
                                type="link"
                                to={ current_user_role === "admin" ? `/admin/users/${user._id}` : `/ed/experts/${user._id}/info`}  
                                className="test_row"  
                            >
                                <Cell type="large"> 
                                    {index + 1}. 
                                    { user.name }
                                </Cell>

                                <Cell type="medium">
                                    { user.role === "expert" && ('Експерт')}
                                    { user.role === "unassigned_expert" && ('Не розподілений експерт')}
                                </Cell>
                            </Row>
                        );
                    })
                }

            </Table>
        </>
    )

}

export default Commision;