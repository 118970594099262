import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import { Loader } from '../../../library'
import { Collapse, Panel, PanelItem } from '../../../library/Collapse'


const AppFinal = ({ data, loading, logs }) => {
  if (data && !loading) {
    return (
      <>
         {
          logs !== null && (
            <Collapse className="collapse-experts-main">
                <Panel
                  title={'Історія заявки'}
                  opened={ true }
                >
              {
                logs.map( log => (
                  <PanelItem
                    key={log._id}
                    title={moment( log.created_at ).format('lll')}
                    value={`${log.message} (${log.user_name })`} />
                ))
              }
              </Panel>
            </Collapse>
          )
        }
      </>
    )
  } else {
    return <Loader/>
  }
}

const mapStateToProps = (state) => ({
  data: state.applications.info.data,
  loading: state.applications.info.loading,
  logs: state.applications.info.logs,
})

export default connect(mapStateToProps, null)(AppFinal)