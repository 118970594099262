import React from 'react'
import { Link } from 'react-router-dom'

import LogoIcon from './icons/logo.svg'

const Logo = ({ link }) => (
  <div className="login-logo">
    <Link to={link}>
      <img src={LogoIcon} alt="logo"/>
    </Link>
  </div>
)

export default Logo