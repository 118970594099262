import React from 'react'
import { Link } from 'react-router-dom';

const CheckboxBlock = React.memo( ({ name, value, label, app_status = false, changeRadio, expert, disabled }) => {
    return(
        <label className="d-single__label">
            <span>{label}</span>
            <input 
                type="radio" 
                name={ name }
                checked={ app_status.status == value }
                value={value}
                onChange={changeRadio(expert._id)}
                required
                disabled={disabled}
            />
        </label>
    )
});


const SingleExpertRow = React.memo(({ expert, index, direction_experts, changeRadio, disabled }) => {

    console.log('render', )

    let app_status = direction_experts.find( d_app => d_app._id === expert._id );
    return(
        <div className="d-single">
            <div className="d-single__top">
                <div className="d-single__item id"> {index} </div>
                <div className="d-single__item actions">
                    <Link to={`/ed/experts/${expert._id}/info`} className="link">
                        { expert.name }
                    </Link>
                </div>
                <div className="d-single__item large">
                    {expert.vote_rank}
                </div>
                <div className="d-single__item actions">

                    <CheckboxBlock
                        name={`app_${expert._id}`}
                        app_status={app_status}
                        expert={expert}
                        changeRadio={changeRadio}
                        value="2"
                        label="Затвердити"
                        disabled={disabled}
                    />
                    <CheckboxBlock
                        name={`app_${expert._id}`}
                        app_status={app_status}
                        expert={expert}
                        changeRadio={changeRadio}
                        value="-2"
                        label="Відхилити"
                        disabled={disabled}
                    />

                </div>
            </div>
        </div>
    )


});

export default SingleExpertRow;