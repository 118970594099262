import {
    CREATE_VALIDATION_PROTOCOL_REQ,
    CREATE_VALIDATION_PROTOCOL_RES,
} from '../../actions';

const usersInitialState = {
    error: null,
    loading: false,
    loaded: false,
    processing: false,
    data: null
}

const documents = ( state = usersInitialState, action) => {
    switch( action.type ){
        case CREATE_VALIDATION_PROTOCOL_REQ:
            return({
                ...state,
                processing: true
            });

        case CREATE_VALIDATION_PROTOCOL_RES:
                return({
                    ...state,
                    processing: false,
                    data: action.payload
                });

        default:
            return state
    }
}

export default documents;