import axios from 'axios'
import config from '../../config'

import {
  getUserToken
} from './index'

export const REGISTRATE_USER_REQ = "REGISTRATE_USER_REQ"
export const REGISTRATE_USER_RES = "REGISTRATE_USER_RES"
export const REGISTRATE_USER_ERR = "REGISTRATE_USER_ERR"

export const userRegistrate = (userData, history ) => (dispatch) => {
  const { name, email, password, is_expert } = userData

  dispatch({ type: REGISTRATE_USER_REQ })

  axios.post( `${config.apiPath}/auth/registration`, { name, email, password, is_expert })
  .then(
    res => {
      dispatch({ type: REGISTRATE_USER_RES })
      dispatch( getUserToken({ login: email, password }) )
    }
  )
  .then( _ => {
    history.push('/');
  })
  .catch(
    err => {
      console.log('err', err.response );
      dispatch({ type: REGISTRATE_USER_ERR, payload: err.response.data})
    }
  )
}