import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { Loader, Breadcrumbs, Signer, DocumentLink, SignerList } from '../../../library';

import { getSingleRollback, notify } from '../../../../actions';


const SingleRollback = ({ match }) => {
    
    const dispatch = useDispatch();

    const { loaded, single } = useSelector( state => state.rollbacks );
    console.log('all', single, loaded );
    
    const signedCallback = () => {
        dispatch( notify('Успішно підписано', "success") )
    }

    useEffect(() => {
        dispatch( getSingleRollback( match.params.id ) );
    }, [] )
        
    if( !loaded ){
        return ( <Loader />)
    }

    return(
        <div>
            <Breadcrumbs items={[
                {name: 'Адмінка', link: `/admin` },
                {name: 'Відкот-машина', link: `/admin/rollback` },
                { name: `Відкот від ${moment( single.rollback.created_at ).format('lll')}`, link: '' }
            ]}/>

            <h1 className="page-title"> Відкот від { moment( single.rollback.created_at ).format('lll') }</h1>

            <br/>
            <DocumentLink 
                url={single.rollback.protocol.fileUrl}
                text="Протокол відкоту"
            />
            <div>
                <Signer 
                    ids={ [ single.rollback.protocol.sign_id ] }
                    statusChangerCallback={ signedCallback }
                />
                <SignerList sign_id={single.rollback.protocol.sign_id}/>
            </div>

        </div>
    )

}

export default SingleRollback;