import React from 'react'

import Container from './Container'

import './styles/styles.scss'

const Notify = () => (
  <div className="notify">
    <Container/>
  </div>
)

export default Notify