import axios from '../../helpers/private.axios';
import { 
    notify,
} from '../../actions'

export const GET_EXPERTS_BY_SEASON_REQ = "GET_EXPERTS_BY_SEASON_REQ";
export const GET_EXPERTS_BY_SEASON_RES = "GET_EXPERTS_BY_SEASON_RES";
export const GET_EXPERTS_BY_SEASON_ERR = "GET_EXPERTS_BY_SEASON_ERR";

export const getExpertsBySeason = ( season_id ) => dispatch => {

    dispatch({ type: GET_EXPERTS_BY_SEASON_REQ });

    axios.get(`/api/expert/seasons/${season_id}`)
    .then( res => {
        dispatch({ type: GET_EXPERTS_BY_SEASON_RES, payload: res.data.data  });
    })
    .catch( error => {
        dispatch( notify( error.response.data.message,  "error") )
    })

}
