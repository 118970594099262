import React, { useEffect } from 'react'
import { Layout, Breadcrumbs, Table, Row, Cell, Loader } from '../../../library';
import { useDispatch, useSelector } from 'react-redux';

import { getRootSeasons } from '../../../../actions';

export const ExpertSeason = () => {


    const { loaded, data } = useSelector( state => state.programs.seasons );

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch( getRootSeasons() );
    }, [])

    if( !loaded ){
        return <Loader />
    }

    return(
        <Layout>
            <Breadcrumbs
                items={[
                    { name: "Експертний відділ", link: `/ed/` },
                    { name: "Експерти", link: `/ed/experts`},
                    { name: "Сезони", link: `/ed/experts/seasons`}
                ]}
            />
            <h1 className="page-title"> Експерти по сезонах </h1>
            
            <Table>
                <Row type="head">
                    <Cell type="large"> Сезон </Cell>
                </Row>

                { data.map( ({ season }) => {

                    return(
                        <Row 
                            key={ season._id }
                            type="link"
                            to={`/ed/experts/seasons/${season._id}`}    
                        >
                            <Cell type="large">{ season.title }</Cell>
                        </Row>
                    )
                })}
            </Table>


        </Layout>
    );
}

export default ExpertSeason;