import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import ArrowDown from './icons/down-arrow.svg'

export const Row = ({children, type, to, className}) => {

  if( type === 'link'){
    return(
      <Link
        className={
          type !== undefined ? `table__row ${type} ${className}` : "table__row"
        }
        to={to}>
        {children}
      </Link>
    )
  } else {
    return(
      <div className={
        type !== undefined ? `table__row ${type} ${className}` : `table__row ${className}`
      }>
        {children}
      </div>
    )
  }
}

export class OpenRow extends Component{
  state = { open: false }

  openRow = () => {
    this.setState({ open: !this.state.open })
  }

  render(){
    const { open } = this.state
    const {children, type, openArea} = this.props
    return(
      <div
        className={
          type !== undefined ? `table__row ${type}` : "table__row"
        }
      >
        <div className="openRow__item" >
          <button className="openRow" onClick={this.openRow}>
            <img src={ArrowDown} alt="Open Row"/>
          </button>
          {children}
        </div>


        {
          open && (
            <div className="openRow__container">
              {openArea}
            </div>
          )
        }


      </div>
    )
  }
}
