import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom'
import moment from 'moment';

import { N_Button, Layout, Breadcrumbs, StatusHandler, Table, Row, Cell } from '../../../library';
import ExpertsHeader from './header';

import UnassignedFilters from './UnassignedFilters';
import GenerateRegister from './GenerateExpertsReister';

import { getAllUnassignedUsers, getSingleField, getRootSeasons } from '../../../../actions';

import { medal } from '../icons/index';

const getLabelForValue = ( value, name,  options ) => {
    console.log('[v]', value, '[n]', name, '[o]', options );

    if( !value ){
        return '';
    }

    if( name === "culture"){
        const option = options.find( opt => opt.value === value )
        if( option ){
            return option.name;
        }
    }

    if( name === "program"){
        // getted seasons
        let program = null;
        const prog = options.map( ({ season, programs }) => {
            let finded = programs.find( program => program._id === value );
            if( finded ){
                program = finded;
            }
        });

        if( program ){
            return program.title.uk;
        }


        return 'program';
    }


    return 'test'

}

const UnassignedExperts = () => {
    const dispatch = useDispatch();

    useEffect( () => {
        dispatch( getAllUnassignedUsers() );

        dispatch( getSingleField('keywords') );
        dispatch( getSingleField('culture') );
        dispatch( getRootSeasons() );

    }, [] );

    const { keywords, keywords_loaded, culture, culture_loaded } = useSelector( state => state.common.fields );
    const loaded = useSelector( state => state.users.unassigned.loaded );
    const seasons = useSelector( state => state.programs.seasons );
    let users = useSelector( state => state.users.unassigned.data );

    const getMatchingStatus = ( matching ) => {

        const dictionary = {
            commission: [
                { status: -2, name: "Не затверджено"},
                { status: 2, name: "Затверджено"},
            ],
            direction: [
                { status: -2, name: "Не затверджено"},
                { status: 2, name: "Затверджено"},
            ],
            supervisory_board: [
                { status: -2, name: "Не затверджено"},
                { status: 2, name: "Затверджено"},
            ]
        };


        if( matching.status === 0){
            return 'Розпочато процес відбору';
        }
        
        if( matching.status === 1 ){
            let block = dictionary.commission.find( block => block.status === matching.commission.status );
            if( block ){
                return `Комісія. ${block.name}`;
            }

        }

        if( matching.status === 2 ){

            let block = dictionary.direction.find( block => block.status === matching.direction.status );
            if( block ){
                return `Дирекція. ${block.name}`;
            }
        }

        if( matching.status === 3 ){
            let block = dictionary.supervisory_board.find( block => block.status === matching.supervisory_board.status );
            if( block ){
                return `Наглядова рада. ${block.name}`;

            }
        }

    }


    return(
        <Layout
            className="projects-division"
        >   
            <h1 className="page-title">Нерозподілені експерти</h1>
            <Breadcrumbs
                items={[
                    { name: "Експертний відділ", link: `/ed/` },
                    { name: "Експерти", link: `/ed/experts`},
                    { name: "Нерозподілені експерти", link: `/ed/experts/unassigned`},
                ]}
            />

            <header className="ed-stats__header">
                <UnassignedFilters />
                <GenerateRegister />
            </header>

            <Table>
                <Row type="head">
                    <Cell type="large"> Експерт </Cell>
                    <Cell type="medium"> Оновлено  </Cell>
                    <Cell type="medium"> Сектор  </Cell>
                    <Cell type="medium"> Прогарма </Cell>
                    <Cell type="medium"> Статус відбору </Cell>
                    <Cell type="small"> Рейтинг </Cell>
                </Row>
                {
                    users.map( (user, index ) => {

                        return(
                            <Row 
                                key={user._id}
                                className="test_row"  
                            >
                                <Cell type="large"> 
                                    {index + 1}. 
                                    <Link className="nav_link" to={`/ed/experts/${user._id}`}  >
                                        { user.name }
                                    </Link>
                                    <span className="ml-8">({ user.email })</span>
                                    {
                                        user.matching !== undefined && (
                                            <img width="16" src={medal} />
                                        )
                                    }
                                </Cell>
                                <Cell type="medium"> { moment(user.updated_at).format('lll') }  </Cell>
                                <Cell type="medium"> 
                                    {
                                        user.fields && (
                                            <>
                                                { culture_loaded && getLabelForValue( user.fields.expected_cultural_sector, 'culture', culture.options.options )  }
                                            </>
                                        )
                                    }
                                </Cell>

                                <Cell type="medium"> 
                                {
                                        user.fields && (
                                            <>
                                                { seasons.loaded && getLabelForValue( user.fields.expected_programs_main, 'program', seasons.data )  }
                                            </>
                                        )
                                    }
                                </Cell>
                                
                                {/* <Cell type="medium"> 
                                    {
                                        user.fields && (
                                            <>
                                                { Array.isArray( user.fields.expected_programs_secondary ) && (
                                                        <ul>
                                                            {
                                                                user.fields.expected_programs_secondary.map( program => (
                                                                    <li>
                                                                        { seasons.loaded && getLabelForValue( program, 'program', seasons.data )  }
                                                                    </li>
                                                                ) )
                                                            }
                                                        </ul>
                                                    )
                                                }
                                            </>
                                        )
                                    }
                                </Cell> */}

                                {/* <Cell type="medium"> 
                                    {
                                        user.fields && (
                                            <>
                                                { Array.isArray( user.fields.expected_keywords ) && (
                                                        <ul>
                                                            {
                                                                user.fields.expected_keywords.map( keyword => (<li>{keyword}</li>) )
                                                            }
                                                        </ul>
                                                    )
                                                }
                                            </>
                                        )
                                    }
                                </Cell> */}
                                

                                <Cell type="medium">
                                    {
                                        user.matching ? (
                                            <span>
                                                { getMatchingStatus( user.matching ) }
                                            </span>
                                        ) : (
                                            <span className="text-gray">
                                                Не розпочато процес відбору
                                            </span>
                                        )
                                    }

                                </Cell>
                                <Cell type="small">{ user.vote_rank }</Cell>
                            </Row>
                        );

                    })
                }
            </Table>


        </Layout>
    )

}

export default UnassignedExperts;