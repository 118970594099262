import axios from '../../helpers/private.axios';

export const GET_DIRECTION_USERS_REQ = "GET_DIRECTION_USERS_REQ";
export const GET_DIRECTION_USERS_RES = "GET_DIRECTION_USERS_RES";

export const getAllDirectionUsers = () => (dispatch, getState) => {
    dispatch({ type: GET_DIRECTION_USERS_REQ })

    axios.get(`/api/users/direction`)
    .then(
        res => {
            dispatch({
                type: GET_DIRECTION_USERS_RES,
                payload: res.data.data.users,
                counter: res.data.data.counter
            });
        }
    )
}

