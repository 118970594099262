import {
  REGISTRATE_USER_REQ,
  REGISTRATE_USER_RES,
  REGISTRATE_USER_ERR,
} from '../../actions'

const usersInitialState = {
  err: null,
  registered: false,
  loading: false,
}

const registrate = (state = usersInitialState, action) => {
  switch (action.type) {
    case REGISTRATE_USER_REQ:
      return {
        ...state,
        loading: true,
      }
    case REGISTRATE_USER_RES:
      return {
        ...state,
        registered: true,
        loading: false,
      }
    case REGISTRATE_USER_ERR:
      return {
        ...state,
        loading: false,
        err: action.payload,
      }

    default:
      return state
  }
}

export default registrate
