import { combineReducers } from 'redux'

import negotiation from './negotiation'
import uploading from './uploading';
import stats from './stats';
import reporting from './reporting';
import agreement from './agreement';

const programs = combineReducers({
    negotiation,
    uploading,
    stats,
    reporting,
    agreement
});

export default programs