import React from 'react'

import MenuItem from './MenuItem'

import { Routes } from '../../root_routes'

const Menu = ({target}) => {
  const filtredRoutes = Routes.filter( route => {
    let isAvialibleToThatRole = false
    if( route.roles !== undefined){
      isAvialibleToThatRole = route.roles.some( role => {
        return role === target
      })
    }
    return isAvialibleToThatRole
  })

  return (
    <div className="sidebar-menu">
      {
        filtredRoutes.map( ( item, key ) =>
          <MenuItem item={item} role={target} key={key}/> )
      }
    </div>
  )
}

export default Menu