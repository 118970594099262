import React, { Component } from 'react'
import { connect } from 'react-redux';
import moment from 'moment';

import { Loader, StatusHandler } from '../../../../library';
import DocumentsList from '../../../../common/DocumentList';
import ApplicantFields from './Parts/ApplicantFields';

import { EmployeeMessage } from './Parts';


import { sendNegotiationRequest, getSingleNegotiationProcedure } from '../../../../../actions';

class SetNegotiationEstimate extends Component{

    state = {
        status: 1,
        message: ''
    }

    handleChange = (e) => {
        this.setState({
            message: e.target.value
        });
    }   

    radioHandle = ( e ) => {
        const value = e.target.value;

        this.setState({
            status: Number(value)
        });

    }

    sendResponse = () => {
        const { role } = this.props;
        const { status, message } = this.state;
        let type = '';
        if( role === "financier"){
            type = "financer";
        }
        if( role === "lawyer"){
            type = "lawyer";
        }
        if( role === "project_manager" || role === "project_head"){
            type = "pm";
        }

        this.props.sendNegotiationRequest( type, status, message );
    }


    componentDidMount(){
        this.props.getEstimate();
    }


    render = () => {
        const { role, feedback, application } = this.props;
        const { status, message } = this.state;
        const { sendResponse } = this;
        if( !feedback.single_loaded ){
            return <Loader/>
        }
        
        console.log( feedback.single.procedure, );

        const allowedRoles = [ 'admin', 'director', 'project_manager', 'project_head', 'lawyer', 'financier'];

        let type = 'pm';
        if( role === "financier"){
            type = "financer";
        }
        if( role === "lawyer"){
            type = "lawyer";
        }
        if( role === "project_manager" || role === "project_head"){
            type = "pm";
        }

        const rules = [
            allowedRoles.some( al_role => al_role === role ),
            message.length > 100
        ];
        
        const disableActions = !rules.every( rule => rule === true );
        const allFeedbacks = [ 
            { type: "pm", name: "Проектний менеджер", ...feedback.single.procedure.pm}, 
            { type: "lawyer", name: "Юрист", ...feedback.single.procedure.lawyer}, 
            { type: "financier", name: "Фінансист", ...feedback.single.procedure.financer} 
        ]; 

        const filtredFeedbacks = allFeedbacks.filter( (item ) => type !== item.type );

        if( feedback.single.procedure.type === "online"){
            return(
                <div className="expert-solution-form">

                    <header className="negotiation__header">
                        <h1 className="expert-solution-form-title">
                            Рішення по переговорній процедурі 
                            
                            { role === "admin" && "(Адмін)"}
                            { role === "project_manager" && "(Проектний менеджер)"}
                            { role === "lawyer" && "(Юрист)"}
                            { role === "financier" && "(Фінансист)"}
                    
                        </h1>
                        <StatusHandler type="reporting.neotiation" statusId={feedback.single.procedure[type].status} />	
                    </header>

                    {
                        filtredFeedbacks.map( item => (
                            <EmployeeMessage key={item.type} employee={ item } employee_name={item.name}  />
                        ))
                    }
                        
                    <div className="negotiation__data">
                        {
                            feedback.single.negotiation_files.length > 0 && (
                                <div className="negotiation__files">
                                    <h3>Заявник завантажив файли:</h3>
                                    <DocumentsList documents={feedback.single.negotiation_files}/>
                                </div>
                            )
                        }
                        {
                            feedback.single.procedure.changed_fields && (
                                <div className="negotiation__fields">
                                    <h3>Заявник змінив поля:</h3>
                                    <ApplicantFields 
                                        application={application}
                                        procedureData={feedback.single}
                                        subfields={feedback.single.all_subfields}
                                    />
                                </div>
                            )
                        }
                    </div>

                    {
                        feedback.single.procedure[type].status !== 0 ? 
                        (
                            <div className="additional_info">
                                {
                                    feedback.single.procedure.protocol_id ? (
                                        <div className="additional_info text-white-space">
                                            <p>
                                                {feedback.single.procedure.message}
                                            </p>
                                            {
                                                feedback.single.procedure.protocol_id && (
                                                    <>
                                                        {
                                                            feedback.single.procedure.protocol_id.fileUrl && (
                                                                <a 
                                                                href={feedback.single.procedure.protocol_id.fileUrl} 
                                                                target="_blank" rel="noopener noreferrer" 
                                                                className="application__documents-protocol"> 
                                                                    Завантажити протокол
                                                                </a>
                                                            )
                                                        }
                                                    </>
                                                )
                                            }
                                        </div>
                                    ) : (
                                        <h3>Рішення прийняв {feedback.single.procedure[type].user.name} ({ moment(feedback.single.procedure[type].updated_at).format('lll') })   </h3>
                                    )
                                }
                            </div>
                        ) : 
                        (
                            <>
                            {
                                feedback.single.procedure.status === 2 && feedback.single.procedure[type].status === 0 && (
                                    <>
                                        <label className="expert-solution-form-label-wrap">
                                            <input
                                                className="expert-solution-form-input"
                                                type="radio"
                                                value="1"
                                                name="status"
                                                checked={status === 1 }
                                                onChange={ this.radioHandle }
                                                disabled={ feedback.single.procedure[type].status !== 0}
                                            />
                                            <span className="expert-solution-form-label">Схвалити переговорну процедуру</span>
                                        </label>
                                        <label className="expert-solution-form-label-wrap">
                                            <input
                                                className="expert-solution-form-input"
                                                type="radio"
                                                value="-1"
                                                name="status"
                                                onChange={ this.radioHandle }
                                                checked={status === -1}
                                                disabled={ feedback.single.procedure[type].status !== 0}
                                            />
                                            <span className="expert-solution-form-label">Процедура потребує доопрацювання</span>
                                        </label>
                                    </>
                                )
                            }
                            </>
                        )
                    }

                    {
                        feedback.single.procedure.status === 2 && (
                            <form>
                                <div className="expert-solution-form-text">Коментар до рішення ( {message.length} / 100 )</div>
                                <textarea
                                    rows="7"
                                    className="expert-solution-form-textarea"
                                    name="message"
                                    onChange={this.handleChange}
                                    placeholder="Введіть щонайменьше 100 симовлів коментаря, для того, щоб розблокувати відправку"
                                    disabled={ feedback.single.procedure[type].status !== 0}
                                    value={ feedback.single.procedure[type].status !== 0 ? feedback.single.procedure[type].message : message}
                                />
                                <button 
                                    className={ disableActions ? "application-expert_apply-btn application-expert_apply-btn-full disabled" : "application-expert_apply-btn application-expert_apply-btn-full"}
                                    disabled={ feedback.single.procedure[type].status !== 0 }
                                    onClick={sendResponse}
                                > Надіслати рішення по переговорній процедурі</button>
                            </form>
                        )
                    }

                    
                </div>
            )
        } else {
            // OFFLINE
            return(
                <div className="expert-solution-form"> 
                    {
                        feedback.single.procedure.protocol_id ? (
                            <div className="additional_info text-white-space">
                                <p>
									{feedback.single.procedure.message}
								</p>
                                {
									feedback.single.procedure.protocol_id && (
										<>
											{
												feedback.single.procedure.protocol_id.fileUrl && (
													<a 
													href={feedback.single.procedure.protocol_id.fileUrl} 
													target="_blank" rel="noopener noreferrer" 
													className="application__documents-protocol"> 
														Завантажити протокол
													</a>
												)
											}
										</>
									)
								}
                            </div>
                        ) : (
                            <div className="additional_info">
                                <h3> Рішення по офлайн процедурі виноситься у вкладці "етапи погодження" </h3>
                            </div>
                        )
                    }
                   
                    
                    <div className="negotiation__data">
                        {
                            feedback.single.negotiation_files.length > 0 && (
                                <div className="negotiation__files">
                                    <h3>Заявник завантажив файли:</h3>
                                    <DocumentsList documents={feedback.single.negotiation_files}/>
                                </div>
                            )
                        }
                        {
                            feedback.single.procedure.changed_fields && (
                                <div className="negotiation__fields">
                                    <h3>Заявник змінив поля:</h3>
                                    <ApplicantFields 
                                        application={application}
                                        procedureData={feedback.single}
                                        subfields={feedback.single.all_subfields}
                                    />
                                </div>
                            )
                        }
                    </div>
                </div>
            )
        }
    }

}

const mapStateToProps = (state) => ({
    role: state.auth.user.role,
    application: state.applications.info.data,
    feedback: state.matching.negotiation
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    sendNegotiationRequest: ( type, status, message ) => {
        dispatch( 
            sendNegotiationRequest(
                ownProps.match.params.estimateid,
                type, status, message,
                ownProps.history
            ) 
        );
    },
    getEstimate: () => {
        dispatch( getSingleNegotiationProcedure(ownProps.match.params.estimateid) )
    }
});



export default connect(mapStateToProps, mapDispatchToProps)(SetNegotiationEstimate);