import React from 'react'


const mergeData = ( name, changedValue, application, subfields, fullFields ) => {

    let field = null;
    if( fullFields ){
        fullFields.find( item => {
            if( item.name === name){
                field = item;
            }
        });
    }

    if( typeof( changedValue) === "string" ){
        /*
            Если измененное поле - строка, просто возвращаем его назад.
        */

        let returnedValue = changedValue;
        if( field.type === "select" || field.type === "radio" ){
            let chosenOption = field.options.options.find( opt => opt.value ===  changedValue );
            if( chosenOption ){
                returnedValue = chosenOption.name;
            }
        }
        return returnedValue;
    }

    if( typeof( changedValue ) === "object"){
        /*
            Если измененное поле - объект, мержим его с исходныим данными, заменяя их измененными.
        */
        // console.log(`%c- - - - - - - - - - - ${name}`, "color: red; font-size: 25px;font-weight: 800");

        let appData = application.data.fields[ name ];
        if( Array.isArray( changedValue ) ){
            /*
                Если измененные данные массив - поздравляю мержим мультитаб.
            */
            if( field.type === "checkbox" ){
                appData = [];
                field.options.options.filter( item => {
                    changedValue.forEach( val => {
                        if( val === item.value ){
                            appData.push( item.name );
                        }
                    });
                });
                // console.log('appData', changedValue, appData );
                return appData;
            } 


            appData = appData.map( (item, key ) => {
                
                if( !changedValue[key] ){
                    /*
                        Если в массиве с изменениями такого индекса нету, просто возвращаем элемент.
                    */
                    return item;
                }

                let mergedItem = {};
                

                // console.log('start data:', item, typeof( item ) );

                if( typeof(item) === "object" ){
                    /*
                        Если перебираемый элемент является объектом, перебираем его свойства.
                    */

                    for( let field in item ){
                        // console.log( field, item[ field ] );
                        // console.log( `%cИзменение`, "color: green ", changedValue[ key ][ field ] );

                        /*
                            Если тип поля строка, просто перезаписываем значение
                        */
                        if( typeof( changedValue[ key ][ field ] ) === "string"){
                            mergedItem[ field ] = changedValue[ key ][ field ];
                        }
                        /*
                            Если тип поля объект, мержим с внутреними данными
                        */
                        if( typeof( changedValue[ key ][ field ] ) === "object"){

                            if( changedValue[ key ][ field ] !== null){
                                mergedItem[ field ] = {
                                    ...item[ field ],
                                    ...changedValue[key][ field ]
                                }
                            } else {
                                mergedItem[ field ] = null;
                            }

                        }
                    } // Inner For

                } // typrof( item) === object

                return mergedItem;
            });

            return appData;

        } else {

            // appData = appData.map( (item, key) => {
            //     for( let i in changedValue ){
            //         if( Number(i) === key){
            //             // console.log('MATCH');
            //             return {
            //                 ...item,
            //                 ...changedValue[i]
            //             }
            //         }
            //     }

            //     return item;
            // });

            // return appData;
        }
    } // typeof( item ) === object END

}



const ApplicantFields = ({ procedureData, application, subfields, fullFields}) => {
    const { procedure, fields } = procedureData;
    const fields_render = [];

    console.log( fullFields );
    
    if ( procedure.changed_fields ){
        for( let field in procedure.changed_fields  ){
            let field_obj = fullFields.find( fld => fld.name === field );
            fields_render.push({ 
                name: field,
                value: mergeData( field, procedure.changed_fields[field], application, subfields, fullFields ),
                title: field_obj ? field_obj.label : null
            });
        }
    }

    console.log('RENDER FIELDS:', fields_render );

    return( 
        <>
            <ul>
                {
                    fields_render.map( (field, key) => {
                        // TODO: NEED ADD SUPPORT ANOTHER FIELDS
                        if( typeof( field.value ) === "string" ){
                            return(
                                <li key={key}  style={{ marginBottom: '20px' }}>
                                    <b>{field.title}: </b> 
                                    <span>{field.value}</span>
                                </li>
                            );
                        }

                        if( typeof( field.value ) === "object"){

                            if( Array.isArray( field.value ) ){
                                
                                return(
                                    <li key={key}>
                                        <b>{field.title}: </b> 
                                            {
                                                field.value.map( (changed_item, ch_key) => {

                                                    if( typeof(changed_item) === "string"){
                                                        return changed_item;
                                                    } 


                                                    return(
                                                        <ul key={ch_key} style={{ marginBottom: '20px' }}>
                                                            {
                                                                Object.keys(changed_item).map( item_key => {

                                                                    const sub_name = subfields.find( sub => sub.name === item_key );

                                                                    if(  changed_item[item_key] === null ){
                                                                        return null;
                                                                    }

                                                                    let first_key = Object.keys( changed_item[item_key] )[0];
                                                                    /*
                                                                        Если вложеный элемент имеет пустое значение, значит, он пустой
                                                                    */
                                                                    if( changed_item[item_key][first_key] === null){
                                                                        return null;
                                                                    }

                                                                    if( typeof( changed_item[item_key] ) === "string" ){
                                                                        console.log('blah', field.title, changed_item)
                                                                        return(
                                                                            <li key={item_key}>
                                                                                {
                                                                                    sub_name !== undefined && (
                                                                                        <>
                                                                                            <b>{sub_name.label}:</b>
                                                                                            <span>
                                                                                                {changed_item[item_key]}
                                                                                            </span>
                                                                                        </>
                                                                                    )
                                                                                }
                                                                            </li>
                                                                        )
                                                                    }

                                                                    if( typeof( changed_item[item_key] ) === "object"  ){  
                                                                        return(
                                                                            <li key={item_key}>
                                                                                {
                                                                                    sub_name.name !== undefined && (
                                                                                        <>
                                                                                            <b>{sub_name.label}:</b>
                                                                                            <ul>
                                                                                                {
                                                                                                    Object.keys( changed_item[item_key] ).map( (item, index) => {
                                                                                                        if( changed_item[item_key][item] === null ){
                                                                                                            return null
                                                                                                        }
                                                                                                        return(
                                                                                                            <li key={index}>{ changed_item[item_key][item] }</li>
                                                                                                        )
                                                                                                    }) 
                                                                                                }
                                                                                            </ul>
                                                                                        </>
                                                                                    )
                                                                                }
                                                                            </li>
                                                                        );
                                                                    }
                                                                    
                                                                })
                                                            }
                                                        </ul>
                                                    );
                                                })
                                            }
                                    </li>
                                );
                            } else {
                                return null;
                            }
                        }

                        return null;
                    })
                }
            </ul>
        </>
    )
}

export default ApplicantFields;