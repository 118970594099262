import React from 'react'
import PropTypes from 'prop-types';

import { pdf_icon, xls_icon } from './icons';
import './style.scss';



export const DocumentLink = ({ url, text, icon }) => {

    let image = null;
    if( icon === "pdf"){
        image = pdf_icon;
    }
    if( icon === "xls" ){
        image = xls_icon;
    }

    console.log( image );

    return(
        <div className="expert_documents">
            <a className="application__documents-item" href={url} target="_blank" rel="noopener noreferrer">
                <img  className="application__documents-icon" src={image} alt="format icon"/>
                <span> { text } </span>
            </a>
        </div>
    );

}

DocumentLink.defaultProps = {
    icon : 'pdf'
}

DocumentLink.propTypes = {
    url: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    icon: PropTypes.oneOf(['pdf', 'xls']),
}