import React, { Component } from 'react'

import { connect } from 'react-redux';

import { Button, Signer } from '../../../../library';

import { finishApplicationReportingWithFailure, signApplicationFinish } from '../../../../../actions';


class Finish extends Component {

    state = {
        message: ''
    }

    changeHandler = (e) => {
        this.setState({ message: e.target.value });
    }

    render = () => {
        const { application } = this.props;
        const { message } = this.state;
        return(
            <>
                {
                    application.reporting_review.finalization_protocol === undefined ? 
                    (
                        <>
                            <textarea
                                rows="3"
                                className="expert-solution-form-textarea"
                                onChange={this.changeHandler}
                                value={message}
                                placeholder="Введіть щонайменьше 100 симовлів коментаря, для того, щоб розблокувати відправку"
                            />
                            <Button
                                color="red" 
                                size="large"
                                text="Згенерувати протокол фіналізації" 
                                handler={this.props.finishApp( message )} 
                                disabled={ application.reporting_review.status === -2}
                            />
                        </>
                    ) : (
                        <>
                            <div className="additional_info text-white-space">
                                {
                                    application.reporting_review.finalization_protocol && (
                                        <a 
                                        href={application.reporting_review.finalization_protocol.fileUrl} 
                                        target="_blank" rel="noopener noreferrer" 
                                        className="application__documents-protocol"> 
                                            Завантажити протокол
                                        </a>
                                    )
                                }
							</div>
                            {
                                application.reporting_review.status !== -3 && (
                                    <Signer 
                                        ids={[ application.reporting_review.finalization_protocol.sign_id ]} 
                                        statusChangerCallback={ this.props.signAppFinalization}    
                                    />
                                )
                            }
                            
                        </>
                    )
                }
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    application: state.applications.info.data,
});

const mapDispatchToProps = ( dispatch, ownProps ) => ({
    finishApp: (message) => _ => {
        dispatch( finishApplicationReportingWithFailure( ownProps.match.params.id, message ) );
    },
    signAppFinalization: () => {
        dispatch( signApplicationFinish(ownProps.match.params.id) );
    }

})


export default connect(mapStateToProps, mapDispatchToProps )(Finish);