import axios from '../../helpers/private.axios';
import { notify } from './../common/notify.actions';
import { getCurrentUser } from '../users/data.actions';
import { getSingleExpertInfo } from '../experts/expert_users';

export const GET_SINGLE_USER_UPDATE_REQUEST = 'GET_SINGLE_USER_UPDATE_REQUEST'
export const GET_SINGLE_USER_UPDATE_SUCCESS = 'GET_SINGLE_USER_UPDATE_SUCCESS'
export const GET_SINGLE_USER_UPDATE_ERROR = 'GET_SINGLE_USER_UPDATE_ERROR'

export const GET_SINGLE_PASSWORD_UPDATE_REQUEST = 'GET_SINGLE_PASSWORD_UPDATE_REQUEST'
export const GET_SINGLE_PASSWORD_UPDATE_SUCCESS = 'GET_SINGLE_PASSWORD_UPDATE_SUCCESS'
export const GET_SINGLE_PASSWORD_UPDATE_ERROR = 'GET_SINGLE_PASSWORD_UPDATE_ERROR'

export const ASSIGN_ROLE_REQUEST = 'ASSIGN_ROLE_REQUEST'
export const ASSIGN_ROLE_SUCCESS = 'ASSIGN_ROLE_SUCCESS'

export const CLEAR_SINGLE_USER = 'CLEAR_SINGLE_USER'
export const CLEAR_ALL_USERS = 'CLEAR_ALL_USERS'

export const CLOSE_USER_NOTIFICATIONS = 'CLOSE_USER_NOTIFICATIONS'

export const updateSingleUser = (id, data, history) => (dispatch) => {
  dispatch({ type: GET_SINGLE_USER_UPDATE_REQUEST });
  axios.post(
    `/api/users/${id}`, data)
    .then( user => {
      dispatch({
        type: GET_SINGLE_USER_UPDATE_SUCCESS,
        payload: user
      });
      dispatch( notify( "Дані було успішно відредаговано", "success") );
      if( history ){
        history.push('/admin/users');
      }
    })
    .then( res => {
      dispatch( getCurrentUser() );
    })
    .catch( error => { dispatch({ 'type': GET_SINGLE_USER_UPDATE_ERROR, error: error }) })
}

export const uploadExpertDocuments = ( user_id, documents ) => dispatch => {
    dispatch({ type: GET_SINGLE_USER_UPDATE_REQUEST });

    let formData = new FormData();

    documents.map( doc => {
      if( doc.file ){
        formData.append( doc.name, doc.file );
      }
    })

    axios.post(
      `/api/users/${user_id}/documents`, formData)
      .then( user => {
        dispatch({
          type: GET_SINGLE_USER_UPDATE_SUCCESS,
          payload: user
        });
        dispatch( notify( "Дані було успішно відредаговано", "success") );
      
      })
      .then( res => {
        dispatch( getCurrentUser() );
      })
}

export const finishUserValidation = ( user_id ) => dispatch => {

  dispatch({ type: GET_SINGLE_USER_UPDATE_REQUEST });

  axios.post(
    `/api/users/${user_id}/create-matching`)
    .then( user => {
        dispatch({
          type: GET_SINGLE_USER_UPDATE_SUCCESS,
          payload: user
        });
      dispatch( notify( "Дані експерта фіналізовані", "success") );
      dispatch( getSingleExpertInfo(user_id) );
    })

}


export const updatePasswordUser = (oldPass, newPass) => (dispatch) => {
  dispatch({ type: GET_SINGLE_PASSWORD_UPDATE_REQUEST });
  const newData = { op: oldPass, np: newPass }
  axios.post(`/api/users`, newData)
    .then( user => {
      dispatch({
        type: GET_SINGLE_PASSWORD_UPDATE_SUCCESS
      });
      dispatch( getCurrentUser() );
      dispatch( notify( "Пароль було успішно відредаговано", "success") );
    })
    .catch( error => { dispatch({ 'type': GET_SINGLE_PASSWORD_UPDATE_ERROR, error: error }) })
}

export const closeUserNotify = () => (dispatch) => {
  dispatch({ type: CLOSE_USER_NOTIFICATIONS })
}

export const clearSingleUserData = () => (dispatch) => {
  dispatch({ type: CLEAR_SINGLE_USER })
}

export const clearAllUserData = () => (dispatch) => {
  dispatch({ type: CLEAR_ALL_USERS })
}