import {
    UPLOAD_AGREEMENT_REQ,
    UPLOAD_AGREEMENT_RES
} from '../../actions'

const usersInitialState = {
    uploading_act: false,
    uploading_grunt: false
}

const childs = ( state = usersInitialState, action) => {
    switch( action.type ){
		case UPLOAD_AGREEMENT_REQ:
			return {
				...state,
				uploading_grunt: true
            }
		case UPLOAD_AGREEMENT_RES:
			return {
				...state,
				uploading_grunt: false
            }
            
        

		default:
			return state
    }
}

export default childs