import {
    GET_ALL_APP_NEGOTIATION_REQ,
	GET_ALL_APP_NEGOTIATION_RES,

	CREATE_PROCEDURE_REQ,
	CREATE_PROCEDURE_RES,

	GET_SINGLE_PROCEDURE_REQ,
	GET_SINGLE_PROCEDURE_RES,

	CHANGE_PROCEDURE_STATUS_REQ,
	CHANGE_PROCEDURE_STATUS_RES,

	REQ_GENERATE_REPORTING_FILE,
	RES_GENERATE_REPORTING_FILE
} from '../../actions'

const usersInitialState = {

	list: [],
	list_loaded: false,

	single: null,
	single_loaded: false,

	create_req: false,
	update_req: false,

	error: null,
	
	create_reporiting_processing: false,
	creating_reporting: false,
	reporting_link: null
}

const childs = ( state = usersInitialState, action) => {
    switch( action.type ){
		case GET_ALL_APP_NEGOTIATION_REQ:
			return {
				...state,
				list_loaded: false,
				list: [],
				create_reporiting_processing: false,
				creating_reporting: false,
				reporting_link: null
			}

		case GET_ALL_APP_NEGOTIATION_RES:
			return {
				...state,
				list: action.payload.procedures,
				list_loaded: true,
			}
	
		case CREATE_PROCEDURE_REQ:
			return {
				...state,
				create_req: true
			}

		case CREATE_PROCEDURE_RES:
			return {
				...state,
				create_req: false
			}
		
		case GET_SINGLE_PROCEDURE_REQ:
			return{
				...state,
				single_loaded: false
			}

		case GET_SINGLE_PROCEDURE_RES:
			return{
				...state,
				single_loaded: true,
				single: action.payload
			}
		
		case CHANGE_PROCEDURE_STATUS_REQ:
			return{
				...state,
				update_req: true
			}

		case CHANGE_PROCEDURE_STATUS_RES:
			return{
				...state,
				update_req: false
			}

		case REQ_GENERATE_REPORTING_FILE:
			return {
				...state,
				creating_reporting: false,
				create_reporiting_processing: true
			}
		
		case RES_GENERATE_REPORTING_FILE:
			return {
				...state,
				creating_reporting: true,
				create_reporiting_processing: false,
				reporting_link: action.payload
			}

		default:
			return state
    }
}

export default childs