import React, { useState } from 'react';
import moment from 'moment';
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import { DocumentLink, Button, ButtonWrap, Spinner } from '../../../library';
import { updateDocument, regenerateDocument, deleteDocumentRequest, notify } from '../../../../actions';


const documentStatuses = [
    { status: -2, name: "Видалений документ ❌"},
    { status: -1, name: "Архівний документ 📁"},
    { status: 0, name: "Створена угода"},
    { status: 1, name: "Відправлено фінансовому менеджеру"},
    { status: 2, name: "Новий документ (Проєкт ґрантової угоди)"},
    { status: 3, name: "Відправлений заявнику"},
    { status: 4, name: "Ґрантова угода (Підписана заявником)"},
    { status: 5, name: "Підписання угоди (Директор)"},
    { status: 6, name: "Завершена угода"},
];


export const DocumentDetails = ({ document, template, application, setDocument }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector( state => state.auth.user );
    const [ isRegenerating, setRegeneraing ] = useState( false );
    const [ documentStatus, changeDocumentStatus ] = useState( document.status );

    const changeStatus = ( status ) => (e) => {
        updateDocument( document._id, { status }, history )
            .then( res => {
                console.log('res', res );
                setDocument( res.data.data.document );
                dispatch( notify( res.data.data.message, 'success') );
            });
    }

    const regenerateDocumentAction = () => {
        setRegeneraing( true );
        regenerateDocument(  document._id, {} )
            .then( res => {
                console.log('res', res );
                setRegeneraing( false );
                setDocument( res.data.data.document );
            })
    }


    const deleteDocument = () => {

        if (window.confirm("Ви точно, хочете видалити документ?")) {
            deleteDocumentRequest(  document._id )
                .then( res => {
                    dispatch( notify( res.message, res.type ) );
                    console.log('[res_comp]', res );
                    if( res.type === "success"){
                        history.push(`/rp/application/${document.application_id }/agreement/list`);
                    }
                });

        }

    }


    return(
        <div className="documents__page">  
            <header className="documents__header">
                <h2>{ document.title }</h2>

                <Button 
                    handler={ regenerateDocumentAction } 
                    size="small" 
                    color="gray" 
                    text="Перегенерувати документ" 
                />
            </header>

            <div className="singleExpert__container">
                <div className="singleExpert__left">
                    <div>
                        <b>Статус:</b>
                        { document.status === -2 && "Видалений документ ❌"}
                        { document.status === -1 && "Архівний документ 📁"}
                        { document.status === 0 && "Створена угода"}
                        { document.status === 1 && "Відправлено фінансовому менеджеру"}
                        { document.status === 2 && "Новий документ (Проєкт ґрантової угоди)"}
                        { document.status === 3 && "Відправлений заявнику"}
                        { document.status === 4 && "Ґрантова угода (Підписана заявником)"}
                        { document.status === 5 && "Підписання угоди (Директор)"}
                        { document.status === 6 && "Завершена угода"}
                    </div>

                    <div>
                        <b>Документ згенеруваний:</b> { document.user.name }
                    </div>
                    <div>
                        <b>Дата генерації:</b> { moment( document.created_at ).format('lll') }
                    </div>
                    <div>
                        <b>Шаблон за яким згенеровано:</b> 
                        <Link to={`/documents/templates/${document.template_id}`} className="documents__link">
                            { document.template_name }
                        </Link>
                    </div>
                    <div>
                        <b>Шаблон реквізитів:</b> 
                        { document.bank_details_template === "natural_person" && "Фізична особа (Стипендії)" }
                        { document.bank_details_template === "legal_entity" && "Юридична особа" }
                    </div>
                    <div>
                        <b>Заявка:</b> 
                        <Link to={`/rp/application/${document.application_id }/agreement`} className="documents__link">
                            { document.application_spec_id }
                        </Link>
                    </div>
                </div>
                <div className="singleExpert__right">
                    <div>
                        Для того, щоб видалити документ, він повинент знаходитись у статусі "Архівний"
                    </div>
                    
                    <button 
                        type="button" 
                        className="documents__deleteConfirm button button-decline"
                        onClick={deleteDocument}
                    >
                        Видалити
                    </button>
                </div>
            </div>


            {
                user.role === "admin" && (
                    <div>
                        <h2> Зміна статусу документу (Бачить лише адмніністратор)</h2>
                        <div className="documents__statusChanger">
                            <select value={documentStatus} onChange={ e => changeDocumentStatus( e.target.value ) }>
                                <option> Оберіть статус </option>
                                {
                                    documentStatuses.map( option => {
                                        return (
                                            <option
                                                key={option.status}
                                                value={ option.status }
                                                disabled={document.status < option.status}
                                            >{ option.name }</option>
                                        )
                                    }) 
                                }
                            </select>
                            <button 
                                type="button" 
                                className="documents__statusChanger-button"
                                onClick={ changeStatus( documentStatus )}
                            > Оновити </button>
                        </div>
                    </div>
                )
            }



            {
                template === null && (
                    <div className="documents__error">
                        Будьте уважні! Шаблон цього документу був видалений. Можливо, це вже не актуальний документ!
                    </div>
                )
            }

            {
                !document.fin_info_data && (
                    <div className="documents__warn">
                        <span role="img">⚠️</span> Схоже, що у документі не заповнена фінансова інформація
                    </div>
                )
            }

            {
                !application.fin_details && (
                    <div className="documents__warn">
                        <span role="img">⚠️</span> Схоже, заявник ще не заповнив реквізити
                    </div>
                )
            }


            {
                !isRegenerating ? (
                    document.source && (
                        <>
                            <h2>Згенерований документ:</h2>
                            <DocumentLink 
                                url={document.source}
                                text={document.title}
                            />
                        </>
                    )
                ) : (
                    <Spinner />
                )
            }
            

            <br/>
            {
                template && (
                    <>
                        <h2> Змінні підтягнуті з заявки:</h2>
                        <ul>
                        {
                            template.variables.filter( item => item.mode === "application").map( (field, index) => (
                                <li className="documents__listItem" key={index}>
                                    <b>{ field.label}:</b> 
                                    { document.application_data[ field.field ]}
                                </li>
                            ))
                        }
                        </ul>

                        <h2> Змінні заповнені при генерації:</h2>
                        <ul>
                        {
                            template.variables.filter( item => item.mode === "generate").map(  (field, index) => (
                                <li className="documents__listItem" key={index}>
                                    <b>{ field.label}:</b> 
                                    { document.template_data[ field.name ]}
                                </li>
                            ))
                        }
                        </ul>
                    </>
                )
            }
            
            <br/>
            <ButtonWrap>

                {
                    document.status === 0 && (
                        <Button 
                            handler={ changeStatus( 1 ) } 
                            size="medium"  
                            text="Надіслати фінансисту" 
                            disabled={ document.status === 1 || document.status === -1 || document.source === undefined }
                        />
                    )
                }
                
                {
                    document.status >= 1 && (
                        <Button 
                            handler={ changeStatus( 3 ) } 
                            size="medium"  
                            text="Надіслати заявнику" 
                            disabled={ document.status === 1 || document.status === -1 || document.source === undefined || document.status > 3 }
                        />
                    )
                }


                {
                    document.status !== -1 ? 
                        <Button 
                            handler={ changeStatus( -1 ) } 
                            size="medium" 
                            color="gray" 
                            text="Архівувати" 
                            disabled={ document.status === -1}
                        />
                        :
                        <Button 
                            handler={ changeStatus( 2 ) } 
                            size="medium" 
                            color="gray" 
                            text="Розахрівувати" 
                            
                        />

                }
            </ButtonWrap>

            

        </div>
    );

}