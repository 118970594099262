export const getApplicationTitle = ( application ) => {
    if( application ){

        if( application.data.fields.title_project ){
            return application.data.fields.title_project;
        }

        if( application.data.fields.title_project2 ){
            return application.data.fields.title_project2;
        }
        if( application.data.fields.name_organization ){
            return application.data.fields.name_organization;
        }
        
        return application.spec_id;

    } else {
        return 'Помилка з назвою заявки';
    }

}