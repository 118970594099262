import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Loader, StatusHandler } from '../../../library';

import { search, getAllApplicationReviews } from '../../../../actions';

const SearchApplication = ({ applications, setApplications, type }) => {
    
    const dispatch = useDispatch();
    const { data, loading } = useSelector( state => state.common.search );
    const { reviews_loaded, reviews } = useSelector( state => ({
        reviews_loaded: state.applications.reviews.loaded,
        reviews: state.applications.reviews.reviews
    }) );
    
    console.log('[reviews]', reviews, reviews_loaded )

    let timer = null;
    let mode = "multiple";
    
    if( type === "expert_review"){
        mode = "single";
    }


    const search_applications = ( e ) => {
        
        let value = e.target.value;
        
        if( value.length > 4 ){
            clearTimeout( timer );

            timer = setTimeout( () => {
                dispatch( search( value ) );
            }, 1000);
        }
    }

    const removeApplication = ( app_id ) => () => {
        setApplications( applications.filter( app => app._id !== app_id ))
    }

    const check_application = ( e ) => {
        let app_id = e.target.value;
        let isOn = applications.some( app => app._id === app_id );

        if( mode === "multiple"){
             // Handle Multiple
            if( !isOn ){
                let application = data.find( app => app._id === app_id );
                setApplications( [...applications, {
                    _id: application._id,
                    spec_id: application.spec_id,
                    reporting_review: application.reporting_review
                } ]);
            } else {
                setApplications( applications.filter( app => app._id !== app_id ))
            }
        } else {
            // Handle Single
            if( !isOn ){
                let application = data.find( app => app._id === app_id );
                setApplications( [ {
                    _id: application._id,
                    spec_id: application.spec_id,
                    reporting_review: application.reporting_review
                } ]);

                dispatch( getAllApplicationReviews( application._id ) );

            } else {
                setApplications([]);
            }
        }

        
    }

    useEffect( () => {
        return () => {
            clearInterval( timer );
        }
    }, [] );

    return(
        <>
            <label className="ed-stats__label">
                <div  className="ed-stats__title"> Введіть спеціальний ІД заявки </div>
                <input 
                    type="text"
                    className="ed-stats__input"
                    onChange={search_applications}
                />
            </label>

            {
                loading ? 
                    ( <> Loading... </> ) :
                    ( 
                        <div className="rollbacks__applications"> 
                            { 
                                data.length > 0 && (   
                                    <ul className="rollbacks__list">
                                        {
                                            data.map( ( app, index ) => {
                                                if( index > 10 ) return;

                                                let isChecked = applications.some( selected_app => {
                                                    return selected_app._id === app._id
                                                });
                                                // console.log('[isChecked]', applications, app.spec_id, isChecked );
                                                return(
                                                    <li key={ app._id }>
                                                        <label>
                                                            <input
                                                                type={ mode === "multiple" ? "checkbox" : "radio" }
                                                                name="application"
                                                                className="rollbacks__checkbox"
                                                                value={ app._id }
                                                                onChange={ check_application }
                                                                checked={ isChecked }
                                                            />
                                                            <span>
                                                                { app.spec_id }
                                                            </span>
                                                            <span className="rollbacks__status">
                                                                (
                                                                    <StatusHandler
                                                                        type="tech_review"
                                                                        statusId={app.tech_review.status}
                                                                    />
                                                                )
                                                            </span>
                                                        </label>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                )
                            }
                            {
                                type === "application" && (
                                    applications.length > 0 && (
                                        <div className="rollbacks__selectedApps">
                                            {
                                                applications.map( app => (
                                                    <div key={app._id} className="rollbacks__appItem">
                                                        {app.spec_id}
                                                        <button 
                                                            className="rollbacks__appButton"
                                                            onClick={removeApplication(app._id)}
                                                        > Видалити </button>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    )
                                )
                            }
                            {
                                type === "expert_review" && (
                                    reviews.length > 0 && (
                                        <div className="rollbacks__selectedApps wide">
                                            {
                                                reviews.map( review => {
                                            
                                                    return (
                                                        <div key={review._id} className="rollbacks__appItem">

                                                            <label className="rollbacks__radioLabel">
                                                                <input 
                                                                    type="radio"
                                                                    name="expert_review"
                                                                    className="rollbacks__checkbox"
                                                                />
                                                                <span>{ review.expert.name }</span>
                                                            </label>

                                                            <StatusHandler  type="expert_review" statusId={ review.status.id}/>
                                                        </div>  
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                )
                            }

                        </div>
                    )
            }
        </>
    );

}

export default SearchApplication;