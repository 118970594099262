import React from 'react'
import { connect } from 'react-redux'

import { hide_notify } from '../../../actions/';
import Message from './Message'

class Container extends React.Component {

  timer = null;

  startCountdown = () => {
    if( this.timer === null ){
      if( this.props.list.length !== 0){
        this.timer = setInterval( () => {
          this.props.removeTopMessage()
        }, 4000);
      }
    }
  }

  componentDidMount(){
    this.startCountdown();
  }

  componentDidUpdate(){
    this.startCountdown();
    if( this.props.list.length === 0){
      clearInterval( this.timer );
      this.timer = null;
    }
  }

  componentWillUnmount(){
    clearInterval( this.timer );
    this.timer = null;
  }


  render () {
    const { list, removeTopMessage } = this.props
    return (
      <div className={`notify-container`}>
        {
          list.map( (message, key) => (
            <Message 
              key={key} 
              type={message.type}
              message={message.message} 
              handler={ removeTopMessage}
            /> 
          ))
        }
      </div>
    )
  }
}

const mapStateToProps = ( state ) => ({
  list: state.common.notify.list,
  message: state.common.notify.message,
})

const mapDispatchToProps = (dispatch) => ({
  removeTopMessage: () => {
    dispatch( hide_notify() );
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Container)