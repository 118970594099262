import React from 'react'
import { Row, Cell, StatusHandler } from '../../../library/';

import moment from 'moment';

import { getApplicationTitle } from '../../../../helpers/getApplicationTitle';

export const ProjectHeader = () => (
    <Row type="head">
        <Cell type="medium">#</Cell>
        <Cell type="medium">Заявник</Cell>
        <Cell type="large">Проект</Cell>
        <Cell type="medium">Статус</Cell>
        <Cell type="large">Користувач</Cell>
    </Row>
)


export const ProjectSearchResults = ({item}) => {
    let title = getApplicationTitle( item );
    return(
        <Row 
            type="link"
            to={`/pd/application/${item._id}`}
        >
            <Cell type="medium">{ item.spec_id }</Cell>
            <Cell type="medium">{ item.user.name }</Cell>
            <Cell type="large">{ title.length > 75 ? `${title.substr(0, 75)}...` :  title }</Cell>
            <Cell type="medium">
                <StatusHandler 
                    statusId={item.tech_review.status}
                    type="tech_review"
                />
            </Cell>
            <Cell type="large">
            {
            item.tech_review.user !== undefined ? (
                <>
                {item.tech_review.user.name}
                <div className="text-noapp">
                    { moment(item.tech_review.user.updated_at).format('ll') }
                </div>
                </>
            ) : null
            }
            </Cell>
        </Row>
    )

};


