export class Spacer {

    static get toolbox() {
        return {
            title: 'Spacer',
            icon: `<svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="0.5" y1="0.5" x2="0.5" y2="15.5" stroke="black" stroke-linecap="round"/>
                    <line x1="23.5" y1="0.5" x2="23.5" y2="15.5" stroke="black" stroke-linecap="round"/>
                    <line x1="4.5" y1="4.5" x2="19.5" y2="4.5" stroke="black" stroke-linecap="round"/>
                    <line x1="4.5" y1="11.5" x2="19.5" y2="11.5" stroke="black" stroke-linecap="round"/>
                    <path d="M4.5 11.5V14M19.5 11.5V14" stroke="black"/>
                    <path d="M4.5 4.5V2M19.5 4.5V2" stroke="black"/>
            </svg>`
        };
    }

	constructor({ data, api }){
		this.api = api;
		this.nodes = {
			wrapper: null,
			body: null
		};
		if( 'size' in data && data.size ){
			this.data = data;
			this.preloaded = true;
		} else {
			this.data = {}
		}
		this.render = this.render.bind(this);
	}

    render() {

        let node;
		if (this.nodes.wrapper) {
			node = this.nodes.wrapper;
		} else {
			this.nodes.wrapper = document.createElement('div');
			node = this.nodes.wrapper;
		}

        const { size = 's' } = this.data;

        node.innerHTML = `
                <div data-block class="ce-page ce-tool ce-divider ce-divider--${size}">
                    <form data-size class="ce-tool-config">
                        <div class="ce-divider__container">
                            <h2 class="ce-tool-head__title"> Spacer size: </h2>
                
                            <div class="ce-divider__item">
                                <input id="size-s" name="size" value="s" ${size === 's' ? 'checked' : ''} type="radio">
                                <label for="size-s">Small</label>
                            </div> 
                            <div class="ce-divider__item">
                                <input id="size-m" name="size" value="m" ${size === 'm' ? 'checked' : ''} type="radio">
                                <label for="size-m">Medium</label>
                            </div>
                            <div class="ce-divider__item">
                                <input id="size-b" name="size" value="b" ${size === 'b' ? 'checked' : ''} type="radio">
                                <label for="size-b">Big</label>
                            </div>     
                        </div>           
                    </form>
                </div>
            `;

        const sizeConfig = node.querySelector('[data-size]');
        sizeConfig.addEventListener('change', e => {
            const block = node.querySelector('[data-block]');

            if( e.target.value === "b"){
                block.classList.remove('ce-divider--s');
                block.classList.remove('ce-divider--m');
                block.classList.add('ce-divider--b');
            }
            if( e.target.value === "m"){
                block.classList.remove('ce-divider--b');
                block.classList.remove('ce-divider--s');
                block.classList.add('ce-divider--m');
            }
            if( e.target.value === "s"){
                block.classList.remove('ce-divider--b');
                block.classList.remove('ce-divider--m');
                block.classList.add('ce-divider--s');
            }

        });

        return this.nodes.wrapper;
    }
    save(node) {
        return {
            size: node.querySelector('[data-size] input:checked').value,
        }
    }
}

export default Spacer;