import React from 'react'

export const NumberTextData = ({ value }) => {
    return(
        <>
            <div>
                <b>
                    { value.sum } ₴
                </b>
            </div>
            <div>
                <i>
                    { value.reason }
                </i>
            </div>
        </>
    )
};