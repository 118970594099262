import React from 'react'

import { roles } from './roles'

export const RenderRoles = ({ role, handleChange}) => {

  return(
    <div className="roles__container">
      {
        roles.map((item, key) => (
            <label className="roles__item" key={key}>
              <input
                className="rolex__checkbox"
                type="radio"
                value={item.title}
                name="role"
                checked={item.title === role ? true : false}
                onChange={ handleChange }
              />
              <span>{ item.name }</span>
            </label>
          )
        )
      }
    </div>
  )
}

export default RenderRoles