import React from 'react'

export const PanelItem = ({ title, value }) => (
  <div className="application__body-item">
    <div className="application__body-item-name text-gray">
      { title }
    </div>
    <div className="application__body-item-value">
      { value }
    </div>
  </div>
)