import axios from '../../helpers/private.axios'
import config from '../../config'
import { GET_ALL_COUNCIL_SUCCESS } from './data.actions'

export const CREATE_COUNCIL_SUCCESS = 'CREATE_COUNCIL_SUCCESS'
export const DELETE_COUNCIL = 'DELETE_COUNCIL'
export const CLEAR_COUNCIL = 'CLEAR_COUNCIL'

export const createCouncil = ( data ) => (dispatch) => {
  axios.post(`${config.apiPath}/api/councils/create`, data)
    .then(
      () => {
        axios.get(
          `${config.apiPath}/api/councils`).then(
          res => {
            dispatch({
              type: GET_ALL_COUNCIL_SUCCESS,
              payload: res.data.data.councils
            })
          }
        )
      }
    )
}

export const clearCouncil = () => (dispatch) => {
  dispatch({ type: CLEAR_COUNCIL })
}

export const deleteCouncil = (id) => () => {
  axios.delete(`${config.apiPath}/api/council/${id}`)
    .then( res => {
      console.log('DELETED COUNCIL:', res )
    })
}
