import axios from '../../helpers/private.axios';

export const GET_SINGLE_ROLLBACK_REQ = "GET_SINGLE_ROLLBACK_REQ";
export const GET_SINGLE_ROLLBACK_RES = "GET_SINGLE_ROLLBACK_RES";


export const getSingleRollback = ( rollback_id ) => (dispatch, getState) => {
    dispatch({ type: GET_SINGLE_ROLLBACK_REQ, })

    axios.get(`/api/rollback/${rollback_id}`)
    .then(
        res => {
            console.log('res', res );
            dispatch({ type: GET_SINGLE_ROLLBACK_RES, payload: res.data.data });
        }
    )

}