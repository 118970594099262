import React from 'react'
import { Breadcrumbs, Table, Row, Cell } from '../../library';

const Folders = () => {

    return(
        <>
            <Breadcrumbs
                items={[
                    { name: 'Мультипідпис', link: `/agreements` }
                ]}
            />
            <h1 className="page-title"> Угоди </h1>
            <Table>
                <Row type="head">
                    <Cell type="id">#</Cell>
                    <Cell type="large"> Розділ </Cell>
                </Row>

                <Row type="link" to="/agreements/main">
                    <Cell type="id"> 1. </Cell>
                    <Cell type="large"> Грантові угоди </Cell>
                </Row>
                <Row type="link" to="/agreements/additional">
                    <Cell type="id"> 2. </Cell>
                    <Cell type="large"> Додаткові угоди </Cell>
                </Row>
                <Row type="link" to="/agreements/acts">
                    <Cell type="id"> 3. </Cell>
                    <Cell type="large"> Акти виконаних проектів </Cell>
                </Row>
            </Table>
        </>
    );
}

export default Folders;