import {
    GET_UNASSIGNED_USERS_REQ,
    GET_UNASSIGNED_USERS_RES,
} from '../../actions'

const contestsInitialState = {
    data: [],
    count: null,
    total: 0,
    loading: false,
    loaded: false,
    errors: []
}

const AllUsers = ( state = contestsInitialState, action) => {
    switch( action.type ){
        
        case GET_UNASSIGNED_USERS_REQ:
            return {
                    ...state,
                loading: true
            }

        case GET_UNASSIGNED_USERS_RES:
            return {
                ...state,
                loading: false,
                loaded: true,
                data: action.payload,
                counter: action.counter,
                total: action.total
            }

        default:
            return state
    }
}

export default AllUsers
