import React, { useEffect } from 'react'
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Breadcrumbs, Table, Row, Cell, Button } from '../../../library';

import { getAllDirections, clearCachedDirections } from '../../../../actions';


const Commision = () => {
    const dispatch = useDispatch();

    useEffect( () => {
        dispatch( getAllDirections('expert_supervisory') );

        return () => {
            dispatch( clearCachedDirections() );
        }
    }, [] );
    const loaded = useSelector( state => state.direction.list.loaded );
    let directions = useSelector( state => state.direction.list.data );

    return(
        <>
            <Breadcrumbs
                items={[
                    { name: "Експертний відділ", link: `/ed/` },
                    { name: "Експерти", link: `/ed/experts`},
                    { name: "Відбір експертів", link: "/ed/experts/selection"},
                    { name: "Наглядова рада", link: "/ed/experts/selection/expert_supervisory"},
                ]}
            />
            <header className="direction__header">
                <h1 className="page-title"> Наглядова рада (Відбір експертів) </h1>
                <Link type="link" className="direction__btn" to="/dr/experts/create/expert_supervisory">
                    Створити засідання наглядової ради
                </Link>
            </header>
            
            {/* <form>
                <input className="direction__search" placeholder="Введіть номер або дату для пошуку"/>
            </form> */}
            
            {
                directions.length > 0 && (
                    <Table>
                        <Row>
                            <Cell type="id" className="id">#</Cell>
                            <Cell className="medium"> Наглядова рада від </Cell>
                        </Row>
                        {
                            directions.map( (direction, key ) => {
                                return(
                                    <Row 
                                        key={direction._id} 
                                        type="link" 
                                        to={`/dr/experts/${direction._id}`}
                                        className={ direction.status === 1 ? 'transparent' : ''}
                                        
                                    >
                                        <Cell type="id" className="id">{ key + 1 } </Cell>
                                        <Cell className="medium">
                                            #{ direction.counter } від { ` ${moment(direction.created_at ).format('lll')} `}
                                        </Cell>
                                        <Cell className="medium">
                                            { direction.status === 1 && (<span className="text-green"> Наглядова рада проведена </span>)}
                                        </Cell>
                                    </Row>
                                )
                            })
                        }
                    </Table>
                )
            }
        </>
    )

}

export default Commision;