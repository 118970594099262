import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import { Table, Row, Cell } from '../../../../library/Table'
import { Loader, Breadcrumbs } from '../../../../library'
import { roles } from './roles'

import { getAllUsers, clearAllUserData } from '../../../../../actions'

class UserList extends Component {
  componentDidMount(){
    this.props.getUsers()
  }

  displayRole = (role) => {
    const obj = roles.find( item => item.title === role)
    return obj.name
  }

  componentWillUnmount () {
    this.props.clearAllUsers()
  }

  render(){
    const { loading, data } = this.props

    if( loading ){
      return <Loader/>
    } else {
      return(
        <div className="innerWrap">
          <Breadcrumbs items={[
            {name:'Адмiнiстративна панель', link: '/admin'},
            {name:'Користувачі', link: '/admin/users'}
          ]}/>
          <h1 className="page-title">Користувачі</h1>

          <Table>
            <Row type="head">
              <Cell type="large">Користувач</Cell>
              <Cell type="large">Пошта</Cell>
              <Cell type="large">Роль</Cell>
              <Cell type="large">Зареєстрований(на)</Cell>
            </Row>
            {
              data && data.map( (user, key) => {
                return(
                  <Row
                    key={key}
                    type="link"
                    to={`/admin/users/${user._id}`}
                  >
                    <Cell type="large">{user.name}</Cell>
                    <Cell type="large">{user.email}</Cell>
                    <Cell type="large">
                      { this.displayRole(user.role) }
                    </Cell>
                    <Cell type="large">
                      { moment( user.created_at ).format('LLL') }
                    </Cell>
                  </Row>
                )
              })
            }
          </Table>
        </div>
      )
    }

  }
}

const mapStateToProps = (state) => ({
  data: state.users.allUsers.data,
  count: state.users.allUsers.count,
  loading: state.users.allUsers.loading,
})

const mapDispatchToProps = (dispatch) => ({
  getUsers: () => {
    dispatch( getAllUsers() )
  },
  clearAllUsers: () => {
    dispatch( clearAllUserData() )
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(UserList)