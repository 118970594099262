import React from 'react'

import { Layout, Breadcrumbs } from '../../library'
import { Table, Row, Cell } from '../../library/Table'

import folderIcon from './icons/folder.svg'

const AdminMain = () => {
  const navLinks = [
    { title: 'Користувачi', link: '/admin/users' },
    { title: 'Програми', link: '/admin/seasons' },
    { title: 'Експертнi ради', link: '/admin/users/council' },
    { title: 'Методика експертного оцінювання', link: '/admin/evaluation/' },
    { title: 'Відкот-машина', link: '/admin/rollback' }
  ]
  return (
    <Layout
      className="projects-division"
      header={<Breadcrumbs items={[
        {name:'Адмiн панель', link: '/admin'}
      ]}/>}
    >
      <Table>
        <Row type="head">
          <Cell type="id">#</Cell>
          <Cell type="large"> Назва проекту </Cell>
        </Row>
        {
          navLinks && navLinks.map( (item, key) => (
            <Row
              key={key}
              type="link"
              to={item.link}
            >
              <Cell type="folder">
                <img src={folderIcon} alt="Категорія з лотами" />
              </Cell>
              <Cell type="large">{item.title}</Cell>
            </Row>
          ))
        }
      </Table>
    </Layout>
  );
}

export default AdminMain;