import React from 'react'
import { connect } from 'react-redux';

import { 
    Table, Row, Cell
} from '../../../../library';


const RootAgreement = ({ application }) => {
    return(
        <Table>
                <Row type="link" to={`/rp/application/${application._id}/agreement/upload`}>
                    <Cell type="large"> 
                        Завантажити грантову угоду мануально
                    </Cell>
                </Row>
                <Row type="link" to={`/rp/application/${application._id}/agreement/list`}>
                    <Cell type="large"> 
                        Згенерувати грантову угоду по шаблону (new)
                    </Cell>
                </Row>
                <Row type="link" to={`/rp/application/${application._id}/agreement/scaned`}>
                    <Cell type="large"> 
                        Завантажити скановану копію
                    </Cell>
                </Row>
                <Row type="link" to={`/rp/application/${application._id}/agreement/additional`}>
                    <Cell type="large"> 
                        Додаткові угоди
                    </Cell>
                </Row>
            </Table>
    )


}

const mapStateToProps = (state) => ({
    application: state.applications.info.data,
    loading: state.applications.info.loading
})


export default connect(mapStateToProps)(RootAgreement);