import axios from '../../helpers/public.axios';

export const GET_SIGNER_LIST_REQ = "GET_SIGNER_LIST_REQ";
export const GET_SIGNER_LIST_RES = "GET_SIGNER_LIST_RES";
export const GET_SIGNER_LIST_ERR = "GET_SIGNER_LIST_ERR";


export const getSignerList = ( sign_id ) => ( dispatch, getState ) => {

    if( sign_id ){
        dispatch({ type: GET_SIGNER_LIST_REQ });
        axios.get(`https://sign.document.online/api/v2/uaculturalfoundation/item/${sign_id}/unsign`)            
        .then( res => {
            console.log('res', res );
            dispatch({ type: GET_SIGNER_LIST_RES, payload: res.data.Signs });
        });
    }

}