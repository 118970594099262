import {
    GET_EXPERTS_BY_SEASON_REQ,
    GET_EXPERTS_BY_SEASON_RES
} from '../../actions'

const usersInitialState = {
	error: null,
	loading: false,
	loaded: false,
	data: null,
}

const seasons = (state = usersInitialState, action) => {
	switch (action.type) {
		case GET_EXPERTS_BY_SEASON_REQ:
            return ({
                ...state,
                loading: true,
                loaded: false,
                data: null
            })
		case GET_EXPERTS_BY_SEASON_RES:
            return ({
                ...state,
                loading: false,
                loaded: true,
                data: action.payload
            })

		default:
            return state
	}
}

export default seasons;