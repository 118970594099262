import React, { useState } from 'react'
import { connect } from 'react-redux';
import uniqid from 'uniqid';
import moment from 'moment';


import { 
    Table, Row, Cell,
    Signer, RoleChecker
} from '../../../../library';

import { signAdditional, uploadFile } from '../../../../../actions';

const Additional = ({
    app, documents, uploading, loaded, user,
    uploadFileAction, signAdditionalAction
}) => {

    const [ additionalAgreement, setAdditional ] = useState( null );

    const handleAdditionalFile = ( e ) => {
        console.log( e.target.files );
        if( e.target.files.length > 0){
            uploadFileAction( e.target.files[0],  'additional_agreements', uniqid() );
            setAdditional( e.target.files[0] );
        }
    }


    if( app.reporting_review.grunt_agreement.status < 4 ){
        return(
            <div className="additional_info text">
                Додаткові угоди можна завантажити лише після завантаження та підпису основної угоди
            </div>
        );
    }

    return(
        <div className="project-app-wrapper">
                <div className="project-app-grand-wrap">
                    <div className="project-app-grand">
                        <div className="project-app-grand-title">Додаткові угоди</div>
                    </div>

                    <div className="project-app-grand">
                        <div className="project-app-grand-title">Завантажити нову додаткову угоду</div>
                        <form 
                            className="project-app-grand-additional-upload"
                            onSubmit={ () => {}}
                        >
                            <label className="project-app-grand-file">
                                
                                {
                                    uploading ? "Угода завантажується" : "Натисніть, щоб обрати файл"
                                }
                                <input
                                    hidden
                                    type="file" 
                                    name="additional_agreement"
                                    className="hidden"
                                    onChange={handleAdditionalFile}
                                    accept=".xls, .xlsx, .pdf"
                                    required
                                    tabIndex="1"
                                    disabled={uploading}   
                                />
                            </label>   
                        </form>

                    </div>
                    


                    <div className="project-app-grand">
                        <Table>
                            <Row type="head">
                                <Cell type="medium"> Угода </Cell>
                                <Cell> Підпис </Cell>
                                <Cell> Статус </Cell>
                            </Row>
                            {
                                app.reporting_review.grunt_agreement.additionalFiles && (
                                    <>
                                        {
                                            app.reporting_review.grunt_agreement.additionalFiles.length === 0 && (
                                                <Row>
                                                    <Cell type="large"> Додаткових угод немає </Cell>
                                                </Row>
                                            )
                                        }
                                        {
                                            app.reporting_review.grunt_agreement.additionalFiles.map( agreement => {
                                                if( agreement !== null ){
                                                    return(
                                                        <Row key={ agreement.id }>
                                                            <Cell type="medium"> 
                                                                <a  
                                                                    className="text-link"
                                                                    href={ agreement.url }
                                                                    target="_blank" 
                                                                    rel="noopener noreferrer"
                                                                > 
                                                                    <span> Додаткова угода від  </span>
                                                                    <span> { moment(agreement.created_at).format('lll') } </span>
                                                                </a>
                                                            </Cell>
                                                            <Cell>
                                                                {
                                                                    agreement.status === 1 && (
                                                                        <RoleChecker 
                                                                            allowedRoles={['chief']} 
                                                                            user={user}
                                                                            message="Тільки директор може підписати грантову угоду"
                                                                        >
                                                                            <Signer 
                                                                                message="Підписати"
                                                                                ids={[ agreement.sign_id] }
                                                                                statusChangerCallback={ () => {
                                                                                    signAdditionalAction( agreement.id );
                                                                                }}
                                                                            />
                                                                        </RoleChecker>
                                                                    )
                                                                }
                                                            </Cell>
                                                            <Cell>
                                                                { agreement.status === 1 && `Угоду завантажено` }
                                                                { agreement.status === 2 && `Угоду підписано директором` }
                                                                { agreement.status === 3 && `Угоду підписано директором і заявником` }
                                                            </Cell>
                                                        </Row>
                                                    )
                                                }
                                            })
                                        }
                                    </>
                                )
                            }
                        </Table>

                    </div>
                </div>

        </div>
    );
}


const mapStateToProps = (state) => ({
    user: state.auth.user,
    app: state.applications.info.data,
    documents: state.applications.info.documents,
    loading: state.applications.info.loading,
    loaded: state.applications.info.loaded,
    uploading: state.matching.uploading.uploading_grunt
});


const mapDispatchToProps = (dispatch, ownProps ) => ({


    uploadFileAction: ( file, type, additional_id ) => {
        dispatch( uploadFile( 
            ownProps.match.params.id, 
            file, type, additional_id,
        ));
    },

    signAdditionalAction: ( additional_agreement_id ) => {
        dispatch(
            signAdditional(
                ownProps.match.params.id, 
                additional_agreement_id
            )
        );
    }


});

export default connect( mapStateToProps, mapDispatchToProps )( Additional );