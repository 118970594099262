import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment';


import { Table, Row, Cell, Empty,  Button } from '../../../../../library';
import {  pdf_icon, xls_icon, spinner } from '../../../icons';

import { uploadNegotiationFile, removeNegotiationFile } from '../../../../../../actions';

class FileUploader extends Component{

    state = {
        uploader: false,
        uploading: false,
        file: null
    }

    removeFile = ( file_id ) => (e) => {
        let sure = window.confirm("Ви впевнені, що хочете видалити файл?")
        if( sure ){
            console.log('Remove file', sure, file_id);
            
            this.props.deleteFile( file_id );
        }

    }

    showUploader = () => {
        this.setState({
            uploader: !this.state.uploader
        })
    }

    fileChangeHandler = (e) => {
        const { uploadFile } = this.props;
        uploadFile( e.target.files[0] );

        this.setState({
            uploading: true,
            file: e.target.files[0]
        });


    }

    render(){
        const { removeFile, showUploader, fileChangeHandler } = this;
        const { uploader, uploading, file } = this.state;
        const { reporting_files } = this.props.procedure;
        const { user } = this.props;

        return(
            <div className="expert-solution-form">
                <header className="negotiation__header">
                    <h1 className="expert-solution-form-title">Прікріплені файли</h1>
                    <Button 
                        handler={ showUploader }
                        size="small" 
                        text="Завантажити файл" 
                    /> 
                </header>

                {
                    uploader && (
                        <div className="popup">
                            <div className="popup__blackwrap" onClick={showUploader}></div>
                            <div className="popup__body">
                                <form>
                                    <h2> Завантаження файлів </h2>
                                    {
                                        uploading && (<div className="text-gray uploader__message"> Не закривайте вікно, поки файл завантажується </div>)
                                    }
                                    <label className={`uploader__label ${ uploading && 'uploading'}`}>
                                        <input 
                                            type="file" 
                                            onChange={fileChangeHandler}
                                            accept=".xls, .xlsx, .pdf"
                                            hidden    
                                        />
                                        {
                                            uploading && 
                                                <div>
                                                    <span>{file.name}</span>
                                                    <img width="60" src={spinner} alt="spinner" />
                                                </div>
                                        }
                                        
                                    </label>

                                </form>
                            </div>
                        </div>
                    )
                }


                <Table>
                    <Row type="head">
                        <Cell type="id"></Cell>
                        <Cell type="medium">Файл</Cell>
                        <Cell type="medium">Користувач</Cell>
                        <Cell type="small">Дата завантаження</Cell>
                        <Cell type="medium">Дії</Cell>
                    </Row>
                    {
                        reporting_files.length > 0 ? 
                        <>
                            {
                                reporting_files.map( file => {

                                    let showDeleteButton = false;
                                    if( user._id  === file.user._id ){
                                        showDeleteButton = true;
                                    }

                                    if( user.role === "admin" ||  user.role  === "project_head"){
                                        showDeleteButton = true;
                                    }

                                    return(
                                        <Row key={file._id}>
                                            <Cell type="id">
                                                { file.extension.toLowerCase() === "pdf" && <img className="reporting_file_icon" src={pdf_icon} alt="pdf" /> }
                                                { file.extension === "xls" && <img className="reporting_file_icon" src={xls_icon} alt="xls" /> }
                                                { file.extension === "xlsx" && <img className="reporting_file_icon" src={xls_icon} alt="xls" /> }
                                            </Cell>
                                            <Cell type="medium">
                                                <a href={file.absolute_path} className="application__documents-protocol" target="_blank" rel="noopener noreferrer">
                                                    { file.name } 
                                                </a>
                                            </Cell>
                                            <Cell type="medium">
                                                { file.user.name }
                                            </Cell>
                                            <Cell type="small">{ moment( file.created_at ).format('lll')  }</Cell>
                                            <Cell type="medium">
                                                {
                                                    showDeleteButton && (
                                                        <Button 
                                                            handler={removeFile( file._id ) }
                                                            size="link" 
                                                            color="red" 
                                                            text="Видалити" 
                                                        />
                                                    )
                                                }
                                            </Cell>    
                                        </Row>
                                    )
                                })
                            }
                        </> : 
                        <Empty text="Ще немає файлів" />
                    }

                </Table>

            </div>
        )
    }

}


const mapStateToProps = (state) => ({
    procedure: state.matching.negotiation.single,
    user: state.auth.user,
});

const mapDispatchToProps = ( dispatch, ownProps ) => ({
    uploadFile: ( file ) => {
        dispatch( 
            uploadNegotiationFile( ownProps.match.params.negotiation_id, file )
        );
    },
    deleteFile: ( file_id ) => {
        dispatch( removeNegotiationFile( file_id, ownProps.match.params.negotiation_id ) );
    }
});


export default connect(mapStateToProps, mapDispatchToProps)(FileUploader);