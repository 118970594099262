import React from 'react';

import './round.scss';

export const Round = ({status}) => {
    let color = '';
	switch( status ){
		case 1:
			color = '#3EB29D';
			break;

		case -1:
			color = '#CE5151';
			break;

		case 'progress':
			color = '#FFCC00';
			break;

		default:
			color = '#9D9D9C';	
	}

	let styles = {
		background: color
	}
	console.log('[status]', status, '[color]', color );
	return (
		<div className="status-round" style={styles}></div>
	);
}

export default Round;
