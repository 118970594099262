import { combineReducers } from 'redux'

import groups from './groups';
import single from './single';
import question from './question';
import methodology from './methodology';

const evaluation = combineReducers({
    groups,
    single,
    question,
    methodology
})

export default evaluation;