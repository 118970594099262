import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { Layout, Breadcrumbs, Table, Row, Cell  } from '../../../library';

import { getAllRollbacks } from '../../../../actions';



const RollbackList = () => {
    const dispatch = useDispatch();

    const { loaded, all } = useSelector( state => state.rollbacks );
    console.log('all', all, loaded );
    
    useEffect(() => {
        dispatch( getAllRollbacks() );
    }, [] )

    return(
        <>
            <Breadcrumbs items={[
                {name: 'Адмінка', link: `/admin` },
                {name: 'Відкот-машина', link: `/admin/rollback` }
            ]}/>

            <h1 className="page-title">Відкот-машина</h1>
            
            <Link className="catalog__button" to="/admin/rollback/create"> Новий відкот </Link>

            <Table>
                <Row type="head">
                    <Cell type="id">#</Cell>
                    <Cell type="medium">Тип</Cell>
                    {/* <Cell type="medium">Статус</Cell> */}
                    <Cell type="medium">Юзер</Cell>
                    <Cell type="medium">Дата</Cell>
                    {/* <Cell type="medium">Протокол</Cell> */}
                </Row>

                {
                    loaded && (
                        <>
                            {
                                all.map( (rollback, index) => {
                                    return(
                                        <Row key={rollback._id} type="link" to={`/admin/rollback/${rollback._id}`}>
                                            <Cell type="id">{index+1}</Cell>
                                            <Cell type="medium">
                                                {rollback.type === "application" && 'Заявка'} 
                                                {rollback.type === "expert_review" && 'Відгук експерта'} 
                                            </Cell>
                                            {/* <Cell type="medium">Статус</Cell> */}
                                            <Cell type="medium">{ rollback.user.name }</Cell>
                                            <Cell type="medium">{ moment(rollback.created_at).format('lll') }</Cell>
                                        </Row>
                                    )
                                })
                            }
                        </>
                    )
                }
            </Table>

            
        </>
    )
}


export default RollbackList;