const review_rollback_schema = [
    {
        label: "Не оброблено",
        name: "assigned",
        status: 0
    },
    {
        label: "Відправлено на валідацію",
        name: "need_to_validate",
        status: 2
    },
    {
        label: "Потрібне доопрацювання",
        name: "need_to_rework",
        status: 4
    }
];

export default review_rollback_schema;

// -3 : Confilcs
// -2 : fierd 
// -1 : Can't apply
// 0 : Assigned
// 1 : Started
// 2 : Need To Validate
// 3 : Success Validated
// 4 : Need to Rework
// 5: Ended