import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import AppHead from './AppHead'
import { 
  Layout, Loader, TabsNav 
} from '../../../library'

import AppInfo from './AppInfo'
import AppDetails from './AppDetails'
import AppExperts from './AppExperts'
import AppLogs from './AppLogs'
import ExpertInfo from './ExpertInfo'
import AppFinal from './AppFinal';
import ReviewHistory from './ReviewHistory';

import { getApplicationInfo, clearApplicationInfo, getSingleField, } from '../../../../actions'

class ExpertsApp extends React.Component {
  componentDidMount() {
    this.props.getApplication(this.props.match.params.id);
    this.props.getDataSingleField('keywords');
    this.props.getDataSingleField('culture');
  }

  componentWillUnmount() {
    this.props.clearApplication()
  }

  render(){
    const { data, breadcrumbs, reviews, loaded } = this.props;
    const { id } = this.props.match.params;

    if( !loaded ){
      return <Loader/>;
    }

    let isAllReviewsFinished = false;
    
    if( reviews.active.length === 5){
      isAllReviewsFinished = reviews.active.every( ( item ) => {
        console.log( item.review.status.id, item.review.status.id === 5)
        if( item.review.status.id === 5){
          return true;
        }
        return false;
      });
    }
    
    console.log( 'isAllReviewsFinished', isAllReviewsFinished );


    const headerNav = [
      { slug: 'info', title: 'Загальна Інформація'},
      { slug: 'details', title: 'Детально'},
      { slug: 'review', title: 'Експерти'},
      { slug: 'report', title: 'Історія заявки'},
      { slug: 'finish', title: 'Фіналізація заявки'}, 
    ]

    return (
      <>
        <Layout
          className="projects-division"
          paddingTop="20px"
          header={ 
          <TabsNav route="/ed/application" tabs={ headerNav } location={id} />
          }
        >
          <AppHead
            name={data.user.name}
            spec_id={data.spec_id}
            status={data.tech_review.status}
            created_at={ data.created_at }
            breadcrumbs={breadcrumbs}
            reviews={reviews}
          />
          <Switch>
            <Redirect exact from="/ed/application/:id/" to="/ed/application/:id/info"/>
            <Route exact path="/ed/application/:id/info" component={ AppInfo }/>
            <Route exact path="/ed/application/:id/details" component={ AppDetails }/>
            <Route exact path="/ed/application/:id/review" component={ AppExperts }/>
            <Route exact path="/ed/application/:id/review/:reviewid" component={ ExpertInfo }/>
            <Route exact path="/ed/application/:id/review/:reviewid/history" component={ ReviewHistory }/>
            <Route exact path="/ed/application/:id/report" component={ AppLogs }/>
            <Route exact path="/ed/application/:id/finish" component={ AppFinal }/>
          </Switch>
        </Layout> 
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  data: state.applications.info.data,
  breadcrumbs: state.applications.info.breadcrumbs,
  reviews: state.applications.info.reviews,
  loaded: state.applications.info.loaded
})

const mapDispatchToProps = (dispatch) => ({
  getApplication: (id) => {
    dispatch(getApplicationInfo(id))
  },
  clearApplication: () => {
    dispatch(clearApplicationInfo())
  },
  getDataSingleField: ( type ) => {
    dispatch( getSingleField( type ) )
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ExpertsApp)