import {
  GET_SINGLE_COUNCIL_REQUEST,
  GET_SINGLE_COUNCIL_SUCCESS,
  GET_SINGLE_COUNCIL_ERROR
} from '../../actions';

const contestsInitialState = {
  data: [],
  loading: false,
  loaded: false,
  errors: []
};

const SingleCouncil = ( state = contestsInitialState, action) => {
  switch( action.type ){
    case GET_SINGLE_COUNCIL_REQUEST:
      return {
        ...state,
      loading: true
      }

    case GET_SINGLE_COUNCIL_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: {
          ...action.payload,
          users: [
            {
              id: 1,
              name: 'Admin',
              applications: 0
            },
            {
              id: 5,
              name: 'Пирожок с капустой',
              applications: 0
            }
          ]
        }
      }

    case GET_SINGLE_COUNCIL_ERROR:
      return {
        ...state,
        error: action.error,
      }

    default:
      return state;
  }
}

export default SingleCouncil;
