import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import UnassignedFilters from './UnassignedFilters';
import GenerateRegister from './GenerateExpertsReister';

import { 
    Layout, Breadcrumbs, Loader, TabsNav,
    Table, Row, Cell
} from '../../../library';

import { 
    getAllFiltredExpertsList,
    getAllExpertsList,
    getRootSeasons,
    getSingleField
} from '../../../../actions';
import { loadState } from '../../../../helpers/storeLocalData';

const getLabelForValue = ( value, name,  options ) => {
    // console.log('[v]', value, '[n]', name, '[o]', options );

    if( !value ){
        return '';
    }

    if( name === "culture"){
        const option = options.find( opt => opt.value === value )
        if( option ){
            return option.name;
        }
    }

    if( name === "keywords" ){

        let result = [];

        if( Array.isArray( value ) ){
            value.forEach( keyword => {
                const option = options.find( opt => opt.value === keyword )
                if( option ){
                    result.push(option.name);
                }
            })
        }

        return result.join(', ');
    }


    if( name === "program"){
        // getted seasons
        let program = null;
        const prog = options.map( ({ season, programs }) => {
            let finded = programs.find( program => program._id === value );
            if( finded ){
                program = finded;
            }
        });

        if( program ){
            return program.title.uk;
        }


        return 'program';
    }


    return 'test'

}

const ExpertsListNew = () => {

    const dispatch = useDispatch();

    const { keywords, keywords_loaded, culture, culture_loaded } = useSelector( state => state.common.fields );
    const loaded = useSelector( state => state.expert.users.loaded );
    let users = useSelector( state => state.expert.users.data );
    const seasons = useSelector( state => state.programs.seasons );

    console.log('[users]', users, loaded );

    let filters = loadState( `experts_filters_all` );

    useEffect( () => {
        dispatch( getAllFiltredExpertsList(filters) );
        dispatch( getSingleField('keywords') );
        dispatch( getSingleField('culture') );
        dispatch( getRootSeasons() );

    }, [] );

    if( !loaded ){
        return <Loader />
    }

    return (
        <Layout
            className="projects-division"
        >   
            <h1 className="page-title">Експерти УКФ</h1>
            <Breadcrumbs
                items={[
                    { name: "Експертний відділ", link: `/ed/` },
                    { name: "Експерти", link: `/ed/experts`},
                    { name: "Активні ексерти", link: `/ed/experts/list`},
                ]}
            />

            <header className="ed-stats__header">
                <UnassignedFilters type="all" />
                <GenerateRegister type="expert"/>
            </header>

            <Table>
                <Row type="head">
                    <Cell type="large"> Експерт </Cell>
                    <Cell type="large"> Основна програма </Cell>
                    <Cell type="large"> Сектор </Cell>
                    <Cell type="large"> Ключові слова </Cell>
                    <Cell type="small"> Загально </Cell>
                    <Cell type="small"> В процесі </Cell>
                    <Cell type="small"> Не оброблено </Cell>
                    <Cell type="medium"> Завершених / Відмов </Cell>
                </Row>
                {
                    users.map( ( item, index ) => {
                        const { reviews, user, count } = item;
                        return(
                            <Row 
                                key={user._id}
                                type="link"
                                to={`/ed/experts/${user._id}`}  
                                className="test_row"  
                            >
                                <Cell type="large"> 
                                    { index + 1}. 
                                    { user.name }
                                </Cell>
                                <Cell type="large"> 
                                    { seasons.loaded && getLabelForValue( user.main_program , 'program', seasons.data )  }
                                </Cell>
                                <Cell type="large"> 
                                    { culture_loaded && getLabelForValue( user.cultural_sector, 'culture', culture.options.options )  }
                                </Cell>
                                <Cell type="large">
                                    { keywords_loaded && getLabelForValue( user.keywords, 'keywords', keywords.options.options )  }
                                </Cell>
                                <Cell type="small">
                                    <b>{ count }</b>
                                </Cell>
                                <Cell type="small">
                                    <span className="text-gray"> { reviews.inProgress.length }  </span>
                                </Cell>
                                <Cell type="small">
                                    <span className="text-gray">{ reviews.notHandled.length } </span>
                                </Cell>
                                <Cell type="medium">
                                    <span className="text-green">{ reviews.ended.length }</span>
                                    <span>/</span>
                                    <span className="text-red"> { reviews.failed.length }  </span>
                                </Cell>
                            </Row>
                        );

                    })
                }
            </Table>

        </Layout>
    )

}




class ExpertsList extends React.Component {

    componentDidMount = () => {
        this.props.getAllUsersList();
        this.props.getAllPrograms();
    }

    render = () => {
        const { data, loaded } = this.props.users;
        const programsLoaded = this.props.programs.loaded;
        const programs = this.props.programs.data;

        if( !loaded ){
            return <Loader />
        }

        return(
            <Layout
                className="projects-division"
            >   
                <h1 className="page-title">Експерти УКФ</h1>
                <Breadcrumbs
                    items={[
                        { name: "Експертний відділ", link: `/ed/` },
                        { name: "Експерти", link: `/ed/experts`},
                        { name: "Активні ексерти", link: `/ed/experts/list`},
                    ]}
                />

            
                <Table>
                    <Row type="head">
                        <Cell type="large"> Експерт </Cell>
                        {/* <Cell type="large"> Основна програма </Cell> */}
                        <Cell type="medium"> Загально заявок </Cell>
                        <Cell type="medium"> Завершених </Cell>
                        <Cell type="medium"> В процесі </Cell>
                        <Cell type="medium"> Не оброблених </Cell>
                        <Cell type="medium"> Відмов/Конфліктів </Cell>
                    </Row>
                    {
                        data.map( (item, index ) => {
                            const { reviews, user, count } = item;
                            return(
                                <Row 
                                    key={user._id}
                                    type="link"
                                    to={`/ed/experts/${user._id}`}    
                                >
                                    <Cell type="large"> { index + 1 }. { user.name }</Cell>
                                    {/* <Cell type="large"> 
                                        {
                                            programsLoaded && (
                                                <>
                                                    { getProgramName( user.main_program , programs ) }
                                                </>
                                            )
                                        }
                                    </Cell> */}
                                    <Cell type="medium">
                                        <b>{ count }</b>
                                    </Cell>
                                    <Cell type="medium">
                                        <span className="text-green">{ reviews.ended.length }</span>
                                    </Cell>
                                    <Cell type="medium">
                                        <span className="text-gray"> { reviews.inProgress.length }  </span>
                                    </Cell>
                                    <Cell type="medium">
                                        <span className="text-gray">{ reviews.notHandled.length } </span>
                                    </Cell>
                                    <Cell type="medium">
                                        <span className="text-red"> { reviews.failed.length }  </span>
                                    </Cell>
                                </Row>
                            );
                        })
                    }
                </Table>
            </Layout>
        )
    }

}

const mapStateToProps = (state) => ({
    users: state.expert.users,
    programs: state.programs.root
});



export default ExpertsListNew;