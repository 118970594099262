import React from 'react'

import emptyStar from './icons/star-disable.svg'
import fullStar from './icons/star-active.svg'

import messages from './messages'
import './styles/stars.scss'

export const Stars = ({  handler, currentEstimate, maxValue, readonly, disabled }) => {
  const arrayOfStars = new Array(Number(maxValue)).fill(null)
  
  // console.log('arrayOfStars', arrayOfStars, maxValue, currentEstimate, Number(maxValue))

  console.log('DISABLED:', disabled );


  const message = messages( maxValue )[ currentEstimate  ];
  const disabledClass = disabled ? 'disabled' : '';

  return (
    <div className="stars">
      <div className="stars__group">
        <div className="stars__container">
          {
            arrayOfStars.map((star, key) => {
              let val = key + 1;
            
              return (
                <div className={ val <= currentEstimate ? `stars__item active ${disabledClass}` : `stars__item ${disabledClass}` } key={key}>
                  <label>
                    <img src={val > currentEstimate ? emptyStar : fullStar} alt={`${key} Бал`} />
                    {
                      readonly !== undefined && readonly !== true ?
                        (
                          <input
                            className="hidden-star"
                            onChange={handler}
                            type="radio"
                            disabled={disabled !== undefined ? disabled : false}
                            value={val}
                            checked={currentEstimate === val ? true : false}
                            required
                          />
                        ) : null
                    }

                  </label>
                </div>
              )
            })
          }
        </div>
        <div className="stars__current_estimate">
          {currentEstimate === null ? 0 : currentEstimate}
        </div>
      </div>
      <div className={`stars__message ${disabledClass}`}>
        {
          message && (
            <span className={message.className}>
              {
                message.message
              }
            </span>
          )
        }
      </div>
    </div>
  )
}