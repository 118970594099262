import EditorJS from '@editorjs/editorjs';
import AlignmentTuneTool from 'editorjs-text-alignment-blocktune';
import Header from "@editorjs/header";
import Table from '@editorjs/table';

import { FinBlock } from './fin_details';
import { Spacer } from './spacer';

export const config = {
    config_type: 'light',
    tools: {
        paragraph: {
            tunes: ['anyTuneName'],
        },
        header: {
            class: Header,
            tunes: ['anyTuneName'],
        },
        table: {
            class: Table,
            inlineToolbar: true,
            config: {
                rows: 2,
                cols: 3,
            },
        },
        fil_details: {
            class: FinBlock
        },
        spacer: {
            class: Spacer,
        },
        // Tunes
        anyTuneName: {
            class:AlignmentTuneTool,
            config:{
                default: "left",
                blocks: {
                    header: 'center',
                    list: 'left'
                }
            },
        }
    }
}


const CreateEditor = ( editor_node, data, setter ) => {

    console.log('INITED', editor_node );
    
    const editor = new EditorJS({
        ...config,
        holder: editor_node,
        placeholder: 'Document content',
        data: data,
        onChange: () => {
            editor.save().then( output => {
                setter( JSON.stringify( output ) )
                // value_node.value = JSON.stringify( output );
            })
        }
    });


    return editor;

}




export default CreateEditor;