import axios from '../../helpers/private.axios';
import { notify } from '../index';

export const CREATE_QUESTION_REQ = "CREATE_QUESTION_REQ";
export const CREATE_QUESTION_RES = "CREATE_QUESTION_RES";
export const CREATE_QUESTION_ERR = "CREATE_QUESTION_ERR";

export const QUESTION_REQ = "QUESTION_REQ";
export const UPDATE_QUESTION_REQ = "UPDATE_QUESTION_REQ";
export const QUESTION_RES = "QUESTION_RES";
export const QUESTION_ERR = "QUESTION_ERR";

export const CLEAR_QUESTION = "CLEAR_QUESTION"; 

export const clearQuestion = () => (dispatch) => {
    dispatch({ type: CLEAR_QUESTION });
} 

export const getQuestion = (id) => (dispatch) => {
    dispatch({ type: CREATE_QUESTION_REQ });

    axios.get(`/api/evaluation/question/${id}`)
    .then( res => {
        console.log('res', res);
        dispatch({
            type: QUESTION_RES,
            payload: res.data.data.question
        });
    });

}


export const createQuestion = ( data, group, history ) => (dispatch) => {

    const dataWithGroup = {
        ...data,
        question_group: group
    }

	dispatch({ type: UPDATE_QUESTION_REQ });
	axios.post(`/api/evaluation/question/store`,
        dataWithGroup
	)
	.then(
		res => {
			dispatch({
				type: CREATE_QUESTION_RES,
				payload: res.data.data.groups
			});
			
			dispatch(
				notify(
					"Питання успішно створенно", "success"
				)
			);

			history.push(`/admin/evaluation/groups/${group}`);


		}
    ).catch( err => {
		dispatch({
			type: CREATE_QUESTION_ERR,
			errors: err
		})
	});
} 

export const updateQuestion = ( data, id, history ) => (dispatch) => {
    
	dispatch({ type: CREATE_QUESTION_REQ });
	axios.post(`/api/evaluation/question/${id}/update`,
        data
	)
	.then(
		res => {
			dispatch({
				type: CREATE_QUESTION_RES,
				payload: res.data.data.groups
			});
			
			dispatch(
				notify(
					"Питання успішно оновленно", "success"
				)
			);

			history.push(`/admin/evaluation/groups/${data.question_group}`);


		}
    ).catch( err => {
		dispatch({
			type: CREATE_QUESTION_ERR,
			errors: err
		})
	});
} 