import React from 'react'
import Helmet from 'react-helmet';

import config from '../../../config';

import favicon1 from '../../../assets/favicons/soup1.png';

export const withHelmet = ( Component ) => ({ title }) => {
    return () => (
        <>
            <Helmet
                titleTemplate={`%s | ${config.helmet_title}`}
            >
                <link rel="shortcut icon" href={favicon1}/>
                <title>{title}</title>
            </Helmet>
            <Component/>
        </>
    );
}

// export default withHelmet;