import React from 'react'
import { NavLink } from 'react-router-dom'

import { homeIcon } from '../../../assets/menuIcons'

const MenuItem = ({ item, role }) => (
  <NavLink
    className="sidebar-item"
    to={ item.path }
    exact={ item.exact }
  >
    <img src={ (item.mainFor && item.mainFor === role) ? homeIcon : item.icon } alt={ item.title }/>
      {
        (item.mainFor && item.mainFor === role) ? "Головна сторінка" : item.title
      }
  </NavLink>
)

export default MenuItem