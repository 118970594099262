import {
    GET_ALL_ROLLBACKS_REQ,
    GET_ALL_ROLLBACKS_RES,
    GET_SINGLE_ROLLBACK_REQ,
    GET_SINGLE_ROLLBACK_RES
} from '../../actions'

const appsInitialState = {
    all: [],
    single: null,
    loaded: false,
}

const rollbacks_reducer = ( state = appsInitialState, action) => {
    switch( action.type ){
        case GET_ALL_ROLLBACKS_REQ:
            return {
                ...state,
                loaded: false,
            }

        case GET_ALL_ROLLBACKS_RES:
            return {
                ...state,
                all: action.payload,
                loaded: true,
            }

        case GET_SINGLE_ROLLBACK_REQ:
            return {
                ...state,
                loaded: false,
            }

        case GET_SINGLE_ROLLBACK_RES:
            return {
                ...state,
                loaded: true,
                single: action.payload
            }


        default:
            return state
    }
}

export default rollbacks_reducer;