import axios from '../../helpers/private.axios'

export const deleteDocumentRequest  = ( id ) => {

    return axios.post(`/api/documents/doc/${id}/delete`)
        .then( res => {
            return {
                type: "success",
                message: res.data.data.message
            }
        })
        .catch( err => {
            return {
                type: "error",
                message: err.response.data.message
            }
        })

    // console.log( data );

}