
const expertDocumentsList = [
    {
        filename: null,
        name: "signed_application",
        required: true,
        label: "Завантажте підписану Заяву (додаток №1 Положення про експертні ради УКФ).",
        short_label: "Підписана заява",
        outside_link: "https://cutt.ly/LeSNRM9"
    },
    {
        filename: null,
        name: "recommendation",
        required: false,
        label: "Завантажте рекомендаційний лист",
        short_label: "Pекомендаційний лист",
    },
    {
        filename: null,
        name: "cover_letter",
        required: true,
        label: "Завантажте мотиваційний лист.",
        short_label: "Мотиваційний лист",
        
    },
    {
        filename: null,
        name: "professional_resume",
        required: true,
        label: "Завантажте розгорнуте фахове резюме із додатками, що підтверджують профільну діяльність (бібліографія, фільмографія, спектаклеграфія, публікації в ЗМІ, тощо).",
        short_label: "Фахове резюме",
    },
    {
        filename: null,
        name: "identity_document",
        required: true,
        label: "Завантажте копію документу, що посвідчує особу (першу, другу сторінку та сторінку з інформацією про місце реєстрації у паспорті)",
        short_label: "Документ, що посвідчує особу",
    },
    {
        filename: null,
        name: "tax_number",
        required: true,
        label: "Завантажте копію індивідуального податкового номеру. (обов'язково)",
        short_label: "Копія ІПН",
    },
    {
        filename: null,
        name: "data_processing",
        required: true,
        label: " Завантажте підписану Згоду кандидата на обробку персональних даних (Додаток № 2 Положення про експертні ради)",
        outside_link: "https://cutt.ly/JeSNZpP",
        short_label: "Згода на обробку персональних даних",
    },
    {
        filename: null,
        name: "higher_education",
        required: true,
        label: "Завантажте копії документів, що підтверджують вищу освіту (а також за наявності – науковий ступінь, наукове або почесне звання).",
        short_label: "Документи, що підтверджують вищу освіту",
    },
    {
        filename: null,
        name: "stuff",
        required: false,
        label: "Завантажте інші документи та матеріали, що на вашу думку, можуть надати вам перевагу у конкурсі (одним файлом).",
        short_label: "Інші документи",
    },
];

export const getExpertDocuments = ( user ) => {

    if( user.documents ){
        return expertDocumentsList.map( doc => {
            if( user.documents[ doc.name ] ){
                doc.link = user.documents[ doc.name ];
                doc.required = false;
            }
            return doc;
        });

    } else {
        return expertDocumentsList;
    }
    
}