import {
    GET_SINGLE_DIRECTION_REQ,
    GET_SINGLE_DIRECTION_RES
} from '../../actions'

const appsInitialState = {
    error: null,
    data: null,
    loaded: false
}

const direction_reducer = ( state = appsInitialState, action) => {
    switch( action.type ){
        case GET_SINGLE_DIRECTION_REQ:
            return {
                ...state,
                loaded: false,
            }

        case GET_SINGLE_DIRECTION_RES:
            return {
                ...state,
                data: action.payload,
                loaded: true,
            }

        default:
            return state
    }
}

export default direction_reducer;