import privateAxios from '../../helpers/private.axios'
import config from '../../config'
import { notify } from '../common/notify.actions';

export const GET_APPLICATION_INFO_REQ ='GET_APPLICATION_INFO_REQ'
export const GET_APPLICATION_INFO_RES ='GET_APPLICATION_INFO_RES'
export const GET_APPLICATION_INFO_ERR ='GET_APPLICATION_INFO_ERR'

export const GET_APPLICATION_DOCUMENTS_REQ = "GET_APPLICATION_DOCUMENTS_REQ";
export const GET_APPLICATION_DOCUMENTS_RES = "GET_APPLICATION_DOCUMENTS_RES";
export const GET_APPLICATION_DOCUMENTS_ERR = "GET_APPLICATION_DOCUMENTS_ERR";

export const CLEAR_APPLICATION_DOCUMENTS = "CLEAR_APPLICATION_DOCUMENTS";

export const CREATE_VALIDATION_PROTOCOL_REQ = "CREATE_VALIDATION_PROTOCOL_REQ";
export const CREATE_VALIDATION_PROTOCOL_RES = "CREATE_VALIDATION_PROTOCOL_RES";
export const CREATE_VALIDATION_PROTOCOL_ERR = "CREATE_VALIDATION_PROTOCOL_ERR";

export const CHANGE_APPLICATION_STATUS_REQ = "CHANGE_APPLICATION_STATUS_REQCHANGE_APPLICATION_STATUS_REQ;"
export const CHANGE_APPLICATION_STATUS_RES = "CHANGE_APPLICATION_STATUS_REQCHANGE_APPLICATION_STATUS_RES;"

export const CLEAR_APPLICATION_INFO ='CLEAR_APPLICATION_INFO'

export const getProjectApplication = (id) => (dispatch) => {
  dispatch({ type: CLEAR_APPLICATION_INFO });
  dispatch({ type: GET_APPLICATION_INFO_REQ });

  privateAxios(`/api/application/project/${id}`)
  .then( res => {
    dispatch({ type: GET_APPLICATION_INFO_RES, payload: res.data.data });
  })
  .catch( err => {
    dispatch({ type: GET_APPLICATION_INFO_ERR, payload: err });
    dispatch( notify( err.response.data.message,  "error") )
  });

}


export const getApplicationInfo = (id) => (dispatch) => {
  dispatch({ type: GET_APPLICATION_INFO_REQ })

  privateAxios(`${config.apiPath}/api/application/${id}`)
  .then( res => {
    dispatch({
      type: GET_APPLICATION_INFO_RES,
      payload: res.data.data
    });
  })
  .catch( err => {
    dispatch({
      type: GET_APPLICATION_INFO_ERR,
      payload: err
    });
    dispatch( notify( err.response.data.message,  "error") )
  });
}

export const clearApplicationInfo = () => (dispatch) => {
  dispatch({ type: CLEAR_APPLICATION_INFO })
}

export const getApplicationDocuments = (id) => (dispatch) => {
  dispatch({ type: GET_APPLICATION_DOCUMENTS_REQ });
  privateAxios.get(`/api/application/${id}/documents`)
    .then( res => {
      dispatch({
        type: GET_APPLICATION_DOCUMENTS_RES,
        payload: res.data.data
      });
    })
}


export const clearDocumentsData = () => dispatch => {
  dispatch({ type: CLEAR_APPLICATION_DOCUMENTS });
}


export const createValidationProtocol = ( id, docs, fields, message, need_direction, lot_priorities, estimate_compliance  ) => (dispatch) => {
  dispatch({ type: CREATE_VALIDATION_PROTOCOL_REQ });

  privateAxios.post(`/api/application/${id}/validate`, {
    docs,
    fields,
    message,
    need_direction,
    lot_priorities,
    estimate_compliance
  })
    .then( res => {
        console.log( res );
        dispatch({
          type: CREATE_VALIDATION_PROTOCOL_RES,
          payload: res.data.data
        });
        dispatch( clearDocumentsData() );
        dispatch( getProjectApplication(id) );
    })
}

export const changeApplicationStatus = (id, type) => (dispatch) => {
  dispatch({ type: CHANGE_APPLICATION_STATUS_REQ });

  privateAxios.post(`/api/application/${id}/status`, {
    type
  })
  .then( res => {
      dispatch({ type: CHANGE_APPLICATION_STATUS_RES });
      dispatch( clearDocumentsData() );
      dispatch( getProjectApplication(id) );
  });

}