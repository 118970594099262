import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Breadcrumbs, Loader } from '../../library';
import Applications from './applications';

import { searchAgreementApplications,  multisignAdditionalsAgreements } from '../../../actions';

const AdditionalAgreements = () => {

    const dispatch = useDispatch();
    const [ selectedApps, setSelectedApps ] = useState([]);

    const loaded = useSelector( state => state.matching.agreement.loaded );
    let apps = useSelector( state => state.matching.agreement.apps );
    const counter = useSelector( state => state.matching.agreement.counter );
    
    useEffect( () => {
        dispatch( searchAgreementApplications( '', 'additional') );
    }, [] );

    const sign_ids = [];
    const applications_ids = [];
    const additional_ids = [];

    const sign_additional_agreement_ids = selectedApps.map( application => {
        application.reporting_review.grunt_agreement.additionalFiles.map( additional => {
            if( additional.status === 1 ){
                additional_ids.push( additional.id );
                sign_ids.push( additional.sign_id );
                return additional.id
            }
            return null;
        }).filter( item => item !== null );

        applications_ids.push( application._id );
        return {
            application_id: application._id
        };
    });

    const multisignAgreementsCallback = () => {
        console.log( sign_additional_agreement_ids );
        dispatch( multisignAdditionalsAgreements( applications_ids, additional_ids, setSelectedApps) );
    };
    console.log('[sign_ids]', sign_ids );
    console.log('[additional_ids]', additional_ids );
    console.log('[applications_ids]', applications_ids );


    return(
        <>
            <Breadcrumbs
                items={[
                    { name: 'Мультипідпис', link: `/agreements` },
                    { name: 'Додаткові грантові угоди', link: `/agreements/additional` },
                ]}
            />
            <h1 className="page-title"> Завантажені додаткові грантові угоди, які потребують підпису </h1>
            {
                loaded ? 
                (
                    <Applications 
                        type="additional_agreement"
                        data={selectedApps}
                        handler={setSelectedApps}
                        loaded={loaded}
                        apps={apps}
                        counter={counter}
                        sign_ids={sign_ids}
                        signCallback={multisignAgreementsCallback}
                    />
                ) : 
                (
                    <Loader />
                ) 
            }
        </>
    )

}

export default AdditionalAgreements;