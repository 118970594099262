import React from 'react'
import { connect } from 'react-redux'

import Registries from './LotsRegisterItem'
import { Layout, Breadcrumbs, Loader } from '../../library'
import { Table } from '../../library/Table'

import { getLotsRegister, switchLotsRegister, clearLotsRegister } from '../../../actions'
import './styles/lots_register.scss'


class ExpertsLotsRegister extends React.Component {
  state = {
    data: {
      "received_applications": null,
      "tech_review": null,
      "rating": null,
      "signed_agreement": null,
      "realized_project": null
    },
    loaded: false
  }
  static getDerivedStateFromProps (props, state) {
    if(props.data) {
      let isChanged = Object.keys(state.data).reduce((sum, key) => {
        if( sum === true){
          return true;
        }
        if( state.data[key] === props.data.registries[key] ){
          return true;
        } else {
          return false;
        }
      }, false);
      if( isChanged ){
        return state;
      } else {
        if( state.loaded === true){
          return state;
        } else {
          return { data: props.data.registries, loaded: true }
        }
      }
    } else {
      return null
    }
  }

  componentDidMount() {
    this.props.findLotsRegister(this.props.match.params.id)
  }

  componentWillUnmount() {
    this.props.clearExpertsLotsRegister()
  }

  handleChange = (name, bool) => (e) =>{
    new Promise(resolve => {
      resolve(
        this.setState({
          data: {
            ...this.state.data,
            [name]: bool
          }
        })
      )
    })
    .then( () => {
      this.props.changeLotsRegister(this.state.data, this.props.match.params.id)
    })

  }

  render() {
    const { data, loading } = this.props

  if (loading) {
    return <Loader/>
  } else {
    return (
      <>
        <Layout
          className="projects-division"
          title={data && data.title.uk}
          breadcrumbs={
            data && <Breadcrumbs items={[
              {name:'Адмiн панель', link: '/admin'},
              {name: `Реєстрація лотiв / ${data._id}`, link: '/admin'},
            ]}/>}
        >
          <Table>
            {
              data && Object.entries(this.state.data).map(( value,key ) => (
                <Registries
                  key={key}
                  data={value}
                  handleChange={this.handleChange}
                  switching={this.props.switching}
                />
              ))
            }
          </Table>
        </Layout>
      </>
    )
  }
  }
}

const mapStateToProps = (state) => ({
  data: state.admin.reg_lots.data,
  loading: state.admin.reg_lots.loading,
  switching: state.admin.reg_lots.switching
})

const mapDispatchToProps = (dispatch) => ({
  findLotsRegister: (id) => {
    dispatch(getLotsRegister(id))
  },
  changeLotsRegister: (data, id) => {
    dispatch(switchLotsRegister(data, id))
  },
  clearExpertsLotsRegister: () => {
    dispatch(clearLotsRegister())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ExpertsLotsRegister)