import {
    GET_TOKEN_USER_REQ,
    GET_TOKEN_USER_RES,
    GET_TOKEN_USER_ERR,

    GET_INFO_USER_RES,

    CLEAR_AUTH_USER,
    RESET_AUTH_ERR
} from '../../actions'


const usersInitialState = {
    token: null,
    expired: null,
    error: null,
    loadingToken: false,
    user: null,
    session: null,
}

const login = ( state = usersInitialState, action) => {
    switch( action.type ){
        case GET_TOKEN_USER_REQ:
            return {
                ...state,
                loadingToken: true,
            }
        case GET_TOKEN_USER_RES:
            return {
                ...state,
                token: action.payload,
                loadingToken: false,
            }
        case GET_TOKEN_USER_ERR:
            return {
                ...state,
                error: action.payload,
            }
        case GET_INFO_USER_RES:
            return {
                ...state,
                user: action.payload
            }
        case CLEAR_AUTH_USER:
            return {
                ...usersInitialState,
                error: action.payload ? { message: action.payload } : null
            }

        case RESET_AUTH_ERR:
            return {
                ...state,
                error: null,
                loadingToken: false
            }

        default:
            return state
    }
}

export default login
