import React, { useState } from 'react'
import { connect } from 'react-redux';

import { Signer, Loader, Button, RoleChecker } from '../../../../library';
import DocumentList from '../../../../common/DocumentList';

import { uploadAgreement, uploadFile, endAgreement, signGrunt } from '../../../../../actions';

import { pdf_icon, xls_icon } from '../../icons';

const Uploading = ({ 
        app, documents, uploading, loaded, user, program,
        uploadFileAction, endAgreementAction, signGruntReq
        
    }) => {

    const [ main_agreement, setMainAgreement ] = useState( null );

    console.log('[program]', program );


    const alreadyUploaded = app.reporting_review.grunt_agreement.status >= 1;

    /*
        Methods
    */
    const handleMainFile = (e) => {
        console.log( e.target.files );

        if( e.target.files.length > 0){
            uploadFileAction( e.target.files[0],  'main_agreement');
            setMainAgreement( e.target.files[0] );
        }
    }

    const uploadAgreements = () => {
        endAgreementAction();
    }


    console.log('[app]', app );

    let sign_ids = [];
    if( alreadyUploaded ){
        sign_ids = [
            app.reporting_review.grunt_agreement.final_protocol.sign_id,
            app.reporting_review.grunt_agreement.final_budget.sign_id,
            app.reporting_review.grunt_agreement.main_file.sign_id,
        ].filter( item => item );
    }

    // let additional_docs = [
    //     {
    //         absolute_path: "https://ucf.in.ua/documents/annex_3.pdf",
    //         extension: "pdf",
    //         title: "Додаток 3. Змістовий звіт про виконання проєкту",
    //         prefix: 'static'
    //     },
    //     {
    //         absolute_path: "https://ucf.in.ua/documents/annex_4.pdf",
    //         extension: "pdf",
    //         title: "Додаток 4. Звіт про надходження та використання коштів для реалізації проєкту",
    //         prefix: 'static'
    //     }
    // ];

    // if( app.data.contest === "5e675d16ca09c00e8358dd82"){
    //     additional_docs = [
    //         {
    //             absolute_path: "https://ucf.in.ua/documents/annex_3_sch.pdf",
    //             extension: "pdf",
    //             title: "Додаток 3. Змістовий звіт про виконання проєкту",
    //             prefix: 'static'
    //         },
    //         {
    //             absolute_path: "https://ucf.in.ua/documents/annex_4_sch.pdf",
    //             extension: "pdf",
    //             title: "Додаток 4. Звіт про надходження та використання коштів для реалізації проєкту",
    //             prefix: 'static'
    //         }
    //     ];
    // }

    // const instIds = ['5f216c277d8de75105718104', '5f50a7449c9e3a62a8069e22', '5f745a1a20ea0b740d758d34'];
    // if( app.data.program ){
    //     let match = instIds.some( id => id == app.data.program );
    //     if( match ){
    //         additional_docs = [
    //             {
    //                 absolute_path: "https://ucf.in.ua/documents/annex_3_inst.pdf",
    //                 extension: "pdf",
    //                 title: "Додаток 3. Змістовий звіт про виконання проєкту",
    //                 prefix: 'static'
    //             },
    //             {
    //                 absolute_path: "https://ucf.in.ua/documents/annex_4_inst.pdf",
    //                 extension: "pdf",
    //                 title: "Додаток 4. Звіт про надходження та використання коштів для реалізації проєкту",
    //                 prefix: 'static'
    //             }
    //         ];
    //     }
    //     console.log('match', match );
    // }

    


    return(
            <div className="project-app-wrapper">
                <div className={ alreadyUploaded ? "project-app-grand-wrap uploaded" : "project-app-grand-wrap"}>
                    <div className="project-app-grand">
                        <div className="project-app-grand-title">Грантова угода</div>

                        <div className="file__wrapper">
                            {
                                !alreadyUploaded && (
                                    <label className="project-app-grand-file">
                                        Натисніть, щоб обрати файл
                                        <input
                                            type="file" 
                                            name="main_agreement"
                                            onChange={handleMainFile}
                                            accept=".xls, .xlsx, .pdf"
                                            hidden
                                        />
                                    </label>
                                )
                            }
                            {
                                app.reporting_review.grunt_agreement.main_file !== undefined && (
                                    <>
                                        {
                                            alreadyUploaded ? (
                                                <div className="expert_documents">
                                                    <a className="application__documents-item" href={app.reporting_review.grunt_agreement.main_file.url} target="_blank" rel="noopener noreferrer">
                                                        <img  className="application__documents-icon" src={pdf_icon} alt="format icon"/>
                                                        <span> Угода { app.spec_id } </span>
                                                    </a>
                                                </div>
                                            ) : (
                                                <a className="uploaded_link" href={app.reporting_review.grunt_agreement.main_file.url} target="_blank" rel="noopener noreferrer">
                                                    <span> Угода { app.spec_id }  </span>
                                                </a>
                                            )
                                        }
                                    </>
                                    
                                )
                            }
                        </div>
                    </div>
                    {
                        alreadyUploaded && (
                            <div className="project-app-grand">
                                <div className="project-app-grand-title"> Кошторис та проектна заявка </div>
                                <div className="project-app-grand-file-wrapper">

                                {
                                    app.reporting_review.grunt_agreement.final_budget !== undefined && (

                                        <div className="expert_documents">
                                            <a className="application__documents-item" href={app.reporting_review.grunt_agreement.final_budget.url} target="_blank" rel="noopener noreferrer">
                                                <img  className="application__documents-icon" src={xls_icon} alt="format icon"/>
                                                <span> Фінальний бюджет { app.spec_id }</span>
                                            </a>
                                        </div>
                                    )
                                }
                                {
                                    app.reporting_review.grunt_agreement.final_protocol !== undefined && (
                                        <div className="expert_documents">
                                            <a className="application__documents-item" href={app.reporting_review.grunt_agreement.final_protocol.file.fileUrl} target="_blank" rel="noopener noreferrer">
                                                <img  className="application__documents-icon" src={pdf_icon} alt="format icon"/>
                                                <span> Проєктна заявка { app.spec_id } </span>
                                            </a>
                                        </div>
                                    )
                                }
                                </div>
                            </div>
                        )
                    }

                    {
                        alreadyUploaded && (
                            <div className="project-app-grand">
                                <div className="project-app-grand-title"> Обов'язкові додатки </div>
                                <div className="project-app-grand-file-wrapper">
                                    <DocumentList documents={documents} except={['budget_']}/>
                                </div>
                            </div>
                        )
                    }

                    {
                        alreadyUploaded && (
                            <div className="project-app-grand">
                                <div className="project-app-grand-title"> Додатки-шаблони </div>
                                <div className="project-app-grand-file-wrapper">
                                    <DocumentList documents={program.annexes} />
                                </div>
                            </div>
                        )
                    }

                    {
                        !alreadyUploaded ? (
                            <>
                                <div className="project-app-grand">
                                    <button 
                                        className={ !uploading ? "project-app-grand-button" : "project-app-grand-button disabled"}
                                        onClick={uploadAgreements}
                                        disabled={uploading}    
                                    >
                                        {
                                            uploading ? "Угода завантажується" : "Зберегти грантову угоду"
                                        }
                                    </button>
                                </div>
                            </>
                        ) : (
                            <>
                                {
                                    app.reporting_review.grunt_agreement.status === 1 ? (

                                        <RoleChecker 
                                            allowedRoles={['chief']} 
                                            user={user}
                                            message="Тільки директор може підписати грантову угоду"
                                        >
                                            <Signer 
                                                ids={sign_ids}
                                                statusChangerCallback={ signGruntReq }
                                            />
                                        </RoleChecker>
                                    ) : (
                                        <Button disabled={true} size="large" text="Грантова угода вже підписана"/>
                                    )  
                                }

                            </>
                            
                        )
                    }
                </div>
            </div>
    );
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    app: state.applications.info.data,
    documents: state.applications.info.documents,
    loading: state.applications.info.loading,
    loaded: state.applications.info.loaded,
    uploading: state.matching.uploading.uploading_grunt,
    program: state.applications.info.program
})

const mapDispatchToProps = (dispatch, ownProps ) => ({
    uploadReq: ( formData ) => {
        dispatch( uploadAgreement( 
            ownProps.match.params.id, 
            formData,
            ownProps.history
        ));
    },

    uploadFileAction: ( file, type, additional_id ) => {
        dispatch( uploadFile( 
            ownProps.match.params.id, 
            file, type, additional_id,
        ));
    },

    endAgreementAction: () => {
        dispatch(
            endAgreement( ownProps.match.params.id, ownProps.history )
        );
    },

    signGruntReq: () => {
        console.log( "GRUNT_SIGN ");
        dispatch(
            signGrunt(  ownProps.match.params.id, ownProps.history )
        );
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Uploading)