import axios from 'axios'
import config from '../../config'

export const RESTORE_PASS_REQ = "RESTORE_PASS_REQ"
export const RESTORE_PASS_RES = "RESTORE_PASS_RES"
export const RESTORE_PASS_ERR = "RESTORE_PASS_ERR"

export const RESTORE_PASS_HASH_REQ = "RESTORE_PASS_HASH_REQ"
export const RESTORE_PASS_HASH_RES = "RESTORE_PASS_HASH_RES"
export const RESTORE_PASS_HASH_ERR = "RESTORE_PASS_HASH_ERR"

export const RESTORE_PASS_CLEAN = "RESTORE_PASS_CLEAN"

export const restorePass = (email) => (dispatch) => {
  dispatch({ type: RESTORE_PASS_REQ })

  axios.post(`${config.apiPath}/auth/restore`,
    {
      "email": email
    }
  )
  .then( res => {
      dispatch({
        type: RESTORE_PASS_RES,
        payload: res
      })
    }
  ).catch( err => {
      dispatch({
        type: RESTORE_PASS_ERR,
        payload: JSON.parse(err.request.response).message
      })
    }
  )
}

export const restorePassHash = (password, hash) => (dispatch) => {
  dispatch({ type: RESTORE_PASS_HASH_REQ })

  axios.post(`${config.apiPath}/auth/restore/change`,
    {
      "recover_hash": hash,
      "password": password
    }
  )
  .then( res => {
      dispatch({
        type: RESTORE_PASS_HASH_RES,
        payload: res
      })
    }
  ).catch( err => {
      dispatch({
        type: RESTORE_PASS_HASH_ERR,
        payload: JSON.parse(err.request.response).message.message
      })
    }
  )
}

export const restorePassClean = () => (dispatch) => {
  dispatch({ type: RESTORE_PASS_CLEAN })
}