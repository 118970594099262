import React from 'react'
import moment from 'moment'
import { Breadcrumbs, StatusHandler } from '../../../library'

const AppHead=({ name, spec_id, breadcrumbs, status, created_at }) => (
  <>
    <Breadcrumbs items={[
        { name: 'Проектний відділ', link: '/pd/' },
        { name: `${breadcrumbs.program.title}`, link: `/pd/programs/${breadcrumbs.program._id}` },
        breadcrumbs.lot !== undefined && breadcrumbs.lot !== null ? { name: `${breadcrumbs.lot.title}`, link: `/pd/lots/${breadcrumbs.lot._id}` } : null,
        { name: `${breadcrumbs.contest.title}`, link: `/pd/contests/${breadcrumbs.contest._id}` },
        { name: `${spec_id}`, link: `/pd/programs` }
      ]}
    />
    <header className="application__header">
      <div className="header-item">
        <h1 className="page-title">{ spec_id }</h1>
      </div>
      <div className="header-item">
        <div className="header-item__name">
          Статус:
        </div>
        <div className="header-item__value">
          <StatusHandler statusId={ status} type="tech_review"/>
        </div>
      </div>
      <div className="header-item">
        <div className="header-item__name">
          Дата подачі заявки
        </div>
        <div className="header-item__value">
          <span className="text-gray">
            { moment( created_at ).format('LLL') }
          </span>
        </div>
      </div>
    </header>
  </>
);

export default AppHead