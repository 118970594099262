import React from 'react'

import DataTypes from './data-inputs';
import './dataSort.scss';

export const DataSort = (props) => {
  const { label, value, type } = props;
  let DataType = DataTypes( type );
  if( value ){
    return (
      <>
        <div className="application__body-item">
          <div className="application__body-item-name text-gray">
            {label}
          </div>
          <div className="application__body-item-value">
            {DataType !== null && <DataType {...props} />}
          </div>
        </div>
      </>
    )
  } else {
    return null;
  }


}