import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Breadcrumbs, Table, Row, Cell, Button, TabsNav } from '../../../library';

import Users from '../common/users';
import Applications from './applications';

import { createDirection } from '../../../../actions';



const CreateDirection = () => {
    
    const dispatch = useDispatch();
    const history = useHistory();
    const [ selectedUsers, setSelectedUsers ] = useState([]);
    const [ selectedApps, setSelectedApps ] = useState([]);
    const [ errorMessage, setErrorMessage ] = useState('');

    const createDirectionAction = () => {
        setErrorMessage('');
        console.log( selectedUsers.length,  selectedApps.length );
        if( selectedUsers.length > 0 && selectedApps.length > 0 ){
            console.log('send');
            dispatch( createDirection(selectedUsers, selectedApps, 'tech_review', history) );
        } else {
            if( selectedUsers.length === 0){
                setErrorMessage('Оберіть хоча б одного користувача, щоб створити дирекцію');
                return;
            } 
            if( selectedApps.length === 0){
                setErrorMessage('Оберіть хоча б одну заявку, щоб створити дирекцію');
                return;
            }
        }
    }

    return(
        <>
            <Breadcrumbs
                items={[
                    {name: 'Дирекція', link: `/dr` },
                    {name: 'Технічний відбір', link: `/dr/tech_review`},
                    {name: 'Нове засідання дирекції', link: `/dr/tech_review/create`},
                ]}
            />
            <header className="direction__header">
                <h1 className="page-title"> Нове засідання дирекції </h1>
                <button className="direction__btn" onClick={createDirectionAction}> Створити </button>
            </header>
            {
                errorMessage && (
                    <div className="direction__error">
                        { errorMessage }
                    </div>
                )
            }
            <div className="direction__container">
                <Users data={selectedUsers} handler={setSelectedUsers}/>
                <Applications data={selectedApps} handler={setSelectedApps}/>
            </div>

        </>
    );

}


export default CreateDirection;