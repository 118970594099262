import { combineReducers } from 'redux'

import registrate from './registrate.reducer'
import restore from './restore.reducer'
import allUsers from './users.all'
import singleUser from './user.single'
import allCouncils from './council.all'
import singleCouncil from './council.single'
import unassigned from './unassigned';

const auth = combineReducers({
    registrate,
    restore,
    allUsers,
    singleUser,
    singleCouncil,
    allCouncils,
    unassigned
})

export default auth