import { combineReducers } from 'redux';

import fields from './keywords.reducer';
import notify from './notify.reducer';
import search from './search.reducer';
import error from './error.reducer';
import signer from './signerList.reducer';

const common = combineReducers({
    search,
    notify,
    fields,
    error,
    signer
})

export default common