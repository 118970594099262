import React, { Component } from 'react'
import { connect } from 'react-redux';
// import moment from 'moment';

// import { Loader, StatusHandler } from '../../../../library';

import { sendReportingStatus } from '../../../../../actions';

class ReportFeedback extends Component{

    state = {
        status: 1,
        message: ''
    }

    handleChange = (e) => {
        this.setState({
            message: e.target.value
        });
    }   

    radioHandle = ( e ) => {
        const value = e.target.value;

        this.setState({
            status: Number(value)
        });

    }

    sendResponse = () => {
        const { role } = this.props;
        const { status, message } = this.state;
        let type = '';
        if( role === "financier"){
            type = "financer";
        }
        if( role === "project_manager"){
            type = "pm";
        }

        this.props.sendReportFeedbackRequest( type, status, message );
    }

    render = () => {
        const { role, application } = this.props;
        const { status, message } = this.state;
        const { sendResponse } = this;
        

        const allowedRoles = [ 'admin', 'director', 'project_manager', 'financier'];

        // let type = 'pm';
        let decision = '';

        if( role === "financier"){
            // type = "financer";
            decision = application.reporting_review.reporting.financer !== undefined ? application.reporting_review.reporting.financer.message : '';
        }
        if( role === "project_manager"){
            // type = "pm";
            decision = application.reporting_review.reporting.project !== undefined ? application.reporting_review.reporting.project.message : '';
        }
        
        console.log( application );

        // console.log('type', type );
        // console.log('feedback', type, feedback.single.procedure[ type ], feedback.single.procedure[ type ].status !== 0    );

        const isReportingApplied = application.reporting_review.reporting.content_report !== undefined && application.reporting_review.reporting.financal_report !== undefined;


        const rules = [
            allowedRoles.some( al_role => al_role === role ),
            message.length > 100,
            // feedback.single[ type ].status !== 0  
        ];

        const disableActions = !rules.every( rule => rule === true );
        
        return(
            <div className="expert-solution-form">
                <header className="negotiation__header">
                    <h1 className="expert-solution-form-title">
                        Рішення по звітуванню
                        (   
                            { role === "admin" && "Адмін"}
                            { role === "project_manager" && "Проектний менеджер"}
                            { role === "lawyer" && "Юрист"}
                            { role === "financier" && "Фінансист"}
                        ) 
                    </h1>
                    {/* <StatusHandler type="reporting.neotiation" statusId={feedback.single.procedure[type].status} />	 */}
                </header>

                <div>
                        {
                            isReportingApplied ?
                            (
                                <>
                                    <h3>Заявник завантажив звітність:</h3>
                                    <a
                                        className="application__documents-item"
                                        href={application.reporting_review.reporting.content_report}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        Змістовий звіт
                                    </a>
                                    <a
                                        className="application__documents-item"
                                        href={application.reporting_review.reporting.financal_report}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        Фінансовий звіт
                                    </a>
                                </>
                            ) : (
                                <>
                                    Заявник ще на завантажив звітність
                                </>
                            )
                        }
                </div>
                <div className="rep_desc">

                { 
                    decision !== '' && (
                        <>
                            <b> Ваше рішення:  </b>
                            {
                                decision
                            }
                        </>
                    )
                }
                    <>
                        {

                        isReportingApplied && (
                            <>
                                <label className="expert-solution-form-label-wrap">
                                    <input
                                        className="expert-solution-form-input"
                                        type="radio"
                                        value="1"
                                        name="status"
                                        checked={status === 1 }
                                        onChange={ this.radioHandle }
                                    />
                                    <span className="expert-solution-form-label"> Звітність вірна </span>
                                </label>
                                <label className="expert-solution-form-label-wrap">
                                    <input
                                        className="expert-solution-form-input"
                                        type="radio"
                                        value="0"
                                        name="status"
                                        checked={status === 0 }
                                        onChange={ this.radioHandle }
                                    />
                                    <span className="expert-solution-form-label"> Звітність потребує доопрацювання </span>
                                </label>

                                <div className="expert-solution-form-text">Коментар до рішення ( {message.length} / 100 )</div>
                                <textarea
                                    rows="7"
                                    className="expert-solution-form-textarea"
                                    name="message"
                                    onChange={this.handleChange}
                                    placeholder="Введіть щонайменьше 100 симовлів коментаря, для того, щоб розблокувати відправку"
                                    disabled={  false }
                                    value={ message}
                                />
                                <button 
                                    className={ disableActions ? "application-expert_apply-btn application-expert_apply-btn-full disabled" : "application-expert_apply-btn application-expert_apply-btn-full"}
                                    disabled={ false }
                                    onClick={sendResponse}
                                > 
                                    Надіслати рішення по звітуванню 
                                </button>
                            </>
                        )
                        }
                    </>
            </div>
            </div>
        )
    }

}

const mapStateToProps = (state) => ({
    role: state.auth.user.role,
    application: state.applications.info.data,
    feedback: state.matching.negotiation
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    sendReportFeedbackRequest: ( type, status, message ) => {
        dispatch( 
            sendReportingStatus(
                ownProps.match.params.id,
                type, status, message,
                ownProps.history
            ) 
        );
    }
});



export default connect(mapStateToProps, mapDispatchToProps)(ReportFeedback);