import React from 'react'

const ReviewSelect = ({ changeSectionHandler, section_name, schema }) => {


    return(
        <>
            <label className="ed-stats__label">
                <div  className="ed-stats__title"> Оберіть етап </div>
                <select
                    className="ed-stats__input"
                    onChange={changeSectionHandler}
                    value={section_name}
                    required
                > 
                    <option value=""> Оберіть етап </option>
                    {
                        schema.map( (item, i) => {
                            return(
                                <option key={i} value={item.name}>
                                    { item.label }
                                </option>
                            );
                        })
                    }
                </select>

            </label>


        </>
    );

}

export default ReviewSelect;