import {
    GET_ALL_EXPERTS_REQ,
    GET_ALL_EXPERTS_RES,
    // GET_ALL_EXPERTS_ERR
} from '../../actions'

const usersInitialState = {
	error: null,
	loading: false,
	loaded: false,
	data: [],
	count: null,
	total: 0
}

const feedbacks = (state = usersInitialState, action) => {
	switch (action.type) {
		case GET_ALL_EXPERTS_REQ:
		return ({
			...state,
			loading: true,
			loaded: false,
			data: null
		})
		case GET_ALL_EXPERTS_RES:
		return ({
			...state,
			loading: false,
			loaded: true,
			data: action.payload,
			total: action.payload.length
		})

		default:
		return state
	}
}

export default feedbacks