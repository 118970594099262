import React from 'react'

const Message = ({ message, type, handler }) => {

  let styling = ''
  if ( type === 'error' ) styling = 'error'
  if ( type === 'success' ) styling = 'success'
  if ( type === 'warning' ) styling = 'warning'

  return (
    <div className={`notify-message ${styling}`} onClick={handler}>
      { message }
    </div>
  )
}

export default Message