import { combineReducers } from 'redux'

import auth from './auth'
import users from './users'
import programs from './programs'
import contests from './contests'
import applications from './applications'
import common from './common'
import admin from './admin'
import expert from './expert'
import matching from './matching';
import direction from './direction';
import rollbacks from './rollbacks';

const reducer = combineReducers({
    auth,
    users,
    applications,
    contests,
    programs,
    common,
    admin,
    expert,
    matching,
    direction,
    rollbacks
})

export default reducer
