import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';

import { Button } from '../../../library';
import { getFiltredUnassignedUsers, getAllFiltredExpertsList } from '../../../../actions';

import { loadState, saveState } from '../../../../helpers/storeLocalData';

import filter_image from '../icons/filter.svg';
import close from '../icons/close.svg';


const initFilter = {
    mode: "exclude",
    sector: "",
    main_program: "",
    additional_program: "",
    keyword: "",
    all_documents_filled : "false",
    all_fields_filled : "false"
}

const UnassignedFilters = ({ type = 'unassigned' }) => {
    const dispatch = useDispatch();
    const [ show, setShow ] = useState( false );

    let initData = loadState( `experts_filters_${type}` )
    if( !initData ){
        initData = initFilter;
    }

    const [ filters, setFilters ] = useState(initData);
    
    const { keywords, keywords_loaded, culture, culture_loaded } = useSelector( state => state.common.fields );
    const { data, loaded } = useSelector( state => state.programs.seasons );

    const results = useSelector( state => {
        console.log('[Type]', type );
        if( type === "unassigned"){
            return state.users.unassigned;
        }
        if( type === "all"){
            return state.expert.users
        }
    });

    const changeShowStatus = () => setShow( !show );
    const handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setFilters({
            ...filters,
            [ name ]: value
        });
    }


    const dataRequest = ( filters ) => {
        saveState(filters, `experts_filters_${type}`);
        if( type === 'unassigned'){
            return getFiltredUnassignedUsers( filters );
        }
        if( type === "all"){
            return getAllFiltredExpertsList( filters )
        }

        
    }

    const sendFilterRequest = () => {
        dispatch( dataRequest( filters ) );
        setShow( false );
    }

    const clearFilters = () => {
        setFilters( initFilter );
        setShow( false );
        dispatch( dataRequest( initFilter ) );
    }   

    console.log('[R]', results );

    return(
        <div className="ed-stats">

                <button className="ed-stats__filtersBtn" onClick={ changeShowStatus }>
                    <img className="ed-stats__filtersIcon" src={ show ? close : filter_image } alt=""/>
                    <span> 
                        { show ? "Сховати фільтри" : "Налаштувати фільтрацію"} 
                        { true && ` (Всього результатів: ${results.data.length}/${results.total})`}
                    </span>
                </button>

                <div className={ show ? "ed-stats__body show" : "ed-stats__body"}>

                    <div className="ed-stats__label">
                        <span className="ed-stats__title"> Режим фільтрації </span>
                        <div>
                            <label>
                                <input 
                                    type="radio" 
                                    name="mode" 
                                    value="exclude" 
                                    onChange={handleChange} 
                                    checked={filters.mode === "exclude"}
                                />
                                <span> Виключення </span>
                            </label>
                            <label>
                                <input 
                                    type="radio" 
                                    name="mode" 
                                    value="include" 
                                    onChange={handleChange} 
                                    checked={filters.mode === "include"}
                                    />
                                <span> Додавання </span>
                            </label>
                        </div>
                    </div>

                    <label className="ed-stats__label">
                        <span className="ed-stats__title"> Культурний сектор </span>
                        <select 
                            className="ed-stats__input" 
                            name="sector" 
                            onChange={ handleChange } 
                            value={ filters.sector }
                        >   
                            {
                                culture_loaded && (
                                    <>
                                        <option value=""> Оберіть сектор </option>
                                        {
                                            culture.options.options.map( ( item, index ) => (
                                                <option key={index} value={item.value}> {item.name}</option>
                                            ))
                                        }
                                    </>
                                )
                            }
                        </select>
                    </label>

                    <label className="ed-stats__label">
                        <span className="ed-stats__title"> Основна програма </span>
                        <select 
                            className="ed-stats__input" 
                            name="main_program" 
                            onChange={ handleChange } 
                            value={ filters.main_program }
                        >   
                            {
                                loaded && (
                                    <>
                                        <option value=""> Оберіть програму </option>
                                        {
                                            data.map( ({ season, programs } ) => (
                                                <optgroup key={season._id} label={season.title}>
                                                    
                                                    {
                                                        programs.map( program => (
                                                            <option key={program._id} value={program._id}> {program.title.uk} </option>
                                                        ))
                                                    }

                                                </optgroup>
                                            ))
                                        }
                                    </>
                                )
                            }
                        </select>
                    </label>

                    <label className="ed-stats__label">
                        <span className="ed-stats__title"> Додаткова програма </span>
                        <select 
                            className="ed-stats__input" 
                            name="additional_program" 
                            onChange={ handleChange } 
                            value={ filters.additional_program }
                        >   
                            {
                                loaded && (
                                    <>
                                        <option value=""> Оберіть програму </option>
                                        {
                                            data.map( ({ season, programs } ) => (
                                                <optgroup key={season._id} label={season.title} >
                                                    
                                                    {
                                                        programs.map( program => (
                                                            <option key={program._id} value={program._id}> {program.title.uk} </option>
                                                        ))
                                                    }

                                                </optgroup>
                                            ))
                                        }
                                    </>
                                )
                            }
                        </select>
                    </label>

                    <label className="ed-stats__label">
                        <span className="ed-stats__title"> Ключові слова </span>
                        <select 
                            className="ed-stats__input" 
                            name="keyword" 
                            onChange={ handleChange } 
                            value={ filters.keyword }
                        >   
                            {
                                keywords_loaded && (
                                    <>
                                        <option value=""> Оберіть ключові слова </option>
                                        {
                                            keywords.options.options.map( ( item ) => (
                                                <option key={item.value} value={item.value}> {item.name} ({item.value})</option>
                                            ))
                                        }
                                    </>
                                )
                            }
                        </select>
                    </label>
                    {
                        type === "unassigned" && (
                            <>
                                <div className="ed-stats__label">
                                    <span className="ed-stats__title"> Чи заповненно всі поля </span>
                                    <div>
                                        <label>
                                            <input 
                                                type="radio" 
                                                name="all_fields_filled" 
                                                value="false" 
                                                onChange={handleChange} 
                                                checked={filters.all_fields_filled === "false" }
                                                />
                                            <span> Не перевіряти </span>
                                        </label>
                                        <label>
                                            <input 
                                                type="radio" 
                                                name="all_fields_filled" 
                                                value="true" 
                                                onChange={handleChange} 
                                                checked={filters.all_fields_filled === "true"}
                                            />
                                            <span> Перевіряти </span>
                                        </label>
                                    </div>
                                </div>

                                <div className="ed-stats__label">
                                    <span className="ed-stats__title"> Чи завантажено усі документи </span>
                                    <div>
                                    <label>
                                            <input 
                                                type="radio" 
                                                name="all_documents_filled" 
                                                value="false" 
                                                onChange={handleChange} 
                                                checked={filters.all_documents_filled === "false" }
                                                />
                                            <span> Не перевіряти </span>
                                        </label>
                                        <label>
                                            <input 
                                                type="radio" 
                                                name="all_documents_filled" 
                                                value="true" 
                                                onChange={handleChange} 
                                                checked={filters.all_documents_filled === "true"}
                                            />
                                            <span> Перевіряти </span>
                                        </label>
                                        
                                    </div>
                                </div>
                            </>
                        )
                    }

                    <footer className="ed-stats__footer">
                        <Button 
                            handler={sendFilterRequest}
                            size="large"
                            text="Застосувати фільтри"
                        />
                        <Button 
                            handler={ clearFilters }
                            size="medium"
                            text="Очистити"
                            color="gray"
                        />
                    </footer>
                </div>
        </div>
    )
    
}

export default UnassignedFilters;