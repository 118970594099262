import privateAxios from '../../helpers/private.axios'
import config from '../../config'

export const GET_CHILD_PROGRAMS_REQ ='GET_CHILD_PROGRAMS_REQ'
export const GET_CHILD_PROGRAMS_RES ='GET_CHILD_PROGRAMS_RES'
export const GET_CHILD_PROGRAMS_ERR ='GET_CHILD_PROGRAMS_ERR'

export const CLEAR_CHILD_PROGRAMS ='CLEAR_CHILD_PROGRAMS'

export const getChildPrograms = ( id ) => (dispatch) => {
  dispatch({ type: GET_CHILD_PROGRAMS_REQ })

  privateAxios(`${config.apiPath}/api/catalogs/programs/${id}`)
    .then( res => {
      dispatch({
        type: GET_CHILD_PROGRAMS_RES,
        payload: res.data.data
      })
    })
    .catch( err => {
      dispatch({
        type: GET_CHILD_PROGRAMS_ERR,
        payload: err
      })
    })
}

export const clearChildPrograms = () => (dispatch) => {
  dispatch({ type: CLEAR_CHILD_PROGRAMS })
}