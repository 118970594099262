import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
    Layout, Breadcrumbs, Loader, StatusHandler,
    Table, Row, Cell, Panel
} from '../../../library';

import { Switch, Route, Redirect } from 'react-router-dom'

import ExpertSingleInfo from './SingleExpertData';
import ExpertInfo from './ExpertInfo';
import ExpertVote from './ExpertVote';

import {  getSingleExpertInfo } from '../../../../actions';

const SingleExpertRoot = ({ match }) => {
    const dispatch = useDispatch();

    const loaded = useSelector( state => state.expert.singleExpert.loaded );

    useEffect(() => {
        dispatch( getSingleExpertInfo( match.params.userid ) );

    }, [] )

    if( !loaded ){
        return( <Loader />);
    }

    return(
        <Layout
            className="projects-division"
            paddingTop="0px"
        >  
            <Switch>
                <Redirect exact from="/ed/experts/:userid/" to="/ed/experts/:userid/applications" />
                <Route exact path="/ed/experts/:userid/applications" component={ExpertSingleInfo} />
                <Route exact path="/ed/experts/:userid/vote" component={ExpertVote} />
                <Route exact path="/ed/experts/:userid/info" component={ExpertInfo} />
            </Switch>
        </Layout>
    )

}


export default SingleExpertRoot;