import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import { userRegistrate } from '../../../actions'

import showHintIcon from './icons/sup_icons_info.svg'
import closeLock from './icons/close-lock.svg'
import openLock from './icons/open-lock.svg'

class Registration extends Component{
  state = {
    name: '',
    login: '',
    password: '',
    is_expert: false,
    showPass: false,
    showHint: false,
  }

  handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value

    this.setState({
      [name]: value
    })
  }

  handleExpertChange = (e) => {
    this.setState({
      is_expert: !this.state.is_expert
    })
  }

  sendRegistration = (e) => {
    e.preventDefault()
    const {name, login, password, is_expert} = this.state

    this.props.registerUser({
      name,
      email: login,
      password,
      is_expert
    })
  }

  showPassword = (e) => {
    e.preventDefault()

    this.setState({
      showPass: !this.state.showPass
    })
  }

  toggleHint = (status) => () => {
    if( status !== undefined ){
      this.setState({ showHint: status })
    }
  }


  render(){
    const { sendRegistration, handleChange, toggleHint, handleExpertChange } = this
    const { showPass, showHint, is_expert } = this.state
    const { reg_error, reg_progress } = this.props

    return(
      <div className="login-container">
        <form
          className="login-form"
          onSubmit={sendRegistration}
        >
          <label className="login-label">
            <div className="login-label-text">
              <span>Ваше ім'я та прізвище <span className="required">*</span></span>
            </div>
            <div className="login-label-input">
              <input
                className="login-input"
                type="text"
                name="name"
                onChange={handleChange}
                onFocus={toggleHint( true )}
                onBlur={toggleHint( false )}
                placeholder="Н-д, Іван Франко"
                required
              />
              <button
                type="button"
                className="login-showHint"
                onClick={toggleHint(true)}
              >
                <img
                  src={ showHintIcon }
                  alt="Подсказка"
                  title="Подсказка"
                />
              </button>

              {
                showHint && (
                  <p className="login-hint">
                    Шановні експерти! Під час реєстрації просимо вказувати Ваші реальні прізвище, ім'я та по-батькові, оскільки ці дані автоматично потраплятимуть в протокол Вашої оцінки.
                  </p>
                )
              }
            </div>
          </label>
          <label className="login-label">
            <div className="login-label-text">
              <span>Пошта <span className="required">*</span> </span>
            </div>
            <div className="login-label-input">
              <input
                className="login-input"
                type="email"
                name="login"
                onChange={handleChange}
                placeholder="Н-д, IvanFranko@gmail.com"
                required
              />
            </div>
          </label>
          <label className="login-label">
            <div className="login-label-text">
              <span>Ваш пароль <span className="required">*</span> </span>
            </div>
            <div className="login-label-input">
              <input
                className="login-input"
                type={ showPass ? 'text' : 'password' }
                name="password"
                onChange={handleChange}
                placeholder="********"
                autoComplete="off"
                required
              />
              <button
                className="login-showPassword"
                onClick={this.showPassword}
              >
                <img
                  src={ !showPass ? closeLock : openLock }
                  alt={ !showPass ? 'Показати пароль' : 'Сховати пароль' }
                  title={ !showPass ? 'Показати пароль' : 'Сховати пароль' }
                />
              </button>
            </div>
          </label>

          <label className="login-label checkbox">
            <div className="login-label-text">
              <span> Ви реєструєтесь як експерт </span>
            </div>
            <div className="login-label-input">
              <input
                className="login-input"
                type="checkbox"
                name="is_expert"
                onChange={handleExpertChange}
                autoComplete="off"
              />
            </div>
          </label>

          {
            reg_error && (
              <div className="login-label">
                <div className="login-label-text"></div>
                <div className="login-label-input text-red">
                  { reg_error.message }
                </div>
              </div>
            )
          }
          <div className="login-label">
            <div className="login-label-text"></div>
            <div className="login-label-input">
              <button
                className={!reg_progress ? "login-button" : "login-button loading"}
                type="submit"
              >
                Зареєструватись
                {
                  reg_progress &&
                    (
                      <svg className="spinner" viewBox="0 0 50 50">
                        <circle className="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
                      </svg>
                    )
                }
              </button>
            </div>
          </div>
        </form>
        <div className="login-footer">
          <a href="https://ucf.in.ua" target="_blank" rel="noopener noreferrer" className="login-footer-info">Український Культурний Фонд</a>
          <Link className="login-footer-recovery" to="/restore">Відновлення паролю</Link>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  reg_error: state.users.registrate.err,
  reg_progress: state.users.registrate.loading
})

const mapDispatchToProps = (dispatch, ownProps ) => ({
  registerUser: ( userData ) => {
    dispatch( userRegistrate( userData, ownProps.history ) )
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Registration)
