import axios from '../../helpers/private.axios';
import { notify } from '../common/notify.actions';


export const APPLICATIONS_ROLLBACK_REQ = "APPLICATIONS_ROLLBACK_REQ";
export const APPLICATIONS_ROLLBACK_RES = "APPLICATIONS_ROLLBACK_RES";


export const applicationsRollback = ( rollback_config, history ) => (dispatch, getState) => {
    dispatch({ type: APPLICATIONS_ROLLBACK_REQ, payload: rollback_config })

    axios.post(`/api/rollback/applications`, rollback_config )
    .then(
        res => {
            console.log('res',  );
            dispatch( notify( res.data.data.message, "success") )
            history.push(`/admin/rollback/${res.data.data.rollback._id}`)
        }
    )

}