import React, { Component } from 'react'
import { connect } from 'react-redux';

import { 
    Layout, Breadcrumbs, Loader, StatusHandler,
    Table, Row, Cell, Panel

} from '../../../library';

import { SingleExpertHeader } from './header';
import DocumentsList from '../../../common/DocumentList';
import SpecidDecoder from '../../../common/SpecidDecoder';
import ExpertSelectedData from './ExpertSelectedData';


import {
    getSingleExpertInfo,
    getRootPrograms,
    getAllPrograms,
    getRootSeasons
} from '../../../../actions';

const getProgramName = ( programId, programs ) => {
    const programm = programs.find( item => item._id === programId );
    if( programm ){
        return programm.title;
    } else {
        return 'Немає програми, або вона не зайдена';
    }
}


class SingleExpertInfo extends Component {

    componentDidMount = () => {
        this.props.getExpertData();
        this.props.getAllPrograms();
        this.props.getRootSeasons();
    }

    render = () => {
        const { data, loaded } = this.props.expert;
        const seasonsLoaded = this.props.seasons.loaded;

        if( !loaded && !seasonsLoaded ){
            return <Loader />
        }

        const {
            ended,
            inProgress,
            notHandled,
            failed,
            prev_ended,
        } = data.reviews;

        const allReviews = [
            ...inProgress,
            ...prev_ended,
            ...notHandled,
            ...ended,
            ...failed
        ];

        const programsLoaded = this.props.programs.loaded;
        const programs = this.props.programs.data;
        const seasons = this.props.seasons.data;
        

        return(
            <>
                <SingleExpertHeader user={data.user} />
                
                <Breadcrumbs
                    items={[
                        { name: "Експертний відділ", link: `/ed/programs` },
                        data.user.role === "unassigned_expert" ? { name: "Нерозподілені експерти", link: `/ed/experts/unassigned`} : { name: "Експерти УКФ", link: `/ed/experts`},
                        { name: `Експерт ${data.user.name}`, link: `/ed/experts/${data.user._id}/applications` },
                        { name: "Заявки експерта", link: ""}
                    ]}
                />
                
                <h1 className="page-title"> Заявки експерта {data.user.name} </h1>
                
                <ExpertSelectedData user={data.user} />

                <Table>
                    <Row type="head">
                        <Cell type="medium"> Заявка </Cell>
                        <Cell type="medium"> Бал </Cell>
                        <Cell type="medium"> Статус </Cell>
                        <Cell type="medium"> Програма </Cell>
                    </Row>

                    {
                        seasonsLoaded && seasons.map( season => {

                            const reviews = allReviews.filter( ({ review, application }) => {
                                if( application.data.season === season.season._id ){
                                    return true;
                                }
                            });

                            if( reviews.length === 0 ){
                                return null;
                            }

                            return(
                                <div key={season.season._id}>
                                    <h3>{ season.season.title }</h3>
                                    {
                                        reviews.map( item => {
                                            const { review, application } = item;
                                            return(
                                                <Row
                                                    key={review._id}
                                                    type="link"
                                                    to={`/ed/application/${application._id}/review/${review._id}`}
                                                >
                                                    <Cell type="medium"> 
                                                        <SpecidDecoder application={application}/>    
                                                    </Cell>
                                                    <Cell type="medium">
                                                        {
                                                            review.score
                                                        }
                                                    </Cell>
                                                    <Cell type="medium">
                                                        <StatusHandler type="expert_review" statusId={review.status.id} />
                                                    </Cell>
                                                    <Cell type="medium">
                                                        {
                                                            programsLoaded && (
                                                                <>
                                                                    { getProgramName( application.data.fields.program , programs ) }
                                                                </>
                                                            )
                                                        }
            
                                                        {
                                                            application.data.fields.program === data.user.main_program && (<span className="text-green"> (Основна) </span>)
                                                        }
                                                    </Cell>
                                                </Row>
                                            )
                                        })
                                    }
                                </div>
                            )

                        })
                    }

                </Table>

            </>
        )
    }
}

/*
    Redux
*/
const mapStateToProps = (state) => ({
    expert: state.expert.singleExpert,
    programs: state.programs.root,
    seasons: state.programs.seasons,
});

const mapDispatchToProps = ( dispatch, ownProps ) => ({
    getExpertData: () => {
        // dispatch( getSingleExpertInfo( ownProps.match.params.userid ) );
    },
    getAllPrograms: () => {
        dispatch( getAllPrograms() );
    },
    getRootSeasons: () => {
        dispatch( getRootSeasons() );
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(SingleExpertInfo);