import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { Button } from '../../../library';

import AppliedFilters from './applied_filters';

import getFiltersConfig from './filters.config';
import { setNegotiationFilters, clearNegotiationSettedFilters } from '../../../../actions';

import { loadState, saveState, removeState } from '../../../../helpers/storeLocalData';

import { close, filter } from '../icons';

const NewFilter = ({ 
        filters, apps_loaded, applications, total,
        clearFiltersProp, setFiltersAction
    }) => {


    const [ show, changeFiltersShow ] = useState( false );
    const [ type, changeFiltersType ] = useState( '' );
    const [ activeType, changeType ] = useState( null );
    const [ spec_id, handleSpecId ] = useState('');
    const [ reporting_stage, changeState ] = useState('')

    const [ rehydrated, changeRehydrated ] = useState( false );

    const showFilters = () => changeFiltersShow( !show )

    const changeFilterType = ( type ) => {
        const allowedTypes = [
            '', 'negotiation', 'direction', 'supervisory_board', 'grunt_agreement', 'additional_agreement', 'reporting', 'act'
        ];
        if( allowedTypes.some( allowed => allowed === type ) ){
            changeFiltersType( type );
            const config = getFiltersConfig( type );
            changeType( config );
        }
    }

    const clearFilters = () => {
        handleSpecId('');
        changeFiltersType('');
        changeState('');
        changeType( null );
        changeFiltersShow( false );
        clearFiltersProp();
    };

    const setFilters = () => {

        const newFilters = {
            spec_id: spec_id,
            reporting_type: type,
            reporting_status: reporting_stage
        }

        changeFiltersShow( false );
        setFiltersAction(newFilters);
        saveState(newFilters, 'negotiation_filters');
    }


    useEffect( () => {
        // console.log('loadState', loadState('negotiation_filters') )
        const preloadedData = loadState('negotiation_filters');
        if( preloadedData ){

            preloadedData.spec_id !== "" && handleSpecId( preloadedData.spec_id );
            preloadedData.reporting_type !== "" && changeFilterType( preloadedData.reporting_type );
            preloadedData.reporting_status !== "" && changeState( preloadedData.reporting_status );

            changeRehydrated( true );
        }   
    }, [ rehydrated ] )


    console.log('filters', filters );
    return(
        <div className="ed-stats">

            <button className="ed-stats__filtersBtn" onClick={showFilters}>
                <img className="ed-stats__filtersIcon" src={ show ? close : filter } alt=""/>
                <span> 
                    { show ? "Сховати фільтри" : "Налаштувати фільтрацію"} 
                    { apps_loaded && ` (Всього результатів: ${applications.length}/${total})`}
                </span>
            </button>
            {/* <AppliedFilters filters={ filters } /> */}

            <div className={ show ? "ed-stats__body show" : "ed-stats__body"}>
                
                <label className="ed-stats__label">
                    <span className="ed-stats__title"> Номер заявки </span>
                    <input 
                        className="ed-stats__input" 
                        type="text" 
                        name="spec_id" 
                        value={spec_id} 
                        onChange={ (e) =>  handleSpecId( e.target.value )}
                    />
                </label>

                <label className="ed-stats__label">
                    <span className="ed-stats__title"> Етап погодження </span>
                    <select 
                        className="ed-stats__input" 
                        name="application_status_type" 
                        onChange={(e) => changeFilterType(e.target.value)} 
                        value={type}
                    >
                        <option value=""> Оберіть статус заявки </option>
                        <option value="negotiation"> Переговорна процедура </option>
                        <option value="direction"> Рішення дирекції </option>
                        <option value="supervisory_board"> Наглядова рада </option>
                        <option value="grunt_agreement"> Грантова угода </option>
                        <option value="additional_agreement"> Додаткові угоди </option>
                        <option value="reporting"> Звітність </option>
                        <option value="act"> Акт виконаного проекту </option>
                    </select>
                </label>

                {
                    activeType && (
                        <label className="ed-stats__label">
                            <span className="ed-stats__title"> { activeType.label } </span>
                            <select 
                                className="ed-stats__input" 
                                name="negotiation_status" 
                                value={reporting_stage}
                                onChange={(e) => changeState( e.target.value )}
                            >
                                {
                                    activeType.statuses.map( status => (
                                        <option key={status.id} value={status.id}> {status.label}</option>
                                    ))
                                }
                            </select>
                        </label>
                    )
                }

                <footer className="ed-stats__footer">
                    <Button 
                        handler={setFilters}
                        size="large"
                        text="Застосувати фільтри"
                    />
                    <Button 
                        handler={clearFilters}
                        size="medium"
                        text="Очистити"
                        color="gray"
                    />
                </footer>
            </div>
        </div>
    )

}


/*
    Redux
*/


const mapStateToProps = (state) => ({
    filters: state.matching.stats.filters,
    applications: state.matching.stats.data,
    apps_loaded: state.matching.stats.loaded,
    total: state.matching.stats.total_with_filter,
})

const mapDispatchToProps = (dispatch) => ({
    setFiltersAction: ( filters ) => {
        dispatch( setNegotiationFilters( filters ) );
    },
    clearFiltersProp: () => {
        dispatch( clearNegotiationSettedFilters() );
    }
});


export default connect( mapStateToProps, mapDispatchToProps )(NewFilter);
