import axios from '../../helpers/private.axios'
import { notify } from '../common/notify.actions';
import { getApplicationInfo } from './project.actions'

export const getRandomizeExperts = ( id ) => (dispatch) => {
	axios.post(`/api/application/${id}/randomize`)
	.then(() => {
			dispatch( getApplicationInfo(id) );
	})
	.catch( error => {
		dispatch( notify( error.response.data.message,  "error") )
	});
}