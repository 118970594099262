import {
    SET_STATS_FILTERS,
    SEARCH_EXPERT_BY_NAME_REQ,
    SEARCH_EXPERT_BY_NAME_RES,
    GET_STATS_APPLICATIONS_REQ,
    GET_STATS_APPLICATIONS_RES,
    GET_STATS_CHUNK_APPLICATIONS_REQ,
    GET_STATS_CHUNK_APPLICATIONS_RES,
    CLEAR_FILTERS
} from '../../actions'

const statsInitialState = {
    filters: {
        spec_id: '',
        application_status: '',
        review_status: '',
        expert_name: '',
        sector: ''
    },

    experts_autocomplete_data: [],
    experts_autocomplete_loaded: false,

    total_with_filter: 0,
    offset: 0,
    limit: 10,

	error: null,
	loading: false,
	loaded: false,
	data: null,
}

const expertStats = (state = statsInitialState, action) => {
	switch (action.type) {

        case SEARCH_EXPERT_BY_NAME_REQ:
            return({
                ...state,
                experts_autocomplete_loaded: false
            })
        
        case SEARCH_EXPERT_BY_NAME_RES:
            return({
                ...state,
                experts_autocomplete_loaded: true,
                experts_autocomplete_data: action.payload
            });


		case SET_STATS_FILTERS:
            return ({
                ...state,
                loaded: false,
                filters:{
                    ...state.filters,
                    ...action.payload
                },
                data: [],
                offset: 0
            });

		case GET_STATS_APPLICATIONS_REQ:
            return ({
                ...state,
                loading: true,
                loaded: false
            });
        
        case GET_STATS_APPLICATIONS_RES:
            return ({
                ...state,
                loading: false,
                loaded: true,
                total_with_filter: action.payload.total,
                data: action.payload.apps,
                offset: state.offset + state.limit
            });

        case GET_STATS_CHUNK_APPLICATIONS_REQ:
            return({
                ...state,
                loading: true,
                offset: action.payload
            })

        case GET_STATS_CHUNK_APPLICATIONS_RES:
            return({
                ...state,
                loading: false,
                total_with_filter: action.payload.total,
                data: [ ...state.data, ...action.payload.apps],
                offset: state.offset + state.limit
            })

        case CLEAR_FILTERS:
            return({
                ...state,
                filters: {
                    spec_id: '',
                    application_status: '',
                    review_status: '',
                    expert_name: '',
                    sector: ''
                },
                data: [],
                loading: false,
                loaded: false,
                total_with_filter: 0,
                offset: 0,
            });

		default:
            return state
	}
}

export default expertStats;