import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Row, Cell, Loader } from '../../../library';

import { searchDirectionApplications } from '../../../../actions';

let throttlingTimer = null;

const ApplicationRow = React.memo(({ app, handler, selected, full }) => {
    const checked = selected.some( selected_app => selected_app._id === app._id );

    return(
        <Row key={app._id}>
            <label className="direction__row">
                <Cell className="id">
                    <input 
                        type="checkbox"
                        value={app._id}
                        onChange={handler}
                        checked={checked}
                        data-spec={ app.spec_id }
                    />
                </Cell>
                <Cell className="large">
                    { app.spec_id }
                    {
                        full && (
                            <>
                                { app.tech_review.status === -2 && (<span style={{ color: "#d16053"}}> Відхилено </span>) }
                                { app.tech_review.status === -3 && (<span style={{ color: "#ffcc2d"}}> Не можу визначитись </span>) }
                                { app.tech_review.status === -4 && (<span style={{ color: "#ffcc2d"}}> Подано апеляцію </span>) }
                            </>
                        )
                    }
                </Cell>
            </label>
        </Row>
    );
});

const Applications = React.memo(({ data, handler }) => {

    

    const dispatch = useDispatch();

    const [ search, setSearch ] = useState('');

    const selectedApps = data;
    const setSelected = handler;

    const loaded = useSelector( state => state.direction.apps.loaded );
    let apps = useSelector( state => state.direction.apps.apps );
    const counter = useSelector( state => state.direction.apps.counter );


    const handleSearch = (e) => {
        let value = e.target.value;
        setSearch( value );

        if( value.length >= 4){
            clearTimeout( throttlingTimer );
            throttlingTimer = setTimeout( () => {
                dispatch( searchDirectionApplications( value ) );
            }, 500 );
            
        }
    }

    const checkApplication = (e) => {
        let value = e.target.value;
        const isChecked = selectedApps.some( app => app._id === value );   
        if( isChecked ){
            setSelected( selectedApps.filter( app => app._id !== value ) );
        } else {
            setSelected( [...selectedApps, {
                _id: value,
                spec_id: e.target.dataset.spec
            } ]);
        }
    } 
    
    console.log( '1', selectedApps )

    return(
        <div className="direction__block">
            <h2 className="direction__subtitle"> Заявки { counter } </h2>
            <input 
                className="direction__search" 
                placeholder="Введіть мінімум 4 симовли ід для пошуку"
                value={search} 
                onChange={handleSearch}    
            />
            <div className="direction__container">
                <div className="direction__block users">
                {
                    loaded ?
                    (
                        <Table>
                            {
                                apps.map( app => 
                                    <ApplicationRow 
                                        key={app._id} 
                                        app={app} 
                                        handler={checkApplication} 
                                        selected={selectedApps} 
                                        full={true}
                                    />
                                )
                            }
                        </Table>
                    )
                    : 
                    (
                        <Table>
                            <Row>
                                <Cell>
                                {
                                    search.length >= 4 ? 'Шукаємо заявки' : 'Введіть для пошуку'
                                }
                                </Cell>
                            </Row>
                        </Table>
                    )
                }
                </div>
                {
                    selectedApps.length > 0 && (
                        <div className="direction__block users selected">
                            <h2 className="direction__subtitle"> Обрані {selectedApps.length} </h2>
                            <Table>
                            {
                                selectedApps.map( app => 
                                    <ApplicationRow 
                                        key={app._id} 
                                        app={app} 
                                        handler={checkApplication} 
                                        selected={selectedApps} 
                                    />
                                )
                            }
                            </Table>
                        </div>  
                    )
                }
            
            
            </div>
                
        </div>
    )


});

export default Applications;
