import axios from '../../helpers/private.axios'

import { getSingleAppForExpert, getApplicationInfo } from '../../actions'
import { notify } from '../common/notify.actions';

export const START_REVIEWING_APPLICATION_REQ = "START_REVIEWING_APPLICATION_REQ"
export const START_REVIEWING_APPLICATION_RES = "START_REVIEWING_APPLICATION_RES"
export const START_REVIEWING_APPLICATION_ERR = "START_REVIEWING_APPLICATION_ERR"

export const CONFLICT_REQ = "CONFLICT_REQ"
export const CONFLICT_RES = "CONFLICT_RES"
export const CONFLICT_ERR = "CONFLICT_ERR"

export const RATE_QUESTIONS_REQ = "RATE_QUESTIONS_REQ";
export const RATE_QUESTIONS_RES = "RATE_QUESTIONS_RES";
export const RATE_QUESTIONS_ERR = "RATE_QUESTIONS_ERR";

export const CHANGE_QUESTION_RANK_REQ = "CHANGE_QUESTION_RANK_REQ";
export const CHANGE_QUESTION_RANK_RES = "CHANGE_QUESTION_RANK_RES";

export const GET_SINGLE_REVIEW_REQ = "GET_SINGLE_REVIEW_REQ";
export const GET_SINGLE_REVIEW_RES = "GET_SINGLE_REVIEW_RES";

export const getSingleReview = ( review_id ) => dispatch => {
	dispatch({ type: GET_SINGLE_REVIEW_REQ });
	axios.get(`/api/expert/review/${review_id}`)
		.then( res => {
			dispatch({ type: GET_SINGLE_REVIEW_RES, payload: res.data.data });
		});
}


export const startReviewingApplication = ( id ) => (dispatch) => {
	dispatch({ type: START_REVIEWING_APPLICATION_REQ })
	axios.post(`/api/expert/review/${id}/start`)
		.then( res => {
			console.log('res', res);
			dispatch({ type: START_REVIEWING_APPLICATION_RES, payload: res });
			dispatch( getSingleAppForExpert( res.data.data.review.application_id ) );
		})
}

export const signalConflict = (id, body) => (dispatch) => {
	dispatch({ type: CONFLICT_REQ })
	axios.post(`/api/expert/review/${id}/failure`, {
		message: body.message
	})
	.then( res => {
		dispatch({ type: CONFLICT_RES, payload: res })
		dispatch( getSingleAppForExpert( res.data.data.review.application_id ) )
	})
}

export const signExpertProtocol = ( id, type_id, reason ) => (dispatch) => {
	axios.post(`/api/expert/review/${id}/sign`, {
		type_id: type_id,
		reason: reason
	})
	.then( res => {
		dispatch( getSingleAppForExpert( res.data.data.review.application_id ) )
	})
}

export const getQuestionForRate = ( group_id ) => (dispatch) => {
	dispatch({ type: RATE_QUESTIONS_REQ });
	axios.get(`/api/evaluation/groups/${group_id}/rate`)
	.then( res => {
		dispatch({
			type: RATE_QUESTIONS_RES,
			payload: res.data.data
		})
	});
}



export const rateQuestion = (review_id, group_id, { question_id, name, title, score}) => (dispatch) => {
	dispatch({ type : CHANGE_QUESTION_RANK_REQ });
	axios.post(`/api/expert/feedback/${review_id}/send/${group_id}`, {
		"question_id": question_id,
		"name": name,
		"title": title,
		"score": score
	})
	.then( res => {
		console.log('res', res );
		dispatch({ type : CHANGE_QUESTION_RANK_RES, payload: res.data.data });
		dispatch( getSingleAppForExpert( res.data.data.review.application_id, true ) );
		dispatch( notify( 'Відгук успішно збережено',  "success") )
	})
	.catch( error => {
		dispatch( notify( error.response.data.message,  "error") )
	})
}


export const SEND_TO_VALIDATION_REQ = "SEND_TO_VALIDATION_REQ";
export const SEND_TO_VALIDATION_RES = "SEND_TO_VALIDATION_RES";

export const sendToValidation = ( message, review_id ) => (dispatch) => {
	dispatch({ type: SEND_TO_VALIDATION_REQ });

	axios.post(`/api/expert/review/${review_id}/validate`, {
		"message": message,
		"status": 2
	})
	.then( res => {
		dispatch({ type: SEND_TO_VALIDATION_RES, payload: res.data.data });
		dispatch( getSingleAppForExpert( res.data.data.review.application_id) );
		dispatch( notify( 'Відгук успішно відправлено на валідацію',  "success") )
	})
}

export const saveDataBeforeValidation = ( message, review_id ) => (dispatch) => {
	dispatch({ type: SEND_TO_VALIDATION_REQ });
	axios.post(`/api/expert/review/${review_id}/save`, {
		"message": message
	})
	.then( res => {
		dispatch({ type: SEND_TO_VALIDATION_RES, payload: res.data.data });
		// dispatch( getSingleAppForExpert( res.data.data.review.application_id) );
		dispatch( notify( 'Відгук успішно збережено',  "success") )
	})
}

export const GET_FEEDBACKS_REQ = "GET_FEEDBACKS_REQ";
export const GET_FEEDBACKS_RES = "GET_FEEDBACKS_RES";

export const getFeedbacks = ( review_id ) => (dispatch) =>  {
	dispatch({ type: GET_FEEDBACKS_REQ });
	axios.get(`/api/expert/review/${review_id}/feedbacks`)
	.then( res => {
		dispatch({ type: GET_FEEDBACKS_RES, payload: res.data.data });
	});
}

export const GENERATE_EXPERT_FIN_PROTOCOL_REQ = "GENERATE_EXPERT_FIN_PROTOCOL_REQ";
export const GENERATE_EXPERT_FIN_PROTOCOL_RES = "GENERATE_EXPERT_FIN_PROTOCOL_RES";
export const GENERATE_EXPERT_FIN_PROTOCOL_ERR = "GENERATE_EXPERT_FIN_PROTOCOL_ERR";

export const generateExpertFinProtocol = ( review_id ) => (dispatch) =>  {
	dispatch({ type: GENERATE_EXPERT_FIN_PROTOCOL_REQ });
	axios.post(`/api/expert/review/${review_id}/protocol`)
	.then( res => {
		dispatch({ type: GENERATE_EXPERT_FIN_PROTOCOL_RES, payload: res.data.data });
		dispatch( getSingleAppForExpert( res.data.data.review.application_id, true ) );
	});
}

export const REMOVE_EXPERT_REQ = "REMOVE_EXPERT_REQ";
export const REMOVE_EXPERT_RES = "REMOVE_EXPERT_RES";
export const REMOVE_EXPERT_ERR = "REMOVE_EXPERT_ERR";

export const removeExpertFromApp = ( review_id ) => dispatch => {
	dispatch({ type: REMOVE_EXPERT_REQ });
	axios.post(`/api/expert/review/${review_id}/fire`)
	.then( res => {
		dispatch({ type: REMOVE_EXPERT_RES, payload: res.data.data });
		dispatch( getApplicationInfo( res.data.data.review.application_id ) );
	});
}


export const SEND_MESSQGE_REQ = "SEND_MESSQGE_REQ";
export const SEND_MESSQGE_RES = "SEND_MESSQGE_RES";
export const SEND_MESSQGE_ERR = "SEND_MESSQGE_ERR";
export const UPDATE_EXPERT_REVIEW = "UPDATE_EXPERT_REVIEW";

export const sendExpertMessage = ( review_id, message ) => (dispatch) => {
	dispatch({ type: REMOVE_EXPERT_REQ });
	axios.post(`/api/expert/review/${review_id}/send-message`, {
		message
	})
	.then( res => {
		dispatch({ type: SEND_MESSQGE_RES, payload: res.data.data });
		dispatch({ type: UPDATE_EXPERT_REVIEW, payload: res.data.data.review  });
		dispatch( notify( 'Звернення успішно надіслано',  "success") )
	});
}