import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";
import { Breadcrumbs, Loader } from '../../../../library';

import { getSingleMethodology, updateMethodology }  from '../../../../../actions';


const Me = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const history = useHistory();


    const methodology = useSelector( state => state.admin.evaluation.methodology );
    const { loaded, create_req, single } = methodology;


    const [ title, changeTitle ] = useState('');

    const sendData = (e) => {
        e.preventDefault();
        dispatch( updateMethodology({ title }, id, history) );
    }

    console.log('single', single);
    useEffect( () => {
        dispatch( getSingleMethodology(id, changeTitle) );
    }, []);

    if( !loaded ){
        return( <Loader />)
    }
    
    return(
        <div className="innerWrap">

            <Breadcrumbs items={[
                {name:'Адмiнiстративна панель', link: '/admin'},
                {name:'Методика експертного оцінювання', link: '/admin/evaluation/methodology'},
                {name:'Редагувати методику експертного оцінювання', link: '/admin/evaluation/methodology/new' }
            ]}/>

            <h1> Редагувати методику експертного оцінювання {title} </h1>

            <form className="form-container" onSubmit={sendData}>
                <label className="form-label">
                    <div className="form-label-text">
                        <span>Назва</span>
                    </div>
                    <div className="form-label-input">
                        <input
                            type="text"
                            className="form-input"
                            name="title"
                            onChange={(e)=>{
                                changeTitle( e.target.value )
                            }}
                            value={title}
                            placeholder=""
                        />
                    </div>
                </label>
                <div className="form-label">
                    <div className="form-label-text"></div>
                    <div className="form-label-input">
                        <button
                            className={ create_req ? "form-button unactive" : "form-button" }
                            type="submit"
                        >
                            {
                                create_req ? 'Створюється' : 'Створити!'
                            }

                            {
                                create_req &&
                                (
                                <svg className="spinner" viewBox="0 0 50 50">
                                    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                                </svg>
                                )
                            }
                        </button>
                    </div>
                </div>
            </form>

        </div>
    )

}


export default Me;