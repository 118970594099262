import React from 'react'

export const Spinner = () => (
  <svg className="spinner" viewBox="0 0 50 50">
    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
  </svg>
)

export const InlineSpinner = () => (
    <svg className="spinner" viewBox="0 0 50 50" style={{ width: '18px '}}>
      <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
    </svg> 
)