import {
  GET_SINGLE_USERS_REQUEST,
  GET_SINGLE_USERS_SUCCESS,
  GET_SINGLE_USERS_ERROR,
  GET_SINGLE_USER_UPDATE_REQUEST,
  GET_SINGLE_USER_UPDATE_SUCCESS,
  CLOSE_USER_NOTIFICATIONS,
  CLEAR_SINGLE_USER
} from '../../actions'

const contestsInitialState = {
  data: null,
  loading: false,
  loaded: false,
  errors: [],
  notification: null,
  updating: false
}

const SingleUser = ( state = contestsInitialState, action) => {
  switch( action.type ){
    case GET_SINGLE_USERS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case GET_SINGLE_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload
      }
    case GET_SINGLE_USERS_ERROR:
      return {
        ...state,
        error: action.error,
      }
    case GET_SINGLE_USER_UPDATE_REQUEST:
      return {
        ...state,
        notification: null,
        updating: true
      }
    case GET_SINGLE_USER_UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        notification: {
          status: true,
          message: 'Користувача успішно оновленно!'
        }
      }
    case CLOSE_USER_NOTIFICATIONS:
      return{
        ...state,
        notification: null
      }
    case CLEAR_SINGLE_USER:
      return{
        state
      }
    default:
      return state
  }
}

export default SingleUser
