import React from 'react';
import { connect } from 'react-redux';

import { Loader, Layout, Breadcrumbs, Empty, StatusHandler } from '../../library';
import { Table, Row, Cell } from '../../library/Table';

import { getReportingContests, clearProjectContests } from '../../../actions';

import { getApplicationTitle } from '../../../helpers/getApplicationTitle';

class ProjectContests extends React.Component {
  componentDidMount () {
    this.props.getContestsList(this.props.match.params.id)
  }
  componentWillUnmount () {
    this.props.clearContestsList()
  }
  render() {
    const { data, loaded } = this.props

    if ( !loaded ) { return <Loader/> }

    return (
      <>
        <Layout
          className="projects-division"
          title={data && data.contest.title}
          breadcrumbs={
            data && <Breadcrumbs items={[
              { name: 'Проектний відділ', link: '/rp/programs' },
              { name: `${data.contest.program.title}`, link: `/rp/programs/${data.contest.program._id}` },
              data.contest.lot !== undefined ? { name: `${data.contest.lot.title}`, link: `/rp/lots/${data.contest.lot._id}` } : null,
              { name: `${data.contest.title}`, link: `/rp/programs` }
            ]}
          />}
        >
          <Table>
            <Row type="head">
              <Cell type="small">Номер</Cell>
              <Cell type="medium">Назва проекту</Cell>
              <Cell type="medium">Рейтинг</Cell>
              <Cell type="medium">Статус</Cell>
            </Row>
            {
              data && data.applications.length ?
                data.applications.map((item, key) => {
                  let title = getApplicationTitle( item ); 

                  return (
                    <Row
                      key={ key }
                      type="link"

                      to={`/rp/application/${item._id}`}
                    >
                      <Cell type="small">
                        {key + 1 }.
                        <span className="text-sub" style={{marginLeft: '5px'}}>
                          {item.spec_id}
                        </span>
                      </Cell>
                      <Cell type="medium">
                        { title.length > 75 ? `${title.substr(0, 75)}...` :  title }
                      </Cell>
                      <Cell type="medium">
                        {
                          item.expert_review.rating
                        }

                      </Cell>
                      <Cell type="medium">
                          <StatusHandler 
                            adaptiveType={true} 
                            adaptiveData={item.reporting_review} 
                            statusId={item.reporting_review.status}
                          />
                      </Cell>
                    </Row>
                  )
                })
                : <Empty/>
            }
          </Table>
        </Layout>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  data: state.contests.projects.data,
  loaded: state.contests.projects.loaded
})

const mapDispatchToProps = (dispatch) => ({
  getContestsList: (id) => {
    dispatch( getReportingContests(id) )
  },
  clearContestsList: () => {
    dispatch( clearProjectContests() )
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ProjectContests)