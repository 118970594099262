import React from 'react'

const RenderPrograms = ({ seasons, mainProgram, currentPrograms, handleChange, showExpected, user }) => {


return(
	<div className="roles__container">
	{

		seasons.map( ( {season, programs} , index ) => {
		
		return(
			<div key={index}  >
			<h2>{ season.title }</h2>

			<div className="roles__row header">
				<div className="rolex__checkbox">
					<input type="radio" disabled />
					Основна
				</div>
				<div className="rolex__checkbox">
					<input type="checkbox" disabled />
					Можу оцінити
				</div>
			</div>

			<div className="roles__container">
				{
					programs.map( (program, key) => {
						let isCheckMain = false;
						let isCheckAll = false;
						let mainDisable = false;
						let allDisable = false;

						let fullDisabledPrograms = [ '5f74557e84e55b412c163442', '5f8047c799325679ed004cc2' ];

						if( fullDisabledPrograms.some( ( disabled_id => disabled_id === program._id )) ){
							allDisable = true;
							mainDisable = true;
						}


						let expected_main = false;
						let expected_secondary = false;

						if ( showExpected && user.fields ){
							expected_main = user.fields.expected_programs_main === program._id;	
							expected_secondary = user.fields.expected_programs_secondary.some( prog => prog === program._id );
						}

						currentPrograms.forEach(item => {
							if(item === program._id) {
								isCheckAll = true;
								isCheckMain = false;
								mainDisable = true;
							}
						});

						if( mainProgram === program._id){
							isCheckMain = true;
							isCheckAll = false;
							allDisable = true;
						}

						// 5f74557e84e55b412c163442
						// 5f8047c799325679ed004cc2
						
						return(
						<div key={key} className="roles__row">
							<div className="roles__item">
								<input 
									type="radio"
									name="main_program"
									className="rolex__checkbox"
									onChange={handleChange}
									value={program._id}
									checked={isCheckMain}
									disabled={mainDisable}
								/>

								<input
									className="rolex__checkbox"
									type="checkbox"
									name="programs_score_ids"
									value={program._id}
									onChange={handleChange}
									checked={isCheckAll}
									disabled={allDisable}
								/>
								<span>{program.title.uk}</span>
								{
									showExpected && (
										<>	
											{ expected_main && ( <span className="expected-item"> Бажана основна </span> ) }
											{ expected_secondary && ( <span className="expected-item"> Бажана додаткова </span> ) }
											
										</>
									)
								}
							</div>
						</div>
						)
					})
				}
			</div>
			</div>
		)

		})
		
	}
	</div>
	)
}

export default RenderPrograms;


