import React from 'react'
import { Layout, Breadcrumbs, Table, Row, Cell } from '../../../library';

const RootExperts = () => {



    return(
        <Layout>
            <Breadcrumbs
                items={[
                    { name: "Експертний відділ", link: `/ed/` },
                    { name: "Експерти", link: `/ed/experts`}
                ]}
            />
            <h1 className="page-title"> Експерти УКФ </h1>
            <Table>
                <Row type="head">
                    <Cell type="id">#</Cell>
                    <Cell type="large"> Розділ </Cell>
                </Row>

                <Row type="link" to="/ed/experts/list">
                    <Cell type="id"> 1. </Cell>
                    <Cell type="large"> Активні експерти </Cell>
                </Row>
                <Row type="link" to="/ed/experts/unassigned">
                    <Cell type="id"> 2. </Cell>
                    <Cell type="large"> Нерозподілені експерти </Cell>
                </Row>
                <Row type="link" to="/ed/experts/selection">
                    <Cell type="id"> 3. </Cell>
                    <Cell type="large"> Відбір експертів</Cell>
                </Row>
                <Row type="link" to="/ed/experts/seasons">
                    <Cell type="id"> 4. </Cell>
                    <Cell type="large"> Експерти по сезонах </Cell>
                </Row>
            </Table>
        </Layout>
    )


}

export default RootExperts;