import {
  RATE_QUESTIONS_REQ,
  RATE_QUESTIONS_RES,
  RATE_QUESTIONS_ERR,
} from '../../actions'

const usersInitialState = {
  error: null,
  loading: false,
  loaded: false,
  data: null,

  sending: false
}

const solution = (state = usersInitialState, action) => {
  switch (action.type) {
    case RATE_QUESTIONS_REQ:
      return ({
        ...state,
        loading: true,
        loaded: false,
      })
    case RATE_QUESTIONS_RES:
      return ({
        ...state,
        loading: false,
        loaded: true,
        data: action.payload
      })
    case RATE_QUESTIONS_ERR:
      return ({
        ...state,
        error: action.payload
      })

    default:
      return state
  }
}

export default solution