import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment';

import { 
	Loader,
	Table, Row, Cell,
	StatusHandler
} from '../../../../library'

import { Round } from './Parts';

import { getAllApplicationNegotiations, generateNegotiationReporting } from '../../../../../actions'


class NegotiationsProcedureList extends React.Component {

	static defaultProps = {
		root: true
	}

	componentDidMount(){
		this.props.getProcedures();
	}

	generatePDFReporting = () => {

		console.log('Generate pdf');
		this.props.generatePDFReport();

	}

	render () {
		const { application, app_loaded, procedures, loaded, root, pdf_reporting } = this.props;
		console.log('app_loaded', app_loaded, 'loaded', loaded )
		console.log('app', application );

		if( !loaded ){
			return <Loader/>
		}
		return (
			<div className="project-app-report-table">
				<header className="negotiation__actions">
					{
						root && (
							<>
								{
									application.reporting_review.status < 2 ? (
										<Link className="negotiation__button wide" to={`/rp/application/${application._id}/procedures/create`}>
											Додати переговорну процедуру
										</Link>
									) : 
									(
										<button className="negotiation__button wide disabled">Додати переговорну процедуру</button>
									)
								}
							</>
						)
					}
					<div className="negotiation__header-aside">				
						{
							pdf_reporting.status ? (
								<a 
									target="_blank" rel="noopener noreferrer" 
									className="negotiation__button b-link"
									href={pdf_reporting.link}> 
									Завантажити 
								</a>
							) : (
								<button 
										onClick={this.generatePDFReporting} 
										className={`negotiation__button ${ pdf_reporting.status && 'loading'} ${pdf_reporting.loading && 'loading'}`}
										disabled={ pdf_reporting.status }
									>
										{
											pdf_reporting.loading ? 'Файл генерується...' : 'Згенерувати файл заявки'
										}
								</button>
							)
						}
					</div>
				</header>
			
			<Table>
				<Row type="head">
					<Cell type="medium">Переговона процедура</Cell>
					<Cell type="small"> Тип </Cell>
					<Cell type="small"> Юрист </Cell>
					<Cell type="small"> Фінансист </Cell>
					<Cell type="small"> ПМ </Cell>
					<Cell type="large"> Статус </Cell>
				</Row>	
				{
					procedures.map( procedure => {

						if( procedure.type === "offline"){
							return(
								<Row 
									key={procedure._id}
									type="link"
									to={ root ? `/rp/application/${application._id}/procedures/${procedure._id}` :  `/rp/application/${application._id}/estimate/${procedure._id}`}
								>
									<Cell type="medium"> {moment(procedure.created_at).format('lll')} </Cell>
									<Cell type="small"> Оффлайн </Cell>
									<Cell type="small"></Cell>
									<Cell type="small"></Cell>
									<Cell type="small"></Cell>
									<Cell type="large">
										<StatusHandler type="reporting.neotiation" statusId={procedure.status} />	
									</Cell>
								</Row>
							)
						} else {

							// to={ root ? `/rp/negotiation/${application._id}/procedures/${procedure._id}` : `/rp/negotiation/${procedure._id}`}
							return(
								<Row 
									key={procedure._id}
									type="link" 
									to={ `/rp/negotiation/${procedure._id}` }
								>
									<Cell type="medium"> {moment(procedure.created_at).format('lll')} </Cell>
									<Cell type="small"> Онлайн </Cell>
									<Cell type="small">
										<Round status={ procedure.lawyer.status } />
									</Cell>
									<Cell type="small">
										<Round status={ procedure.financer.status } />
									</Cell>
									<Cell type="small">
										<Round status={ procedure.pm.status } />
									</Cell>
									<Cell type="large"> 
										<StatusHandler type="reporting.neotiation" statusId={procedure.status} />	
									</Cell>
								</Row>
							);
						}

					})
				}
			</Table>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	procedures: state.matching.negotiation.list,
	loaded: state.matching.negotiation.list_loaded,
	application: state.applications.info.data,
	app_loaded: state.applications.info.loading,
	pdf_reporting: {
		status: state.matching.negotiation.creating_reporting,
		link: state.matching.negotiation.reporting_link,
		loading: state.matching.negotiation.create_reporiting_processing
	}
})

const mapDispatchToProps = (dispatch, ownProps ) => ({
	getProcedures: () => {
		dispatch( getAllApplicationNegotiations( ownProps.match.params.id ) ) 
	},
	generatePDFReport: () => {
		dispatch( generateNegotiationReporting(ownProps.match.params.id) );
	}

})

export default connect(mapStateToProps, mapDispatchToProps)(NegotiationsProcedureList)