import { combineReducers } from 'redux'

import info from './info.reducer'
import documents from './documents.reducer';
import validation from './validation.reducer';
import reviews from './reviews.reducer';

const applications = combineReducers({
    info,
    documents,
    validation,
    reviews
})

export default applications