import React from 'react';
import { connect } from 'react-redux';

import RateSolution from './RateSolution';
import RateItem from './RateItem';
import { Loader, Breadcrumbs, Empty, Table, Row, Cell  } from '../../../library';
import { startReviewingApplication } from '../../../../actions';

import '../styles/styles.scss';

const ExpertSingleRate = ({ data, loading }) => {
  console.log( 'data', data )
  if (data && !loading) {

    const feedbacksData = data.feedbacks.reduce( (sum, current) => {
      const questionsScore = current.questions.reduce( (sub_sum, question) => sub_sum + question.score, 0); 

      let disabled = current.status_id === 0;
      if( sum.disabled === true ){
        disabled = true;
      }

      return {
        totalScore: sum.totalScore + questionsScore,
        disabled: disabled
      };
    }, { totalScore: 0, status: false });
    
    console.log( 'feedbacksData', feedbacksData);

    return (
      <>
        <Breadcrumbs
            margin="15px 0"
            items={[
              { name: 'Експертна частина', link: '/expert' },
              { name: `${data.application.spec_id}`, link: `/expert/${data.application._id}/info` },
              { name: `Оцінювання заявки`, link: `/expert/${data.application._id}/rate` }
          ]}/>
        <div className="expert-title">{data.application.spec_id}</div>
        <Table>
          <Row type="head">
            <Cell type="id">#</Cell>
            <Cell type="large"> Блок питаннь </Cell>
            <Cell type="medium"> Бал </Cell>
            <Cell type="large"> Статус </Cell>
          </Row>
          {
            data.feedbacks && data.feedbacks.length ?
              data.feedbacks.map((item, key) => {
                return (
                  <RateItem 
                    key={ key } 
                    order={key} 
                    item={item} 
                    application_id={data.application._id}
                  />
                )
              })
              : <Empty size="small"/>
          }
        </Table>
        <RateSolution rate={feedbacksData.totalScore} disabled={feedbacksData.disabled}/>
      </>
    )
  } else {
    return <Loader/>
  }
}

const mapStateToProps = (state) => ({
  data: state.expert.single.data,
  loading: state.applications.info.loading
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  startApplication: ( review_id ) => () => {
    dispatch( startReviewingApplication( review_id ) );
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ExpertSingleRate)