import React from 'react'
import { connect } from 'react-redux'

import { 
    Loader,
    Table, Row, Cell,
    StatusHandler 
} from '../../../library'

import { getApplicationInfo, clearApplicationInfo } from '../../../../actions'

const getReportingStatuses = ( application_data ) => {
    console.log('application_data', application_data);

    // If at least one procedure finished with success status > unblock direction
    let isDirectionActive = application_data.reporting_review.negotiation_procedures.some( proc => proc.status === 4) ;
    let isRadaActive = application_data.reporting_review.direction.status === 1;
    let isGruntActive = application_data.reporting_review.supervisory_board.status === 1 ||  application_data.reporting_review.supervisory_board.status === 2;
    let isReportingActive = application_data.reporting_review.reporting.status !== 0;
    let isActActive = application_data.reporting_review.status >= 7;

    console.log('isActActive', isActActive, application_data.reporting_review.reporting.status );

    const statusLibrary = [
        {
            id: 1,
            name: 'negotiation_procedures',
            label: "Переговорна процедура",
            route: "procedures",
            status_key: "reporting.neotiation",
            disabled: false,
        },
        {
            id: 2,
            name: 'direction',
            label: "Рішення дирекції",
            route: "direction",
            status_key: "reporting.direction",
            disabled: !isDirectionActive,
        },
        {
            id: 3,
            name: 'supervisory_board',
            label: "Наглядова рада",
            route: "supervisory",
            status_key: "reporting.supervisory_board",
            disabled: !isRadaActive,
        },
        {
            id: 4,
            name: 'grunt_agreement',
            label: "Грантова угода",
            status_key: "reporting.grunt_agreement",
            route: "agreement",
            disabled: !isGruntActive,
        },
        {
            id: 5,
            name: 'reporting',
            label: "Звітність",
            route: "reporting",
            status_key: "reporting.reporting",
            disabled: !isReportingActive,
        },
        {
            id: 6,
            name: 'completed_act',
            label: "Акт виконаного проекту",
            route: "act",
            status_key: "reporting.act",
            disabled: !isActActive,
        },
        // {
        //     id: 7,
        //     name: 'monitoring_report',
        //     label: "Моніторнинговий звіт",
        //     route: "/",
        //     disabled: true,
        // }  
    ];

    return statusLibrary;
}


class AppReport extends React.Component {
    render () {
        const { data, loading } = this.props

        const statusesList = getReportingStatuses( data );



        if (data && !loading) {
            return (
                <div className="project-app-report-table">
                    <Table>
                        <Row type="head">
                            <Cell type="large">Етап</Cell>
                            <Cell type="medium">Статус</Cell>
                            </Row>

                            {
                                statusesList.map( status_item => {
                                    // console.log('data rep', status_item, data.reporting_review );
                                    // console.log('con', status_item.name !== "negotiation_procedure", status_item.name, 'rep:', data.reporting_review[ status_item.name ] === null );


                                    // console.log('status', data.reporting_review[ status_item.name ].length === 0, );
                                    if( status_item.name === "negotiation_procedures" && data.reporting_review[ status_item.name ].length === 0){
                                        // Переговорна процедура
                                        return(
                                            <Row
                                                key={status_item.id}
                                                type="link"
                                                to={ `/rp/application/${data._id}/procedures/`}
                                                >
                                                <Cell type="large">{status_item.label}</Cell>
                                                <Cell type="large">Ще не розпочата</Cell>
                                            </Row>
                                        );
                                    }
                                    
                                    if( status_item.disabled ){
                                        return (
                                        <Row
                                            key={status_item.id}
                                            type="disabled"
                                        >
                                            <Cell type="large">{status_item.label}</Cell>
                                            <Cell type="large">
                                                    <StatusHandler 
                                                        type={status_item.status_key}
                                                        statusId={ 0 }    
                                                    />
                                            </Cell>

                                        </Row>
                                        );
                                    } else {
                                        return(
                                            <Row
                                                key={status_item.id}
                                                type="link"
                                                to={ `/rp/application/${data._id}/${status_item.route}/`}
                                                >
                                                <Cell type="large">{status_item.label}</Cell>
                                                <Cell type="large">
                                                    <StatusHandler 
                                                        type={status_item.status_key}
                                                        statusId={ status_item.name === "negotiation_procedures" ? data.reporting_review[ status_item.name ][0].status : data.reporting_review[ status_item.name ].status }    
                                                    />
                                                </Cell>
                                            </Row>
                                        );
                                    }

                                })
                            }
                    </Table>
                </div>
            )
        } else {
            return <Loader/>
        }
    }
}

const mapStateToProps = (state) => ({
    data: state.applications.info.data,
    loading: state.applications.info.loading
})

const mapDispatchToProps = (dispatch) => ({
    getApplication: (id) => {
        dispatch(getApplicationInfo(id))
    },
    clearApplication: () => {
        dispatch(clearApplicationInfo())
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(AppReport)