import axios from '../../helpers/private.axios';
import { notify } from '../common/notify.actions';

export const GET_ALL_ROLLBACKS_REQ = "GET_ALL_ROLLBACKS_REQ";
export const GET_ALL_ROLLBACKS_RES = "GET_ALL_ROLLBACKS_RES";


export const getAllRollbacks = () => (dispatch, getState) => {
    dispatch({ type: GET_ALL_ROLLBACKS_REQ })

    axios.get(`/api/rollback`)
    .then(
        res => {
            dispatch({
                type: GET_ALL_ROLLBACKS_RES,
                payload: res.data.data.rollbacks
            });
        }
    )

}
