import React from 'react'
import { connect } from 'react-redux';

import {  Table, Row, Cell, StatusHandler } from '../../../../../library';

const ProcedureIndexScreen = ({ negotiation }) => {
    return(
        <>
            <header className="negotiation__header">
                <h1 className="expert-solution-form-title">
                    Рішення по переговорній процедурі
                </h1>

                <span style={{ fontSize: '2rem' }}>
                    <StatusHandler type="reporting.neotiation" statusId={negotiation.status} />	
                </span>
            </header>
            <Table>
                <Row type="link" to={`/rp/negotiation/${negotiation._id}/edits`}>
                    <Cell> Правки у заявці 
                        {/* (edits) */}
                    </Cell>
                </Row>
                <Row type="link" to={`/rp/negotiation/${negotiation._id}/feedbacks`}>
                    <Cell> Фідбек 
                        {/* (feedbacks)  */}
                    </Cell>
                </Row>
                <Row type="link" to={`/rp/negotiation/${negotiation._id}/attached`}>
                    <Cell> Додані файли 
                        {/* (attached) */}
                    </Cell>
                </Row>
                <Row type="link" to={`/rp/negotiation/${negotiation._id}/decision`}>
                    <Cell> Рішення по переговорній процедурі 
                        {/* (decision) */}
                    </Cell>
                </Row>
            </Table>

        </>
    );
}

const mapStateToProps = (state) => ({
    negotiation: state.matching.negotiation.single !== null && state.matching.negotiation.single.procedure
});

export default connect(mapStateToProps)(ProcedureIndexScreen);