import axios from '../../helpers/private.axios';
import { notify } from '../common/notify.actions';

import { getApplicationInfo } from '../index'


export const GET_APPLICATION_REPORTING_REQ = "GET_APPLICATION_REPORTING_REQ";
export const GET_APPLICATION_REPORTING_RES = "GET_APPLICATION_REPORTING_RES";
export const GET_APPLICATION_REPORTING_ERR = "GET_APPLICATION_REPORTING_ERR";

export const getReportingProcedures = ( application_id ) => (dispatch) => {

    dispatch({ type: GET_APPLICATION_REPORTING_REQ });
    axios.get(`/api/reporting/${application_id}/reporting`)
        .then( res => {

            dispatch({
                type: GET_APPLICATION_REPORTING_RES,
                payload: res.data.data.procedures
            });

        });
}

export const GET_SINGLE_REPORTING_PROCEDURE_REQ = "GET_SINGLE_REPORTING_PROCEDURE_REQ";
export const GET_SINGLE_REPORTING_PROCEDURE_RES = "GET_SINGLE_REPORTING_PROCEDURE_RES";
export const GET_SINGLE_REPORTING_PROCEDURE_ERR = "GET_SINGLE_REPORTING_PROCEDURE_ERR";

export const getSingleReportingProcedure = ( application_id, procedure_id ) => dispatch => {
    dispatch({ type: GET_SINGLE_REPORTING_PROCEDURE_REQ });
    axios.get(`/api/reporting/${application_id}/reporting/${procedure_id}`)
        .then( res => {
            console.log( res );

            dispatch({
                type: GET_SINGLE_REPORTING_PROCEDURE_RES,
                payload: res.data.data.procedure,
                files: res.data.data.files
            });
        });
}

export const SEND_REPORTING_FEEDBACK_REQ = "SEND_REPORTING_FEEDBACK_REQ";
export const SEND_REPORTING_FEEDBACK_RES = "SEND_REPORTING_FEEDBACK_RES";

export const sendReportingProcedureFeedback = ( application_id, procedure_id, data ) => dispatch => {

    console.log('[ DATA: ]', application_id, procedure_id, data );

    let formData = new FormData();
    formData.append('status', data.status );    

    if( data.status == 1 || data.status == -1  ){
        formData.append('message_inner', data.message_inner ); 
        formData.append('file', data.file );     
    }

    if( data.status == 2 || data.status == -2){
        formData.append('message_applicant', data.message_applicant );    
    }

    dispatch({ type: SEND_REPORTING_FEEDBACK_REQ });
    axios.post(`/api/reporting/${application_id}/reporting/${procedure_id}`, formData )
    .then( res => {
        
            dispatch({ 
                type: SEND_REPORTING_FEEDBACK_RES, 
                payload: res.data
            });

            dispatch( getSingleReportingProcedure( application_id, procedure_id ) );

        })

}



export const CREATE_REPORITING_PROCEDURE_REQ = "CREATE_REPORITING_PROCEDURE_REQ";
export const CREATE_REPORITING_PROCEDURE_RES = "CREATE_REPORITING_PROCEDURE_RES";

export const startReportingProcedure = ( application_id, type ) => ( dispatch ) => {

    dispatch({ type: CREATE_REPORITING_PROCEDURE_REQ });
    axios.post(`/api/reporting/${application_id}/reporting/create`, {
        type: type
    })
    .then( res => {
        console.log('craeted', res );
        dispatch({
            type: CREATE_REPORITING_PROCEDURE_RES,
            payload: res.data.data.procedure,
            type: type
        })

        dispatch( getReportingProcedures(application_id) );
    })
}   


// old action
export const START_REPORTIONG_REQ = "START_REPORTIONG_REQ";
export const START_REPORTIONG_RES = "START_REPORTIONG_RES";
export const START_REPORTIONG_ERR = "START_REPORTIONG_ERR";

export const startReporting = (  application_id, iterations, history ) => (dispatch) => {
    dispatch({ type: START_REPORTIONG_REQ, application_id, history });
    axios.post(`/api/reporting/${application_id}/reporting/start`, {
        iterations
    })
    .then( res => {
        dispatch({ type: START_REPORTIONG_RES, payload: res.data.data });
        dispatch( getApplicationInfo(application_id) );
        dispatch( 
            notify( res.data.data.message, "success" )
        );
        // history.push(`/rp/application/${application_id}/matching`)
    })
};

// old action
export const sendReportingStatus = (  application_id, type, status, message, history ) => (dispatch) => {
    dispatch({ type: START_REPORTIONG_REQ, application_id, history });
    axios.post(`/api/reporting/${application_id}/reporting/save`, {
        type,
        status,
        message
    })
    .then( res => {
        dispatch({ type: START_REPORTIONG_RES, payload: res.data.data });
        // dispatch( getApplicationInfo(application_id) );
        dispatch( 
            notify( res.data.data.message, "success" )
        );
        // history.push(`/rp/application/${application_id}/matching`)
    })
};

// old action
export const saveReportFeedback = ( application_id, status, message, history ) => (dispatch) => {
    dispatch({ type: START_REPORTIONG_REQ, application_id, history });
    axios.post(`/api/reporting/${application_id}/reporting/sendback`, {
        status,
        message
    })
    .then( res => {
        dispatch({ type: START_REPORTIONG_RES, payload: res.data.data });
        dispatch( getApplicationInfo(application_id) );
        dispatch( 
            notify( res.data.data.message, "success" )
        );
        // history.push(`/rp/application/${application_id}/matching`)
    })
}

const FINISH_REPORTING_FAILURE_REQ = "FINISH_REPORTING_FAILURE_REQ";
const FINISH_REPORTING_FAILURE_RES = "FINISH_REPORTING_FAILURE_RES";

export const finishApplicationReportingWithFailure = ( application_id, message ) => dispatch => {
    dispatch({ type: FINISH_REPORTING_FAILURE_REQ, application_id });
    axios.post(`/api/reporting/${application_id}/failure`, { message })
    .then( res => {
        dispatch({ type: FINISH_REPORTING_FAILURE_RES, payload: res.data.data });
        dispatch( getApplicationInfo(application_id) );
        dispatch( 
            notify( res.data.data.message, "success" )
        );
        // history.push(`/rp/application/${application_id}/matching`)
    })
}

export const signApplicationFinish = ( application_id ) => dispatch => {
    axios.post(`/api/reporting/${application_id}/failure/sign`)    
    .then( res => {
        dispatch( getApplicationInfo(application_id) );
        dispatch( 
            notify( res.data.data.message, "success" )
        );
    })

}