import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment';

import { EmployeeMessage } from '../Parts';

import { sendNegotiationRequest } from '../../../../../../actions';

class Feedbacks extends Component{

    state = {
        status: 1,
        message: ''
    }

    handleChange = (e) => {
        this.setState({
            message: e.target.value
        });
    }   

    radioHandle = ( e ) => {
        const value = e.target.value;

        this.setState({
            status: Number(value)
        });
    }

    sendResponse = () => {
        const { role } = this.props;
        const { status, message } = this.state;
        let type = '';
        if( role === "financier"){
            type = "financer";
        }
        if( role === "lawyer"){
            type = "lawyer";
        }
        if( role === "project_manager" || role === "project_head"){
            type = "pm";
        }

        this.props.sendNegotiationRequest( type, status, message );
    }


    render = () => {
        const { sendResponse } = this;
        const { role, feedback } = this.props;
        const { status, message } = this.state;

        const allowedRoles = [ 'admin', 'director', 'project_manager', 'project_head', 'lawyer', 'financier'];
        let type = 'pm';
        if( role === "financier"){
            type = "financer";
        }
        if( role === "lawyer"){
            type = "lawyer";
        }
        if( role === "project_manager" || role === "project_head"){
            type = "pm";
        }

        const rules = [
            allowedRoles.some( al_role => al_role === role ),
            message.length > 100
        ];
        
        const disableActions = !rules.every( rule => rule === true );
        const allFeedbacks = [ 
            { type: "pm", name: "Проектний менеджер", ...feedback.single.procedure.pm}, 
            { type: "lawyer", name: "Юрист", ...feedback.single.procedure.lawyer}, 
            { type: "financier", name: "Фінансист", ...feedback.single.procedure.financer} 
        ]; 

        const filtredFeedbacks = allFeedbacks.filter( (item ) => type !== item.type )

        return(
            <div className="expert-solution-form">

                    {
                        filtredFeedbacks.map( item => (
                            <EmployeeMessage key={item.type} employee={ item } employee_name={item.name}  />
                        ))
                    }

{
                        feedback.single.procedure[type].status !== 0 ? 
                        (
                            <div className="additional_info">
                                {
                                    feedback.single.procedure.protocol_id ? (
                                        <div className="additional_info text-white-space">
                                            <p>
                                                {feedback.single.procedure.message}
                                            </p>
                                            {
                                                feedback.single.procedure.protocol_id && (
                                                    <>
                                                        {
                                                            feedback.single.procedure.protocol_id.fileUrl && (
                                                                <a 
                                                                href={feedback.single.procedure.protocol_id.fileUrl} 
                                                                target="_blank" rel="noopener noreferrer" 
                                                                className="application__documents-protocol"> 
                                                                    Завантажити протокол
                                                                </a>
                                                            )
                                                        }
                                                    </>
                                                )
                                            }
                                        </div>
                                    ) : (
                                        <h3>Рішення прийняв {feedback.single.procedure[type].user.name} ({ moment(feedback.single.procedure[type].updated_at).format('lll') })   </h3>
                                    )
                                }
                            </div>
                        ) : 
                        (
                            <>
                            {
                                feedback.single.procedure.status === 2 && feedback.single.procedure[type].status === 0 && (
                                    <>
                                        <label className="expert-solution-form-label-wrap">
                                            <input
                                                className="expert-solution-form-input"
                                                type="radio"
                                                value="1"
                                                name="status"
                                                checked={status === 1 }
                                                onChange={ this.radioHandle }
                                                disabled={ feedback.single.procedure[type].status !== 0}
                                            />
                                            <span className="expert-solution-form-label">Схвалити переговорну процедуру</span>
                                        </label>
                                        <label className="expert-solution-form-label-wrap">
                                            <input
                                                className="expert-solution-form-input"
                                                type="radio"
                                                value="-1"
                                                name="status"
                                                onChange={ this.radioHandle }
                                                checked={status === -1}
                                                disabled={ feedback.single.procedure[type].status !== 0}
                                            />
                                            <span className="expert-solution-form-label">Процедура потребує доопрацювання</span>
                                        </label>
                                    </>
                                )
                            }
                            </>
                        )
                    }

                    {
                        feedback.single.procedure.status === 2 && (
                            <>
                                <div className="expert-solution-form-text">Коментар до рішення ( {message.length} / 100 )</div>
                                <textarea
                                    rows="7"
                                    className="expert-solution-form-textarea"
                                    name="message"
                                    onChange={this.handleChange}
                                    placeholder="Введіть щонайменьше 100 симовлів коментаря, для того, щоб розблокувати відправку"
                                    disabled={ feedback.single.procedure[type].status !== 0}
                                    value={ feedback.single.procedure[type].status !== 0 ? feedback.single.procedure[type].message : message}
                                />
                                <button 
                                    className={ disableActions ? "application-expert_apply-btn application-expert_apply-btn-full disabled" : "application-expert_apply-btn application-expert_apply-btn-full"}
                                    disabled={ feedback.single.procedure[type].status !== 0 }
                                    onClick={sendResponse}
                                > Надіслати рішення по переговорній процедурі</button>
                            </>
                        )
                    }

            </div>
        );

    }

}

const mapStateToProps = (state) => ({
    role: state.auth.user.role,
    feedback: state.matching.negotiation,
    application: state.applications.info.data,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    sendNegotiationRequest: ( type, status, message ) => {
        dispatch( 
            sendNegotiationRequest(
                ownProps.match.params.negotiation_id,
                type, status, message,
                ownProps.history
            ) 
        );
    }
})



export default connect(mapStateToProps, mapDispatchToProps)(Feedbacks);