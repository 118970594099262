import React from 'react'

const RenderFields = ({fields, currentfields, handleChange, name, showExpected, user }) => {
  return (
    <div className="roles__container">
      <ul className="roles__container-list">
    {
      fields.map( (field, key) => {

        if( field.archive == "1"){
          return null;
        }

        let isCheck = false
        let isExpeced = false;

          
        currentfields.forEach(item => {
          if(item === field.value) {
            isCheck=true
          }
        });

        if( showExpected && user.fields ){
          isExpeced = user.fields.expected_keywords.some( keyword => keyword === field.value );
        }

        return(
          <li className="roles__container-list-item" key={key}>
            <label className="roles__item">
                <input
                  className="rolex__checkbox"
                  type="checkbox"
                  name={name}
                  value={field.value}
                  onChange={handleChange}
                  checked={isCheck}
                />
                <span>{field.name}</span>
                {
                  showExpected && (
                    <>
                      {
                        isExpeced && (
                          <span className="expected-item"> Бажане </span>
                        )
                      }
                    </>
                  )
                }
            </label>
          </li>
        )}
      )
    }
    </ul>
    </div>
  )
}

export default RenderFields