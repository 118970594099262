import React from 'react'
import { Switch, Route } from 'react-router-dom';

// Components 
import ExpertsRootData from './ExpertsRootData';
import ExpertsList from '../ExpertsList';
import UnassignedExperts from '../ExpertsList/UnassignedExperts';
import SingleExpertRoot from '../ExpertsList/SingleExpertRoot';
import SelectionRoot from '../ExpertsSelection';
import ExpertSeason from '../ExpertsSeason';
import ExpertSingleSeason from '../ExpertsSeason/SingleSeason';

const ExpertsRoot = () => {


    return(
        <Switch>
            <Route exact path="/ed/experts" component={ExpertsRootData}/>
            <Route exact path="/ed/experts/list" component={ExpertsList} />
            <Route exact path="/ed/experts/unassigned" component={UnassignedExperts}/>
            <Route path="/ed/experts/selection" component={SelectionRoot} />
            <Route path="/ed/experts/seasons/:season_id" component={ExpertSingleSeason} />
            <Route path="/ed/experts/seasons" component={ExpertSeason} />
            <Route path="/ed/experts/:userid" component={SingleExpertRoot} />
        </Switch>
    )

}


export default ExpertsRoot;