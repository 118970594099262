import {
  GET_ALL_USERS_REQUEST,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_ERROR,
  CLEAR_ALL_USERS
} from '../../actions'

const contestsInitialState = {
  data: [],
  count: null,
  loading: false,
  loaded: false,
  errors: []
}

const AllUsers = ( state = contestsInitialState, action) => {
  switch( action.type ){
    case GET_ALL_USERS_REQUEST:
      return {
        ...state,
      loading: true
      }
    case GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.users,
        count: action.count
      }
    case GET_ALL_USERS_ERROR:
      return {
        ...state,
        error: action.error,
      }
    case CLEAR_ALL_USERS:
      return {
        state
      }
    default:
      return state
  }
}

export default AllUsers
