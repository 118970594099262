import axios from 'axios'
import config from '../config'
import store from '../redux/store'
import { CLEAR_AUTH_USER } from '../actions'
import { configurateInterceptors } from './setAxiosInterceptors'


export const privateAxios = axios.create({
    baseURL: config.apiPath,
    headers: {
        get: {
            'Content-type': 'application/json',
            'Accept' : 'application/json'
        }
    }
})

const removeUserSession = () => {
    store.dispatch({
        type: CLEAR_AUTH_USER,
        payload: 'Упс! Здається, ваша сессія закінчилась. Щоб продовжити роботу, будь ласка, зайдіть у свій аккаунт ще раз.'
    });
    localStorage.removeItem('state');
}


privateAxios.interceptors.response.use(function (response) {
    refreshToken( response )
    return response
}, function (error) {
    /*
        Handle session end and logout user
    */
    console.log('error.response', error.response );
    if( error.response === undefined ){
        removeUserSession();
    }
    if( error.response.status === 401 ){
        removeUserSession();
    }
    return Promise.reject(error)
})

/*
    Refresh user JWT token
*/
const refreshToken = ({ headers }) => {
    if( headers !== undefined ){
        if( headers.authorization !== undefined){
            configurateInterceptors( headers.authorization )
        }
    }
}

export default privateAxios