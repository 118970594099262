import React from 'react'
import { Link } from 'react-router-dom';

const NegotiationCircle = ({ application_id, negotiation }) => {

    let addedClass = '';

    let status = negotiation.status;

    if( status < 0){
        addedClass = 'red';
    } else if( status === 2){
        addedClass = 'yellow';
    } else if ( status >= 3){
        addedClass = 'green'
    }

    return(
        <Link
            className={`ed-stats__circle ${addedClass}`} 
            to={`/rp/negotiation/${negotiation._id}`}
        >

        </Link>
    )
}

export default NegotiationCircle;