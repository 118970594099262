import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Breadcrumbs, Table, Row, Cell, Button, TabsNav } from '../../../library';

import Users from '../common/users';
import UnassignedExperts from './expers_selector';
// import Applications from './applications';

import { createDirection } from '../../../../actions';


const dictionary = {
    experts: {
        breadcrumbs: [
            {name: 'Дирекція', link: `/dr` },
            {name: 'Відбір експертів', link: `/dr/experts`},
            {name: 'Нове засідання дирекції', link: `/dr/experts/create`},
        ],
        name: "Нове засідання дирекції"
    },
    
    expert_commision: {
        breadcrumbs: [
            { name: "Експертний відділ", link: `/ed/` },
            { name: "Експерти", link: `/ed/experts`},
            { name: "Відбір експертів", link: "/ed/experts/selection"},
            { name: "Комісія", link: "/ed/experts/selection/commission"},
            { name: 'Нове засідання комісії', link: `/dr/experts/create`},
        ],
        name: "Нове засідання комісії"
    },
    
    expert_supervisory: {
        breadcrumbs: [
            { name: "Експертний відділ", link: `/ed/` },
            { name: "Експерти", link: `/ed/experts`},
            { name: "Відбір експертів", link: "/ed/experts/selection"},
            { name: "Комісія", link: "/ed/experts/selection/commission"},
            { name: 'Нове засідання наглядової ради', link: ``},
        ],
        name: "Нове засідання наглядової ради"
    }
}


const CreateDirection = () => {
    
    const dispatch = useDispatch();
    const history = useHistory();
    const { type } = useParams();
    const [ selectedUsers, setSelectedUsers ] = useState([]);
    const [ selectedApps, setSelectedApps ] = useState([]);
    const [ errorMessage, setErrorMessage ] = useState('');
    const createBlockData = dictionary[ type ];


    const createDirectionAction = () => {
        setErrorMessage('');
        console.log( selectedUsers.length,  selectedApps.length );
        if( selectedUsers.length > 0 && selectedApps.length > 0 ){
            dispatch( createDirection(selectedUsers, selectedApps, type , history) );
        } else {
            if( selectedUsers.length === 0){
                setErrorMessage('Оберіть хоча б одного користувача, щоб створити дирекцію');
                return;
            } 
            if( selectedApps.length === 0){
                setErrorMessage('Оберіть хоча б одного експерта, щоб створити дирекцію');
                return;
            }
        }
    }



    return(
        <>
            <Breadcrumbs
                items={createBlockData.breadcrumbs}
            />
            <header className="direction__header">
                <h1 className="page-title"> {createBlockData.name} </h1>
                <button className="direction__btn" onClick={createDirectionAction}> Створити </button>
            </header>
            {
                errorMessage && (
                    <div className="direction__error">
                        { errorMessage }
                    </div>
                )
            }
            <div className="direction__container">
                <Users data={selectedUsers} handler={setSelectedUsers}/>
                <UnassignedExperts 
                    blockName="Відібрані експерти"
                    type={type}
                    data={selectedApps} 
                    handler={setSelectedApps} 
                    
                />
            </div>

        </>
    );

}


export default CreateDirection;