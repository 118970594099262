import axios from '../../helpers/private.axios';

// import axios from 'axios';
/*
        axios.get(`https://back.ucf.in.ua/api/expert/all`, {
        headers: {
            "Authorization": `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZGNkNjQwOTRkYjdiYWNkZjhlYWM4YTIiLCJyb2xlIjoiYWRtaW4iLCJuYW1lIjoiYWRtaW4zMyIsImlhdCI6MTU4MTUxNzg5OCwiZXhwIjoxNTgxNjA0Mjk4fQ.79A-roeRJlw92YQJm5MWcQSgqR38VQPt-Wjx2wZfbWA`
        }
    })
*/

export const GET_ALL_EXPERTS_REQ = "GET_ALL_EXPERTS_REQ";
export const GET_ALL_EXPERTS_RES = "GET_ALL_EXPERTS_RES";
export const GET_ALL_EXPERTS_ERR = "GET_ALL_EXPERTS_ERR";

export const getAllExpertsList = () => ( dispatch, getState ) => {
    dispatch({ type: GET_ALL_EXPERTS_REQ });
    axios.get(`/api/expert/all`)
        .then( res => {
            dispatch({ 
                type: GET_ALL_EXPERTS_RES,
                payload: res.data.data.data
            });
        })
        .catch( err => {
            dispatch({ type: GET_ALL_EXPERTS_ERR, error: err });
        })
} 


export const getAllFiltredExpertsList = filters => dispatch => {

    dispatch({ type: GET_ALL_EXPERTS_REQ })

    axios.post(`/api/expert/all`, filters )
    .then(
        res => {
            dispatch({
                type: GET_ALL_EXPERTS_RES,
                payload: res.data.data.data,
                // counter: res.data.data.counter,
                total: res.data.data.total
            });
        }
    )

}

export const GET_SINGLE_EXPERT_INFO_REQ = "GET_SINGLE_EXPERT_INFO_REQ";
export const GET_SINGLE_EXPERT_INFO_RES = "GET_SINGLE_EXPERT_INFO_RES";
export const GET_SINGLE_EXPERT_INFO_ERR = "GET_SINGLE_EXPERT_INFO_ERR";

export const getSingleExpertInfo = ( id ) => ( dispatch, getState ) => {
    dispatch({ type: GET_SINGLE_EXPERT_INFO_REQ });
    axios.get(`/api/expert/single/${id}`)
        .then( res => {
            dispatch({ 
                type: GET_SINGLE_EXPERT_INFO_RES, 
                payload: res.data.data 
            });
        })
        .catch( err => {
            dispatch({ type: GET_SINGLE_EXPERT_INFO_ERR });
        })
}