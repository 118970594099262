import React from 'react'
import { connect } from 'react-redux';

import { TabsNav } from '../../../../library';


const ReportingTopTabs = ({ application, user }) => {

    if( application === null ){
        return null;
    }

    const rolesToReporingTab = ['admin', 'chief', 'director', 'project_manager', 'project_head'];

    let headerNav = [];

    if( application.reporting_review.status === -3){
        headerNav = [
            { slug: 'info', title: 'Інформація'},
            { slug: 'experts', title: 'Експертна оцiнка'},
            rolesToReporingTab.some( item => item === user.role ) ? { slug: 'matching', title: 'Етапи погодження' } : null,
            { slug: 'estimate', title: 'Переговорна процедура', disabled: true},
            { slug: 'reporting', title: 'Звітність', disabled: true},
            rolesToReporingTab.some( item => item === user.role ) ? { slug: 'finish', title: 'Фіналізація', disabled: false} : null,
        ];
    } else {
        headerNav = [
            { slug: 'info', title: 'Інформація'},
            { slug: 'experts', title: 'Експертна оцiнка'},
            rolesToReporingTab.some( item => item === user.role ) ? { slug: 'matching', title: 'Етапи погодження'} : null,
            { slug: 'estimate', title: 'Переговорна процедура'},
            { slug: 'reporting', title: 'Звітність', disabled: application.reporting_review.reporting.status === 0 },
            rolesToReporingTab.some( item => item === user.role ) ? { slug: 'finish', title: 'Фіналізація'} : null,
        ];
    }


    return (
        <TabsNav route={`/rp/application/${application._id}`} tabs={ headerNav } />
    );

}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    application: state.applications.info.data,    
});

export default connect(mapStateToProps, null)(ReportingTopTabs);