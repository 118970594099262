
const VALIDATOR_PATTERN = /^[a-z0-9-_]+$/ig


const checkIfValidURLSlug = (str) => {
    // Regular expression to check if string is a valid url slug
    console.log('val:', VALIDATOR_PATTERN.test(str), str );
    return VALIDATOR_PATTERN.test(str);
}

export const validateSlug = ( string ) => {

    if( !checkIfValidURLSlug( string ) ){
        return string
            .replace(/[^a-z0-9-_]/g, "") // remove invalid chars
            .replace(/\s+/g, "-") // collapse whitespace and replace by -
            .replace(/-+/g, "-") // collapse dashes
            .replace(/^-+/, "") // trim - from start of text
    }

    return null;
}