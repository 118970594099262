import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Loader, Breadcrumbs, Table, Row, Cell } from '../../../../library';

import { getAllMethodology }  from '../../../../../actions';

const Methodology = () => {
    const dispatch = useDispatch();
    const metodology = useSelector( state => state.admin.evaluation.methodology );

    const { data, loaded } = metodology;

    useEffect( () => {
        dispatch( getAllMethodology() );
    }, [ metodology.data.length ]);

    return(
        <div className="innerWrap">
            <Breadcrumbs items={[
                {name:'Адмiнiстративна панель', link: '/admin'},
                {name:'Методика експертного оцінювання', link: '/admin/evaluation/'}
            ]}/>

            <h1 className="page-title">Методика експертного оцінювання</h1>

            <Link className="catalog__button" to="/admin/evaluation/methodology/new"> Додати нову методологію </Link>

            <Table>
                <Row type="head">
                    <Cell type="id">#</Cell>
                    <Cell type="large">Група питань</Cell>
                    <Cell type="large">

                    </Cell>
                </Row>
                {
                    loaded && (
                        <>
                            {
                                data.map( (group, key) => (
                                    <Row key={group._id} className="table__row-withLinks">
                                        <Cell type="id">{key + 1}</Cell>
                                        <Cell type="large" className="row_">
                                            <Link to={`/admin/evaluation/methodology/${group._id}`}>
                                                {group.title}
                                            </Link>
                                        </Cell>
                                        <Cell type="large">
                                            <Link to={`/admin/evaluation/methodology/${group._id}/edit`}>Редагувати</Link>
                                        </Cell>
                                    </Row>
                                )) 
                            }
                        </>
                    )
                }
            </Table>
        </div>
    );
}


export default Methodology;