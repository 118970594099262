import React from 'react'

import { Switch, Route, Redirect } from 'react-router-dom';
import { Layout } from '../../../library'

import Groups from './group/groups';
import NewGroup from './group/new_group';
import GroupEdit from './group/group_edit';
import ShowGroup from './group/group_show';

import CreateQuestion from './question/question_edit';

import Methodology from './methodology';
import NewMethodology from './methodology/methodology_new';
import MethodologyEdit from './methodology/methodology_edit';
import MethodologySingle from './methodology/methodology_single';

const EvaluationWrapper = () => {
    return(
        <div className="admin-evaluation">
            <Layout className="projects-division">
                <Switch>
                    <Redirect exact from="/admin/evaluation" to="/admin/evaluation/methodology" />
                    <Route exact path="/admin/evaluation/methodology" component={Methodology}/>
                    <Route exact path="/admin/evaluation/methodology/new" component={NewMethodology}/>
                    <Route exact path="/admin/evaluation/methodology/:id" component={MethodologySingle}/>
                    <Route exact path="/admin/evaluation/methodology/:id/edit" component={MethodologyEdit}/>
                    <Route exact path="/admin/evaluation/methodology/:id/new" component={NewGroup}/>

                    <Redirect exact from="/admin/evaluation/groups" to="/admin/evaluation/methodology" />
                    {/* <Route exact path="/admin/evaluation/groups" component={Groups} /> */}
                    <Route exact path="/admin/evaluation/groups/new" component={NewGroup} />
                    <Route exact path="/admin/evaluation/groups/:id" component={ShowGroup} />
                    <Route exact path="/admin/evaluation/groups/:id/edit" component={GroupEdit} />
                    <Route exact path="/admin/evaluation/groups/:id/create" component={CreateQuestion} />
                    <Route exact path="/admin/evaluation/groups/:id/question/:questionid" component={CreateQuestion} />
                </Switch>
            </Layout>
        </div>
    );
}


export default EvaluationWrapper;