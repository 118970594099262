import React from 'react'

import moment from 'moment';
import { Round } from "../Parts";


export const EmployeeMessage = ({ employee, employee_name }) => (
    <div className="negotiation__block">
        <header className="negotiation__blockHeader">
            <Round status={ employee.status }/>
            <h3> 
                { employee_name }
                { employee.user !== undefined && ` - ${employee.user.name}  ` }
                { employee.updated_at !== undefined && `(${moment( employee.updated_at ).format('lll')})` } 

            </h3>
        </header>
        <p className="negotiation__blockMessage">
            {
                employee.message !== undefined ? employee.message : "Коментаря немає"
            }
        </p>
    </div>
);

export default EmployeeMessage;