import {
  GET_ALL_APP_FOR_EXPERT_REQ,
  GET_ALL_APP_FOR_EXPERT_RES,
  GET_ALL_APP_FOR_EXPERT_ERR,
} from '../../actions'

const usersInitialState = {
  error: null,
  loading: false,
  data: null
}

const documents = (state = usersInitialState, action) => {
  switch (action.type) {
    case GET_ALL_APP_FOR_EXPERT_REQ:
      return ({
        ...state,
        loading: true
      })
    case GET_ALL_APP_FOR_EXPERT_RES:
      return ({
        ...state,
        loading: false,
        data: action.payload
      })
    case GET_ALL_APP_FOR_EXPERT_ERR:
      return ({
        ...state,
        error: action.payload
      })

    default:
      return state
  }
}

export default documents