import privateAxios from '../../helpers/private.axios'
import config from '../../config'

export const GET_LOTS_DATA_REQ ='GET_LOTS_DATA_REQ'
export const GET_LOTS_DATA_RES ='GET_LOTS_DATA_RES'
export const GET_LOTS_DATA_ERR ='GET_LOTS_DATA_ERR'

export const CLEAR_LOTS_DATA ='CLEAR_LOTS_DATA'

export const getLotsData = (id) => (dispatch) => {
  dispatch({ type: GET_LOTS_DATA_REQ })

  privateAxios(`${config.apiPath}/api/catalogs/lots/${id}`)
  .then( res => {
    dispatch({
      type: GET_LOTS_DATA_RES,
      payload: res.data.data
    })
  })
  .catch( err => {
    dispatch({
      type: GET_LOTS_DATA_ERR,
      payload: err
    })
  })
}

export const clearLotsData = () => (dispatch) => {
  dispatch({ type: CLEAR_LOTS_DATA })
}