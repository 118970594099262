import axios from '../../helpers/private.axios';
import { notify } from '../common/notify.actions';

export const GET_NEGOTIATION_STATS_REQ = "GET_NEGOTIATION_STATS_REQ";
export const GET_NEGOTIATION_STATS_NEXT_CHUNK_REQ = "GET_NEGOTIATION_STATS_NEXT_CHUNK_REQ";

export const GET_NEGOTIATION_STATS_RES = "GET_NEGOTIATION_STATS_RES";
export const GET_NEGOTIATION_STATS_NEXT_CHUNK_RES = "GET_NEGOTIATION_STATS_NEXT_CHUNK_RES";

export const GET_NEGOTIATION_STATS_ERR = "GET_NEGOTIATION_STATS_ERR";

export const getReportingApplicationsStats = ( chunk, type ) => (dispatch, getState ) => {
    const { filters, limit, offset, loading } = getState().matching.stats;
    
    console.log('req data', {
        type,
        loading,
        skip: offset,
        filters
    })

    if( !loading ){
        if( !chunk ){
            dispatch({ type: GET_NEGOTIATION_STATS_REQ  });
        } else {
            dispatch({ type: GET_NEGOTIATION_STATS_NEXT_CHUNK_REQ  });
        }
    
        axios.post(`/api/reporting/stats`, {
            limit: 10,
            skip: offset,
            filters
        })
        .then( res => {
            if( !chunk ){
                dispatch({ type: GET_NEGOTIATION_STATS_RES, 
                    payload: res.data.data,
                    total: res.data.data.total
                });
            } else {
                dispatch({ type: GET_NEGOTIATION_STATS_NEXT_CHUNK_RES, 
                    payload: res.data.data,
                    total: res.data.data.total
                });
            }
            
        })
    }

    
};

export const SET_NEGOTIATION_STATS_FILTERS = "SET_NEGOTIATION_STATS_FILTERS";

export const setNegotiationFilters = ( filters ) => ( dispatch, getState ) => {

    const activeFilters = getState().matching.stats.filters;
    
    if( JSON.stringify(activeFilters) !== JSON.stringify(filters) ){
        dispatch({
            type: SET_NEGOTIATION_STATS_FILTERS,
            payload: filters
        });
    } else {
        dispatch( notify('Ви намагаєтесь застосувати той самий фільтр', 'warning' )); 
    }

}

export const CLEAR_NEGOTIATION_FILTERS = "CLEAR_NEGOTIATION_FILTERS";

export const clearNegotiationSettedFilters = () => (dispatch) => {
    dispatch({ type: CLEAR_NEGOTIATION_FILTERS });
}

export const CLEAR_NEGOTIATION_DATA = "CLEAR_NEGOTIATION_DATA";

export const clearNegotiationData = () => (dispatch) => {
    dispatch({ type: CLEAR_NEGOTIATION_DATA });
}