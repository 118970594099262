import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { getAllDocumentTemplates } from '../../../../actions';
import { Table, Row, Cell, Breadcrumbs } from '../../../library';


export const Templates = () => {

    const [ templates, setTemplates ] = useState([]);

    useEffect(() => {
        
        getAllDocumentTemplates()
            .then( res => {
                setTemplates( res.data.data.templates );
            });
    
    }, [])
    


    return(
        <>  
            <Breadcrumbs items={[
                {name: 'Документи', link: `/documents` },
                {name: 'Шаблони', link: `/documents/templates` },
            ]}/>

            <header className="documents__header">
                <h1> Шаблони документів </h1>
                <Link className="documents__btn" to="/documents/create"> Новий шаблон </Link>
            </header>


            <Table>
                <Row className="head">
                    <Cell type="id" className="id">#</Cell>
                    <Cell className="large"> Назва шаблону </Cell>
                    <Cell className="medium"> Хто створив </Cell>
                    <Cell className="medium"> Дата створення </Cell>
                </Row>
            {
                templates.map( (item, index) => {
                    return (
                        <Row 
                            key={item._id} 
                            type="link" 
                            to={`/documents/templates/${item._id}`}
                        >
                            <Cell type="id" className="id">{ index + 1 }</Cell>
                            <Cell className="large"> { item.title } </Cell>
                            <Cell className="medium">
                                { item.user && item.user.name }
                            </Cell>
                            <Cell className="medium">{ moment(item.created_at ).format('lll') }</Cell>
                        </Row>
                    )
                })
            }
            </Table>

        </>
    );

}
