/*
    Root component for wrappers
*/

import React from 'react'
import { Provider } from "react-redux"
import { BrowserRouter, Route, withRouter } from 'react-router-dom'

import GlobalErrorBoundadry from './common/GlobalErrorBoundary';
import AuthWrapper from './common/AuthWrapper';
import { withHelmet } from './library';

import config from '../config'
import store from '../redux/store'
import { configurateInterceptors } from '../helpers/setAxiosInterceptors'
import moment from 'moment'
import 'moment/locale/uk'
/*
    Services:
*/
import * as Sentry from '@sentry/browser'
import withAnalytics, { initAnalytics } from 'react-with-analytics';
/*
    Pollifills for old browsers:
*/
import 'formdata-polyfill'

/*
    Global styles:
*/
import 'normalize.css';
import '../styles/globals.scss';


let Application = null;

if( process.env.NODE_ENV === 'production' ){
    /*
        Sentry - monitoring system, for debbuging client JS.
        Connected to 
    */
    Sentry.init({
        dsn: config.sentry_path,
        release: config.sentry_project
    });
    /*
        Init Google Analytics
    */
    initAnalytics( config.analytics_id );

    Application = withHelmet(
        withRouter(withAnalytics(AuthWrapper)),
    )({
        title: "Система управління проєктами Українського Культурного Фонду",
    });
} else {
    Application = withHelmet(
        withRouter(AuthWrapper),
    )({
        title: "Система управління проєктами Українського Культурного Фонду",
    });
}

moment.locale('uk')

const supportsHistory = 'pushState' in window.history

if( store.getState().auth.token !== null ){
    configurateInterceptors( store.getState().auth.token )
}

const Wrappers = () => {
    return(
        <>
            <BrowserRouter forceRefrech={!supportsHistory}>
                <Provider store={store}>
                    <GlobalErrorBoundadry>
                        <Route component={Application}/>
                    </GlobalErrorBoundadry>
                </Provider>
            </BrowserRouter>
        </>
    )
}

export default Wrappers