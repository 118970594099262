import React, { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import moment from 'moment';

import { Loader, Table, Row, Cell } from '../../../../../library';

import { getApplicationAgreementDocuments } from '../../../../../../actions';

export const DocumentsList = () => {
    const { id } = useParams();

    const [ {documents, loaded }, setDocuments ] = useState({
        documents: [],
        loaded: false
    });


    useEffect(() => {
        getApplicationAgreementDocuments(id)
            .then( res => {
                setDocuments({
                    loaded: true,
                    documents: res.data.data.documents
                });
            })
    }, [])
    


    return(
        <div className="documents__page">  

            <div className="documents__header">
                <h2> Згенеровані угоди </h2>
                <Link
                    className="documents__btn"
                    to={`/rp/application/${id}/agreement/generate`}
                >
                    Згенерувати нову угоду
                </Link>
            </div>


            <Table>
                <Row type="head">
                    <Cell type="medium"> Назва </Cell>
                    <Cell type="medium"> Хто згенерував </Cell>
                    <Cell type="medium"> Статус </Cell>
                    <Cell type="medium"> Дата </Cell>
                </Row>

                { !loaded && <Loader />}
                {
                    documents.map( document => {
                        return (
                            <Row 
                                type="link" 
                                key={document._id}
                                to={`/documents/${document._id}`}    
                            >
                                <Cell type="medium">{ document.title }</Cell>
                                <Cell type="medium">{ document.user.name }</Cell>
                                <Cell type="medium">
                                    { document.status === -2 && "Видалений документ ❌"}
                                    { document.status === -1 && "Архівний документ 📁"}
                                    { document.status === 0 && "Створена угода"}
                                    { document.status === 1 && "Відправлено фінансовому менеджеру"}
                                    { document.status === 2 && "Новий документ (Проєкт ґрантової угоди)"}
                                    { document.status === 3 && "Відправлений заявнику"}
                                    { document.status === 4 && "Ґрантова угода (Підписана заявником)"}
                                    { document.status === 5 && "Підписання угоди (Директор)"}
                                    { document.status === 6 && "Завершена угода"}
                                </Cell>
                                <Cell type="medium">{ moment( document.created_at ).format('lll') }</Cell>
                            </Row>
                        );
                    })
                }


            </Table>
        
        </div>
    )


}


export default DocumentsList;