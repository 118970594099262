import React, { useEffect } from 'react'
import { useDispatch, useSelector }  from 'react-redux'
import { Link } from 'react-router-dom';
import { Loader, Table, Row, Cell, Round, Button, StatusHandler } from '../../../../library';

import { getReportingProcedures, startReportingProcedure } from '../../../../../actions';



const Reporting = () => {

    const dispatch = useDispatch();
    const data = useSelector( state => state.applications.info.data );
    const loaded = useSelector( state => state.applications.info.loading );
    const reporting = useSelector( state => state.matching.reporting.procedures );
    const reporting_loaded = useSelector( state => state.matching.reporting.loaded );

    // console.log('reporting', reporting );

    let load_status = reporting !== null;

    useEffect( () => {
        dispatch( getReportingProcedures( data._id ) );   
    }, [ load_status ])

    console.log('[data]', data, loaded, reporting, reporting_loaded );

    if( !reporting_loaded ){
        return <Loader />
    }

    return(
        <div className="expert-solution-form project-app-reporting-wrap">
            <Table>
                <Row type="head">
                    <Cell type="medium"> Тип звітністі </Cell>
                    <Cell type="large"> Статуси </Cell>
                </Row>	

                <Row>
                    <Cell type="medium"> 
                        Змістова звітність
                    </Cell>
                    <Cell type="large"> 
                        {
                            reporting.content.length === 0 ? (
                                <Button
                                    size="low"
                                    text="Розпочати огляд"
                                    handler={ () => {
                                        dispatch( startReportingProcedure( data._id, 'content') )
                                    }}
                                />
                            ) : 
                            (
                                <div className="reporting_statuses">
                                    {
                                        reporting.content.map( procedure => {
                                            let status = procedure.status;
                                            if(  procedure.status === -2 ){
                                                status = -1;
                                            }
                                            if(  procedure.status === 2){
                                                status = 1;
                                            }
                                            if( procedure.status === -1 || procedure.status === 1){
                                                status = 'progress';
                                            }

                                            console.log('[status]', procedure._id,  procedure.status, status )

                                            return(
                                                <Link key={procedure._id} to={`/rp/application/${data._id}/reporting/${procedure._id}`}>
                                                    <Round status={ status } />
                                                </Link>
                                            )
                                        })
                                    }
                                </div>
                            )
                        }
                    </Cell>
                </Row>	

                <Row>
                    <Cell type="medium"> 
                        Фінансова звітність
                    </Cell>
                    <Cell type="large">
                    {
                            reporting.finance.length === 0 ? (
                                <Button
                                    size="low"
                                    text="Розпочати огляд"
                                    handler={ () => {
                                        dispatch( startReportingProcedure( data._id, 'finance') )
                                    }}
                                />
                            ) : 
                            (
                                <div className="reporting_statuses">
                                    {
                                        reporting.finance.map( procedure => {
                                            let status = procedure.status;
                                            if(  procedure.status === -2 ){
                                                status = -1;
                                            }
                                            if(  procedure.status === 2){
                                                status = 1;
                                            }
                                            return(
                                                <Link key={procedure._id} to={`/rp/application/${data._id}/reporting/${procedure._id}`}>
                                                    <Round  status={ status } />
                                                </Link>
                                            )
                                        })
                                    }
                                </div>
                            )
                        }
                    </Cell>
                </Row>	

                
            </Table>
        
        </div>
    )
}


export default Reporting;