import { combineReducers } from 'redux'

import users from './users';
import apps from './apps';
import list from './list';
import single from './single';

const direction = combineReducers({
    users,
    apps,
    list,
    single
});

export default direction;