export const CLEAR_AUTH_USER_REQ = "CLEAR_AUTH_USER_REQ"
export const CLEAR_AUTH_USER = "CLEAR_AUTH_USER"
export const CLEAR_AUTH_USER_ERR = "CLEAR_AUTH_USER_ERR"

export const RESET_AUTH_ERR = "RESET_AUTH_ERR"

export const logout = () => (dispatch) => {
  dispatch({ type: CLEAR_AUTH_USER_REQ })

  try {
    localStorage.removeItem('state')
    return dispatch({ type: CLEAR_AUTH_USER })
  }
  catch (error) {
    dispatch({
      type: CLEAR_AUTH_USER_ERR,
      payload: error.response.data
    })
  }
}

export const resetError = () => (dispatch) => {
  dispatch({ type: RESET_AUTH_ERR })
}