import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import Menu from './Menu'
import Logo from './Logo'
import User from './User'

import { logout } from '../../../actions'
import { getPath } from '../../../helpers/roles'

import './styles/styles.scss'

const Sidebar = ({user, exit}) => {
  console.log('sb', user);

  if( user === null ){
    return null;
  }

  let link = getPath(user.role)

  if (user || !user.role === 'unassigned') {
    return (
      <div className="sidebar">
        <Logo link={link}/>
        <Menu target={user.role}/>
        <User name={user.name} logout={exit}/>
      </div>
    )
  } else {
    return <Redirect to='/'/>
  }
}

const mapStateToProps = ( state ) => ({
  user: state.auth.user
})

const mapDispatchToProps = (dispatch) => ({
  exit: () => {
    dispatch( logout() )
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)