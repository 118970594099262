import React from 'react'
import { connect } from 'react-redux'

import { updatePasswordUser, clearSingleUserData,  } from '../../../actions'

class Replacement extends React.Component {
  state = {
    oldPassword: '',
    newPassword: '',
    loaded: false
  }

  componentWillUnmount() {
    this.props.updateDataUser()
  }

  sendData = (e) => {
    e.preventDefault()
    this.props.updatePassword(
      this.props.user._id,
      {
        name: this.state.name,
        email: this.state.email,
      },
      this.props.match.path)
  }

  handleChange = (e) => {
    let key = e.target.name
    let value = e.target.value

    this.setState({
      [key]: value
    })
  }

  render() {
    const { updating } = this.props
    const { name, email } = this.state

    return (
      <div className="user-account-edit">
        <form className="form-container" onSubmit={this.sendData}>
          <label className="form-label">
            <div className="form-label-text">
              <span>Ваш старий пароль</span>
            </div>
            <div className="form-label-input">
              <input
                type="text"
                className="form-input"
                name="name"
                onChange={ this.handleChange }
                value={name}
              />
            </div>
          </label>
          <label className="form-label">
            <div className="form-label-text">
              <span>Ваш новий пароль</span>
            </div>
            <div className="form-label-input">
              <input
                type="text"
                className="form-input"
                name="email"
                onChange={ this.handleChange }
                value={email}
              />
            </div>
          </label>
          <div className="form-label">
            <div className="form-label-text"></div>
            <div className="form-label-input">
              <button
                className={updating ? "form-button unactive" : "form-button"}
                type="submit"
              >
                {
                  updating ? 'Пароль Змінюється' : 'Змінити пароль'
                }

                {
                  updating &&
                  (
                    <svg className="spinner" viewBox="0 0 50 50">
                      <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                    </svg>
                  )
                }
              </button>
            </div>
          </div>
        </form>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  user: state.auth.user,
  updating: state.users.singleUser.updating,
})

const mapDispatchToProps = (dispatch) => ({
  updatePassword: (id, data, history) => {
    dispatch(
      updatePasswordUser(id, data, history)
    )
  },
  updateDataUser: () => {
    dispatch(
      clearSingleUserData()
    )
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Replacement)