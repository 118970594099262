import React from 'react'
import { Link } from 'react-router-dom';

const ExpertCircle = ({ id, name, application_id, review_id }) => {

    let addedClass = '';
    if( id < 0){
        addedClass = 'red';
    } else if( id > 0 && id <= 4){
        addedClass = 'yellow';
    } else if ( id === 5){
        addedClass = 'green'
    }


    return(
        <Link
            className={`ed-stats__circle ${addedClass}`} 
            title={name}
            to={`/ed/application/${application_id}/review/${review_id}`}
        >

        </Link>
    )
}

export default ExpertCircle;