import axios from '../../helpers/private.axios'
import config from '../../config'

export const GET_ALL_COUNCIL_REQUEST = 'GET_ALL_COUNCIL_REQUEST'
export const GET_ALL_COUNCIL_SUCCESS = 'GET_ALL_COUNCIL_SUCCESS'
export const GET_ALL_COUNCIL_ERROR = 'GET_ALL_COUNCIL_ERROR'

export const GET_SINGLE_COUNCIL_REQUEST = 'GET_SINGLE_COUNCIL_REQUEST'
export const GET_SINGLE_COUNCIL_SUCCESS = 'GET_SINGLE_COUNCIL_SUCCESS'
export const GET_SINGLE_COUNCIL_ERROR = 'GET_SINGLE_COUNCIL_ERROR'

export const getAllCouncil = () => (dispatch, getState) => {
  dispatch({ type: GET_ALL_COUNCIL_REQUEST })

  axios.get(`${config.apiPath}/api/councils`)
    .then(
      res => {
        dispatch({
          type: GET_ALL_COUNCIL_SUCCESS,
          payload: res.data.data.councils
        })
      }
    )
}

export const getSingleCouncil = ( id ) => (dispatch) => {
  dispatch({ type: GET_SINGLE_COUNCIL_REQUEST })

  axios.get(`${config.apiPath}/api/councils/${id}`).then(
    res => {
      dispatch({
        type: GET_SINGLE_COUNCIL_SUCCESS,
        payload: res.data.data.council
      })
    }
  )
}