import { notify } from '../actions';
import store from '../redux/store';

class SignerController {

    externalSignerUrl = "https://sign.document.online/plugin/v2/uaculturalfoundation?loadJquery=true&loadBootstrap=true"

    constructor( callback, afterEach ){
        if( SignerController.signer !== undefined ){
            return SignerController.signer;
        }
        this.inited = false;  
        this.callback = callback;
        SignerController.signer = this;
    }

    init(){
        return new Promise( ( resolve, reject) => {
            console.log('Signer Plugin initialization');
            window.onerror = function(message, url, line, col, errorObj) {
                console.log(`[GEH]: ${message}\n${url}, ${line}:${col}`);
            };

            const script = document.createElement('script');
            script.type = "text/javascript";
            script.onload = () => {
                this.inited = true;
                resolve( true );
            };
            script.onerror = () => {
                console.log('Signer Error')
                reject( false );
                store.dispatch( 
                    notify('Сервер підпису не відповідає. Будь ласка, оновіть сторінку для перепідключення', 'error')
                ); 
            }
            script.src = this.externalSignerUrl;
            document.head.appendChild( script );
        });
    
    } // Init

    getSettings(){
        const { eSign } = window;
        let signerSettings = new eSign.SignerSettings("ua");
        signerSettings.info = console.log;
        signerSettings.useOCSP = true;
        signerSettings.useTSP = true;
        signerSettings.enableDiviceKeys = true;

        let esignPrivateKeyProviderName = "key";
        let esignTokenProviderName = "token";
        let depositSignProvider = "depositSign";
        // let esignExternalUkeyProviderName = "ukey";
        // let esignBlockchainProviderName = "blockchain";
    
        signerSettings.providers = [esignPrivateKeyProviderName, esignTokenProviderName, depositSignProvider ];
        signerSettings.KeyStorageType = 'Session';
        return signerSettings;
    }

    getSigner( callback ){
        const { eSign } = window;

        console.log('[getSigner] this.eSign', this.eSign);
        
        if( this.eSign ){
            callback( this.eSign );
            return;
        }

        try {
            this.eSign = new eSign.Signer();
            this.eSign.initialize(
                this.getSettings(),
                () => {
                    console.log('th', this.eSign );
                    callback( this.eSign );
                    this.setModalCoords();
                }
            );
            return;

        } catch (error) {
            console.log(error);
            store.dispatch( 
                notify('Сервер підпису не відповідає. Будь ласка, оновіть сторінку для перепідключення', 'error')
            );    
        }

        
    }



    errorCallback( err ){
        store.dispatch( 
            notify('Помилка підписання протоколу', 'error')
        );
    }

    cancelCallback( err ){
        store.dispatch( 
            notify('Підписання договору було перервано', 'error')
        );
    }

    okCallback( callback ){
        console.log('callback', callback );
        return () => {
            callback( true );
            store.dispatch(
                notify('Протокол успішно підписано', 'success')
            );
        }
    }


    signItem( itemId, settings, callback, cancel, error ){
        // SIGN SINGLE ITEM
        const { eSign } = window;

        this.getSigner( ( signer ) => {
            
            let signItemRequest = new eSign.SignItemRequest();
            // console.log( 'item', signItemRequest );
            signItemRequest.errorCallback = this.errorCallback;
            signItemRequest.okCallback = callback;
            signItemRequest.cancelCallback = this.cancelCallback;
            
            signItemRequest.isExternalSign = false;
    
            signItemRequest.displayMessage = "text1";

            let bindKey = new eSign.BindKey(settings.keyIdentity);
            bindKey.required = true;
    
            //only if we know key identity - we could skip key choosing
            if (settings.keyIdentity) {
                signItemRequest.allowToSkipChooseKey = true;
            }
    
            signItemRequest.keys.push(bindKey);
            signer.signItem(itemId, signItemRequest);

        });
    
    }


    setCoords( width, top, left ){
        this.coords = {
            width: width,
            top: top,
            left: left
        }

        console.log('setted coords:', this.coords);
    }

    setModalCoords(){
        console.log('coords:', this.coords);
        const modal = document.getElementById('eSignReadPrivateKeyModal');
        modal.style.top = `${this.coords.top -150}px`;
        modal.style.left = `${this.coords.left}px`;
        // modal.style.width = `${this.coords.width}px`;
        modal.style.width = `775px`;
    }


    signItems( dataItems, keyIdentity = null, okCallback, afterEach ){
        // SIGN MULTIPLE ITEMS
        console.log('datta items', dataItems );
        if (dataItems.length === 0) {
            console.log('ok callback', okCallback )
            okCallback( true );
            return;
        }
        let docId = dataItems[0];
        dataItems.splice(0, 1);

        let settings = {
            innerSign: true, 
            keyIdentity,
        };
        this.signItem( docId, settings, ( signingResult ) => {
            console.log('sign rees:', signingResult );
            if (signingResult) {
                if (!keyIdentity) {
                    keyIdentity = signingResult.keyIdentities && signingResult.keyIdentities.length === 1
                    ? signingResult.keyIdentities[0]
                    : null;
                }
                console.log('keyIdentity', keyIdentity);
                this.signItems(dataItems, keyIdentity, okCallback, afterEach );
                if( afterEach ){
                    store.dispatch( afterEach(docId) );
                }
            }  
        });

    } // Sign Item 


    doSomething(){
        console.log( 'smsng' );
    }

}

export default SignerController;

/*


*/