import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import { Layout, Breadcrumbs, Loader } from '../../../../library';

import ReportingTopTabs from './ReportingTopTabs';

import IndexScreen from './Data/';
import NegotiationEdits from './Data/Edits';
import Feedbacks from './Data/Feedback';
import FileUploader from './Data/Files';
import Decision from './Data/Decision';

import { getSingleNegotiationProcedure } from '../../../../../actions';

class Negotiation extends Component {


    componentDidMount(){
        this.props.getProcedureData()
    }

    render(){
        const { breadcrumbs, application, negotiation, loaded } = this.props;
        if( !loaded ){
            return <Loader />
        }

        return(
            <Layout
                className="projects-division"
                paddingTop="20px"
                header={ 
                    <ReportingTopTabs />
                }
            >
                <Breadcrumbs items={[
                    { name: 'Погодження', link: '/rp/programs' },
                    { name: breadcrumbs.program.title, link: `/rp/programs/${breadcrumbs.program._id}` },
                    breadcrumbs.lot !== undefined && breadcrumbs.lot !== null ?
                    { name: `${breadcrumbs.lot.title}`, link: `/rp/lots/${breadcrumbs.lot._id}` } :
                    null,
                    { name: `${breadcrumbs.contest.title}`, link: `/rp/contests/${breadcrumbs.contest._id}` },
                    { name: `${application.spec_id}`, link: `/rp/application/${application._id}/info` },
                    { name: `Переговорна процедура`, link: `/rp/negotiation/${negotiation._id}` },
                    null 
                ]} />
    
                <Switch>
                    <Route exact path="/rp/negotiation/:negotiation_id" component={IndexScreen} />
                    <Route exact path="/rp/negotiation/:negotiation_id/edits" component={NegotiationEdits} />
                    <Route exact path="/rp/negotiation/:negotiation_id/feedbacks" component={Feedbacks} />
                    <Route exact path="/rp/negotiation/:negotiation_id/attached" component={FileUploader} />
                    <Route exact path="/rp/negotiation/:negotiation_id/decision" component={Decision} />
                </Switch>
    
            </Layout>
        );

    }
}

const mapStateToProps = (state) => ({
    application: state.applications.info.data,
    breadcrumbs: state.applications.info.breadcrumbs,
    loaded: state.matching.negotiation.single_loaded,
    negotiation: state.matching.negotiation.single !== null && state.matching.negotiation.single.procedure
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    getProcedureData: () => {
        dispatch( getSingleNegotiationProcedure( ownProps.match.params.negotiation_id ) );
    }  
})



export default connect(mapStateToProps, mapDispatchToProps )(Negotiation);
