import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Button } from '../../../library';

import AppliedFilters from './applied_filters';


import { setNegotiationFilters, clearNegotiationSettedFilters } from '../../../../actions';
import { loadState, saveState, removeState } from '../../../../helpers/storeLocalData';

import { close, filter } from '../icons';

const isFiltersNotSetted = ( obj ) => {
    let status = true;
    for( let key in obj ){
        if( obj[key] !== ''){
            status = false;
        }
    }
    return status;
}


class Filters extends Component {

    state = {
        filters: {
            spec_id: "",
            application_status_type: "",
            negotiation_status: ""
        },
        show: false,
        loaded: false
    }

    throttlingTimer = null

    static getDerivedStateFromProps(props, state) {
        console.log('state', state, props )
        if( !state.loaded ){
            return {
                ...state,
                filters: props.filters,
                loaded: true
            }
        } else {
            // console.log("isFiltersNotSetted(props.filters)", isFiltersNotSetted(props.filters))
            // if( isFiltersNotSetted(props.filters) ){
            //     return{
            //         ...state,
            //         filters: props.filters
            //     }
            // }    

        }

        return null;
    }  

    isFiltersNotSetted = () => {
        const { filters } = this.props;
        let status = true;
        for( let key in filters ){
            if( filters[key] !== ''){
                status = false;
            }
        }
        return status;
    }


    showFilters = () => { 
        const { show } = this.state;
        this.setState({ show: !show });
    }

    changeHandler = ( e ) => {
        let value = e.target.value;
        let name = e.target.name;

        this.setState({
            filters: {
                ...this.state.filters,
                [ name ] : value 
            }
        })
    }

    setFilters = () => {
        const { filters } = this.state;
        const { setFiltersAction } = this.props;
        
        setFiltersAction( filters );
        saveState(filters, 'negotiation_filters');

        this.setState({
            show: false
        })
    }

    clearFilters = () => {
        this.props.clearFiltersAction();
        this.setState({ show: false });

        removeState('negotiation_filters')
    }


    componentDidMount = () => {
        const { setFiltersAction } = this.props;
        console.log('loadState', loadState('negotiation_filters') )
        const preloadedData = loadState('negotiation_filters');
        if( preloadedData ){
            this.setState({
                filters: preloadedData
            })
            setFiltersAction( preloadedData );
        }

    }

    render = () => {

        const { show } = this.state;
        const { changeHandler, setFilters, clearFilters } = this;
        const { spec_id, application_status_type, negotiation_status } = this.state.filters;

        const { filters, apps_loaded, applications, total } = this.props;
        const { showFilters } = this;

        const disableClearButton = isFiltersNotSetted(filters);

        return(
            <div className="ed-stats">

                <button className="ed-stats__filtersBtn" onClick={showFilters}>
                    <img className="ed-stats__filtersIcon" src={ show ? close : filter } alt=""/>
                    <span> 
                        { show ? "Сховати фільтри" : "Налаштувати фільтрацію"} 
                        { apps_loaded && ` (Всього результатів: ${applications.length}/${total})`}
                    </span>
                </button>
                {/* <AppliedFilters filters={ filters } /> */}

                <div className={ show ? "ed-stats__body show" : "ed-stats__body"}>
                    
                    <label className="ed-stats__label">
                        <span className="ed-stats__title"> Номер заявки </span>
                        <input 
                            className="ed-stats__input" 
                            type="text" 
                            name="spec_id" 
                            value={spec_id} 
                            onChange={changeHandler} 
                        />
                    </label>

                    <label className="ed-stats__label">
                        <span className="ed-stats__title"> Етап погодження </span>
                        <select 
                            className="ed-stats__input" 
                            name="application_status_type" 
                            onChange={changeHandler} 
                            value={application_status_type}
                        >
                            <option value=""> Оберіть статус заявки </option>
                            <option value="reporting.neotiation"> Переговорна процедура </option>
                            <option value="reporting.direction"> Рішення дирекції </option>
                            <option value="reporting.supervisory_board"> Наглядова рада </option>
                            <option value="reporting.grunt_agreement"> Грантова угода </option>
                            <option value="reporting.reporting"> Звітність </option>
                            <option value="reporting.act"> Акт виконаного проекту </option>
                        </select>
                    </label>

                    <label className="ed-stats__label">
                        <span className="ed-stats__title"> Статус переговорної процедури </span>
                        <select 
                            className="ed-stats__input" 
                            name="negotiation_status" 
                            value={negotiation_status}
                            onChange={changeHandler}
                        >
                            <option value=""> Усі статуси </option>
                            <option value="-2"> Потребує доопрацювання, підписана </option>
                            <option value="-1"> Потребує доопрацювання, не підписана </option>
                            {/* <option value="0"> Не розпочата </option> */}
                            <option value="1"> Триває переговорна процедура </option>
                            <option value="2"> Заявник вніс зміни </option>
                            <option value="3"> Завершена, не підписана </option>
                            <option value="4"> Завершена, підписана </option>
                        </select>
                    </label>
                
                    <footer className="ed-stats__footer">
                        <Button 
                            handler={setFilters}
                            size="large"
                            text="Застосувати фільтри"
                        />
                        <Button 
                            handler={clearFilters}
                            size="medium"
                            text="Очистити"
                            color="gray"

                            disabled={disableClearButton}
                        />
                    </footer>
                </div>
            </div>
        )
    }
}


/*
    Redux
*/


const mapStateToProps = (state) => ({
    filters: state.matching.stats.filters,
    applications: state.matching.stats.data,
    apps_loaded: state.matching.stats.loaded,
    total: state.matching.stats.total_with_filter,
})

const mapDispatchToProps = (dispatch) => ({
    setFiltersAction: ( filters ) => {
        dispatch( setNegotiationFilters( filters ) );
    },
    clearFiltersAction: () => {
        dispatch( clearNegotiationSettedFilters() );
    }
});


export default connect( mapStateToProps, mapDispatchToProps )(Filters);