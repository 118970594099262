const application_rollback_schema = [
    {
        label: "Технічний відбір",
        name: "tech_review",
        subqueries: [
            {
                label: "Не оброблено",
                status: 0
            }
        ]
    },
    {
        label: "Експертний відбір",
        name: "expert_review",
        subqueries: [
            {
                label: "Не оброблено",
                status: 0
            },
            {
                label: "Розподілено експертів",
                status: 1
            }
        ]
    },
    {
        label: "Погодження",
        name: "reporting_review",
        subqueries: [
            {
                label: "Пройдено технічний та екпертний відбір",
                status: 0,
            },
            {
                label: "Тривають переговорні процедури",
                status: 1
            },
            {
                label: "Пройдено переговорну процедуру",
                status: 2
            },
            {
                label: "Дирекція",
                name: 'direction',
                status: 3,
                substatuses: [
                    {
                        status: 0,
                        label: "Немає рішення"
                    }
                ]
            },
            {
                label: "Наглядова рада",
                name: 'supervisory_board',
                status: 4,
                substatuses: [
                    {
                        status: 0,
                        label: "Немає рішення"
                    }
                ]
            },
            {
                label: "Грантова угода",
                name: 'grunt_agreement',
                status: 5,
                substatuses: [
                    {
                        status: 0,
                        label: "Угода не завантажена"
                    },
                    {
                        status: -1,
                        label: "Видалити додаткові угоди"
                    },
                    {
                        status: 4,
                        label: "Видалити скановану угоди"
                    }
                ]
            },
            {
                label: "Звітування",
                name: 'reporting',
                status: 6
            },
            {
                label: "Акт виконаного проекту",
                name: "completed_act",
                status: 7
            },
            
        ]

    },
];

export default application_rollback_schema;