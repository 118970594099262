import React from 'react';

import getTypeValue from '../data-inputs';

/*
    Multidata helperes:
*/
const isEmpty = ( dataItem ) => {
    const value = dataItem[Object.keys( dataItem )[0]];
    if( typeof(value) === 'object' && value !== null ){
        return value[ Object.keys( value )[0] ] === null;
    } else {
        return value === null;
    }
};
const findSubfield = ( name, subfields ) => subfields.find( subfield => subfield.name === name ); 

export const MultitabData = ({ value, subfields = [] }) => {
    const isAllEmpty = value.every( item => isEmpty(item) );
    if( isAllEmpty ){
        return <b> Немає </b>
    }

    return (
        <div className="dataSort__multitab">
            {
                value.map( (dataItem, key) => {
                    const isItemEmpty = isEmpty(dataItem);
                    const itemKeys = Object.keys( dataItem );
                    if( !isItemEmpty ){
                        return(
                            <div key={key} className="dataSort__multitabItem">
                                {
                                    itemKeys.map( field_name => {
                                        let subfield = findSubfield( field_name, subfields );
                                        if( subfield ){
                                            let DataComponent = getTypeValue( subfield.type );
                                            return(
                                                <div className="dataSort__multitabValue" key={field_name}>
                                                    <span className="text-sub">{subfield.label}</span>
                                                    <DataComponent value={dataItem[field_name]} options={subfield.options} />
                                                </div>
                                            );
                                        }


                                    })
                                }
                            </div>
                        )
                    } else {
                        return null;
                    }
                })  
            }
        </div>
    )
};