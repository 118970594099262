import {
  GET_SINGLE_FIELD_REQUEST,
  GET_SINGLE_FIELD_SUCCESS,
  GET_SINGLE_FIELD_ERROR,

  GET_ALL_FIELDS_REQ,
  GET_ALL_FIELDS_RES,
  GET_ALL_FIELDS_ERR

} from '../../actions'

const contestsInitialState = {
  all: {
    fields: [],
    count: 0
  },
  all_loaded: false,
  culture: null,
  keywords: null,
  culture_loading: false,
  keywords_loading: false,
  errors: [],
}

const keywords = ( state = contestsInitialState, action) => {
  switch( action.type ){
    case GET_SINGLE_FIELD_REQUEST:
      return {
        ...state,
        [`${action.field}_loaded`]: false
      }
    case GET_SINGLE_FIELD_SUCCESS:
      return {
        ...state,
        [`${action.field}_loaded`]: true,
        [action.field]: action.payload
      }
    case GET_SINGLE_FIELD_ERROR:
      return {
        ...state,
        error: action.error,
      }

    case GET_ALL_FIELDS_REQ:
      return {
        ...state,
        all_loaded: false
      }
    case GET_ALL_FIELDS_RES:
      return {
        ...state,
        all_loaded: true,
        all: action.payload
      }

    case GET_ALL_FIELDS_ERR:
      return {
        ...state,
        error: action.error,
      }

    default:
      return state
  }
}

export default keywords