import React, { Component } from 'react'
import { connect } from 'react-redux';

import Confirm from '../../../common/ConfirmAction'
import { sendToValidation, saveDataBeforeValidation } from '../../../../actions';


class RateSolution extends Component {

	state = {
		message: "",
		error: "",
		loaded: false
	}

	handleChange = (e) => {

		let error = this.state.error;
		if( this.state.message.length >= 200 ){
			error = '';
		}
		
		this.setState({ message: e.target.value, error });
	}

	static getDerivedStateFromProps = ( props, state ) => {
		console.log('s', state, 'p', props);
		if( props.review.expert_message !== '' && state.loaded === false){
			return {
				...state,
				message: props.review.expert_message !== undefined ? props.review.expert_message : '',
				loaded: true
			}
		} else {
			return null;
		}
	}


	sendToValidation = (e) => {
		e.preventDefault();

		if( this.state.message.length < 200 ){
			this.setState({
				error:  'Коментар закороткий'
			});
			return;
		} else {
			this.setState({ error: '' })
		}


		this.props.sendToValReq(
			this.state.message,
			this.props.review._id
		);
	}

	saveData = (e) => {
		e.preventDefault();

		if( this.state.message.length < 200 ){
			this.setState({
				error:  'Коментар закороткий'
			});
			return;
		} else {
			this.setState({ error: '' })
		}


		this.props.saveDataBeforeValid(
			this.state.message,
			this.props.review._id
		);
	}


	render = () => {
		const { sendToValidation, saveData } = this;
		let { rate, user, disabled, review } = this.props;
		const { message, error } = this.state;

		let gradeStyles;
		if (parseInt(rate) < 40) { gradeStyles = { color: "#CE5151" }}
		if (parseInt(rate) >= 40 && parseInt(rate) < 70) { gradeStyles = { color: "#29ABE2" }}
		if (parseInt(rate) >= 70 && parseInt(rate) <= 100) { gradeStyles = { color: "#3EB29D" }}

		console.log("STAT", review.status.id, review.status.id !== 1 , review.status.id !== 4, review.status.id !== 1 && review.status.id !== 4 );
		if( review.status.id !== 1 && review.status.id !== 4  ){
			disabled = true;
		}


		return (
			<>
			<div className="rate-solution">
				<div className="rate-solution-title">Загальний бал:</div>
				<div className="rate-solution-rate">
				{rate ? rate : 0}
				</div>
				{/* <div className="rate-solution-brief" style={gradeStyles}>
					Я, {user.name}, експерт УКФ, пропоную
					<b>
						{ parseInt(rate) <= 40 && ' не підтримувати проект!'}
						{ parseInt(rate) > 40 && parseInt(rate) < 80 && ' підтримати проект із зауваженнями' }
						{ parseInt(rate) >= 80 && parseInt(rate) <= 100 && ' підтримати проект' }
					</b>
				</div> */}
				
				<div className="rate-solution-brief-title">
					Зведений коментар експерта по проекту, аргументуйте свою оцінку
				</div>

				<header className="rate-solution-form-header">
					{/* <div className="rate-solution-brief-text">
						{ parseInt(rate) <= 40 && 'Обґрунтуйте, будь ласка, ваше рішення, зазначивши мінімум три вагомих аргументи, чому проект не може претендувати на фінансування Фонду.'}
						{ parseInt(rate) > 40 && parseInt(rate) < 80 && 'Обґрунтуйте, чому ви пропонуєте Фонду підтримати даний проект, зазначивши мінімум три вагомих аргументи і вкажіть, що саме має бути доопрацьовано до початку реалізації проекту.' }
						{ parseInt(rate) >= 80 && parseInt(rate) <= 100 && 'Обґрунтуйте, чому ви пропонуєте Фонду підтримати проект, зазначивши мінімум три вагомих аргументи' }
						<span className="required">*</span>
					</div> */}
					<div>
						{
							message && (
								<>
									<span>{message.length}</span>
									<span>/200</span>
								</>
							)
						}
						
					</div>
				</header>

				{
					error !== '' && 
					(
						<div className="text invalid">
							{error}
						</div>
					)
				}

				{
					review.reviewer_message !== undefined && review.reviewer_message !== "" && review.status.id !== 2 && review.status.id !== 6 &&
					(
						<div className="additional_info">
							<b>Коментар експертного відділу:</b>
							<i>
								{ review.reviewer_message } 
							</i>
						</div>
					)
				}

				<div>
					<textarea
						rows="6"
						className="expert-solution-form-textarea"
						onChange={this.handleChange}
						value={message}
						name="textarea"
						placeholder={ disabled ? "Поле для коментаря буде розблоковано, коли ви оціните усі поля у заявці" : ""}
						disabled={disabled}
					/>
					<footer className="direction__comment">

				

						<button onClick={saveData} disabled={disabled} className={disabled ? "direction__btn disabled" : "direction__btn"} type="submit">Зберегти відгук</button>
						
						<Confirm
							disabled={disabled}
							type="accept"
							title="Відправити відгук на валідацію"
							message="Ви впевнені, що хочете відправити відгук на валідацію? Після цього функція редагування відгуку буде заблокована"
							closeMessage="Повернутись назад"
							redirect="/admin/users/council"
							confirmButtonClass="direction__btn finish"
						>
							<button onClick={sendToValidation} className={disabled ? "direction__btn disabled" : "direction__btn finish"} type="submit" disabled={disabled}>Відправити відгук на валідацію</button>
						</Confirm>
						
					</footer>
				</div>
				
			</div>
			</>
		);
	}
}


const mapStateToProps = (state) => ({
	user: state.auth.user,
	review: state.expert.single.data.review
});

const mapDispatchToProps = (dispatch, ownProps ) => ({
	sendToValReq: (message, review_id ) => {
		dispatch( sendToValidation(message, review_id) );
	},
	saveDataBeforeValid: (message, review_id) => {
		dispatch( saveDataBeforeValidation(message, review_id) )
	}
});






export default connect(mapStateToProps, mapDispatchToProps)(RateSolution);