import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from './../../library'

const RestoreDone = () => (
  <div className="login-container">
    <p className="login-recovery-title">Вiдновлення паролю пройло успiшно</p>
    <p className="login-recovery-subtitle">Перейдiть, будь ласка, на початкову строрiнку</p>
    <br />
    <Button type="link" text="Форма входу у систему" to="/" />
    <br />
    <p className="login-recovery-title">З повагою адмiнiстрацiя!</p>

    <div className="login-footer">
      <button className="login-footer-info">Український Культурний Фонд</button>
      <Link className="login-footer-recovery" to="/">Форма входу у систему</Link>
    </div>
  </div>
)

export default RestoreDone