import {
  RESTORE_PASS_RES,
  RESTORE_PASS_REQ,
  RESTORE_PASS_ERR,

  RESTORE_PASS_HASH_REQ,
  RESTORE_PASS_HASH_RES,
  RESTORE_PASS_HASH_ERR,

  RESTORE_PASS_CLEAN
} from '../../actions'

const usersInitialState = {
  error: null,
  loading: false,
  success: false,
  done: false
}

const restore = ( state = usersInitialState, action) => {
  switch( action.type ){
    case RESTORE_PASS_REQ:
      return {
        ...state,
        loading: true
      }
    case RESTORE_PASS_RES:
      return {
        ...state,
        success: true,
        loading: false,
      }
    case RESTORE_PASS_ERR:
      return {
        ...state,
        error: action.payload,
      }
    case RESTORE_PASS_HASH_REQ:
      return {
        ...state,
        loading: true
      }
    case RESTORE_PASS_HASH_RES:
      return {
        ...state,
        done: true,
        loading: false,
      }
    case RESTORE_PASS_HASH_ERR:
      return {
        ...state,
        error: action.payload,
      }
    case RESTORE_PASS_CLEAN:
      return usersInitialState

    default:
        return state
  }
}

export default restore
