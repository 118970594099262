import privateAxios from '../../helpers/private.axios'
import config from '../../config'
import { notify } from './../common/notify.actions'

export const GET_LOTS_REGISTER_REQ ='GET_LOTS_REGISTER_REQ'
export const GET_LOTS_REGISTER_RES ='GET_LOTS_REGISTER_RES'
export const GET_LOTS_REGISTER_ERR ='GET_LOTS_REGISTER_ERR'

export const SWITCH_LOTS_REGISTER_REQ ='SWITCH_LOTS_REGISTER_REQ'
export const SWITCH_LOTS_REGISTER_RES ='SWITCH_LOTS_REGISTER_RES'
export const SWITCH_LOTS_REGISTER_ERR ='SWITCH_LOTS_REGISTER_ERR'

export const CLEAR_LOTS_REGISTER ='CLEAR_LOTS_REGISTER'

export const getLotsRegister = (id) => (dispatch) => {
  dispatch({ type: GET_LOTS_REGISTER_REQ })

  privateAxios.get(`${config.apiPath}/api/catalogs/contests/${id}`)
  .then( res => {
    dispatch({
      type: GET_LOTS_REGISTER_RES,
      payload: res.data.data.contest
    })
  })
  .catch( err => {
    dispatch({
      type: GET_LOTS_REGISTER_ERR,
      payload: err
    })
  })
}

export const switchLotsRegister = (data, id) => (dispatch) => {
  dispatch({ type: SWITCH_LOTS_REGISTER_REQ })

  const toSend = {"registries": data }

  privateAxios.post(`${config.apiPath}/api/catalogs/contests/${id}/registery`, toSend)
  .then( res => {
    dispatch({
      type: SWITCH_LOTS_REGISTER_RES,
      payload: res
    })
    dispatch( notify(res.data.data.message, "success") )
  })
  .catch( err => {
    dispatch({
      type: SWITCH_LOTS_REGISTER_ERR,
      payload: err
    })
    // dispatch( notify(err.message, "error") )
  })
}

export const clearLotsRegister = () => (dispatch) => {
  dispatch({ type: CLEAR_LOTS_REGISTER })
}