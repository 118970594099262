import axios from '../../helpers/private.axios'
import config from '../../config'

import { getFeedbacks } from  './review.actions';
import { notify } from '../common/notify.actions';

export const GET_ALL_APP_FOR_EXPERT_REQ = 'GET_ALL_APP_FOR_EXPERT_REQ'
export const GET_ALL_APP_FOR_EXPERT_RES = 'GET_ALL_APP_FOR_EXPERT_RES'
export const GET_ALL_APP_FOR_EXPERT_ERR = 'GET_ALL_APP_FOR_EXPERT_ERR'

export const GET_SINGLE_APP_FOR_EXPERT_REQ = 'GET_SINGLE_APP_FOR_EXPERT_REQ'
export const GET_SINGLE_APP_FOR_EXPERT_RES = 'GET_SINGLE_APP_FOR_EXPERT_RES'
export const GET_SINGLE_APP_FOR_EXPERT_ERR = 'GET_SINGLE_APP_FOR_EXPERT_ERR'

export const CLEAR_EXPERT_APP_SINGLE = 'CLEAR_EXPERT_APP_SINGLE'
export const CLEAR_EXPERT_APP_ALL = 'CLEAR_EXPERT_APP_ALL'



export const getAllAppForExpert = () => (dispatch) => {
  dispatch({type: GET_ALL_APP_FOR_EXPERT_REQ })

  axios.get(`${config.apiPath}/api/expert`)
    .then(res => {
      dispatch({
        type: GET_ALL_APP_FOR_EXPERT_RES,
        payload: res.data.data.applications
      })
    }
  )
  .catch( error => {
    dispatch({
      type: GET_ALL_APP_FOR_EXPERT_ERR,
      payload: error.message
    })
  })
}

export const getSingleAppForExpert = (id, repeat) => (dispatch) => {

  if( !repeat ){
    dispatch({type: GET_SINGLE_APP_FOR_EXPERT_REQ })
  }

  axios.get(`${config.apiPath}/api/expert/${id}`)
    .then(res => {
      dispatch({
        type: GET_SINGLE_APP_FOR_EXPERT_RES,
        payload: res.data.data
      })
    }
  )
  .catch( error => {
    dispatch({
      type: GET_SINGLE_APP_FOR_EXPERT_ERR,
      payload: error.message
    })
  })
}

export const clearSingleAppForExpert = () => (dispatch) => {
  dispatch({ type: CLEAR_EXPERT_APP_SINGLE })
}

export const clearExpertAll = () => (dispatch) => {
  dispatch({ type: CLEAR_EXPERT_APP_ALL })
}

const SEND_TO_REWORK_REQ = "SEND_TO_REWORK_REQ";
const SEND_TO_REWORK_RES = "SEND_TO_REWORK_RES";

export const sendReviewToRework = ( message, id ) => (dispatch) => {
  dispatch({ type: SEND_TO_REWORK_REQ });
  axios.post(`/api/expert/review/${id}/rework`,
  {
    message
  })
		.then( res => {
			console.log('res', res);
			dispatch({ type: SEND_TO_REWORK_RES, payload: res });
      dispatch( getFeedbacks(id) );
      dispatch( notify(res.data.data.message, "success") );
		})
}

const APPROVE_VALIDATION_REQ = "APPROVE_VALIDATION_REQ";
const APPROVE_VALIDATION_RES = "APPROVE_VALIDATION_RES";

export const approveValidation = ( message, id ) => (dispatch) => {
  dispatch({ type: APPROVE_VALIDATION_REQ });
  axios.post(`/api/expert/review/${id}/approve`,
  {
    message
  })
		.then( res => {
			console.log('res', res);
			dispatch({ type: APPROVE_VALIDATION_RES, payload: res });
      dispatch( getFeedbacks(id) );
      dispatch( notify(res.data.data.message, "success") );
		})
}


export const PrevApproveValidation = ( message, id ) => (dispatch) => {
  dispatch({ type: APPROVE_VALIDATION_REQ });
  axios.post(`/api/expert/review/${id}/prev-approve`,
  {
    message
  })
		.then( res => {
			console.log('res', res);
			dispatch({ type: APPROVE_VALIDATION_RES, payload: res });
      dispatch( getFeedbacks(id) );
      dispatch( notify(res.data.data.message, "success") );
		})
}