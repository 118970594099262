import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import CreateEditor from '../editor/config';
import { VaribalesConfigurator } from '../editor/variables_configurator';

import tempData from '../editor/tempJson.json';

import { Breadcrumbs } from '../../../library';
import { storeTemplate } from '../../../../actions';

export const CreateTemplate = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const inputNode = useRef(null);
    const editorNode = useRef(null);
    const [ editorValue, setEditorValue ] = useState(tempData);
    const [ editorInited, setEditorInited ] = useState(false);
    const [ documentValues, setDocumentValue ] = useState({ title: "", comment: "" });
    const [ errorMessage, setErrorMessage ] = useState("");

    const [ variables, setVariables ] = useState([
        {
            name: "sum_number",
            mode: "finance",
            label: "Сума гранту числом",
        },
        {
            name: "sum_text",
            mode: "finance",
            label: "Сума гранту прописом",
        },
        {
            name: "name_in_genitive_case",
            mode: "applicant",
            label: "ПІБ у родовому відмінку",
        },
        {
            name: "full_applicant_name",
            mode: "applicant",
            label: "ПІБ у називному відмінку (тільки стипендії)"
        },
        {
            name: "works_by_document",
            mode: "applicant",
            label: "На основі чого діє компанія",
        }
    ]);

    useEffect( () => {
        if( !editorInited ){
            setEditorInited( true );
            CreateEditor( editorNode.current, editorValue, setEditorValue )
        }
    }, [ editorInited, editorValue ])


    const handleDocumentValues = ( e ) => {
        let { name, value } = e.target;
        setDocumentValue({
            ...documentValues,
            [name]: value
        });
    }

    const storeTemplateAction = () => {
        if( documentValues.title === ""){
            setErrorMessage("Не вказано назву документа");
            return;
        }

        setErrorMessage("");

        dispatch( storeTemplate({ 
            title: documentValues.title,
            comment: documentValues.comment,
            template: typeof(editorValue) === "string" ? JSON.parse(editorValue) : editorValue,
            variables: variables
        }, history) );
        console.log('[Data to store]', editorValue, variables, documentValues );
    }
    

    return(
        <>  
            <Breadcrumbs items={[
                {name: 'Документи', link: `/documents` },
                {name: 'Шаблони', link: `/documents/templates` },
                {name: 'Новий шаблон', link: `#` },
            ]}/>

            <header className="documents__header">
                <h1> Новий шаблон </h1>
                <button className="documents__store" onClick={storeTemplateAction}> Зберегти шаблон </button>
            </header>

            {
                errorMessage && (
                    <div className="documents__error">
                        { errorMessage }
                    </div>
                ) 
            }

            <div className="documents__row documents__row--vertacal">
                <label className="documents__label">
                    <p className="documents__labelText"> Назва документу <span className="required">*</span></p>
                    <input 
                        className="documents__variablesInput" 
                        name="title"
                        value={documentValues.title}
                        onChange={handleDocumentValues}  
                    />
                </label>

                <label className="documents__label">
                    <p className="documents__labelText"> Опис/нотатка до документу (Не обовʼязково) </p>
                    <textarea 
                        className="documents__variablesInput"  
                        rows="5" 
                        name="comment"   
                        value={documentValues.comment}
                        onChange={handleDocumentValues}  
                    />
                </label>
            </div>

            <textarea hidden style={{ width: "100%", fontSize: "15px"}} ref={inputNode} rows="5" value={JSON.stringify(editorValue)} onChange={ e => {} } />

            <div className="documents__wrapper">
                <div className="documents__editor" style={{ maxWidth: "800px"}}>
                    <div ref={editorNode}></div>
                </div>  
                <VaribalesConfigurator
                    variables={variables} 
                    setVariables={setVariables}
                    setErrorMessage={setErrorMessage}
                />
            </div>

        </>
    );

}
