import axios from '../../helpers/private.axios';
import { notify } from '../common/notify.actions';

export const GET_DIRECTION_APPS_REQ = "GET_DIRECTION_APPS_REQ";
export const GET_DIRECTION_APPS_RES = "GET_DIRECTION_APPS_RES";


export const searchDirectionApplications = ( query = '' ) => (dispatch, getState) => {
    dispatch({ type: GET_DIRECTION_APPS_REQ })

    axios.post(`/api/catalogs/search`, {
        query: query,
        status: true
    })
    .then(
        res => {
            dispatch({
                type: GET_DIRECTION_APPS_RES,
                payload: res.data.data.applications,
                counter: res.data.data.applications.length
            });
        }
    )

}

export const CREATE_DIRECTION_REQ = "CREATE_DIRECTION_REQ";
export const CREATE_DIRECTION_RES = "CREATE_DIRECTION_RES";

export const createDirection = ( users, apps, type, history ) => (dispatch, getState) => {
    dispatch({ type: CREATE_DIRECTION_REQ })
    console.log('[createDirection]',  users, apps );

    let data = {};
    let redirect_path = `/dr/${type}/`;


    if( type === "experts"){
        data = {
            users: users,
            experts: apps,
            type: type
        }
    }
    if( type === "tech_review"){
        data = {
            users: users,
            applications: apps,
            type: type
        }
    }
    if( type === "expert_commision"){
        data = {
            users: users,
            experts: apps,
            type: type
        }
        redirect_path = '/ed/experts/selection/commission'
    }
    if( type === "expert_supervisory"){
        data = {
            users: users,
            experts: apps,
            type: type
        }
        redirect_path = '/ed/experts/selection/supervisory'
    }

    axios.post(`/api/direction/create`, data )
    .then(
        res => {
            dispatch({
                type: CREATE_DIRECTION_RES
            });
            history.push( redirect_path );
        }
    )
}


export const GET_ALL_DIRECTION_REQ = "GET_ALL_DIRECTION_REQ";
export const GET_ALL_DIRECTION_RES = "GET_ALL_DIRECTION_RES";
export const CLEAR_DIRECTIONS_LIST = "CLEAR_DIRECTIONS_LIST";

export const getAllDirections = ( type ) => (dispatch, getState) => {
    dispatch({ type: GET_ALL_DIRECTION_REQ });
    axios.get(`/api/direction/${type}/all`)
    .then(
        res => {
            dispatch({
                type: GET_ALL_DIRECTION_RES,
                payload: res.data.data.directions
            });
        }
    )
}

export const clearCachedDirections = () => ( dispatch ) => {
    dispatch({ type: CLEAR_DIRECTIONS_LIST });
}



export const GET_SINGLE_DIRECTION_REQ = "GET_SINGLE_DIRECTION_REQ";
export const GET_SINGLE_DIRECTION_RES = "GET_SINGLE_DIRECTION_RES";

export const getSingleDirection = ( direction_id, callback_set_protocol, setDirectionApps ) => (dispatch, getState) => {
    dispatch({ type: GET_SINGLE_DIRECTION_REQ });
    axios.get(`/api/direction/${direction_id}`)
    .then(
        res => {
            const { direction } = res.data.data;
            dispatch({
                type: GET_SINGLE_DIRECTION_RES,
                payload: direction
            });

            if( callback_set_protocol ){
                callback_set_protocol( direction );
            }
            if( setDirectionApps ){
                let settedApps = [];

                console.log('[direction]', direction );

                if( direction.type === "tech_review"){
                    if( direction.results ){
                        settedApps = direction.results;
                    } else {
                        settedApps = direction.applications.map( app => {
                            console.log('app', app );
                            return {
                                _id: app._id,
                                spec_id: app.spec_id,
                                status: app.tech_review.status,
                                appeal_link: app.tech_review.appeal_file
                            };
                        });
                    }
                }
                
                if( direction.type === "expert_commision" || direction.type === "experts" || direction.type === "expert_supervisory" ){
                    if( direction.results ){
                        settedApps = direction.results;
                    } else {
                        settedApps = direction.experts.map( expert => {
                            return {
                                _id: expert._id,
                                name: expert.name,
                                status: 2 // default falue
                            }
                        })
                    }
                }
                
                
                setDirectionApps( settedApps )
            }
        }
    )
}

export const SAVE_DIRECTION_REQ = "SAVE_DIRECTION_REQ";
export const SAVE_DIRECTION_RES = "SAVE_DIRECTION_RES";

export const updateSingleDirection = ( direction_id, data ) => dispatch => {
    dispatch({ type: SAVE_DIRECTION_REQ });
    axios.post(`/api/direction/${direction_id}`, data )
    .then(
        res => {
            dispatch({
                type: SAVE_DIRECTION_RES,
                payload: res.data.data.direction
            });
            dispatch( 
                notify( res.data.data.message, "success" )
            );
        }
    )
}


export const finishDirection = ( direction_id, data ) => dispatch => {
    dispatch({ type: SAVE_DIRECTION_REQ });
    axios.post(`/api/direction/${direction_id}/finish`, data)
    .then(
        res => {
            dispatch({
                type: SAVE_DIRECTION_RES,
                payload: res.data.data.direction
            });
            dispatch( 
                notify( res.data.data.message, "success" )
            );
            dispatch(
                getSingleDirection( direction_id )
            );
        }
    )

}