import {
    GET_SIGNER_LIST_REQ,
    GET_SIGNER_LIST_RES,
    GET_SIGNER_LIST_ERR
} from '../../actions'

const contestsInitialState = {
    data: null,
    loaded: false,
    errors: [],
}

const signerList = ( state = contestsInitialState, action) => {
    switch( action.type ){
        case GET_SIGNER_LIST_REQ:
            return {
                ...state,
                loading: false
            }

        case GET_SIGNER_LIST_RES:
            return {
                loaded: true,
                data: action.payload
            }

        case GET_SIGNER_LIST_ERR:
            return {
                ...state,
                error: action.error,
            }

        default:
            return state
    }
}

export default signerList