import React, { Component } from 'react'
import { connect } from 'react-redux';

import { Breadcrumbs } from '../../../../library';

import { createGroup } from '../../../../../actions';


class NewGroup extends Component {

    state = {
        title: "",
        order: 0,
        slug: ""
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    sendRequest = (e) => {
        e.preventDefault();
        this.props.createGroupReq(
            this.state
        );
    }

    render = () => {
        const { handleChange, sendRequest } = this;
        const { title, order, slug } = this.state;
        const { sending } = this.props;
        return(
            <div className="innerWrap">
                <Breadcrumbs items={[
                    {name:'Адмiнiстративна панель', link: '/admin'},
                    {name:'Методика експертного оцінювання', link: '/admin/evaluation/groups'},
                    {name:'Нова группа питань', link: '/admin/evaluation/groups/new' }
                ]}/>
                <h1>Нова группа питань</h1>
                <form className="form-container" onSubmit={sendRequest}>
                    <label className="form-label">
                        <div className="form-label-text">
                            <span>Назва</span>
                        </div>
                        <div className="form-label-input">
                            <input
                                type="text"
                                className="form-input"
                                name="title"
                                onChange={handleChange}
                                value={title}
                                placeholder=""
                            />
                        </div>
                    </label>
                    <label className="form-label">
                        <div className="form-label-text">
                            <span>Порядок</span>
                        </div>
                        <div className="form-label-input">
                            <input
                                type="number"
                                className="form-input"
                                name="order"
                                onChange={handleChange}
                                value={order}
                                placeholder=""
                            />
                        </div>
                    </label>
                    <label className="form-label">
                        <div className="form-label-text">
                            <span>Слаг (Латинкою без пробілів)</span>
                        </div>
                        <div className="form-label-input">
                            <input
                                type="text"
                                className="form-input"
                                name="slug"
                                onChange={handleChange}
                                value={slug}
                                placeholder=""
                            />
                        </div>
                    </label>
                    <div className="form-label">
                        <div className="form-label-text"></div>
                        <div className="form-label-input">
                            <button
                                className={ sending ? "form-button unactive" : "form-button" }
                                type="submit"
                            >
                                {
                                    sending ? 'Створюється' : 'Створити!'
                                }

                                {
                                    sending &&
                                    (
                                    <svg className="spinner" viewBox="0 0 50 50">
                                        <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                                    </svg>
                                    )
                                }
                            </button>
                        </div>
                    </div>
                </form>

            </div>
        )
    }

}

const mapStateToProps = (state) => ({
    sending:  state.admin.evaluation.groups.create_req
});

const mapDispatchToProps =  (dispatch, ownProps) => ({
    createGroupReq: ( data ) => {
        dispatch( createGroup( data, ownProps.match.params.id, ownProps.history ) );
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(NewGroup);