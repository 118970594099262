import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import {Breadcrumbs} from '../../../../library'

import { createCouncil } from '../../../../../actions'

class NewCouncil extends Component{
  state = {
    title: '',
    redirect: false
  }

  componentDidMount(){
    this.setState({redirect: false})
  }

  handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value

    this.setState({
      [name]: value
    })
  }

  submitCouncil = (e) => {
    e.preventDefault()
    const { createCouncil } = this.props
    createCouncil({title: this.state.title })

    this.setState({
      redirect: true
    })
  }

  render(){
    const { handleChange, submitCouncil } = this

    if( this.state.redirect ){
      return <Redirect to="/admin/users/council/"/>
    } else {
      return(
        <div className="innerWrap">
          <Breadcrumbs items={[
            {name:'Адмiн панель', link: '/admin'},
            {name:'Користувачі', link: '/admin/users'},
            {name:'Список груп', link:'/admin/users/council'},
            {name:'Нова група', link:'/admin'}
          ]}/>
          <header className="page-header">
            <h1 className="page-title">Нова група</h1>
          </header>

          <form className="form-container" onSubmit={submitCouncil}>

          <label className="form-label">
            <div className="form-label-text">
              <span>Назва групи</span>
            </div>
            <div className="form-label-input">
              <input
                type="text"
                className="form-input"
                name="title"
                onChange={handleChange}
                placeholder="Н-д, Візуального мистецтва"
              />
            </div>
          </label>

          <div className="form-label">
            <div className="form-label-text"></div>
            <div className="form-label-input">
              <button
                className="form-button"
                type="submit"
              >
                Створити групу
              </button>
            </div>
          </div>


          </form>

        </div>
      )
    }
  }
}

const mapDispatchToProps = (dispatch) => ({
  createCouncil: ( council ) => {
    dispatch( createCouncil(council) )
  }
})

export default connect(null, mapDispatchToProps)(NewCouncil)
