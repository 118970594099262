import React, { Component } from 'react'

export class ErrorBoundary extends Component {
    
    state = { hasError: false };
    
    static getDerivedStateFromError(error) {
        // Обновить состояние с тем, чтобы следующий рендер показал запасной UI.
        return { hasError: true };
    }
    
    componentDidCatch(error, errorInfo) {
        // Можно также сохранить информацию об ошибке в соответствующую службу журнала ошибок
        // logErrorToMyService(error, errorInfo);
    }
    
    render() {
        if (this.state.hasError) {
            // Можно отрендерить запасной UI произвольного вида
            return <h4> Сталась помилка </h4>;
        }
    
        return this.props.children; 
    }
}
