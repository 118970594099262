import React from 'react'

import { Breadcrumbs, Table, Row, Cell } from '../../library';

const MainPage = () => {

    return(
        <>
            <Breadcrumbs
                items={[
                    {name: 'Дирекція', link: `/dr` }
                ]}
            />
            <h1 className="page-title">Дирекція</h1>
            <Table>
                <Row type="head">
                    <Cell type="id">#</Cell>
                    <Cell type="large"> Тип </Cell>
                </Row>

                <Row type="link" to="/dr/tech_review">
                    <Cell type="id"> 1. </Cell>
                    <Cell type="large"> Технічний відбір </Cell>
                </Row>
                <Row type="link" to="/dr/experts">
                    <Cell type="id"> 2. </Cell>
                    <Cell type="large"> Відбір експертів </Cell>
                </Row>


            </Table>

        </>
    )


}

export default MainPage;