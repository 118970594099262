import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom';
import { Layout } from '../../library';
import Sidebar from '../../common/SideBar';
import Notify from '../../common/Notify';

import MainAgreements from './main_application';
import Folders from './folders';
import AdditionalAgreements from './additional_agreements';
import CompletedActs from './completed_acts';

import './styles/agreements.scss';

const Agreement = () => {
    return(
        <>
            <Sidebar/> 
            <Layout>
                <Switch>
                    <Route exact path="/agreements" component={Folders}/>
                    <Route exact path="/agreements/main" component={MainAgreements} />
                    <Route exact path="/agreements/additional" component={AdditionalAgreements} />
                    <Route exact path="/agreements/acts" component={CompletedActs} />
                </Switch>
                <Notify /> 
            </Layout>
        </>
    )



}

export default Agreement;