import {
    GET_NEGOTIATION_STATS_REQ,
    GET_NEGOTIATION_STATS_RES,
    GET_NEGOTIATION_STATS_NEXT_CHUNK_REQ,
    GET_NEGOTIATION_STATS_NEXT_CHUNK_RES,
    SET_NEGOTIATION_STATS_FILTERS,
    CLEAR_NEGOTIATION_FILTERS,
    CLEAR_NEGOTIATION_DATA
    // GET_NEGOTIATION_STATS_ERR
} from '../../actions'

const initialState = {

    filters: {
        spec_id: '',
        reporting_type: '',
        reporting_status: ''
    },
    
    total_with_filter: 0,
    offset: 0,
    limit: 10,

    loading: false,
    loaded: false,
    data: [],
    error: null
}

const childs = ( state = initialState, action) => {
    switch( action.type ){
		case GET_NEGOTIATION_STATS_REQ:
			return {
				...state,
                loading: true,
                loaded: false
            }

		case GET_NEGOTIATION_STATS_RES:
			return {
                ...state,
                loading: false,
                loaded: true,
                total_with_filter: action.payload.total,
                data: action.payload.apps,
                offset: state.offset + state.limit
            }
            
        case GET_NEGOTIATION_STATS_NEXT_CHUNK_REQ:
            return{
                ...state,
                loading: true
            }

        case GET_NEGOTIATION_STATS_NEXT_CHUNK_RES:
            return{
                ...state,
                loading: false,
                total_with_filter: action.payload.total,
                data: [...state.data, ...action.payload.apps ],
                offset: state.offset + state.limit
            }

        case SET_NEGOTIATION_STATS_FILTERS:
            return ({
                ...state,
                loaded: false,
                filters:{
                    ...state.filters,
                    ...action.payload
                },
                data: [],
                total_with_filter: 0,
                offset: 0
            });

        case CLEAR_NEGOTIATION_FILTERS:
            return {
                ...state,
                filters: {
                    spec_id: '',
                    reporting_type: '',
                    reporting_status: ''
                },
                data: [],
                loading: false,
                loaded: false,
                total_with_filter: 0,
                offset: 0,
            };

        case CLEAR_NEGOTIATION_DATA:
            return{
                ...state,
                data: [],
                loaded: false,
                total_with_filter: 0,
                offset: 0,
            }


		default:
			return state
    }
}

export default childs