import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import ProgramsRoot from '../../common/Programs/ProgramsRoot'
import ProgramsChilds from '../../common/Programs/ProgramsChilds'
import ProgramsLots from '../../common/Programs/ProgramsLots'
import SeasonsRoot from '../../common/Programs/SeasonsRoot';

import ExpertsContests from './ExpertsContests'
import DivisionContext from '../../../helpers/DivisionContext'
import ExpertsApp from './ExpertsApp'


import Sidebar from '../../common/SideBar'
import Notify from '../../common/Notify'

import ExpertStats from './ExpertsStats';

import ExpertsRoot from './ExpertsRoot';
import ExpertSingleInfo from './ExpertsList/SingleExpertData';

import './styles/styles.scss'

const ExpertsDivision = () => (
  <DivisionContext.Provider
    value={{
      type: 'experts',
      route: 'ed',
      title: 'Експертний відділ'
    }}>
    <Sidebar/>
		<Switch>
      <Redirect exact from="/ed" to="/ed/seasons"/>
			<Route exact path="/ed/seasons/:seasonid" component={ ProgramsRoot }/>
			<Route exaxt path="/ed/seasons" component={SeasonsRoot}/>

      <Route exact path="/ed/programs" component={ ProgramsRoot }/>
			<Route exact path="/ed/programs/:progid" component={ ProgramsChilds }/>
      <Route exact path="/ed/lots/:id" component={ ProgramsLots }/>
      <Route exact path="/ed/contests/:id" component={ ExpertsContests }/>
      <Route path="/ed/application/:id" component={ ExpertsApp }/>
      <Route path="/ed/stats" component={ExpertStats} />
      <Route path="/ed/experts" component={ExpertsRoot} />

      {/* <Route exact path="/ed/experts/:userid" component={ExpertSingleInfo} /> */}
      
		</Switch>
    <Notify/>
	</DivisionContext.Provider>
)

export default ExpertsDivision