import React from 'react'
import { connect } from 'react-redux'

import { Layout, Breadcrumbs, Empty, StatusHandler, Table, Row, Cell  } from '../../library'

import { getExpertContests, clearExpertContests, getSingleField } from '../../../actions'
import getFieldValue from '../../../helpers/getFieldValue';

import { getApplicationTitle } from '../../../helpers/getApplicationTitle';

class ExpertsContests extends React.Component {
  componentDidMount() {
    this.props.getDataSingleField('culture');
    this.props.getExpertContestsList(this.props.match.params.id)
  }

  componentWillUnmount() {
    this.props.clearExpertContestsList()
  }

  render() {
    const { data, culture, culture_loaded } = this.props

    
    return (
      <>
        <Layout
          className="projects-division"
          title={data && data.contest.title}
          breadcrumbs={
            data && <Breadcrumbs items={[
              { name: 'Експертний відділ', link: '/ed/programs' },
              // { name: `${data.contest.program.title}`, link: `/ed/programs/${data.contest.program._id}` },
              // data.contest.lot !== undefined ?
              //   { name: `${data.contest.lot.title}`, link: `/pd/lots/${data.contest.lot._id}` } : null,
              { name: `${data.contest.title}`, link: `/ed/programs` }
            ]}
          />}
        >
          <Table>
            <Row type="head">
              <Cell type="medium">#</Cell>
              <Cell type="large"> Назва проекту </Cell>
              <Cell type="large"> Статус </Cell>
              <Cell type="large"> Сектор </Cell>
            </Row>
            {
              data && data.applications.length ?
                data.applications.map((item, key) => {

                  let title = getApplicationTitle(item);
                  
                  return (
                    <Row
                      key={ key }
                      type="link"
                      to={`/ed/application/${item._id}`}
                    >
                      <Cell type="medium">
                        {key + 1 }.
                        <span className="text-sub" style={{marginLeft: '5px'}}>
                          {item.spec_id}
                        </span>
                      </Cell>
                      <Cell type="large">
                        { title.length > 75 ? `${title.substr(0, 75)}...` :  title }
                      </Cell>
                      <Cell
                        type="large"
                      >
                        <StatusHandler
                          type="expert_division_application"
                          statusId={ item.expert_review.status }
                        />
                          
                      </Cell>
                      <Cell type="large">
                        {
                          culture_loaded && getFieldValue( item.data.fields.cultural_sector, culture.options.options )
                        }
                      </Cell>

                    </Row>
                  )
                })
                : <Empty/>
            }
          </Table>
        </Layout>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  data: state.contests.experts.data,
  loading: state.contests.experts.loading,
  culture_loaded: state.common.fields.culture_loaded,
  culture: state.common.fields.culture,
});

const mapDispatchToProps = (dispatch) => ({
  getExpertContestsList: (id) => {
    dispatch(getExpertContests(id))
  },
  clearExpertContestsList: () => {
    dispatch(clearExpertContests())
  },
  getDataSingleField: ( type ) => {
    dispatch( getSingleField( type ) )
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ExpertsContests)
