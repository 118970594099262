import React from 'react'
import { connect } from 'react-redux'
// import { Link } from 'react-router-dom'

import { StatusHandler,  Collapse, Panel,  Loader, Empty,  Table, Row, Cell  } from '../../../library';

import { getRandomizeExperts } from '../../../../actions'

class AppExperts extends React.Component {
  render () {
  const { data, loading, reviews, randomizeExperts } = this.props
  console.log("REVIEWS", reviews)
  if (data && !loading) {
    return (
      <>
        {/* <Link to={`/ed/application/${data._id}/expert`}></Link> */}
        <button className="application-expert_apply-btn" onClick={randomizeExperts(data._id)}>Обрати випадкового експерта</button>
        <Collapse className="collapse-experts-main">
          {
            <Panel
              title={'Обрані експерти для цієї заявки'}
              opened={ true }
            >
                <Table>
                  <Row type="head">
                    <Cell type="large">Експерт</Cell>
                    <Cell type="medium">Загальний бал</Cell>
                    <Cell type="large">Статус</Cell>
                  </Row>
                  {
                    reviews && reviews.active ?
                      reviews.active.length ?
                      reviews.active.map(( item, key ) => (
                        <Row
                          key={key}
                          type="link"
                          to={`/ed/application/${item.review.application_id}/review/${item.review._id}`}
                        >
                          <Cell type="large">{item.review.expert.name}</Cell>
                          <Cell type="medium">{item.score}</Cell>
                          <Cell type="large">
                            <StatusHandler
                                type="expert_review"
                                statusId={item.review.status.id}
                            />
                          </Cell>
                        </Row>
                      )) : <Empty text="Нажаль активних експертiв нема..."/>
                      : <Loader/>
                  }
              </Table>
            </Panel>
          }
        </Collapse>
        <Collapse className="collapse-experts-main">
          {
            <Panel
              title={'Експерти  які були видалені з заявки'}
              opened={ false }
            >
                <Table>
                  <Row type="head">
                    <Cell type="large">Експерт</Cell>
                    <Cell type="medium">Загальний бал</Cell>
                    <Cell type="large">Статус</Cell>
                  </Row>
                  {
                    reviews && reviews.failed ?
                      reviews.failed.length ?
                      reviews.failed.map(( item, key ) => (
                        <Row
                          key={key}
                          type="link"
                          to={`/ed/application/${item.application_id}/review/${item._id}`}
                        >
                          <Cell type="large">{item.expert.name}</Cell>
                          <Cell type="medium">{item.score}</Cell>
                          <Cell type="large">
                            <StatusHandler
                                type="expert_review"
                                statusId={item.status.id}
                            />
                            </Cell>
                        </Row>
                      )) : <Empty text="Нажаль активних експертiв нема..."/>
                      : <Loader/>
                  }
              </Table>
            </Panel>
          }
        </Collapse>
      </>
    )
  } else {
    return <Loader/>
  }
}
}

const mapStateToProps = (state) => ({
  data: state.applications.info.data,
  reviews: state.applications.info.reviews,
  loading: state.applications.info.loading
})

const mapDispatchToProps = (dispatch) => ({
  randomizeExperts: (id) => (e) => {
    dispatch(getRandomizeExperts(id))
    e.target.disabled = true;
    e.target.classList.add('disabled');
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(AppExperts)