import privateAxios from '../../helpers/private.axios'
import config from '../../config'

export const GET_ROOT_SEASONS_REQ ='GET_ROOT_SEASONS_REQ'
export const GET_ROOT_SEASONS_RES ='GET_ROOT_SEASONS_RES'
export const GET_ROOT_SEASONS_ERR ='GET_ROOT_SEASONS_ERR'

export const CLEAR_ROOT_SEASONS ='CLEAR_ROOT_SEASONS'

export const getRootSeasons = () => (dispatch) => {
    dispatch({ type: GET_ROOT_SEASONS_REQ })
    privateAxios(`${config.apiPath}/api/catalogs/seasons`)
    .then( res => {
        dispatch({
        type: GET_ROOT_SEASONS_RES,
        payload: res.data.data
        })
    })
    .catch( err => {
        dispatch({
        type: GET_ROOT_SEASONS_ERR,
        payload: err
        })
    })
}

export const clearRootSeasons = () => (dispatch) => {
    dispatch({ type: CLEAR_ROOT_SEASONS })
}