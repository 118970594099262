import React from 'react'
import { N_Button, Layout, Breadcrumbs, Loader, Table, Row, Cell } from '../../../library';

const ExpertSelectionRoot = () => {


    return(
        <>
            <Breadcrumbs
                items={[
                    { name: "Експертний відділ", link: `/ed/` },
                    { name: "Експерти", link: `/ed/experts`},
                    { name: "Відбір експертів", link: "/ed/expers/selection"}
                ]}
            />
            <h1 className="page-title"> Відбір експертів </h1>
            <Table>
                <Row type="head">
                    <Cell type="id">#</Cell>
                    <Cell type="large"> Розділ </Cell>
                </Row>

                <Row type="link" to="/ed/experts/selection/commission">
                    <Cell type="id"> 1. </Cell>
                    <Cell type="large"> Комісія </Cell>
                </Row>
                <Row type="link" to="/dr/experts">
                    <Cell type="id"> 2. </Cell>
                    <Cell type="large"> Дирекція </Cell>
                </Row>
                <Row type="link" to="/ed/experts/selection/supervisory">
                    <Cell type="id"> 3. </Cell>
                    <Cell type="large"> Наглядова рада </Cell>
                </Row>
                <Row type="link" to="/ed/experts/selection/final">
                    <Cell type="id"> 4. </Cell>
                    <Cell type="large"> Експерти які пройшли відбір</Cell>
                </Row>
            </Table>
        </>
    )
}


export default ExpertSelectionRoot;