import { 
    GET_APPLICATION_REVIEWS_REQ,
    GET_APPLICATION_REVIEWS_RES
} from '../../actions';

const initialState = {
    loaded: false,
    reviews: []
}


const reviews = ( state = initialState, action) => {
    switch( action.type ){
        case GET_APPLICATION_REVIEWS_REQ:
            return({
                ...state,
                loaded: false
            });
        
        case GET_APPLICATION_REVIEWS_RES:
                return({
                    ...state,
                    loaded: true,
                    reviews: action.payload
                });

        default:
            return state
    }
}

export default reviews;