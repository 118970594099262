import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';


import { getSingleField } from '../../../../actions';
import { getLabelForValue } from '../../../../helpers/getLabelForValue';

const ExpertSelectedData = ({ user }) => {
    const dispatch = useDispatch();
    const { 
        culture, culture_loaded,
        keywords, keywords_loaded
    } = useSelector( state => state.common.fields );
    const seasons = useSelector( state => state.programs.seasons );

    useEffect( () => {
        dispatch( getSingleField('culture') );
        dispatch( getSingleField('keywords') );

    }, [])

    console.log('[cs]', culture, culture_loaded );

    return(
        <div className="singleExpert__container">
            <div>
                <b> Основна програма:</b>

                {
                    seasons.loaded ? 
                        getLabelForValue( user.main_program, 'program', seasons.data ) : 
                        <span> Завантажуємо... </span>
                }
            </div>
            {
                user.programs_score_ids.length > 0 && (
                    <div>
                        <b> Програми які оцінює:</b>
                        {
                            seasons.loaded ? 
                                user.programs_score_ids.map( program_id  => {
                                    return(
                                        <span key={program_id}>{ getLabelForValue( program_id, 'program', seasons.data )  },</span>
                                    );
                                }) : 
                            <span> Завантажуємо... </span>
                        }
                    </div>
                )
            }
            
            <div>
                <b> Сектор:</b>
                {
                    culture_loaded ? 
                        culture_loaded && getLabelForValue( user.cultural_sector, 'culture', culture.options.options ) : 
                        <span> Завантажуємо... </span>
                }
            </div>
            <div>
                <b> Ключові слова:</b>
                { 
                    keywords_loaded ? 
                        user.keywords.map( keyword => {
                            return(
                                <span key={keyword}>{ getLabelForValue( keyword, 'culture', keywords.options.options ) },</span>
                            );
                        }) :
                        <span> Завантажуємо... </span>
                }
            </div>

            <hr />
            
            <div>
                <b>Пошта:</b> <span> { user.email }</span>
            </div>

            {
                user.fields && (
                    <>
                    {
                        user.fields.phone && (
                            <div>
                                <b>Телефон:</b> <span> { user.fields.phone }</span>
                            </div>
                        )
                    }
                    {
                        user.fields.facebook_link && (
                            <div>
                                <b>Фейсбук:</b> <a class="link" href={ user.fields.facebook_link }> { user.fields.facebook_link }</a>
                            </div>
                        )
                    }
                    </>
                )
            } 
        </div>
    )

}


export default ExpertSelectedData;