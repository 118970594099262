import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Breadcrumbs, Loader } from '../../library';
import Applications from './applications';

import { searchAgreementApplications,  multisignActs } from '../../../actions';


const CompletedActs = () => {

    const dispatch = useDispatch();
    const [ selectedApps, setSelectedApps ] = useState([]);

    const loaded = useSelector( state => state.matching.agreement.loaded );
    let apps = useSelector( state => state.matching.agreement.apps );
    const counter = useSelector( state => state.matching.agreement.counter );


    useEffect( () => {
        dispatch( searchAgreementApplications( '', 'act') );
    }, [] );


    const sign_ids = [];
    const applications_ids = [];

    const signed_apps_ids = selectedApps.map( application => {

        if( application.reporting_review.completed_act.sign_id ){
            sign_ids.push( application.reporting_review.completed_act.sign_id );
            applications_ids.push( application._id );
        }

        return {
            application_id: application._id
        };
    });

    const multisignAgreementsCallback = () => {
        // dispatch( multisignAdditionalsAgreements( applications_ids, additional_ids, setSelectedApps) );
        dispatch( multisignActs( applications_ids, setSelectedApps ) );
    };

    console.log('[sign_ids]', sign_ids );


    return(
        <>
            <Breadcrumbs
                items={[
                    { name: 'Мультипідпис', link: `/agreements` },
                    { name: 'Акти виконаних проєктів', link: `/agreements/additional` },
                ]}
            />
            <h1 className="page-title"> Акти виконаних проєктів </h1>

            {
                loaded ? 
                (
                    <Applications 
                        type="act"
                        data={selectedApps}
                        handler={setSelectedApps}
                        loaded={loaded}
                        apps={apps}
                        counter={counter}
                        sign_ids={sign_ids}
                        signCallback={multisignAgreementsCallback}
                    />
                ) : 
                (
                    <Loader />
                ) 
            }
        </>
    )


}

export default CompletedActs;