import React, { useState } from 'react'

import arrowIcon from './icons/arrow-point-to-right.svg'

export const Panel = ({ title, children, opened }) => {
    const [ active, setActive] = useState( opened )

    return (
      <div className={`collapse-panel collapse-panel-body`}>
        <div
          className="collapse-panel-title"
          onClick={() => setActive(active => !active)}
        >
          { title ? title : 'Данні заявки' }
        </div>
        <div onClick={() => setActive(active => !active)} className={`collapse-panel-arrow ${active ? 'active' : ''}`}>
          <img src={arrowIcon} alt="arrow"/>
        </div>
        <div className={`collapse-panel-content ${active ? 'active' : ''}`}>
          { children }
        </div>
      </div>
    )
  }
