import React, { useState, useEffect }  from 'react'
import moment from 'moment';
import uniqid from 'uniqid';

import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Breadcrumbs, Loader, Signer, SignerList } from '../../../library';

import { getSingleDirection, updateSingleDirection, finishDirection, notify } from '../../../../actions';
import { pdf_icon } from '../icons';

import SingleExpertRow from './single_expert_block';

const getDictionary = direction => {

    if( !direction ){
        return null;
    }

    const dictionary = {
        experts: {
            breadcrumbs: [
                {name: 'Дирекція', link: `/dr` },
                {name: 'Відбір експертів', link: `/dr/experts`},
                {name: `Дирекція від ${moment(direction.created_at ).format('lll')}` , link: ``},
            ],
            name: `Дирекція від ${moment(direction.created_at ).format('lll')} `,
            instance: "Дирекції",
        },
        
        expert_commision: {
            breadcrumbs: [
                { name: "Експертний відділ", link: `/ed/` },
                { name: "Експерти", link: `/ed/experts`},
                { name: "Відбір експертів", link: "/ed/experts/selection"},
                { name: "Комісія", link: "/ed/experts/selection/commission"},
                { name: `Комісія від ${moment(direction.created_at ).format('lll')}`, link: ``},
            ],
            name: `Комісія від ${moment(direction.created_at ).format('lll')}`,
            instance: "Комісії",
        },
        expert_supervisory: {
            breadcrumbs: [
                { name: "Експертний відділ", link: `/ed/` },
                { name: "Експерти", link: `/ed/experts`},
                { name: "Відбір експертів", link: "/ed/experts/selection"},
                { name: "Наглядові ради", link: "/ed/experts/selection/supervisory"},
                { name: `Наглядова рада від ${moment(direction.created_at ).format('lll')}`, link: ``},
            ],
            name: `Наглядова рада від ${moment(direction.created_at ).format('lll')}`,
            instance: "Наглядової ради",
        }
    }

    return dictionary[ direction.type ];
}



const SingleDirection = () => {
    let { direction_id } = useParams();
    const dispatch = useDispatch();

    const [ direction_apps, setDirectionApps ] = useState( [] );
    const loaded = useSelector( state => state.direction.single.loaded );
    let direction = useSelector( state => state.direction.single.data );
    const dictionary = getDictionary( direction );

    const [ messages, setDirMessage ] = useState({
        text_protocol: '',
        text_protocol_short: ''
    });


    const setDirectionMessages = ( direction ) => {
        setDirMessage({
            text_protocol: direction.text_protocol,
            text_protocol_short: direction.text_protocol_short
        })
    }


    useEffect( () => {
        dispatch( getSingleDirection( direction_id, setDirectionMessages, setDirectionApps ) );
    }, [ direction_id ] );


    const updateDirection = () => { 
        const body = {
            text_protocol: messages.text_protocol,
            text_protocol_short: messages.text_protocol_short, 
            results: direction_apps
        };

        dispatch( updateSingleDirection( direction._id, body ) );
    }

    const changeHandler = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setDirMessage({ ...messages, [name] : value });
    }

    const changeRadio = ( expert_id ) => ( e ) => {

        let updatedApps = direction_apps.map( d_app => {
            if( d_app._id === expert_id ){
                d_app.status = Number(e.target.value);
            }
            return d_app;
        });

        setDirectionApps( updatedApps );
    }

    const finishDirectionAction = () => {

        const body = {
            text_protocol: messages.text_protocol,
            text_protocol_short: messages.text_protocol_short,
            results: direction_apps
        };

        dispatch( finishDirection(direction_id, body) );
    }

    const getDirection = () => {
        dispatch( getSingleDirection( direction_id, setDirMessage, setDirectionApps ) );
        dispatch( notify('Протокол успішно підписано', 'success') )
    }

    if( !loaded ){
        return <Loader />
    }
    
    const enableFinish = direction_apps.every( res => {
        return res.status !== 0;
    });


    return(
        <>
            <Breadcrumbs
                items={dictionary.breadcrumbs}
            />
            <h1>{dictionary.name}</h1>
            
            <div className="text">
                Склад {dictionary.instance}: 
                {
                    direction.users.map( (user, index) => {
                        return (
                            <b className="text" key={user._id}>
                                {user.name}
                                {index !== direction.users.length - 1 && ','}
                            </b>
                        )
                    })
                }
            </div>

            <div className="d-single__body">
                <div className="d-single bold">
                    <div className="d-single__top">
                        <div className="d-single__item id"> # </div>
                        <div className="d-single__item actions">
                            Ім'я експерта
                        </div>
                        <div className="d-single__item large">
                            Рейтингова оцінка
                        </div>
                        <div className="d-single__item large">
                            Дії
                        </div>
                    </div>
                </div>
            
                {
                    direction.experts.map( (expert, index) => {
                        return(
                            <SingleExpertRow 
                                key={ uniqid() } 
                                index={ index + 1 }
                                expert={expert}
                                direction_experts={direction_apps}
                                changeRadio={changeRadio}
                                disabled={direction.status === 1}
                            />
                        );
                    })
                }
            </div>

            <div className="direction__column">

                <label className="direction__label">
                    <div className="direction__message"> Вступ </div>
                    <textarea 
                        rows="8"
                        className={ direction.status === 1 ? "application__textarea disabled" : "application__textarea"}
                        name="text_protocol_short"
                        defaultValue={messages.text_protocol_short}
                        onBlur={changeHandler}
                        disabled={direction.status === 1}
                    ></textarea>
                </label>

                <label className="direction__label">
                    <div className="direction__message">Рішення для протоколу</div>
                    <textarea 
                        rows="24"
                        className={ direction.status === 1 ? "application__textarea disabled" : "application__textarea"}
                        name="text_protocol"
                        defaultValue={messages.text_protocol}
                        onBlur={changeHandler}
                        disabled={direction.status === 1}
                    ></textarea>
                </label>
                {
                    direction.status === 1 && (
                        <div className="direction__footer">
                            <div className="application__label">
                                <div className="direction__message"> Підписати рішення </div>
                                <div className="direction__signer">
                                    <Signer 
                                        ids={[ direction.protocol.sign_id ]}
                                        statusChangerCallback={getDirection}
                                    />
                                    
                                    <a 
                                        href={direction.protocol.fileUrl}
                                        className="direction__signer-file application__documents-item"
                                        rel="noopener noreferrer" target="_blank"
                                    > 
                                        <img
                                            className="application__documents-icon"
                                            src={ pdf_icon }
                                            alt="format icon"
                                        />
                                        <span className="direction__message">Протокол дирекції </span>
                                    </a>	
                                </div>
                            </div>
                            {
                                direction.protocol && (
                                    <div className="direction__footerItem">
                                        <SignerList sign_id={direction.protocol.sign_id} />
                                    </div>
                                )
                            }
                        </div>
                    )
                }
            </div>
            <footer className="direction__comment">
                {
                    direction.status !== 1 ? (
                        <>
                            <button className="direction__btn" onClick={updateDirection}> Зберегти дирекцію </button>
                            <button 
                                className="direction__btn finish"
                                onClick={finishDirectionAction}  
                            > Прийняти рішення </button>
                        </>
                    ) : (
                        <>
                            <button disabled className="direction__btn disabled"> Зберегти дирекцію </button>
                            <button disabled className="direction__btn disabled"> Прийняти рішення </button>
                        </>
                    )
                }
            </footer>
        </>
    )

}

export default SingleDirection;