import React, { useState, useEffect } from 'react'

import axios from '../../../helpers/private.axios';

import './style.scss';


const SpecidDecoder = ({ application }) => {

    const [ loaded, setLoaded ] = useState( false );
    const [ data, setData ] = useState({});

    const decodeApplicationSpecId = () => {

        if( !loaded ){
            axios.get(`/api/common/decode/${application._id}`)
                .then( res => {
                    console.log('[res]', res );
                    setData( res.data.data );
                    setLoaded( true );
                });
        }
    }

    return(
        <div className="specid__container" onMouseEnter={decodeApplicationSpecId}>
            
            <div className={ loaded ? "specid__popup loaded" : "specid__popup"}>
                {
                    loaded ?
                    (
                        <>
                            <div>
                                Сезон: {data.season}
                            </div>
                            <div>
                                Програма: {data.program}
                            </div>
                            <div>
                                Лот: {data.lot}
                            </div>
                            <div>
                                Конкурс: {data.contest}
                            </div>
                        </>
                    ) : 
                    (
                        <div>
                            Завантажуємо...
                        </div>
                    )
                }
                
            </div>
            <div className="specid__body">
                { application.spec_id }
                <span class="specid__icon"></span>
            </div>
        </div>
    )

}


export default SpecidDecoder;