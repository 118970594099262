import {
  GET_APPLICATION_INFO_RES,
  GET_APPLICATION_INFO_REQ,
  GET_APPLICATION_INFO_ERR,
  GET_SINGLE_PROCEDURE_RES,
  CLEAR_APPLICATION_INFO,
} from '../../actions'

const usersInitialState = {
  error: null,
  loading: false,
  loaded: false,
  data: null,
  breadcrumbs: null,
  reviews: null,
  logs: null,
  documents: null,
  program: null,
  tech_review_protocol: null
}

const info = ( state = usersInitialState, action) => {
  switch( action.type ){
    case GET_APPLICATION_INFO_REQ:
      return {
        ...state,
        loaded: false,
        loading: true,
      }
    case GET_APPLICATION_INFO_RES:

      console.log('[payload]', action.payload );
      return {
        ...state,
        data: action.payload.application,
        breadcrumbs: action.payload.breadcrumbs,
        logs: action.payload.logs,
        reviews: action.payload.reviews,
        documents: action.payload.documents,
        program: action.payload.breadcrumbs.program,
        tech_review_protocol: action.payload.protocol,
        loaded: true,
        loading: false
      }

    case GET_APPLICATION_INFO_ERR:
      return {
        ...state,
        error: action.payload
      }

    case GET_SINGLE_PROCEDURE_RES:
      console.log('action', action.payload );
      return{
        ...state,
        data: action.payload.application,
        breadcrumbs: action.payload.breadcrumbs
      }

    case CLEAR_APPLICATION_INFO:
      return usersInitialState;
      
    default:
      return state
  }
}

export default info