import axios from '../../helpers/private.axios';
import { notify } from '../common/notify.actions';

import { getApplicationInfo } from '../index'


export const CHANGE_DIRECTION_STATUS_REQ = "CHANGE_DIRECTION_STATUS_REQ";
export const CHANGE_DIRECTION_STATUS_RES = "CHANGE_DIRECTION_STATUS_RES";
export const CHANGE_DIRECTION_STATUS_ERR = "CHANGE_DIRECTION_STATUS_ERR";

export const changeDirectionStatus = (  application_id, status, message, history ) => (dispatch) => {
    dispatch({ type: CHANGE_DIRECTION_STATUS_REQ });
    axios.post(`/api/reporting/${application_id}/direction/status`, {
        status,
        message
    })
    .then( res => {
        dispatch({ type: CHANGE_DIRECTION_STATUS_RES });
        dispatch( getApplicationInfo(application_id) );
        dispatch( 
            notify( res.data.data.message, "success" )
        );
        history.push(`/rp/application/${application_id}/matching`)
    })

};