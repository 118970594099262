import React from 'react'
import { DataSort } from '../../../library';

import '../styles/collapse-rate.scss'

export const Question = ({ format, text, question, fields }) => {
	let subfieldsForGroup = [];
	if( format === "old" ){
		subfieldsForGroup = fields.find( group => group._id === question.fieldGroupId );
	} else {
		subfieldsForGroup = fields.find( group => {
			return group.relations.find( relation => relation.field_id === question._id );
		})
	}

	if( subfieldsForGroup ){
		return(
			<div className="collapse-rate-group-declarer">
				<DataSort value={text} subfields={ subfieldsForGroup.subfields } {...question} />
			</div>
		)
	} else {
		return null;
	}
}

export const RateGroupItem = (props) => (
	<div className="collapse-rate">
		<div className="collapse-rate-group">
			<div className="collapse-rate-group-info">
				<div className="collapse-rate-group-text">
				{props.question}
				</div>
				<div className="collapse-rate-group-stars">
					<div className="collapse-rate-group-stars-wrap">
						{ props.stars }
					</div>
				</div>
			</div>
			<div className="collapse-rate-group-apply">
				{props.children}
			</div>
		</div>
	</div>
)