import {
    GET_ALL_DIRECTION_REQ,
    GET_ALL_DIRECTION_RES,
    CLEAR_DIRECTIONS_LIST
} from '../../actions'

const appsInitialState = {
    error: null,
    data: [],
    counter: 0,
    loaded: false
}

const tech_review_reducer = ( state = appsInitialState, action) => {
    switch( action.type ){
        case GET_ALL_DIRECTION_REQ:
            return {
                ...state,
                loaded: false,
            }

        case GET_ALL_DIRECTION_RES:
            return {
                ...state,
                data: action.payload,
                loaded: true,
            }

        case CLEAR_DIRECTIONS_LIST:
            return appsInitialState;

        default:
            return state
    }
}

export default tech_review_reducer;