import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { Loader, StatusHandler, Signer } from '../../../../library';
import { OfflineProcedure, OnlineProcedure }  from './Parts';

import { getSingleNegotiationProcedure, changeNegotiationProcedureStatus } from '../../../../../actions';


class NegotiationSingleProcedure extends React.Component {
	state = {
		message: ''
	}

	componentDidMount(){
		this.props.getNegotiationProcedure();
	}

	handleMessageChange = (e) => {
		this.setState({
			message: e.target.value
		});
	}

	end_procedure = () => {
		const { procedure } = this.props.procedure;
		if( procedure.status === -1 ){
			this.props.changeProcedureStatus(-2, this.state.message );
		}

		if( procedure.status === 3){
			this.props.changeProcedureStatus(4, this.state.message );
		}

	}
	
	end_procedure_success = () => {
		this.props.changeProcedureStatus(3, this.state.message );
	}

	end_procedure_failure = () => {
		this.props.changeProcedureStatus(-1, this.state.message );
	}


	render() {
		let { procedure, loaded, update_req, user } = this.props;

		
		const { end_procedure_success, end_procedure_failure, end_procedure, handleMessageChange } = this;
		const { message } = this.state;

		console.log( procedure );

		if( !loaded){
			return <Loader />;
		}

		procedure = procedure.procedure;
		let rules;
		/*
			If Rule == true, disable submit
		*/
		console.log( 'ROLE', user.role, user.role !== "project_head", user.role !== "project_manager", );
		if( procedure.type === "offline"){
			rules = [
				user.role === "financier",
				user.role === "lawyer",
				procedure.status === -2,
				procedure.status === 3,
				procedure.status === 1,
				procedure.status === 4,
				update_req
			];
		} else {
			rules = [
				user.role === "financier",
				user.role === "lawyer",
				procedure.status === -2,
				procedure.status === 3,
				procedure.status === 1,
				procedure.status === 4,
				update_req,
				procedure.lawyer.status === 0,
				procedure.pm.status === 0,
				procedure.financer.status === 0,
			];
		}

		let disableSubmit = rules.some( item => item === true );
		console.log('RULES', rules );

		let showSigner =  (procedure.status === -1 || procedure.status === 3 ) && user.role === 'project_head';

		console.log('showSigner', showSigner );
		return (
			<div className="expert-solution-form">
				<header className="negotiation__header">
					<h1 className="expert-solution-form-title">
						Переговорна процедура  від {moment( procedure.created_at ).format('lll')}
					</h1>
					<StatusHandler type="reporting.neotiation" statusId={procedure.status}/>
				</header>

				{
					procedure.type === "offline" && (
						<OfflineProcedure 
							procedure={procedure}
						/>
					)
				}
				{
					procedure.type === "online" && (
						<OnlineProcedure 
							procedure={procedure}
						/>
					)
				}
				




				<div className="expert-solution-form-form">
					<div className="expert-solution-form-text">
						Коментар до переговорної процедури (Буде надіслано заявнику та зафіксовано у протоколі)
					</div>
					{
						procedure.status !== 2 && procedure.status !== 1 ? 
						(
							<div className="additional_info text-white-space">
								<p>
									{procedure.message}
								</p>
								{
									procedure.protocol_id && (
										<>
											{
												procedure.protocol_id.fileUrl && (
													<a 
													href={procedure.protocol_id.fileUrl} 
													target="_blank" rel="noopener noreferrer" 
													className="application__documents-protocol"> 
														Завантажити протокол
													</a>
												)
											}
										</>
									)
								}
							</div>
						) : 
						(
							<textarea
								rows="7"
								className="expert-solution-form-textarea"
								name="message"
								onChange={ handleMessageChange }
								value={ message }
							/>
						)
					}
					
					{
						showSigner ? (
							<>
								<Signer 
									ids={[ procedure.protocol_id.sign_id ]}
									statusChangerCallback={ end_procedure }
								/>
							</>
						) : (
							<>
								{
									procedure.status === -1 ? (
										<div className="application-expert_apply">
											Підписати процедуру може лише Голова відділу
										</div>
									) : (
										<div className="application-expert_apply">
											<button 
												className={ disableSubmit ? "application-expert_apply-btn disabled" : "application-expert_apply-btn apply"}
												onClick={ disableSubmit ? undefined : end_procedure_success }
												disabled={disableSubmit}
											>
												Успішно завершити процедуру
											</button>
											<button 
												className={ disableSubmit ? "application-expert_apply-btn disabled" : "application-expert_apply-btn dismiss"}
												onClick={disableSubmit ? undefined : end_procedure_failure }
												disabled={disableSubmit}
											>
												Потрібна ще одна процедура
											</button>
										</div>
									)
								}

							</>
						)
					}
					

				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	procedure: state.matching.negotiation.single,
	loaded: state.matching.negotiation.single_loaded,
	update_req: state.matching.negotiation.update_req,
	user: state.auth.user
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	getNegotiationProcedure: () => {
		dispatch( getSingleNegotiationProcedure( ownProps.match.params.procid ) );
	},
	changeProcedureStatus: ( status, message ) => {
		dispatch( changeNegotiationProcedureStatus( ownProps.match.params.procid, status, message ) );
	}
})

export default connect(mapStateToProps, mapDispatchToProps)(NegotiationSingleProcedure)