import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SingleExpertHeader } from './header';
import {  Layout, Breadcrumbs, Loader, StatusHandler, Table, Row, Cell, Panel } from '../../../library';

import { getExpertDocuments } from '../../Account/ExpertData/getExpertDocuments';
import {  getSingleField, getRootSeasons  } from '../../../../actions';

const fieldsDictionary = [
    { name: "name", label: "ПІБ" },
    { name: "phone", label: "Контактний номер телефону" },
    { name: "address", label: "Місце проживання (місто, країна)" },
    { name: "workplace", label: "Місце роботи" },
    { name: "position", label: "Посада" },
    { name: "education", label: "Освіта" },
    { name: "facebook_link", label: "Посилання на профіль у Facebook" },
    { name: "prof_expirience", label: " Чи маєте Ви не менше п'яти років досвіду професійної діяльності у відповідному секторі? " },
    { name: "activity_last_years", label: "Надайте інформацію про Вашу роботу протягом останніх п'яти років (не більше 10-ти речень). У разі наявності, надайте активні лінки, які підтверджують профільну діяльність" },
    { name: "project_managment", label: "Чи маєте Ви досвід управління проектами, не менше трьох років?" },
    { name: "maiking_budget", label: "Чи маєте Ви досвід бюджетування (фінансового менеджменту, складання бюджету проектів) не менше трьох років? " },
    { name: "budget_info", label: "Надайте інформацію про Ваш досвід бюджетування протягом останніх трьох років (не більше 10-ти речень - розгорнута відповідь)" },
    { name: "rating_expirience", label: "Чи має кандидат досвід оцінки проектів у культурно-мистецькій сфері (участь в експертних групах, радах, комісіях з присудження премій, журі тощо) не менше трьох років?" },
    { name: "rating", label: "Надайте інформацію про Ваш досвід оцінки проектів у культурно-мистецькій сфері (участь в експертних групах, радах, комісіях з присудження премій, журі тощо) протягом останніх трьох років (не більше 10-ти речень)." },
    { name: "expected_cultural_sector", label: "Бажаний культурний сектор" },
    { name: "expected_keywords", label: "Бажані ключові слова" },
    { name: "expected_programs_main", label: "Бажана основна програма" },
    { name: "expected_programs_secondary", label: "Можу оцінити програми" },
];

const ExpertInfo = () => {
    
    const dispatch = useDispatch();
    const data = useSelector( state => state.expert.singleExpert.data );
    const user = data.user;

    let fields = [];
    if( data.user.fields ){
        fields = Object.keys( data.user.fields );    
    }

    const keywords = useSelector( state => {
        if( state.common.fields.keywords_loaded  ){
            return state.common.fields.keywords;
        }
        return null;
    });
    const culture = useSelector( state => {
        if( state.common.fields.culture_loaded  ){
            return state.common.fields.culture;
        }
        return null;
    });

    const seasons = useSelector( state => {
        let seasons_data = state.programs.seasons;
        if( seasons_data.loaded ){
            return state.programs.seasons.data;
        }
        return null;
    });

    useEffect(() => {
        dispatch( getSingleField('keywords') );
        dispatch( getSingleField('culture') );
        dispatch( getRootSeasons() );
    }, [] );



    const documents = getExpertDocuments( user );

    return(
        <>
            <SingleExpertHeader user={user} />
            <Breadcrumbs
                items={[
                    { name: "Експертний відділ", link: `/ed/programs` },
                    user.role === "unassigned_expert" ? { name: "Нерозподілені експерти", link: `/ed/experts/unassigned`} : { name: "Експерти УКФ", link: `/ed/experts`},
                    { name: `Експерт ${user.name}`, link: `/ed/experts/${user._id}/applications` },
                    { name: "Інформація про експерта", link: ""}
                ]}
            />
            <h1 className="page-title"> Інформація </h1>            

            <div className="">
                {
                    fields.length === 0 && (
                        <h2>Експерт ще не заповнив інформацію про себе</h2>
                    )
                }
                {
                    fields.map( field => {
                        let label = fieldsDictionary.find( label => label.name === field );

                        if( label ){

                            if( field === "expected_cultural_sector"){
                                if( culture ){
                                    let sector = culture.options.options.find( sector => sector.value === user.fields.expected_cultural_sector );
                                    if( sector ){
                                        return (
                                            <div className="application__body-item" key={field}>
                                                <div className="application__body-item-name text-gray">
                                                    {label.label}
                                                </div>
                                                <div className="application__body-item-value">
                                                    {
                                                        sector.name
                                                    }
                                                </div>
                                            </div>
                                        );
                                    }
                                }
                            }

                            if( field === "expected_keywords" ){

                                if( keywords ){
                                    let values = user.fields[ field ];
                                    let checkedKeywords = keywords.options.options.filter( keyword =>   
                                        values.some( val => val === keyword.value )  
                                    );
                                    return(
                                        <div className="application__body-item" key={field}>
                                            <div className="application__body-item-name text-gray">
                                                {label.label}
                                            </div>
                                            <div className="application__body-item-value">
                                                {
                                                    checkedKeywords.map( keyword => (
                                                        <span key={keyword.value}>{ keyword.name },</span>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    );
                                }
                            }

                            if( field === "expected_programs_secondary"){

                                if( seasons ){
                                    let values = user.fields[ field ];
                                    let programs = [];

                                    seasons.forEach(season => {
                                        
                                        if( season.season.status === "active"){
                                            season.programs.forEach( program => {
                                                if( values.some( val => program._id === val ) ){
                                                    programs.push( program );
                                                }
                                            })
                                        }
                                    });

                                    return(
                                        <div className="application__body-item" key={field}>
                                            <div className="application__body-item-name text-gray">
                                                {label.label}
                                            </div>
                                            <div className="application__body-item-value">
                                                {
                                                    programs.map( program => {
                                                        return(
                                                            <span key={program._id}> { program.title.uk } / </span> 
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>  
                                    )


                                }

                            }

                            if( field === "expected_programs_main"){
                                if( seasons ){
                                    let value = user.fields[ field ];
                                    let programs = [];

                                    seasons.forEach(season => {
                                        season.programs.forEach( program => {
                                            if( value === program._id ){
                                                programs.push( program );
                                            }
                                        })
                                    });

                                    return(
                                        <div className="application__body-item" key={field}>
                                            <div className="application__body-item-name text-gray">
                                                {label.label}
                                            </div>
                                            <div className="application__body-item-value">
                                                {
                                                    programs.map( program => {
                                                        return(
                                                            <span key={program._id}> { program.title.uk } </span> 
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>  
                                    )


                                }
                            }

                            return(
                                <div className="application__body-item" key={field}>
                                    <div className="application__body-item-name text-gray">
                                        {label.label}
                                    </div>
                                    <div className="application__body-item-value">
                                        { user.fields[ field ] === true && (<span> Так </span>)}
                                        { user.fields[ field ] === false && (<span> Ні </span>)}
                                        { user.fields[ field ] }
                                    </div>
                                </div>
                            );
                        }
                    })
                }
            </div>


            <h2>Документи</h2>
            <div className="expForm">
            {
                documents.map( doc => {
                    if( doc.link ){
                        return(
                            
                                <div className="expForm__item file" key={doc.name}>
                                    <a className="project-app-grand-file" href={doc.link} target="_blank">
                                        {doc.short_label}
                                    </a>
                                </div>
                        )
                    }
                })
            }
            </div>

        </>
    )

    
}

export default ExpertInfo;