import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Loader, Breadcrumbs, Table, Row, Cell } from '../../../../library';

import { getAllGroups }  from '../../../../../actions';

class Groups extends Component {

    componentDidMount = () => {
        this.props.getGroups();
    }

    render = () => {
        const { loaded, data } = this.props.groups;

        if( !loaded ){
            return <Loader />;
        }

        const total = data.reduce( (sum, current) => {
            return sum + current.count;
        }, 0)

        return(
            <div className="innerWrap">
                <Breadcrumbs items={[
                    {name:'Адмiнiстративна панель', link: '/admin'},
                    {name:'Методика експертного оцінювання', link: '/admin/evaluation/groups'}
                ]}/>
                <h1 className="page-title">Методика експертного оцінювання</h1>
                <Link className="catalog__button" to="/admin/evaluation/groups/new"> Додати группу питань </Link>
                <Table>
                    <Row type="head">
                        <Cell type="id">#</Cell>
                        <Cell type="large">Група питань</Cell>
                        <Cell type="large">Загальна кільість балів ({total})</Cell>
                        <Cell type="large">

                        </Cell>
                    </Row>
                    {
                        data.map( group => (
                            <Row key={group._id} className="table__row-withLinks">
                                <Cell type="id">{group.order}</Cell>
                                <Cell type="large" className="row_">
                                    <Link to={`/admin/evaluation/groups/${group._id}`}>
                                        {group.title}
                                    </Link>
                                </Cell>
                                <Cell type="large">{group.count}</Cell>
                                <Cell type="large">
                                    <Link to={`/admin/evaluation/groups/${group._id}/edit`}>Редагувати</Link>
                                </Cell>
                            </Row>
                        )) 
                    }
                </Table>
            </div>
        )
    }

}

const mapStateToProps = (state) => ({
    groups: state.admin.evaluation.groups
})

const mapDispatchToProps = ( dispatch ) => ({
    getGroups: () => {
        dispatch( getAllGroups() );
    }
})


export default connect(mapStateToProps, mapDispatchToProps)(Groups);