import React from 'react';
import { connect } from 'react-redux';

import { Loader, DataSort, DocumentLink } from '../../../library';
import { Collapse, Panel } from '../../../library/Collapse';

import DocumentsList from '../../../common/DocumentList';

import { getApplicationInfo, clearApplicationInfo } from '../../../../actions';

const AppDetails = ({ data, documents, loaded }) => {
  
  if( !loaded ){
    return <Loader/>
  }
  return (
    <>
      <Collapse>
        <Panel title="Документи" opened="true">
          <DocumentsList documents={documents} />
          {
            data.tech_review.appeal_file && (
              <DocumentLink 
                  url={data.tech_review.appeal_file}
                  icon="pdf"
                  text="Звернення заявника"
              />
            )
          }
        </Panel>
        {
          data.data.fields.map( (group, key) => {
            if( group !== null ){
              return(
                  <Panel
                    key={key}
                    title={group.title}
                    opened={ key === 0 }
                  >
                    {
                      group.fields.map( (subfield, subkey) => {
                        return (
                          <DataSort key={subkey} subfields={group.subfields} {...subfield}/>
                        )
                      })
                    }
                  </Panel>
                )
              } else { return null }
            })
        }
      </Collapse>
    </>
  )
  
}


const mapStateToProps = (state) => ({
  data: state.applications.info.data,
  documents: state.applications.info.documents,
  loaded: state.applications.info.loaded
})

const mapDispatchToProps = (dispatch) => ({
  getApplication: (id) => {
    dispatch(getApplicationInfo(id))
  },
  clearApplication: () => {
    dispatch(clearApplicationInfo())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(AppDetails)