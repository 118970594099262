import {
    GET_SINGLE_EXPERT_INFO_REQ,
    GET_SINGLE_EXPERT_INFO_RES,
    // GET_SINGLE_EXPERT_INFO_ERR
} from '../../actions'

const usersInitialState = {
	error: null,
	loading: false,
	loaded: false,
	data: null,
}

const feedbacks = (state = usersInitialState, action) => {
	switch (action.type) {
		case GET_SINGLE_EXPERT_INFO_REQ:
            return ({
                ...state,
                loading: true,
                loaded: false,
                data: null
            });
		case GET_SINGLE_EXPERT_INFO_RES:
            return ({
                ...state,
                loading: false,
                loaded: true,
                data: action.payload
            });

		default:
            return state;
	}
}

export default feedbacks