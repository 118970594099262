import axios from '../../helpers/private.axios';
import { notify } from '../index';
import { ALL_EVAL_GROUPS_RES } from './groups';

export const GET_METHODOLOGY_REQ = "GET_METHODOLOGY_REQ";
export const GET_METHODOLOGY_RES = "GET_METHODOLOGY_RES";
export const GET_METHODOLOGY_ERR = "GET_METHODOLOGY_ERR";


export const getAllMethodology = (id) => (dispatch) => {
    dispatch({ type: GET_METHODOLOGY_REQ });

    axios.get(`/api/evaluation/methodology`)
    .then( res => {
        dispatch({
            type: GET_METHODOLOGY_RES,
            payload: res.data.data.methodologies
        });
    })
    .catch( err => {
        dispatch({ type: GET_METHODOLOGY_ERR, errors: err });
    });
}

export const CREATE_METHODOLOGY_REQ = "CREATE_METHODOLOGY_REQ";
export const CREATE_METHODOLOGY_RES = "CREATE_METHODOLOGY_RES";
export const CREATE_METHODOLOGY_ERR = "CREATE_METHODOLOGY_ERR";


export const createMethodology = ( data, history ) => (dispatch) => {

    console.log('Here!');

	dispatch({ type: CREATE_METHODOLOGY_REQ });
	axios.post(`/api/evaluation/methodology/store`,
		data
	)
	.then(
		res => {
			dispatch({
				type: CREATE_METHODOLOGY_RES,
				payload: res.data.data.groups
			});
			
			dispatch(
				notify(
					"Методологія успішно створена", "success"
				)
			);
			history.push('/admin/evaluation/methodology');
		}
    ).catch( err => {
		dispatch({
			type: CREATE_METHODOLOGY_ERR,
			errors: err
		})
	});
} 


export const GET_SINGE_METHODOLOGY_REQ = "GET_SINGE_METHODOLOGY_REQ";
export const GET_SINGE_METHODOLOGY_RES = "GET_SINGE_METHODOLOGY_RES";
export const GET_SINGE_METHODOLOGY_ERR = "GET_SINGE_METHODOLOGY_ERR";

export const getSingleMethodology = (id, loadData ) => (dispatch) => {
	dispatch({ type: GET_SINGE_METHODOLOGY_REQ })
	axios.get(`/api/evaluation/methodology/${id}`)
	.then(
		res => {
			dispatch({
				type: GET_SINGE_METHODOLOGY_RES,
				payload: res.data.data.metodology
            });

            dispatch({
                type: ALL_EVAL_GROUPS_RES,
                payload: res.data.data.groups
            });
            

            if( loadData ){
                loadData( res.data.data.metodology.title );
            }
		}
    ).catch( err => {
		dispatch({
			type: GET_SINGE_METHODOLOGY_ERR,
			errors: err
		})
	});
}

export const UPDATE_SINGE_METHODOLOGY_REQ = "UPDATE_SINGE_METHODOLOGY_REQ";
export const UPDATE_SINGE_METHODOLOGY_RES = "UPDATE_SINGE_METHODOLOGY_RES";
export const UPDATE_SINGE_METHODOLOGY_ERR = "UPDATE_SINGE_METHODOLOGY_ERR";

export const updateMethodology = ( data, id, history ) => (dispatch) => {
    
	dispatch({ type: UPDATE_SINGE_METHODOLOGY_REQ });
	axios.post(`/api/evaluation/methodology/${id}/update`,
        data
	)
	.then(
		res => {
			dispatch({
				type: UPDATE_SINGE_METHODOLOGY_RES,
				payload: res.data.data.metodology
			});
			
			dispatch(
				notify(
					"Методологія успішно оновленна", "success"
				)
			);

			history.push(`/admin/evaluation/methodology`);


		}
    ).catch( err => {
		dispatch({
			type: UPDATE_SINGE_METHODOLOGY_ERR,
			errors: err
		})
	});
} 