import React from 'react'
import { connect } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'

import Edit from './Edit'
import Replacement from './Replacement'
import ExpertForm from './ExpertData/Form';
import ExpertDocuments from './ExpertData/Documents';

import Sidebar from '../../common/SideBar'
import Notify from '../../common/Notify'
import { Layout, TabsNav } from '../../library'

import { logout } from '../../../actions'
import './styles.scss'



const Account = (props) => {
  const { data } = props

  let headerNav = [
    { slug: 'edit', title: 'Параметри аккаунту'},
    { slug: 'replacement', title: 'Зміна паролю'}
  ];


  let routesMap = [
    {
        type: "redirect",
        exact: true,
        from: "/account",
        to: `/account/${data._id}/edit`
    },
    {
        type: "route",
        exact: true,
        path: "/account/:id/edit",
        component: Edit
    },
    {
        type: "route",
        exact: true,
        path: "/account/:id/replacement",
        component: Replacement
    }
  ];

  if( data.role === "expert" || data.role === "unassigned_expert"){
    headerNav = [
      { slug: 'edit', title: 'Параметри аккаунту'},
      { slug: 'expert', title: 'Дані експерта'},
      { slug: 'documents', title: 'Документи експерта'},
      { slug: 'replacement', title: 'Зміна паролю'},
    ];

    routesMap = [
      ...routesMap,
      {
          type: "route",
          exact: true,
          path: "/account/:id/expert",
          component: ExpertForm
      },
      {
          type: "route",
          exact: true,
          path: "/account/:id/documents",
          component: ExpertDocuments
      }
    ];
  }

  return (
    <>
      <Sidebar />
      <Layout
        className="projects-division"
        title={"Аккаунт користувача"}
        breadcrumbs={
          <TabsNav route="/account" tabs={ headerNav } location={data._id} />
        }
      >
        <Switch>
          {
            routesMap.map( (route, index) => {
                if( route.type === "redirect"){
                  return <Redirect key={index} {...route} />
                }

                if( route.type === "route" ){
                  return <Route key={index} {...route} />
                }

            })
          }
        </Switch>
      </Layout>
      <Notify />
    </>
  )
}

const mapStateToProps = (state) => ({
  data: state.auth.user
})

const mapDispatchToProps = (dispatch) => ({
  userLogout: () => {
    dispatch(logout())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Account)