import React from 'react'
import { connect } from 'react-redux'


import DocumentsList from '../../../../../common/DocumentList';
import ApplicantFields from '../Parts/ApplicantFields';


const  NegotiationEdits = ({ feedback, application }) => {

    const { single } = feedback;
    const { fields, fieldsFull, negotiation_files, procedure, all_subfields } = single;
    console.log('[fields]', fieldsFull );

    if( procedure.changed_fields === undefined && negotiation_files.length === 0){
        return(
            <h2>Заявник не вніс ніяких змін</h2>
        )
    }

    let flattenFields = [];

    fieldsFull.forEach( group => {
        flattenFields = [ ...flattenFields, ...group.fields ];
    });

    console.log( flattenFields );

    return(
        <div className="expert-solution-form">
            <div className="negotiation__data">
                {
                    negotiation_files.length > 0 && (
                        <div className="negotiation__files">
                            <h3>Заявник завантажив файли:</h3>
                            <DocumentsList documents={negotiation_files}/>
                        </div>
                    )
                }
                {
                    procedure.changed_fields && (
                        <div className="negotiation__fields">
                            <h3>Заявник змінив поля:</h3>
                            <ApplicantFields 
                                application={application}
                                procedureData={single}
                                subfields={all_subfields}
                                fullFields={flattenFields}
                            />
                        </div>
                    )
                }
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    feedback: state.matching.negotiation,
    application: state.applications.info.data,
    fields: state.matching
});

export default connect(mapStateToProps)(NegotiationEdits);