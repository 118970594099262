export default {
	/*
		Config of application
	*/
	
	// Path to API from .env 
	apiPath: process.env.REACT_APP_API_URL,
	version: process.env.REACT_APP_VERSION,

	// Global Title for helmet
	helmet_title: "СУП УКФ",

	// Google analytics ID:
	analytics_id: "UA-135975555-2",
	
	// Sentry.io data:
	sentry_path: "https://17390705cb524d60a5e1271d89e680df@sentry.io/1808618",
	sentry_project: `ucf_spm_2@${process.env.REACT_APP_VERSION}`
}

    