
import axios from '../../helpers/private.axios';

export const SET_STATS_FILTERS = "SET_STATS_FILTERS";

export const setFilters = ( filters ) => ( dispatch, getState ) => {

    const activeFilters = getState().matching.stats.filters;
    
    if( JSON.stringify(activeFilters) !== JSON.stringify(filters) ){
        dispatch({
            type: SET_STATS_FILTERS,
            payload: filters
        });
    }


}

export const CLEAR_FILTERS = "CLEAR_FILTERS";

export const clearSettedFilters = () => (dispatch) => {
    dispatch({ type: CLEAR_FILTERS });
}

export const GET_STATS_APPLICATIONS_REQ = "GET_STATS_APPLICATIONS_REQ";
export const GET_STATS_APPLICATIONS_RES = "GET_STATS_APPLICATIONS_RES";
export const GET_STATS_CHUNK_APPLICATIONS_REQ = "GET_STATS_CHUNK_APPLICATIONS_REQ";
export const GET_STATS_CHUNK_APPLICATIONS_RES = "GET_STATS_CHUNK_APPLICATIONS_RES";


export const getExpertStatsApplications = ( chunk ) => ( dispatch, getState ) => {
    const { filters, offset, limit, loading } = getState().expert.stats;

    console.log('req data', {
        skip: offset,
        limit,
        filters,
    })

    if( !loading ){ 
        if( chunk ){
            dispatch({ type: GET_STATS_CHUNK_APPLICATIONS_REQ, payload: offset });
        } else {
            dispatch({ type: GET_STATS_APPLICATIONS_REQ, payload: offset });
        }



        axios.post(`/api/expert/stats`, {
            filters: {
                ...filters,
            },
            limit, 
            offset
        } )
            .then( res => {

                if( chunk ){
                    dispatch({ type: GET_STATS_CHUNK_APPLICATIONS_RES, payload: res.data.data });
                } else {
                    dispatch({ type: GET_STATS_APPLICATIONS_RES, payload: res.data.data });
                }
            });
    }
} 

export const SEARCH_EXPERT_BY_NAME_REQ = "SEARCH_EXPERT_BY_NAME_REQ";
export const SEARCH_EXPERT_BY_NAME_RES = "SEARCH_EXPERT_BY_NAME_RES";

export const searchExpertByName = ( query ) => (dispatch) => {

    dispatch({ type: SEARCH_EXPERT_BY_NAME_REQ });
    axios.post(`/api/expert/stats/find`, { query })
        .then( res => {
            dispatch({ 
                type: SEARCH_EXPERT_BY_NAME_RES,
                payload:  res.data.data.length >= 10 ? res.data.data.slice( 0, 10) : res.data.data
            });
        });

}