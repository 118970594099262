// import React from 'react';

import ProjectDivision from './pages/ProjectDivision'
import ExpertsDivision from './pages/ExpertsDivision'
import Experts from './pages/Experts'
import Admin from './pages/Admin'

import Search from './pages/Search'
// import Logs from './pages/Logs'
import Account from './pages/Account'
import Reporting from './pages/Reporting';
import Direction from './pages/Direction';
import Agreements from './pages/Agreements';
import ReportingStats from './pages/Reporting/Stats';

import Documents from './pages/Documents';

import NotFound from './pages/NotFound'
import Unassigned from './pages/Auth/Unassigned'

import { accountIcon, homeIcon, searchIcon, edIcon, pdIcon, statistic, pie, speach, manager_star, chief, expert } from '../assets/menuIcons'

export const Routes = [
    {
        path: '/admin',
        type: 'private',
        component: Admin,
        icon: homeIcon,
        title: 'Головна сторінка',
        mainFor: ['admin', 'chief'],
        roles: ['admin', 'chief']
    },
    {
        path: '/agreements',
        type: 'private',
        component: Agreements,
        icon: chief,
        title: 'Мультипідпис',
        mainFor: ['admin', 'chief'],
        roles: ['admin', 'chief']
    },
    {
        path: '/pd',
        type: 'private',
        component: ProjectDivision,
        icon: pdIcon,
        title: 'Проектний відділ',
        mainFor: ['project_manager', 'project_head'],
        roles:  ['admin', 'project_manager', 'project_head', 'chief', 'lawyer', 'supervisory']
    },
    {
        path: '/dr',
        type: 'private',
        component: Direction,
        icon: manager_star,
        title: 'Дирекція',
        mainFor: ['project_manager', 'project_head'],
        roles:  ['admin', 'project_manager', 'project_head', 'expert_manager', 'chief', 'financier', 'lawyer', 'supervisory' ]
    },
    {
        path: '/ed',
        type: 'private',
        component: ExpertsDivision,
        icon: edIcon,
        title: 'Експертний відділ',
        mainFor: ['expert_manager',],
        roles:  ['admin', 'project_head', 'expert_manager', 'chief', 'supervisory'  ]
    },
    {
        path:'/ed/stats',
        type: 'private',
        icon: pie,
        component: NotFound,
        title: 'Стан справ',
        mainFor: [],
        roles: ['admin', 'project_head', 'expert_manager', 'chief', 'supervisory'  ]
    },
    {
        path:'/ed/experts',
        type: 'private',
        icon: expert,
        component: NotFound,
        title: 'Експерти',
        roles: ['admin', 'project_head', 'expert_manager', 'chief', 'supervisory'  ]
    },
    {
        path: '/expert',
        type: 'private',
        component: Experts,
        icon: homeIcon,
        title: 'Сторінка експерта',
        mainFor: ['expert'],
        roles:  ['expert']
    },
    {
        path: '/rp',
        type: 'private',
        component: Reporting,
        icon: statistic,
        title: 'Погодження',
        mainFor: ['financier', 'lawyer', 'director'],
        roles: ['admin',  'project_manager', 'project_head', 'lawyer', 'financier', 'chief', 'supervisory']
    },
    {
        path: '/',
        type: 'private',
        exact: true,
        component: Unassigned,
        roles:  ['unassigned']
    },
    // {
    //     path: '/logs',
    //     component: Logs,
    //     icon: messageIcon,
    //     type: 'private',
    //     title: 'Повідомлення',
    //     roles: ['admin', 'project_manager', 'lawyer', 'financier', 'expert_manager', 'expert', 'chief']
    // },
    {
        path: '/search',
        component: Search,
        icon: searchIcon,
        type: 'private',
        title: 'Пошук',
        mainFor: ['supervisory'],
        roles: ['admin', 'project_manager', 'project_head', 'lawyer', 'financier', 'expert_manager', 'chief', 'supervisory']
    },
    {
        path: '/rp/stats',
        component: ReportingStats,
        icon: speach,
        type: 'private',
        title: 'Стан переговорки',
        mainFor: ['financier', 'lawyer', 'director'],
        roles: ['admin', 'project_manager', 'project_head', 'lawyer', 'financier', 'chief', 'supervisory']
    },
    {
        path: '/documents',
        component: Documents,
        icon: chief,
        type: 'private',
        title: 'Документи',
        mainFor: [],
        // roles: [],
        roles: ['admin', 'project_manager', 'project_head', 'lawyer', 'financier', 'chief',]
    },
    {
        path: '/account',
        component: Account,
        icon: accountIcon,
        type: 'private',
        title: 'Особистий кабінет',
        mainFor: ['unassigned_expert'],
        roles: ['admin',  'project_manager', 'project_head', 'lawyer', 'financier', 'expert_manager', 'expert', 'chief', 'unassigned_expert', 'supervisory']
    },
    {
        component: NotFound,
        type: 'private'
    },
]


export default Routes;