import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment'
import { Link } from 'react-router-dom';

import { Loader } from '../../../library'
import { getFeedbacks } from '../../../../actions';

const ReviewHistory = () => {

    let { reviewid, id  } = useParams();
    const dispatch = useDispatch();
    const feedbacks = useSelector( state =>  state.expert.feedbacks );
    const application = useSelector( state => state.applications.info.data )

    useEffect(() => {
        dispatch( getFeedbacks( reviewid ) );
    }, [reviewid])

    // dispatch( getFeedbacks( ownProps.match.params.reviewid ) );
    // application: state.applications.info.data,
	// loaded: state.expert.feedbacks.loaded,
	// feedbacks: state.expert.feedbacks

    if( !feedbacks.loaded ){
        return <Loader/>;
    }

    return(
        <div>
            <div className="expMsg__titleHead">
                <h1> Історія переписки </h1>
                <Link className="expMsg__link short" to={`/ed/application/${application._id}/review/${feedbacks.data.review._id}/`}> Назад на відгук </Link>
            </div>

            <div className="expMsg__body">
                {
                    feedbacks.data.review.messages.length > 0 ? 
                    (
                        <ul className="expMsg__list">
                            {
                                feedbacks.data.review.messages.map( (item, key) => {

                                    return(
                                        <li className={`expMsg__item ${item.message_type}`} key={key}>
                                            <header className="expMsg__itemHead">
                                                <div> 
                                                    <span>{item.user_name}</span>
                                                    {item.message_type === "expert_division" && (<span className="expMsg__itemSub text-gray">(Експертний відділ)</span>)}
                                                    {item.message_type === "expert" && (<span className="expMsg__itemSub text-gray">(Експерт)</span>)}
                                                    {item.message_type === "expert_message" && (<span className="expMsg__itemSub">(Офіційне звернення)</span>)}
                                                    
                                                </div>
                                                <div> {moment(item.created_at ).format('lll')}</div>
                                            </header>
                                            <div>
                                                {item.message}
                                            </div>
                                        </li>
                                    )
                                }) 
                            }
                        </ul>
                    ) : (
                        <div>
                            Історія порожня
                        </div>
                    )

                }



            </div>


        </div>
    );

}

export default ReviewHistory;