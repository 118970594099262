import React from 'react'
import { N_Button, Layout, Breadcrumbs, Loader, Table, Row, Cell } from '../../../library';

import { Switch, Route } from 'react-router-dom';

import Folders from './Folders';
import Commision from './Commision';
import Supervisory from './Supervisory';
import Final from './Final';

const ExpertSelectionRoot = () => {


    return(
        <Layout>
            <Switch>
                <Route exact path="/ed/experts/selection" component={Folders}/>
                <Route exact path="/ed/experts/selection/commission" component={Commision}/>
                <Route exact path="/ed/experts/selection/supervisory" component={Supervisory}/>
                <Route exact path="/ed/experts/selection/final" component={Final}/>
            </Switch>
        </Layout>
    )
}


export default ExpertSelectionRoot;