import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, ButtonWrap } from '../../../library';
import { updateDocument, notify } from '../../../../actions';

export const GenInfo = ({ document, template, setDocument }) => {
	// const history = useHistory();
	const dispatch = useDispatch();
	const [ isProcessing, setProcessing ] = useState( false );
	const [ fields, setFields ] = useState([]);


	useEffect(() => {
		let fields = template.variables.map( item => { 
			console.log('[item]', item );
			if( document.template_data &&  document.template_data.hasOwnProperty( item.name  )   ){
				item.value = document.template_data[ item.name ];
				console.log('effected', item );
			}
			return item;
		});
		
		setFields( fields );

	}, [ document ])
	

	let timer = null;

	const inputHandler = ( e ) => {
		let { value, name } = e.target;
		clearTimeout( timer );
		setProcessing( true );
		timer = setTimeout( () => {
			let updated_fields = fields.map( item => {
				if( item.name === name ){
					item.value = value;
				}
				return item;
			})
			setFields( updated_fields );
			setProcessing( false );
		}, 1000 );
	}

	const updateGeneratedData = () => {

		let documentData = {};

		fields.map( field => {
			documentData[ field.name ] = field.value;
		})

		console.log('[doc data]', documentData );

		updateDocument( document._id, { template_data: documentData } )
			.then( res => {
				console.log('res', res, res.data.data.document );
				setDocument( res.data.data.document );
				dispatch( notify( res.data.data.message, 'success') );
			});
	}

	const template_vairables = template.variables.filter( item => item.mode !== "finance" && item.mode !== "applicant");


	return(
		<div className="documents__page"> 
			<h2> Змінні заповнені при генерації:</h2>
			
			{
				template_vairables.length > 0 ? (
					<div className="documents__wrapper">
						<div className="documents__editable fin">
							<div className="documents__form">
								{
									template_vairables.map( (field, index) => {
										return(
											<label key={`${field.name}_${index}`}>
												<div>{ field.label }</div>
												<input 
													className="documents__input"
													name={field.name}
													defaultValue={ field.value }
													onChange={ inputHandler }
												/>
											</label>
										);
									})
								}

							</div>
						</div>
						<div className="documents__vairables fin">
								<ButtonWrap>
										<Button 
												handler={ updateGeneratedData } 
												size="large"  
												ltext="Інорфмація оновлюється"
												text="Збереги інформацію"
												loading={ isProcessing }
												disabled={ isProcessing }
										/>
								</ButtonWrap>
						</div>
					</div>
				) : (
					<div className="documents__wrapper">
							<h3> Немає змінних </h3>
					</div>
				)
			}

		</div>
	);



};