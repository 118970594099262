import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { 
    Layout, Breadcrumbs, Loader, StatusHandler, Empty, Skeleton,
    Table, Row, Cell

} from '../../../library';

import Filters from './filter_element';
import ExpertCircle from './expert_status_circle';

import {
    getExpertStatsApplications, getSingleField
} from '../../../../actions';
import getFieldValue from '../../../../helpers/getFieldValue';
import debounce from '../../../../helpers/debounce';

import '../styles/expert_stats.scss';


class ExpertStats extends React.PureComponent {

    ref = React.createRef();

    componentDidMount = () => {
        this.props.getStatsApplications();
        this.props.getDataSingleField('culture');

        this.deboundedOnload = debounce( this.handleOnScroll, 300 );
        window.addEventListener('scroll',  this.deboundedOnload );

    }   

    componentWillUnmount = () => {
        window.removeEventListener('scroll',  this.deboundedOnload );
    }


    componentDidUpdate = ( prevProps ) => {
        if( JSON.stringify(this.props.filters) !== JSON.stringify(prevProps.filters) && this.props.applications.length === 0 ){
            this.props.getStatsApplications();    
        }
    }

    handleOnScroll = () => {
        if( window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight ){
            this.loadMore();
        }
    }

    loadMore = () => {
        const { offset, total, loading } = this.props;
        if( offset <= total ){
            if( !loading ){
                this.props.getStatsApplications( true );
            }
        }
    }


    render = () => {
        const { applications, loaded, loading, fields, total, offset } = this.props;
        return(
            <Layout
                className="projects-division"
                title="Стан справ"
            >
                <Breadcrumbs
                    items={[
                        { name: "Експертний відділ", link: `/ed/programs` },
                        { name: "Стан справ", link: `/ed/stats`}
                    ]}
                />
                <Filters />
                <div ref={this.ref}>
                    <Table>
                        <Row type="head">
                            <Cell type="small"> # </Cell>
                            <Cell type="medium"> Статус оцінки / заявки </Cell>
                            <Cell type="extra-large"> Експерти </Cell>
                            <Cell type="large"> Сектор </Cell>
                        </Row>
                        {
                            loaded ? (
                                <>
                                    {
                                        applications.length > 0 ? applications.map( application_data => {
                                            const { application, reviews } = application_data;
                                            return(
                                                <Row key={application._id}>
                                                    <Cell type="small">
                                                        <Link to={`/ed/application/${application._id}`} className="link">
                                                            {application.spec_id}
                                                        </Link>
                                                    </Cell>
                                                    {/* <Cell type="id"> - </Cell> */}
                                                    <Cell type="medium">
                                                        <StatusHandler type="expert_division_application" statusId={Number(application.expert_review.status)}/>
                                                    </Cell>
                                                    <Cell type="extra-large">
                                                        {
                                                            application.expert_review.status > 0 ? (
                                                                <div className="ed-stats__circles-container">
                                                                    {
                                                                        reviews.map( review => {
                                                                            return(
                                                                                <ExpertCircle 
                                                                                    key={ review._id }
                                                                                    id={review.status.id} 
                                                                                    name={ review.expert.name }
                                                                                    application_id={ application._id }
                                                                                    review_id={ review._id }
                                                                                />
                                                                            )
                                                                        })
                                                                    }

                                                                </div>
                                                            ) : (
                                                                <>
                                                                    Експерти ще не були розподілені
                                                                </>
                                                            )
                                                        }

                                                    </Cell>
                                                    <Cell type="large">
                                                        {
                                                            fields.culture_loaded && (
                                                                <>
                                                                    { getFieldValue( application.data.fields.cultural_sector, fields.culture.options.options )  }
                                                                </>
                                                            )
                                                        }
                                                        
                                                    </Cell>
                                                </Row>
                                            )
                                        }) : <Empty text="Пошук за цими критеріями не дав результатів" />
                                    }
                                </>
                            ) : <Loader />
                        }


                        {
                            loading && loaded && (
                                <>
                                    <Row>
                                        <Skeleton />
                                    </Row>
                                    <Row>
                                        <Skeleton />
                                    </Row>
                                </>
                            )
                        }


                        {
                            offset < total ? 
                            (
                                !loading && ( <button  className="row__button"  onClick={this.loadMore}> Завантажити ще </button>  )
                            ) :
                            <Row>
                                <Cell type="huge"> Ви вже завантажили усі данні по цьому фільтру </Cell>
                            </Row>
                        }


                    </Table>
                </div> 
            </Layout>
        )
        
    }
}

/*
    Redux
*/
const mapStateToProps = (state) => ({
    fields: state.common.fields,
    filters: state.expert.stats.filters,
    applications: state.expert.stats.data,
    loaded: state.expert.stats.loaded,
    loading: state.expert.stats.loading,
    offset: state.expert.stats.offset,
    total: state.expert.stats.total_with_filter,
})

const mapDispatchToProps = (dispatch) => ({
    getStatsApplications: ( chunk) => {
        dispatch( getExpertStatsApplications( chunk) );
    },
    getDataSingleField: ( type ) => {
        dispatch( getSingleField( type ) )
    }
});



export default connect(mapStateToProps, mapDispatchToProps)(ExpertStats);