import React from 'react'
import { connect } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'

import Conflict from './Application/InfoConflict';
import Info from './Application/Info';
import Rate from './RateApp/Rate'
import RateGroup from './RateApp/RateGroup'
import Protocol from './Protocol'
import Head from './Application/TabsNav';

import { Layout, Loader } from '../../library'
import { getSingleAppForExpert, clearSingleAppForExpert } from '../../../actions'

import './styles/styles.scss'

class App extends React.Component {
  componentDidMount () {
    this.props.getSingleApp(this.props.match.params.id)
  }

  componentWillUnmount () {
    this.props.clearSingleApp()
  }

  render () {
    const { data, loaded } = this.props

    if( !loaded ){
      return <Loader/>
    }
    return (
      <Layout
        className="projects-division"
        paddingTop="20px"
        header={<Head id={this.props.match.params.id} review={data.review}/>}
      >
        <Switch>
          <Redirect exact from="/expert/:id" to="/expert/:id/info" />
          <Route exact path="/expert/:id/info" component={Info}/>
          <Route exact path="/expert/:id/conflict" component={Conflict}/>
          <Route exact path="/expert/:id/rate" component={Rate}/>
          <Route exact path="/expert/:id/rate/:groupid" component={RateGroup}/>
          <Route exact path="/expert/:id/protocol" component={Protocol}/>
        </Switch>
      </Layout>
    )
  }
}

const mapStateToProps = (state) => ({
  data: state.expert.single.data,
  loaded: state.expert.single.loaded,
})

const mapDispatchToProps = (dispatch) => ({
  getSingleApp: (id) => {
    dispatch(getSingleAppForExpert(id))
  },
  clearSingleApp: () => {
    dispatch(clearSingleAppForExpert())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(App)