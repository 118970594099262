import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { Layout, Breadcrumbs, Loader, Table, Row, Cell  } from './../../library';
import DivisionContext from '../../../helpers/DivisionContext';
import { AppliationProjectCount, ApplicationsExpertCount } from './ApplicationsCounters';
import { getChildPrograms, clearChildPrograms } from './../../../actions';
import folderIcon from './icons/folder.svg';

class ProgramsChilds extends Component {
  componentDidMount() {
    this.props.getPrograms(this.props.match.params.progid);
  }

  componentWillUnmount() {
    this.props.clearPrograms()
  }

  render() {
    const { loaded, data } = this.props

    if( !loaded ){
      return <Loader/>;
    }

    return (
      <DivisionContext.Consumer>
        {
          division => (
            <Layout
              className="projects-division"
              title={ data && data.program.title }
              breadcrumbs={
                data && <Breadcrumbs items={[
                  {name: division.title, link: `/${division.route}/programs` },
                  {name: data.program.title, link: `/${division.route}/programs` }
                ]}/>
              }
            >
              <Table>
                <Row type="head">
                  <Cell type="id">#</Cell>
                  <Cell type="large">Назва програми</Cell>
                  {
                    data.type === "contests" && (
                      <>
                        <Cell type="medium">Опрацьовано заявок</Cell>
                        <Cell type="medium">Дедлайни Конкурcу</Cell>
                      </>
                    )
                  }
                </Row>
                {
                  data && data.childs.map(( item, key ) => {
                    const contests = `/${division.route}/contests/${item._id}`
                    const lots = `/${division.route}/lots/${item._id}`

                    return (
                      <Row
                        key={key}
                        type="link"
                        to={data.type === "contests" ? contests : lots}
                      >
                        <Cell type="folder">
                          <img src={folderIcon} alt="Категорія з лотами" />
                        </Cell>
                        <Cell type="large">{ key + 1 }.{item.title}</Cell>
                        {
                          data.type === "contests" && (
                            <>
                              <Cell type="medium">
                              { division.type === "projects" && <AppliationProjectCount apps={item.apps} /> }
                              { division.type === "experts" && <ApplicationsExpertCount apps={item.apps} /> }
                              </Cell>
                              <Cell type="medium">
                                {
                                  moment( item.deadlines.realization.start ).format('ll')
                                } - {
                                  moment( item.deadlines.realization.end ).format('ll')
                                }
                              </Cell>
                            </>
                          )
                        }
                      </Row>
                    )
                  })
                }
              </Table>
            </Layout>
          )
        }
      </DivisionContext.Consumer>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.programs.childs.data,
  loaded: state.programs.childs.loaded
})

const mapDispatchToProps = (dispatch) => ({
  getPrograms: (id) => {
    dispatch( getChildPrograms(id) )
  },
  clearPrograms: () => {
    dispatch( clearChildPrograms() )
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ProgramsChilds)