import React from 'react'
import { EmployeeMessage } from '../Parts';

export const OnlineProcedure = ({ procedure }) => {
    return (
        <>
            <EmployeeMessage employee={procedure.pm} employee_name="Проектний менеджер" />
            <EmployeeMessage employee={procedure.lawyer} employee_name="Юрист" />
            <EmployeeMessage employee={procedure.financer} employee_name="Фінансист" />
        </>
    );
}

export default OnlineProcedure;