import privateAxios from '../../helpers/private.axios'

export const GET_PROJECT_CONTESTS_REQ ='GET_PROJECT_CONTESTS_REQ'
export const GET_PROJECT_CONTESTS_RES ='GET_PROJECT_CONTESTS_RES'
export const GET_PROJECT_CONTESTS_ERR ='GET_PROJECT_CONTESTS_ERR'

export const CLEAR_PROJECT_CONTESTS ='CLEAR_PROJECT_CONTESTS'

export const getReportingContests = (id) => (dispatch) => {
    dispatch({ type: GET_PROJECT_CONTESTS_REQ })

    privateAxios(`/api/catalogs/contests/${id}/reporting`)
    .then( res => {
        dispatch({
        type: GET_PROJECT_CONTESTS_RES,
        payload: res.data.data
        })
    })
    .catch( err => {
        dispatch({
        type: GET_PROJECT_CONTESTS_ERR,
        payload: err
        })
    })
}

export const clearProjectContests = () => (dispatch) => {
    dispatch({ type: CLEAR_PROJECT_CONTESTS })
}