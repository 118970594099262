import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { DocumentLink, Button, ButtonWrap } from '../../../library';

import { finishDocumentReq, updateDocument, notify } from '../../../../actions/';

export const FinishDocument = ({ document, setDocument }) => {
  const dispatch = useDispatch();

  const sendToSign = () => {
    updateDocument( 
      document._id,
      { 
        status: 5,
      },
    ).then( res => {
      dispatch( notify( 'Документ відправлено на підпис директору', 'success'))
      setDocument( res.data.data.document );

      console.log('res', res.data );
    });
  }

  return (
    <div className="documents__page">  

      <h2> Відправка на підпис </h2>
      <p>
        Перед відправкою перевірте документ на валідність!
      </p>
      <DocumentLink 
          url={document.source}
          text={document.title}
      />

      {
          document.status === 5 && (
              <div className="documents__warn">
                  <span role="img">✅</span> Документ відправлено на підпис директору
              </div>
          )
      }

      {
        document.status === 4 ? (
          <ButtonWrap>
              <Button 
                  handler={ sendToSign } 
                  size="medium"  
                  text="Відправити на підпис директору"
              />
          </ButtonWrap>
        ) : (
          <ButtonWrap>
              <Button 
                  size="medium"  
                  text="Відправити на директору можна тільки після підпису заявником "
                  disabled={true}
              />
          </ButtonWrap>
        )
      }

    </div>
  )
}