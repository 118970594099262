import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Button } from '../../../library';

import { setFilters, searchExpertByName, clearSettedFilters } from '../../../../actions';
import AppliedFilters from './applied_filters';

import { loadState, saveState, removeState } from '../../../../helpers/storeLocalData';

import filter_image from '../icons/filter.svg';
import close from '../icons/close.svg';


class Filters extends Component {

    state = {
        filters: {
            spec_id: "",
            application_status: "",
            review_status: "",
            expert_name: "",
            sector: ""
        },
        show: false,
        loaded: false
    }

    throttlingTimer = null

    static getDerivedStateFromProps(props, state) {
        if( !state.loaded ){
            return {
                ...state,
                filters: props.filters,
                loaded: true
            }
        }
        return null;
    }  
    showFilters = () => { 
        const { show } = this.state;
        this.setState({ show: !show });
    }
    changeHandler = ( e ) => {
        let value = e.target.value;
        let name = e.target.name;
        if( name === "expert_name"){
            clearTimeout(this.throttlingTimer)
            if (value.length > 3) {
                this.throttlingTimer = setTimeout( () => {
                    this.props.searchExpert(value)
                }, 400 )
            }
        }

        this.setState({
            filters: {
                ...this.state.filters,
                [ name ] : value 
            }
        })
    }

    setFilters = () => {
        const { setFiltersAction } = this.props;
        const { filters } = this.state;

        setFiltersAction( filters );

        saveState(filters, 'expert_filters');

        this.setState({
            show: false
        })
    }

    clearFilters = () => {
        this.props.clearFiltersAction();
        this.setState({ show: false });

        removeState('expert_filters')
    }

    componentDidMount = () => {
        const { setFiltersAction } = this.props;
        const preloadedData = loadState('expert_filters');
        if( preloadedData ){
            this.setState({
                filters: preloadedData
            })
            setFiltersAction( preloadedData );
        }
    }


    render = () => {

        const { show } = this.state;
        const { changeHandler, setFilters, clearFilters } = this;
        const { spec_id, application_status, review_status, expert_name, sector} = this.state.filters;
        const { data, loaded } = this.props.autocomplete;
        const { filters, apps_loaded, applications, fields, total } = this.props;
        const { showFilters } = this;

        return(
            <div className="ed-stats">

                <button className="ed-stats__filtersBtn" onClick={showFilters}>
                    <img className="ed-stats__filtersIcon" src={ show ? close : filter_image } alt=""/>
                    <span> 
                        { show ? "Сховати фільтри" : "Налаштувати фільтрацію"} 
                        { apps_loaded && ` (Всього результатів: ${applications.length}/${total})`}
                    </span>
                </button>

                <AppliedFilters filters={ filters } fields={fields}/>


                <div className={ show ? "ed-stats__body show" : "ed-stats__body"}>
                    
                    <label className="ed-stats__label">
                        <span className="ed-stats__title"> Номер заявки </span>
                        <input 
                            className="ed-stats__input" 
                            type="text" 
                            name="spec_id" 
                            value={spec_id} 
                            onChange={changeHandler} 
                        />
                    </label>

                    <label className="ed-stats__label">
                        <span className="ed-stats__title"> Статус заявки </span>
                        <select 
                            className="ed-stats__input" 
                            name="application_status" 
                            onChange={changeHandler} 
                            value={application_status}
                        >
                            <option value=""> Усі статуси </option>
                            <option value="-2"> Відхилено </option>
                            <option value="-1"> Відхилено, не підписано </option>
                            <option value="0"> Не обробленно </option>
                            <option value="1"> Розподілено експертів </option>
                            <option value="2"> Схвалено, не підписано </option>
                            <option value="3"> Схвалено </option>
                        </select>
                    </label>

                    <label className="ed-stats__label">
                        <span className="ed-stats__title"> Статус відгуку </span>
                        <select 
                            className="ed-stats__input" 
                            name="review_status" 
                            value={review_status}
                            onChange={changeHandler}
                        >
                            <option value=""> Усі статуси </option>
                            <option value="-3"> Конфлікт інтересів </option>
                            <option value="-2"> Звільнений з заявки </option>
                            <option value="-1"> Не можу оцінити заявку </option>
                            <option value="0"> Не оброблено </option>
                            <option value="1"> Триває опрацювання </option>
                            <option value="2"> Відправлено на валідацію </option>
                            <option value="3"> Відгук схвалений </option>
                            <option value="4"> Потрібне доопрацювання </option>
                            <option value="5"> Завершена </option>

                        </select>
                    </label>

                    <label className="ed-stats__label">
                        <span className="ed-stats__title"> Ім'я експерта </span>
                        {
                            loaded && data.length === 0 && (<span className="text-red" style={{ textAlign: 'left', margin: '10px 0px'}}> Такого експерта не існує - пошук не дасть результатів </span>)
                        }
                        
                        <input 
                            className="ed-stats__input" 
                            type="text" 
                            name="expert_name" 
                            value={expert_name}
                            onChange={changeHandler}
                            placeholder="Мінімум 3 символи з імені експерта"
                        />

                        {
                            expert_name.length > 3 && loaded && data.length > 0 && (
                                <div className="ed-stats__autocomplete-body">
                                    <div>
                                        Існують такі експерти:
                                    </div>
                                    {
                                        data.map( item => (
                                            <div 
                                                key={item._id}
                                                className="ed-stats__autocomplete-item"
                                            >{item.name}</div>
                                        ))
                                    }
                                </div>
                            )
                        }
                        
                    </label>

                    <label className="ed-stats__label">
                        <span className="ed-stats__title"> Сектор </span>
                        <select 
                            className="ed-stats__input" 
                            name="sector" 
                            value={sector}
                            onChange={changeHandler}
                        >
                            <option value=""> Усі сектори </option>
                            <option value="cultural_sector_visual_art"> візуальне мистецтво </option>
                            <option value="cultural_sector_audio_art"> аудіальне мистецтво </option>
                            <option value="cultural_sector_audiovisual_art"> аудіовізуальне мистецтво </option>
                            <option value="cultural_sector_design_fashion"> дизайн та мода </option>
                            <option value="cultural_sector_performative_performing_arts"> перформативне та сценічне мистецтво </option>
                            <option value="cultural_sector_cultural_heritage"> культурна спадщина </option>
                            <option value="cultural_sector_literature_publishing"> література та видавнича справа </option>
                            <option value="cultural_sector_cultural_creative_industries"> культурні та креативні індустрії </option>
                        </select>
                    </label>
                    

                    <footer className="ed-stats__footer">
                        <Button 
                            handler={setFilters}
                            size="large"
                            text="Застосувати фільтри"
                        />
                        <Button 
                            handler={clearFilters}
                            size="medium"
                            text="Очистити"
                            color="gray"
                        />
                    </footer>

                </div>
            </div>
        )
    }
}


/*
    Redux
*/


const mapStateToProps = (state) => ({
    fields: state.common.fields,
    filters: state.expert.stats.filters,
    applications: state.expert.stats.data,
    apps_loaded: state.expert.stats.loaded,
    total: state.expert.stats.total_with_filter,
    
    autocomplete: {
        data: state.expert.stats.experts_autocomplete_data,
        loaded: state.expert.stats.experts_autocomplete_loaded,
    }
})

const mapDispatchToProps = (dispatch) => ({
    setFiltersAction: ( filters ) => {
        dispatch( setFilters( filters ) );
    },
    searchExpert: ( query ) => {
        dispatch( searchExpertByName(query) )
    },
    clearFiltersAction: () => {
        dispatch( clearSettedFilters() );
    }
});


export default connect( mapStateToProps, mapDispatchToProps )(Filters);