import axios from '../../helpers/private.axios'



export const updateDocument  = ( id, data ) => {

    return axios.post(`/api/documents/doc/${id}/update`, data );

    // console.log( data );

}