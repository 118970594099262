
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Loader, Breadcrumbs, Stars } from '../../../library';
import { RateGroupItem, Question } from './RateGroupItem';
import { getQuestionForRate, rateQuestion } from '../../../../actions';


class RateGroup extends Component {

	componentDidMount(){
		this.props.getFieldsForRateReq();
	}

	rateQuestion = ( question ) => e => {
		console.log('rw', this.props.data.review._id, 'question', question, 'e', parseInt(e.target.value) );
		this.props.rateQuestionReq(
			this.props.data.review._id, // review id
			{
				question_id: question._id,
				name: question.name,
				title: question.title,
				score: parseInt(e.target.value)
			}
		)
	}

	findQuestionScore = ( question ) => {
		const finded_feedback = this.props.data.feedbacks.find( feedback => feedback.question_group_id === this.props.match.params.groupid );
		const finded_question = finded_feedback.questions.find( qut => qut.question_id === question._id );
		if( finded_question === undefined ){
			return  0;
		} else {
			return finded_question.score;
		}
	}


	render = () => {
		const { data, ranking, application } = this.props;
		const { rateQuestion, findQuestionScore } = this;

		console.log('ranking', ranking.data );

		if( !ranking.loaded ){
			return <Loader/>;
		}

		let format = null;
		if( moment(application.created_at) < moment('2020-09-30 00:00:00.000Z') ){
			format = 'old';
		} else {
			format = 'new';
		}

		return (
			<>
			<Breadcrumbs
				margin="15px 0"
				items={[
				{ name: 'Експертна частина', link: '/expert' },
				{ name: `${data.application.spec_id}`, link: `/expert/${data.application._id}/info` },
				{ name:  `Оцінювання заявки`, link: `/expert/${data.application._id}/rate` },
				{ name: ranking.data.group.title, link: `/expert/${data.application._id}/rate/${ranking.data.group._id }` }
			]}/>
			<div className="expert-title" style={{marginBottom: "20px"}}>
				{ranking.data.group.title}
			</div>

			<div className="collapse-rate-group-head">
				<div className="collapse-rate-group-head-info">
					Питання до експерта
				</div>
				<div className="collapse-rate-group-head-apply">
					Заявник написав у заявці
				</div>
			</div>
			{
				ranking.data.questions.map( question => (
					<RateGroupItem
						key={question._id}
						stars={
							<Stars
								currentEstimate={ findQuestionScore(question) }
								handler={ rateQuestion(question) }
								maxValue={question.max}
								disabled={ data.review.status.id !== 1 && data.review.status.id !== 4  }
								readonly={ data.review.status.id !== 1 && data.review.status.id !== 4 }
							/>
						}
						question={question.title}
				>
					{
						question.application_questions.map( sub_question => {
							return(
								<Question
									key={sub_question._id}
									format={format}
									question={sub_question}
									title={sub_question.label}
									text={ application.data.raw_fields[ sub_question.name ] }
									fields={ application.data.fields }
								/>
							)
						})
					}
				</RateGroupItem>

				))
			}
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	application: state.expert.single.data.application,
	data: state.expert.single.data,
	loading: state.applications.info.loading,
	ranking: state.expert.ranking
})

const mapDispatchToProps = (dispatch, ownProps ) => ({
	getFieldsForRateReq: () => {
		dispatch( getQuestionForRate( ownProps.match.params.groupid ) );
	},
	rateQuestionReq: ( review_id, data ) => {
		dispatch( rateQuestion( review_id, ownProps.match.params.groupid, data) );
	}
})

export default connect(mapStateToProps, mapDispatchToProps)(RateGroup)