import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { Loader, DataSort, Breadcrumbs, Collapse, Panel, DocumentLink  } from '../../../library'
import { startReviewingApplication } from '../../../../actions'
import { pdf_icon, xls_icon } from '../icons';

const ExpertSingleInfo = ({ data, loading, startApplication }) => {
  console.log("DATA INFO", data.application)
  if (data && !loading) {
    return (
      <>
        <Breadcrumbs
          margin="15px 0"
          items={[
            { name: 'Експертна частина', link: '/expert' },
            { name: `${data.application.spec_id}`, link: `/expert/${data.application._id}/info` },
            { name: `Загальна інформація`, link: `/expert` }
          ]}/>
        <div className="expert-title">{data.application.spec_id}</div>

        <div className="expert-solution">
          <button
            className={ data.review.status.id !== 0 ? "expert-solution-btn disabled" : "expert-solution-btn apply" }
            disabled={ data.review.status.id !== 0 }
            onClick={startApplication( data.review._id ) }>
              {
                data.review.status.id !== 0 ?
                'Ви вже почали оцінку' :
                'Розпочати оцінювання'
              }
          </button>
          {
              data.review.status.id !== -3 && data.review.status.id !== -1 && data.review.status.id !== 5 ? 
              (
                  <Link to={`/expert/${data.application._id}/conflict`}>
                    <div className="expert-solution-btn conflict">Не можу оцінити заявку</div>
                  </Link>
              ) : (
                <div className="expert-solution-btn disabled">Не можу оцінити заявку</div>
              )
          }
        </div>
        {
          data.review.status.id === -3 || data.review.status.id === -1 && (
            <div className="additional_info">							
                  <a href={data.protocol.fileUrl} target="_blank" rel="noopener noreferrer" className="application__documents-protocol">
                    Завантажити протокол відмови
                  </a>
                  <br />
                  <i>Причина відмови: </i> 
                  <span> { data.review.status.message } </span> 
            </div>
          )
        }

        <Collapse>
            <Panel title="Документи">
              <div className="expert_documents">
              {
                data.documents.map( doc => {
                  if( doc.file === undefined ){ return null; }
                  return(
                    <a
                    className="application__documents-item"
                    key={doc.file.absolute_path}
                    href={doc.file.absolute_path}
                    rel="noopener noreferrer"
                    target="_blank"
                    >
                      <img
                        className="application__documents-icon"
                        src={ doc.item.format === 'pdf' ? pdf_icon : xls_icon}
                        alt="format icon"
                        />
                      {doc.item.title}
                    </a>
                  )
                })
              }
              {
                data.application.appeal_file && (
                  <DocumentLink 
                    url={data.application.appeal_file}
                    icon="pdf"
                    text="Звернення від заявника"
                  />
                )
              }
              </div>
            </Panel>
            {
              data.application.data.fields.map( (group, key) => {
                if( group !== null ){
                  return(
                      <Panel
                        key={key}
                        title={group.title}
                      >
                        {
                          group.fields.map( (subfield, subkey) => {
                            return (
                              <DataSort key={subkey} subfields={group.subfields} {...subfield}/>
                            )
                          })
                        }
                      </Panel>
                    )
                  } else { return null }
                })
            }
          </Collapse>
      </>
    )
  } else {
    return <Loader/>
  }
}

const mapStateToProps = (state) => ({
  data: state.expert.single.data,
  loading: state.applications.info.loading
})

const mapDispatchToProps = (dispatch) => ({
  startApplication: ( review_id ) => (e) => {
    dispatch( startReviewingApplication( review_id ) );
    e.target.disabled = true;
    e.target.classList.add('disabled');
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ExpertSingleInfo)