import {
  GET_ROOT_PROGRAMS_RES,
  GET_ROOT_PROGRAMS_REQ,
  GET_ROOT_PROGRAMS_ERR,

  CLEAR_ROOT_PROGRAMS,
} from '../../actions'

const usersInitialState = {
  error: null,
  loading: false,
  loaded: false,
  data: null
}

const root = ( state = usersInitialState, action) => {
  switch( action.type ){
    case GET_ROOT_PROGRAMS_REQ:
      return {
        ...state,
        loading: true,
      }
    case GET_ROOT_PROGRAMS_RES:
      return {
        ...state,
        data: action.payload,
        loaded: true,
        loading: false
      }
    case GET_ROOT_PROGRAMS_ERR:
      return {
        ...state,
        error: action.payload
      }
    case CLEAR_ROOT_PROGRAMS:
      return {
        state
      }
      default:
        return state
  }
}

export default root