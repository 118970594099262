import { combineReducers } from 'redux'

import root from './root.reducer'
import childs from './childs.reducer'
import lots from './lots.reducer'
import seasons from './season.reducer';

const programs = combineReducers({
    root,
    childs,
    lots,
    seasons
})

export default programs