import React from 'react'
import { Link } from 'react-router-dom'
import {
    sup_logo
} from './icons'

import './not_found.scss'

const NotFound = () => {
    return (
        <div className="nf">
                <div className="nf__logo">
                    <img src={sup_logo} alt="logo" />
                </div>
                <div className="nf__body">
                    <div className="nf__body-code">404</div>
                    <div className="nf__body-title">Сторінка не знайдена</div>
                    <div className="nf__body-links">
                        <div className="nf__body-link">
                            <Link to="/">
                                Головна сторінка
                            </Link>
                        </div>
                        <div className="nf__body-link">
                            <Link to="/">
                                Увійти
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="nf__footer">Український Культурний Фонд</div>
        </div>
    )
}

export default NotFound