export const DISPLAY_NOTIFY = 'DISPLAY_NOTIFY'
export const HIDE_NOTIFY = 'HIDE_NOTIFY'
export const CLEAR_NOTIFY = 'CLEAR_NOTIFY'

export const notify = ( text, type ) => (dispatch) => {
  let message = {
    message: text,
    type: type ? type : 'default'
  }
  dispatch({
    type: DISPLAY_NOTIFY,
    payload: message,
  })
}

export const hide_notify = () => (dispatch) => {
  dispatch({
    type: HIDE_NOTIFY
  })
}

export const clear_notify = () => (dispatch) => {
  dispatch({
    type: CLEAR_NOTIFY
  })
}
