import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import { Loader, StatusHandler } from '../../../../library'
import { changeDirectionStatus } from '../../../../../actions'
import { pdf_icon, xls_icon } from '../../icons';


class AppReportSingle extends React.Component {
	state = {
		message: '',
		type_id: 1
	}

	handleCheckboxChange = (e) => {
		this.setState({
			type_id: e.target.value
		})
	}

	handleText = (e) => {
		this.setState({
			message: e.target.value
		})
	}

	handleSubmit = (e) => {
		const { message, type_id } = this.state;
		this.props.changeDirStatus( type_id, message );
	}

	render() {
		const { data, loading } = this.props
		const { type_id, message } = this.state;
		const { handleSubmit, handleCheckboxChange, handleText } = this;

		if (data && !loading) {
		return (
			<div className="expert-solution-form">
				<header className="negotiation__header">
					<h1 className="expert-solution-form-title">
						Рішення дирекції
					</h1>
					<StatusHandler type="reporting.direction" statusId={data.reporting_review.direction.status}/>
				</header>
				{
					data.reporting_review.grunt_agreement.final_protocol && data.reporting_review.grunt_agreement.final_budget && (
						<div className="direction__files">
								<a className="direction__item" href={data.reporting_review.grunt_agreement.final_protocol.file.fileUrl} target="_blank" rel="noopener noreferrer">  
									<img className="direction__icon" src={pdf_icon} alt="Проектна заявка"/>
									Завантажити заявку 
								</a>
								<a className="direction__item" href={data.reporting_review.grunt_agreement.final_budget.url} target="_blank" rel="noopener noreferrer">  
									<img className="direction__icon" src={xls_icon} alt="Кошторис проекту"/>
									Завантажити кошторис
								</a>
						</div>
					)
				}



				{
					data.reporting_review.direction.status === 0 ? (
						<>
							<label className="expert-solution-form-label-wrap">
								<input
									className="expert-solution-form-input"
									type="radio"
									value="1"
									name="type_id"
									checked={type_id === "1" ? true : false }
									onChange={handleCheckboxChange}
								/>
								<span className="expert-solution-form-label">Затверджено</span>
							</label>
							<label className="expert-solution-form-label-wrap">
								<input
									className="expert-solution-form-input"
									type="radio"
									value="-1"
									name="type_id"
									onChange={handleCheckboxChange }
									checked={type_id === "-1" ? true : false }
								/>
								<span className="expert-solution-form-label">Не затверджено</span>
							</label>
							<div className="expert-solution-form-text">Коментар до рішення (Не обов’язково)</div>
							<textarea
								rows="7"
								className="expert-solution-form-textarea"
								name="message"
								onChange={handleText}
								value={message}
							/>
							<button 
								className="expert-solution-form-submit"
								type="submit"
								onClick={handleSubmit}
							>
								Затвердити рішення дирекції
							</button>
						</>
					) : (
						<>
								<div className="negotiation__block">
								<header className="negotiation__blockHeader">
									<h3> 
										Рішення вніс { data.reporting_review.direction.user.name }
										( { moment( data.reporting_review.direction.updated_at ).format('lll')})
									</h3>
								</header>
								<p className="negotiation__blockMessage">
									{
										data.reporting_review.direction.message !== undefined ? data.reporting_review.direction.message : "Коментаря немає"
									}
								</p>
							</div>
						</>
					)
				}

				
		</div>
		)
		} else {
		return <Loader />
		}
	}
}

const mapStateToProps = (state) => ({
	data: state.applications.info.data,
	loading: state.applications.info.loading
})

const mapDispatchToProps = (dispatch, ownProps ) => ({
	changeDirStatus: ( status, message ) => {
		console.log( ownProps.match.params.id, status, message );
		dispatch( changeDirectionStatus( ownProps.match.params.id, status, message, ownProps.history ) )
	}
})

export default connect(mapStateToProps, mapDispatchToProps)(AppReportSingle)