import React from 'react'

import { Row, Cell, InlineSpinner } from '../../library'

const Registries = ({ data, handleChange, switching }) => {
  const names = [
    { value: 'received_applications', title: "Реєстр отриманих заявок" },
    { value: 'tech_review', title: "Реєстр технічного відбору" },
    { value: 'rating', title: "Рейтинговий рееєстр" },
    { value: 'signed_agreement', title: "Реєстр підписаних угод" },
    { value: 'realized_project', title: "Реєстр реалізованих проектів" }
  ]

  const findName = names.find( item => {
      if (item.value === data[0]) return item
      return null
  })

  return (
    <Row className="lots-register">
      <Cell className="lots-register-cell" type="medium">
        <span className="text-bold">
          {
            findName.title
          }
        </span>
      </Cell>
      <Cell
        type="medium"
        className={ data[1] ? 'lots-register-cell text-right text-green' : 'lots-register-cell text-right text-red' }
      >
        {
          data[1] ? 'Опубліковано' : 'Не опубліковано'
        }
      </Cell>
      <Cell type="large" className='lots-register-cell last'>
        <button
          onClick={ handleChange(data[0], !data[1]) }
          className={data[1] ? "button-reg button-reg-disabled" : "button-reg button-reg-active"}>
          {
            data[1] ? 'Сховати реєстр' : 'Опублікувати реєстр'
          }
          {
            switching && <InlineSpinner/>
          }
        </button>
      </Cell>
    </Row>
  )
}

export default Registries