import {
    GET_FEEDBACKS_REQ,
    GET_FEEDBACKS_RES
} from '../../actions'

const usersInitialState = {
	error: null,
	loading: false,
	loaded: false,
	data: null,
}

const feedbacks = (state = usersInitialState, action) => {
	switch (action.type) {
		case GET_FEEDBACKS_REQ:
		return ({
			...state,
			loading: true,
			loaded: false,
			data: null
		})
		case GET_FEEDBACKS_RES:
		return ({
			...state,
			loading: false,
			loaded: true,
			data: action.payload
		})

		default:
		return state
	}
}

export default feedbacks