import React from 'react';
import { Row, Cell, StatusHandler } from '../../../library/';

import { getApplicationTitle } from '../../../../helpers/getApplicationTitle';

export const ExpertHeader = () => (
    <Row type="head">
        <Cell type="medium">#</Cell>
        <Cell type="medium">Заявник</Cell>
        <Cell type="large">Проект</Cell>
        <Cell type="medium">Статус</Cell>
    </Row>
)


export const ExpertSearchResults = ({ item }) => {
    let title = getApplicationTitle( item ); 
    return(
        <Row 
            type="link"
            to={`/ed/application/${item._id}`}
        >
            <Cell type="medium">{ item.spec_id }</Cell>
            <Cell type="medium">{ item.user.name }</Cell>
            <Cell type="large">{ title.length > 75 ? `${title.substr(0, 75)}...` :  title }</Cell>
            <Cell type="medium">
                <StatusHandler 
                    statusId={item.expert_review.status}
                    type="expert_review"
                />
            </Cell>
        </Row>
    );
};


