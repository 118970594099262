import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector }  from 'react-redux'

import DocumentList from '../../../../common/DocumentList';
import { Loader, Round } from '../../../../library';
import { pdf_icon, xls_icon } from '../../icons';
import { getSingleReportingProcedure, sendReportingProcedureFeedback } from '../../../../../actions';

const ReportingProcedure = ({ match }) => {

    const dispatch = useDispatch();
    const data = useSelector( state => state.applications.info.data );
    const loaded = useSelector( state => state.applications.info.loading );

    const reporting = useSelector( state => state.matching.reporting.procedure );
    const reporting_files = useSelector( state => state.matching.reporting.procedure_files );
    const reporting_loaded = useSelector( state => state.matching.reporting.loaded_procedure );

    let load_status = reporting !== null;

    let [ comment, changeComment ] = useState('');
    let [ file , changeFile ] = useState( null );
    let [ error_message, setErrorMessage ] = useState( null );


    useEffect( () => {
        dispatch( getSingleReportingProcedure( data._id, match.params.procedure_id ) );   
    }, [ load_status ])

    console.log('[l]', reporting_loaded, '[r]', reporting );

    if( !reporting_loaded ){
        return <Loader />
    }

    let show_second_step = reporting.status === 1 || reporting.status === -1;
    let show_result = reporting.status == 2 || reporting.status == -2;
    console.log('!!!!', show_second_step, show_result );

    const documents = reporting.documents.map( item => {
        var ext = item.url.substr( item.url.lastIndexOf('.') + 1);
        return({
            absolute_path: item.url,
            title: item.name,
            extension: ext
        })
    })  

    const sendFeedback = ( status ) => () => {
        // console.log('stats', status )
        if( comment.length == 0 ){
            setErrorMessage('Коментар не може бути пустим');
            return;
        } else {
            setErrorMessage( null );
        }

        if( status == 1 || status == -1 ){
            dispatch( 
                sendReportingProcedureFeedback( match.params.id, match.params.procedure_id, {
                    message_inner: comment,
                    file: file,
                    status: status
                })
            );
        }

        if( status == 2 || status == -2 ){
            dispatch(
                sendReportingProcedureFeedback( match.params.id, match.params.procedure_id, {
                    message_applicant: comment,
                    status: status
                })
            )
        }

    }

    return(
        <div>

            {
                reporting.type === "content" && (
                    <>
                        <div className="expert_documents">
                            <a className="application__documents-item" href={reporting.attachment} target="_blank" rel="noopener noreferrer">
                                <img  className="application__documents-icon" src={pdf_icon} alt="format icon"/>
                                <span> Змістовий звіт </span>
                            </a>
                        </div>
                    </>
                )
            }

            {
                reporting.type === "finance" && (
                    <>
                        <div className="expert_documents">
                            <a className="application__documents-item" href={ reporting.attachment } target="_blank" rel="noopener noreferrer">
                                <img  className="application__documents-icon" src={xls_icon} alt="format icon"/>
                                <span> Фінансовий звіт </span>
                            </a>
                        </div>
                    </>
                )
            }
            
            <div className="project-app-grand">
                <div className="project-app-grand-title"> Додаткові файли </div>
                <div className="project-app-grand-file-wrapper">
                    <DocumentList documents={documents} />
                </div>
            </div>

            <div className="expert-solution-form-form">
                {
                    reporting.status === 0 ? (
                        <>
                            <div className="expert-solution-form-text" style={{ fontSize: '20px' }}>
                                Коментар до документу (НЕ БУДЕ надіслано заявнику)
                            </div>
                            {
                                error_message !== null && (
                                    <div className="error_area">{ error_message }</div>
                                )
                            }
                            <textarea
                                rows="7"
                                className="expert-solution-form-textarea"
                                name="message"
                                onChange={ (e) => { changeComment( e.target.value ) } }
                                value={comment}
                            />
                            <label className="fileUploader">
                                <span> 
                                    {
                                        file === null ? 'Оберіть файл' : file.name 
                                    }
                                </span>
                                <input 
                                    type="file" 
                                    accept=".xls, .xlsx, .pdf"
                                    onChange={ (e) => { 
                                        if( e.target.files.length > 0 ){
                                            changeFile( e.target.files[0] );
                                        }
                                    }}
                                    hidden    
                                />            
                            </label>
                            <div className="application-expert_apply">
                                <button 
                                    type="button"
                                    onClick={ sendFeedback(1) }
                                    className="application-expert_apply-btn apply"
                                >
                                    Успішно завершити процедуру
                                </button>
                                <button 
                                    type="button"
                                    onClick={ sendFeedback(-1) }
                                    className="application-expert_apply-btn dismiss"
                                >
                                    Потрібна ще одна процедура
                                </button>
                            </div> 
                        </>
                    ) : (
                        <>
                            <div className="reproting__manager__message">
                                <div className="reproting__manager__title">
                                    {
                                        show_second_step && (
                                            <Round status={reporting.status} /> 
                                        )
                                    }
                                    <b>Коментар внутрішній { reporting.user_manager.name } </b>
                                </div>
                                <p style={{ display: 'flex', fontSize: '16px'}}>
                                    {
                                        reporting.message_inner
                                    }
                                </p>
                                {
                                    show_second_step && (
                                        <>
                                            {
                                                reporting_files.length > 0 && (
                                                    <div>
                                                        <span> Додані файли: </span>
                                                        {
                                                            reporting_files.map( (link, index) => {
                                                                return (
                                                                    <a key={index} className="link" href={link.absolute_path} target="_blank">
                                                                        { link.name }
                                                                    </a>
                                                                )

                                                            })
                                                        }
                                                    </div>
                                                )
                                            }
                                        </>
                                    )
                                    
                                }
                            </div>
                        </>
                    )
                }

                {
                    show_second_step && (
                        <>
                            <div className="expert-solution-form-text" style={{ fontSize: '20px' }}>
                                Коментар до процедури (<b>БУДЕ</b> надіслано заявнику)
                            </div>
                            {
                                error_message !== null && (
                                    <div className="error_area">{ error_message }</div>
                                )
                            }
                            <textarea
                                rows="5"
                                className="expert-solution-form-textarea"
                                name="message"
                                onChange={ (e) => { changeComment( e.target.value ) } }
                                value={comment}
                            />
                            <div className="application-expert_apply">
                                <button 
                                    type="button"
                                    className="application-expert_apply-btn apply"
                                    onClick={ sendFeedback(2) }
                                >
                                    Успішно завершити процедуру
                                </button>
                                <button 
                                    type="button"
                                    className="application-expert_apply-btn dismiss"
                                    onClick={ sendFeedback(-2) }
                                >
                                    Потрібна ще одна процедура
                                </button>
                            </div>
                        </>
                    )
                }

                {
                    show_result && (
                        <div>
                            
                            <div className="reproting__manager__message">
                                <div className="reproting__manager__title">
                                    {
                                        <Round status={reporting.status === -2 ? -1 : 1 } /> 
                                    }
                                    <b>Коментар заявнику { reporting.user_decision.name } </b>
                                </div>
                                <p style={{ display: 'flex', fontSize: '16px'}}>
                                    {
                                        reporting.message_applicant
                                    }
                                </p>
                                {
                                    reporting_files.length > 0 && (
                                        <div>
                                            <span> Додані файли: </span>
                                            {
                                                reporting_files.map( (link, index) => {
                                                    return (
                                                        <a key={index} className="link" href={link.absolute_path} target="_blank">
                                                            { link.name }
                                                        </a>
                                                    )

                                                })
                                            }
                                        </div>
                                    )
                                }
                            </div>
                                
                            {
                                reporting.protocol && (
                                    <div className="expert_documents" style={{ marginTop: '20px' }}>
                                        <a className="application__documents-item" href={ reporting.protocol } target="_blank">
                                            <img  className="application__documents-icon" src={pdf_icon} alt="format icon"/>
                                            <span> Протокол процедури </span>
                                        </a>
                                    </div>
                                )
                            }

                        </div>
                    )

                }

            </div>
        </div>
    )

};



export default ReportingProcedure;

