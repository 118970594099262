import {
    GET_ROOT_SEASONS_REQ,
    GET_ROOT_SEASONS_RES,
    GET_ROOT_SEASONS_ERR,

    CLEAR_ROOT_SEASONS,
} from '../../actions'

const usersInitialState = {
    error: null,
    loading: false,
    loaded: false,
    data: null
}

const lots = ( state = usersInitialState, action) => {
    switch( action.type ){
        case GET_ROOT_SEASONS_REQ:
            return {
                ...state,
                loading: true,
            }
        case GET_ROOT_SEASONS_RES:
            return {
                ...state,
                data: action.payload,
                loaded: true,
                loading: false
            }
        case GET_ROOT_SEASONS_ERR:
            return {
                ...state,
                error: action.payload
            }
        case CLEAR_ROOT_SEASONS:
            return {
                state
            }
        default:
            return state
    }
}

export default lots