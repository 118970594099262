import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import { SingleExpertHeader } from './header';
import { USwitch, Breadcrumbs } from '../../../library';

import { updateSingleUser, finishUserValidation } from '../../../../actions';

const voteDictionary = [
    { value: 0, name: "education", label: "Вища освіта другого і вище (науковий ступінь) рівня. (спеціаліст, магістр – 1 бал; доктор філософії, доктор мистецтв, кандидат наук – 2 бали; доктор наук – 3 бали).", max: 3 },
    { value: 0, name: "prof_expirience", label: "Досвід професійної діяльності у відповідному секторі не менше п'яти років.", max: 2 },
    { value: 0, name: "project_managment", label: "Досвід управління проектами не менше трьох років.",max: 2 },
    { value: 0, name: "maiking_budget", label: "Досвід бюджетування проектів не менше трьох років.", max: 2 },
    { value: 0, name: "rating_expirience", label: "Досвід оцінки проектів у культурно-мистецькій сфері (участь в експертних групах, радах, комісіях з присудження премій, журі тощо) не менше трьох років.", max: 2 },
    { value: 0, name: "science", label: "Почесне наукове або почесне звання у відповідному секторі культури.", max: 2 },
    { value: 0, name: "awards", label: "Державні нагороди, премії, відзнаки, лауреатство на всеукраїнських та/або міжнародних фестивалях і конкурсах у відповідному секторі культури.", max: 2 },
    { value: 0, name: "significant_events", label: "Реалізація, участь у знакових проектах, міжнародне.", max: 1 },
    { value: 0, name: "publications", label: "Публікації у міжнародних фахових виданнях, одноосібні монографії, навчальні посібники, розроблені авторські навчальні курси у відповідному секторі культури, має високий професійний авторитет.", max: 2 },
    { value: 0, name: "profile_education", label: "Профільна вища освіта (наявність диплому).", max: 1 },
    { value: 0, name: "recommendations", label: "Наявність рекомендацій від центрального органу виконавчої влади, що забезпечує формування державної політики у сферах культури та мистецтв, установ Національної академії наук України, Національної академії мистецтв України, закладів вищої освіти (університет, академія, інститут), національних творчих спілок, які здійснюють діяльність у секторах культури і мистецтв. ", max: 2 },
    { value: 0, name: "not_formal_education", label: "Наявність документів про додаткову неформальну профільну освіту (школи, курси, тренінги, семінари, які підтверджуються відповідними документами) з веб-посиланнями на освітні установи та організації, де було отримані ці документи.", max: 2 },
]

const initDictionary = ( user ) => {
    const votes = voteDictionary.map( vote => {
        if( user.votes ){
            let el = user.votes.find( elem => elem.name === vote.name );
            if( el ){
                return el; 
            }
        }
        return vote;
    });
    console.log('init', votes );
    return votes;
}

const ExpertVote = () => {

    const dispatch = useDispatch();
    const data = useSelector( state => state.expert.singleExpert.data );
    const user = data.user;

    const [ votes, setVotes ] = useState( initDictionary(user) );
    
    const rank = votes.reduce( (sum, current ) => {
        if( current.value ){
            return sum + current.value;
        } else {
            return sum;
        }
    }, 0 );

    const changeVote = (e) => {
        let name = e.target.name;
        let value = Number(e.target.value);

        console.log('[CHANGES]', name, value );

        let changedVotes = votes.map( vote => {
            if( vote.name === name ){
                vote.value = value;
            }
            return vote;
        });
        setVotes( changedVotes );
    }

    const sendData = () => {

        dispatch(
            updateSingleUser(
                user._id,
                {
                    vote_rank: rank,
                    votes: votes
                }
            )
        );
    }

    const finishUser = () => {

        dispatch( 
            finishUserValidation( user._id )
        );
        dispatch(
            updateSingleUser(
                user._id,
                {
                    vote_rank: rank,
                    votes: votes
                }
            )
        );
    }

    return(
        <div>

            <SingleExpertHeader user={user} />
            <Breadcrumbs
                items={[
                    { name: "Експертний відділ", link: `/ed/programs` },
                    user.role === "unassigned_expert" ? { name: "Нерозподілені експерти", link: `/ed/experts/unassigned`} : { name: "Експерти УКФ", link: `/ed/experts`},
                    { name: `Експерт ${user.name}`, link: `/ed/experts/${user._id}/applications` },
                    { name: "Голосування за експерта", link: ""}
                ]}
            />
            <h1 className="page-title"> Голосування за експерта </h1>
            <h2>
                Рейтинг експерта: { rank }
            </h2>
            <ul className="documents__app-list">
                {
                    voteDictionary.map( vote => {

                        let value = Number(vote.value);

                        if( user.votes !== undefined ){
                            let el = user.votes.find( elem => elem.name === vote.name );
                            if( el ){
                                value = Number(el.value);
                            }
                        }

                        let items = Array.from({ length: vote.max+1 });

                        return(
                            <li className="application__body-item documents" key={vote.name}>
                                <div className="application__body-item-name text-gray">{ vote.label }</div>
                                <div className="application__documents-validation">
                                    {
                                        items.map( ( _, index ) => {        

                                            return (
                                                <label key={index} className="expert__item">
                                                    <div>{index}</div>
                                                    <input 
                                                        type="radio"
                                                        name={vote.name}
                                                        value={index}
                                                        onChange={changeVote}
                                                        disabled={user.matching}
                                                        checked={ index == value }
                                                    />
                                                </label>
                                            )
                                        })
                                    }
                                    

                                    {/* 
                                    <USwitch
                                        name={vote.name}
                                        handler={changeVote}
                                        value={ value }
                                        left="Ні"
                                        right="Так"
                                        disabled={false}
                                    /> */}
                                </div>
                            </li>
                        )

                    })
                }
                
            </ul>

            {
                !user.matching ? (
                        <footer className="direction__comment">
                            <button className="direction__btn" onClick={sendData}> Зберегти дані </button>
                            <button className="direction__btn finish" onClick={finishUser}> Фіналізувати експерта </button>
                        </footer>
                ) : (
                        <footer className="direction__comment">
                            <button disabled className="direction__btn disabled"> Зберегти дані </button>
                            <button disabled className="direction__btn disabled"> Фіналізувати експерта </button>
                        </footer>
                )
            }

            
            
        </div>
    )

}

export default ExpertVote;