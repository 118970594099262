import React from 'react'


export const ApplicationsExpertCount = ({ apps }) => (
    <>
        <span>
            <span className={ apps.expert === 0 ? "text-gray" : "text-green"}>{apps.expert}</span>
            {` з ${apps.success}`}
        </span>
    </>
);

export const AppliationProjectCount = ({ apps }) => (
    <>
        <span>
        {apps.success + apps.failed}  
        </span>
        <span>
        {` з ${apps.total} `}  
        </span>
        ( <span className="text-green">{apps.success}</span> 
        /
        <span className="text-red">{apps.failed}</span> )
    </>
)