import React from 'react';
import { 
	ProjectSearchResults, ProjectHeader,
	ExpertSearchResults, ExpertHeader,
	AdminHeader, AdminSearchResults

} from './search_results';

const fieldsDictionary = [
	{
		roles: ['admin', 'chief', 'supervisory'],
		component: AdminSearchResults,
		head: AdminHeader
	},
	{
		roles: ['project_manager', 'financier', 'lawyer', 'project_head'],
		component: ProjectSearchResults,
		head: ProjectHeader
	},
	{
		roles: ['expert_manager'],
		component: ExpertSearchResults,
		head: ExpertHeader
	}
];


const SearchField = ({ data, user }) => {
	const row = fieldsDictionary.find( item => {
		return item.roles.some( role => role === user.role );
	});

	console.log( user, row );
	if( row ){
		const SearchField = row.component;
		const RowHead = row.head;
		return (
			<>
				{ row.head && <RowHead /> }
				{
					data && (
						<>
							{
								data.map( (item, key) => {
									return(
										<SearchField key={key} item={item}/>
									)
								})
							}
						</>
					)
				}
			</>
		);
	} else {
		return null;
	}
}

export default SearchField;