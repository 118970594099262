import axios from '../../helpers/public.axios'
import config from '../../config'
import privateAxios from '../../helpers/private.axios'
import { configurateInterceptors } from '../../helpers/setAxiosInterceptors';


export const GET_TOKEN_USER_REQ = "GET_TOKEN_USER_REQ"
export const GET_TOKEN_USER_RES = "GET_TOKEN_USER_RES"
export const GET_TOKEN_USER_ERR = "GET_TOKEN_USER_ERR"

export const GET_INFO_USER_REQ = "GET_INFO_USER_REQ"
export const GET_INFO_USER_RES = "GET_INFO_USER_RES"
export const GET_INFO_USER_ERR = "GET_INFO_USER_ERR"

export const REFRESH_USER_TOKEN = "REFRESH_USER_TOKEN"

export const getUserToken = ({ login, password }) => ( dispatch ) => {
	dispatch({ type: GET_TOKEN_USER_REQ })

	axios.post(`${config.apiPath}/auth/login`,
		{
			"email": login,
			"password": password,
		}
	)
	.then(
		res => {
			let token = res.headers.authorization
			configurateInterceptors( token );
			dispatch({
				type: GET_TOKEN_USER_RES,
				payload: token
			});
			dispatch({
				type: GET_INFO_USER_RES,
				payload: res.data.data.account
			});
		}
	)
	.catch( error => {
		if( error.response !== undefined ){
			dispatch({
				type: GET_TOKEN_USER_ERR,
				payload: error.response.data
			});
		} else {
			dispatch({
				type: GET_TOKEN_USER_ERR,
				payload: {
					message: "Помилка зєднання з сервером. Перевірте своє підключення до інтернету"
				}
			});
		}
	})
}

export const getUserProfile = () => ( dispatch ) => {
	dispatch({ type: GET_INFO_USER_REQ})

	privateAxios('/auth/profile')
	.then(res => {
		dispatch({
			type: GET_INFO_USER_RES,
			payload: res.data.data.account
		})
		return res
	})
	.catch( error => {
		console.log('Error:', error)
		dispatch({
			type: GET_INFO_USER_ERR,
			payload: error.response.data
		})
	})
}