import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import ProgramsRoot from '../../common/Programs/ProgramsRoot'
import ProgramsChilds from '../../common/Programs/ProgramsChilds'
import ProgramsLots from '../../common/Programs/ProgramsLots'
import SeasonsRoot from '../../common/Programs/SeasonsRoot';

import ProjectContests from './ProjectContests'
import ProjectApp from './ProjectApp'

import Notify from '../../common/Notify'
import Sidebar from '../../common/SideBar'
import DivisionContext from '../../../helpers/DivisionContext'


import './styles/style.scss'

const ProjectDivision = () => (
	<DivisionContext.Provider
		value={{
			type: 'projects',
			route: 'pd',
			title: 'Проектний відділ'
		}}>
		<Sidebar />
		<Switch>
			<Redirect exact from="/pd" to="/pd/seasons"/>
			<Route exact path="/pd/seasons/:seasonid" component={ ProgramsRoot }/>
			<Route exaxt path="/pd/seasons" component={SeasonsRoot}/>

			<Route exact path="/pd/programs/:progid" component={ ProgramsChilds }/>
			<Route exact path="/pd/lots/:id" component={ ProgramsLots }/>
			<Route exact path="/pd/contests/:id" component={ ProjectContests }/>
			<Route path="/pd/application/:id" component={ ProjectApp }/>
		</Switch>
		<Notify />
	</DivisionContext.Provider>
)


export default ProjectDivision