import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Loader, Breadcrumbs, Table, Row, Cell } from '../../../../library';

import { getSingleMethodology }  from '../../../../../actions';

const MethodologySingle = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const history = useHistory();

    const metodology = useSelector( state => state.admin.evaluation.methodology );
    const groups = useSelector( state => state.admin.evaluation.groups );

    const { loaded, single } = metodology;
    
    useEffect( () => {
        dispatch( getSingleMethodology(id) );
    }, []);

    if( !loaded && single === null ){
        return( <Loader />)
    }
    let title = '';
    if( loaded && metodology.single ){
        title = single.title;
    }

    return(
        <div className="innerWrap">
            <Breadcrumbs items={[
                {name:'Адмiнiстративна панель', link: '/admin'},
                {name:'Методика експертного оцінювання', link: '/admin/evaluation/'},
                {name: title, link: '/admin/evaluation/'}
            ]}/>

            <h1 className="page-title"> { title }</h1>

            <Link className="catalog__button" to={`/admin/evaluation/methodology/${id}/new`}> Додати нову групу питань </Link>

            <Table>
                <Row type="head">
                    <Cell type="id">#</Cell>
                    <Cell type="large">Група питань</Cell>
                    <Cell type="large"></Cell>
                </Row>
                {
                    loaded && (
                        <>
                            {
                                groups.data.map( (group, key) => (
                                    <Row key={group._id} className="table__row-withLinks">
                                        <Cell type="id">{key + 1}</Cell>
                                        <Cell type="large" className="row_">
                                            <Link to={`/admin/evaluation/groups/${group._id}`}>
                                                {group.title}
                                            </Link>
                                        </Cell>
                                        <Cell type="large">
                                            <Link to={`/admin/evaluation/groups/${group._id}/edit`}>Редагувати</Link>
                                        </Cell>
                                    </Row>
                                )) 
                            }
                        </>
                    )
                }
            </Table>
        </div>
    );
}


export default MethodologySingle;