
import axios from '../../../helpers/private.axios'



export const validateTemplate = ( application_id, template ) => {

    return axios.post(`/api/documents/templates/${template}/check`, {
        application_id
    });

}