import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Layout  } from '../../../library';

import List from './list';
import Create from './create-rollback';
import SingleRollback from './single-rollback';

import './style.scss';

const RollbackRoot = () => {
    return(
        <Layout className="projects-division">
            <Switch>
                <Route exact path="/admin/rollback" component={List} />
                <Route exact path="/admin/rollback/create" component={Create} />
                <Route exact path="/admin/rollback/:id" component={SingleRollback} />
            </Switch>
        </Layout>
    )
}


export default RollbackRoot;