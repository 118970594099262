import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import { Loader } from '../../../library'
import { Collapse, Panel, PanelItem } from '../../../library/Collapse'
import getFieldValue from '../../../../helpers/getFieldValue';

import { getApplicationTitle } from '../../../../helpers/getApplicationTitle';

const AppInfo = ({ data, loading, fields }) => {
  if (data && !loading) {
    let title = getApplicationTitle(data);
    let keywords = data.data.raw_fields.keywords;
    if( keywords === undefined ){
      keywords = [];
    }
    return (
      <>
        <Collapse className="collapse-experts-main">
          {
            <Panel
              title={'Інформація про заявку і заявника'}
              opened={ true }
            >
              <PanelItem title="Заявник" value={ data.user.name }/>
              <PanelItem title="Назва проекту" value={ title }/>
              <PanelItem title="Дата подачі заявки" value={ moment(data.created_at).format('lll') }/>
              <PanelItem title="Сектор мистецтв" value={ getFieldValue( data.data.raw_fields.cultural_sector, fields.culture.options.options ) }/>
              <PanelItem title="Ключові слова" value={
                getFieldValue( keywords, fields.keywords.options.options, true )
              } />
            </Panel>
          }
        </Collapse>
      </>
    )
  } else {
    return <Loader/>
  }
}

const mapStateToProps = (state) => ({
  fields: state.common.fields,
  data: state.applications.info.data,
  loading: state.applications.info.loading
})

export default connect(mapStateToProps, null)(AppInfo)