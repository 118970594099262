import React from 'react'
import { Link } from 'react-router-dom'


const RestoreSuccess = () => (
  <div className="login-container">
    <p className="login-recovery-title">Залишився ще один крок для завершення відновлення паролю</p>
    <p className="login-recovery-subtitle">Будь ласка, перевiрте поштову скриньку і виконуйте інструкції які описані у листі.</p>
    <p className="login-recovery-title">З повагою адмiнiстрацiя!</p>

    <div className="login-footer">
      <button className="login-footer-info">Український Культурний Фонд</button>
      <Link className="login-footer-recovery" to="/">Форма входу у систему</Link>
    </div>
  </div>
)

export default RestoreSuccess