import axios from '../../helpers/private.axios'
import config from '../../config'
import { notify } from './notify.actions'

export const SEARCH_REQUEST = 'SEARCH_REQUEST'
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS'
export const SEARCH_ERROR = 'SEARCH_ERROR'

export const search = (value) => (dispatch) => {
  dispatch({ type: SEARCH_REQUEST })

  let query = value.trim();


  axios.post(`${config.apiPath}/api/catalogs/search`, { query:query })
  .then( res => {
    dispatch({
      type: SEARCH_SUCCESS,
      payload: res.data.data.applications
    })
    if (!res.data.data.applications.length) {
      dispatch( notify('Вибачте такої заявки не існує', 'error'))
    }
  }).catch( error => {
    dispatch({
      type: SEARCH_SUCCESS,
      payload: error
    })
    dispatch( notify(error.message, 'error'))
  })
}