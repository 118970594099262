import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Cell } from '../../../library/';

import { getApplicationTitle } from '../../../../helpers/getApplicationTitle';

export const AdminHeader = () => (
    <Row type="head">
        <Cell type="small">#</Cell>
        <Cell type="medium">Проект</Cell>
        <Cell type="medium">Проектний відділ</Cell>
        <Cell type="medium">Експертни відділ</Cell>
        <Cell type="medium">Погодження</Cell>
    </Row>
);

export const AdminSearchResults = ({ item }) => {
    let title = getApplicationTitle( item );

    return(
        <Row>
            <Cell type="small">{ item.spec_id }</Cell>
            <Cell type="medium">{ title.length > 75 ? `${title.substr(0, 75)}...` : title }</Cell>
            <Cell type="medium">
                <Link className="nav_link" to={`/pd/application/${item._id}`}>
                    Проектний відділ
                </Link>
            </Cell>
            <Cell type="medium">
                <Link className="nav_link" to={`/ed/application/${item._id}`}>
                    Експертний відділ
                </Link>
            </Cell>
            <Cell type="medium">
                <Link className="nav_link" to={`/rp/application/${item._id}`}>
                    Погодження
                </Link>
            </Cell>
        </Row>
    )

};


