import {
  SEARCH_REQUEST,
  SEARCH_SUCCESS,
  SEARCH_ERROR
} from '../../actions'

const contestsInitialState = {
  data: [],
  loading: false,
  errors: [],
}

const search = ( state = contestsInitialState, action) => {
  switch( action.type ){
    case SEARCH_REQUEST:
      return {
        ...state,
        loading: true
      }
    case SEARCH_SUCCESS:
      return {
        loading: false,
        data: action.payload
      }
    case SEARCH_ERROR:
      return {
        ...state,
        error: action.error,
      }
    default:
      return state
  }
}

export default search