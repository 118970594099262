import axios from '../../helpers/private.axios';

export const GET_UNASSIGNED_USERS_REQ = "GET_UNASSIGNED_USERS_REQ";
export const GET_UNASSIGNED_USERS_RES = "GET_UNASSIGNED_USERS_RES";

export const getAllUnassignedUsers = ( type = "all" ) => (dispatch, getState) => {
    dispatch({ type: GET_UNASSIGNED_USERS_REQ })

    axios.get(`/api/users/unassigned/${type}`)
    .then(
        res => {
            dispatch({
                type: GET_UNASSIGNED_USERS_RES,
                payload: res.data.data.users,
                counter: res.data.data.counter,
                total: res.data.data.total
            });
        }
    )
}


export const getFiltredUnassignedUsers = ( filters ) => ( dispatch ) => {
    dispatch({ type: GET_UNASSIGNED_USERS_REQ })

    axios.post(`/api/users/unassigned`, filters )
    .then(
        res => {
            dispatch({
                type: GET_UNASSIGNED_USERS_RES,
                payload: res.data.data.users,
                counter: res.data.data.counter,
                total: res.data.data.total
            });
        }
    )
}