import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import AppHead from './AppHead'
import { Layout, Loader } from '../../../library'

import AppInfo from './AppInfo'
import AppExperts from './AppExperts'
import ExpertInfo from './ExpertInfo';
// import AppDiscussion from './AppDiscussion';

import MatchingMain from '../Matching/';
import Finish from '../Matching/Finish';

import NegotiationsProcedureList from '../Matching/Negotiation/NegotiationsProcedureList';
import NegotiationSingleProcedure from '../Matching/Negotiation/NegotiationSingleProcedure';
import CreateNegotiation from '../Matching/Negotiation/CreateNegotiation';
import SetEstimate from '../Matching/Negotiation/SetEstimate';

import Direction from '../Matching/Direction';
import Supervisory from '../Matching/Supervisory';

import Agreement from '../Matching/Agreement';
import Reporting from '../Matching/Reporting'
import ReportingProcedure from '../Matching/Reporting/procedure'
import ReportFeedback from '../Matching/Reporting/reportFeedback';

import Act from '../Matching/Act';

import ReportingTopTabs from '../Matching/Negotiation/ReportingTopTabs';

import { getApplicationInfo, clearApplicationInfo } from '../../../../actions';

class ReportApp extends React.Component {
  componentDidMount() {
    this.props.getApplication(this.props.match.params.id)
  }

  componentWillUnmount() {
    this.props.clearApplication()
  }

  render(){
    const { data, breadcrumbs, reviews, loaded, application } = this.props
    
    if( !loaded ){
      return <Loader/>;
    }

    return (
      <>
        <Layout
          className="projects-division"
          paddingTop="20px"
          header={
            <ReportingTopTabs />
          }
        >
            <AppHead
              name={data.user.name}
              spec_id={data.spec_id}
              status={data.tech_review.status}
              created_at={ data.created_at }
              breadcrumbs={breadcrumbs}
              reviews={reviews}
              application={application}
            />
          <Switch>
            <Redirect exact from="/rp/application/:id/" to="/rp/application/:id/info"/>
            <Route exact path="/rp/application/:id/info" component={ AppInfo }/>
            <Route exact path="/rp/application/:id/experts" component={ AppExperts }/>
            <Route exact path="/rp/application/:id/review/:reviewid" component={ ExpertInfo }/>
            <Route exact path="/rp/application/:id/finish" component={Finish} />
            <Route exact path="/rp/application/:id/matching" component={MatchingMain} />
            <Route exact path="/rp/application/:id/procedures" component={NegotiationsProcedureList} />
            <Route exact path="/rp/application/:id/procedures/create" component={CreateNegotiation} />
            <Route exact path="/rp/application/:id/procedures/:procid" component={NegotiationSingleProcedure} />

            <Route exact path="/rp/application/:id/estimate" render={ ( props ) => {
              return( <NegotiationsProcedureList root={false} {...props} />)
            } }/>
            <Route exact path="/rp/application/:id/estimate/:estimateid" component={SetEstimate} />
            <Route exact path="/rp/application/:id/direction" component={Direction} />
            <Route exact path="/rp/application/:id/supervisory" component={Supervisory} />
            <Route path="/rp/application/:id/agreement" component={Agreement} />

            <Route exact path="/rp/application/:id/reporting" component={Reporting} />

            <Route exact path="/rp/application/:id/reporting" component={Reporting} />
            <Route exact path="/rp/application/:id/reporting/:procedure_id" component={ReportingProcedure} />

            {/* <Route exact path="/rp/application/:id/reporting/feedback" component={ReportFeedback} /> */}
            <Route exact path="/rp/application/:id/act" component={Act} />
            
          </Switch>
        </Layout>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  data: state.applications.info.data,
  application: state.applications.info.data,
  breadcrumbs: state.applications.info.breadcrumbs,
  reviews: state.applications.info.reviews,
  loaded: state.applications.info.loaded
})

const mapDispatchToProps = (dispatch) => ({
  getApplication: (id) => {
    dispatch(getApplicationInfo(id))
  },
  clearApplication: () => {
    dispatch(clearApplicationInfo())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ReportApp)