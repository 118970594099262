import {
    GET_AGREEMENT_APPS_REQ,
    GET_AGREEMENT_APPS_RES,
    SET_AGREEMENT_SIGNED
} from '../../actions'

const appsInitialState = {
    error: null,
    apps: [],
    counter: 0,
    loaded: false,
    signed_ids: []
}

const tech_review_reducer = ( state = appsInitialState, action) => {
    switch( action.type ){
        case GET_AGREEMENT_APPS_REQ:
            return {
                ...state,
                loaded: false,
            }

        case GET_AGREEMENT_APPS_RES:
            return {
                ...state,
                apps: action.payload,
                counter: action.counter,
                loaded: true,
            }

        case SET_AGREEMENT_SIGNED:
            return{
                ...state,
                signed_ids: [...state.signed_ids, action.payload ]
            }

        default:
            return state
    }
}

export default tech_review_reducer;