
export const getLabelForValue = ( value, name,  options ) => {
    console.log('[v]', value, '[n]', name, '[o]', options );

    if( !value ){
        return '';
    }

    if( name === "culture"){
        const option = options.find( opt => opt.value === value )
        if( option ){
            return option.name;
        }
    }

    if( name === "program"){
        // getted seasons
        let program = null;
        const prog = options.map( ({ season, programs }) => {
            let finded = programs.find( program => program._id === value );
            if( finded ){
                program = finded;
            }
        });

        if( program ){
            return program.title.uk;
        }

        return 'Програму не знайдено';
    }


    return null;

}