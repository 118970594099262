import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";

import { Breadcrumbs, Loader } from '../../../../library';
import { getSingleGroup, updateGroup } from '../../../../../actions';


const GroupEdit = () => {

    const [ title, changeTitle ] = useState('');
    const [ order, changeOrder ] = useState('');
    const [ slug, changeSlug ] = useState('');

    const dispatch = useDispatch();
    const { id } = useParams();
    const history = useHistory();

    const group = useSelector( state => state.admin.evaluation.single );
    
    let sending = group.create_req;
    
    const loadData = ( data ) => {

        changeTitle( data.title )
        changeOrder( data.order )
        changeSlug( data.slug )
        
    }


    const sendRequest = (e) => {
        e.preventDefault();
        dispatch( 
            updateGroup({
                title,
                order,
                slug
            }, id, history, )
        );
    }

    

    useEffect( () => {
        dispatch( getSingleGroup(id, loadData) );

    }, []);

    if( !group.loaded ){
        return <Loader />;
    }

    const groupTitle = `Редагувати ${group.data.group.title}`;

    return(
        <div className="innerWrap">
            <Breadcrumbs items={[
                {name:'Адмiнiстративна панель', link: '/admin'},
                {name:'Методика експертного оцінювання', link: '/admin/evaluation/groups'},
                {name:'Методологія', link: `/admin/evaluation/methodology/${group.data.group.metodology_id}`},
                {name: groupTitle, link: '/admin/evaluation/groups/new' }
            ]}/>
            <h1>{groupTitle}</h1>
            <form className="form-container" onSubmit={sendRequest}>
                <label className="form-label">
                    <div className="form-label-text">
                        <span>Назва</span>
                    </div>
                    <div className="form-label-input">
                        <input
                            type="text"
                            className="form-input"
                            name="title"
                            onChange={(e) => changeTitle(e.target.value) }
                            value={title}
                            placeholder=""
                        />
                    </div>
                </label>
                <label className="form-label">
                    <div className="form-label-text">
                        <span>Порядок</span>
                    </div>
                    <div className="form-label-input">
                        <input
                            type="number"
                            className="form-input"
                            name="order"
                            onChange={(e) => changeOrder(e.target.value) }
                            value={order}
                            placeholder=""
                        />
                    </div>
                </label>
                <label className="form-label">
                    <div className="form-label-text">
                        <span>Слаг (Латинкою без пробілів)</span>
                    </div>
                    <div className="form-label-input">
                        <input
                            type="text"
                            className="form-input"
                            name="slug"
                            onChange={(e) => changeSlug(e.target.value) }
                            value={slug}
                            placeholder=""
                        />
                    </div>
                </label>
                <div className="form-label">
                    <div className="form-label-text"></div>
                    <div className="form-label-input">
                        <button
                            className={ sending ? "form-button unactive" : "form-button" }
                            type="submit"
                        >
                            {
                                sending ? 'Оновлюється' : 'Оновити!'
                            }

                            {
                                sending &&
                                (
                                <svg className="spinner" viewBox="0 0 50 50">
                                    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                                </svg>
                                )
                            }
                        </button>
                    </div>
                </div>
            </form>

        </div>
    )
}


export default GroupEdit;