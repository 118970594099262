import {
    RegularData,
    OptionsData,
    DateData,
    RadioTextData,
    NumberTextData,
    MultitabData
} from './DataValues';

const DataDictionary = [
    { type: 'text', component: RegularData },
    { type: 'textarea', component: RegularData },
    { type: 'number', component: RegularData },
    { type: 'select', component: OptionsData },
    { type: 'checkbox', component: OptionsData },
    { type: 'radio', component: OptionsData },
    { type: 'date-limits', component: DateData },
    { type: 'multidata-tab', component: MultitabData },
    { type: 'radio_text', component: RadioTextData },
    { type: 'number_text', component: NumberTextData },
];

const getTypeValue = ( type ) => {
    const typeComponent = DataDictionary.find( item => item.type === type );
    if( typeComponent ){
        return typeComponent.component;
    }
    return null;
}

export default getTypeValue;