import React, { Component } from 'react';
import { connect } from 'react-redux';
// import uniqid from 'uniqid';

import { Signer, RoleChecker, DocumentLink } from '../../../../library';


/* <Loader /> */
import { uploadFile, endAct, signAct } from '../../../../../actions';


class Act extends Component {

    state = {
        act: null,
        loaded: false
    }

    handleMainFile = ( e ) => {
        console.log( e.target.files );

        if( e.target.files.length > 0){          
            this.props.uploadFile( e.target.files[0],  'completed_act');
            this.setState({
                main_agreement: e.target.files[0]
            })
        }
    }

    uploadAct = () => {
        this.props.endActAction();
    }


    render = () => {
        const {
            handleMainFile,
            uploadAct
        } = this;
        const { app, user, signAct } = this.props;

        const alreadyUploaded = app.reporting_review.completed_act.status === 1;

        return (
            <div className={ alreadyUploaded ? "project-app-grand-wrap uploaded" : "project-app-grand-wrap"}>
                <div className="project-app-grand">
                    <div className="project-app-grand-title">Акт виконаного проекту</div>
                    {
                        app.reporting_review.completed_act.file && (
                            <>
                                <DocumentLink
                                    text={`Акт виконаного проекту ${app.spec_id}`}
                                    format="pdf"
                                    url={app.reporting_review.completed_act.file}
                                />
                            </>
                        )
                    }

                    {
                        app.reporting_review.completed_act.sign_id === undefined ?
                        (
                            <div className="act_wrapper">
                            {
                                !alreadyUploaded && (
                                    <>
                                    <label className="project-app-grand-file">
                                        Натисніть, щоб обрати файл
                                        <input
                                            type="file" 
                                            name="completed_act"
                                            onChange={handleMainFile}
                                            hidden
                                            accept=".xls, .xlsx, .pdf"
                                            required
                                            tabIndex="1"

                                        />
                                    </label>
                                    </>
                                )
                            }
                            {
                                app.reporting_review.completed_act.file !== undefined && (
                                    <a className="uploaded_link" href={app.reporting_review.completed_act.file} target="_blank" rel="noopener noreferrer">
                                        Завантажити акт
                                    </a>
                                )
                            }
                            </div>
                        ) : (
                            <>
                                <RoleChecker 
                                    allowedRoles={['chief']} 
                                    user={user}
                                    message="Тільки директор може підписати акт"
                                >
                                    <Signer 
                                        ids={[ app.reporting_review.completed_act.sign_id ]}
                                        statusChangerCallback={signAct}
                                    />
                                </RoleChecker>
                            </>
                        )
                    }
                </div>
            </div>
        );
    }
}



const mapStateToProps = (state) => ({
    app: state.applications.info.data,
    loading: state.applications.info.loading,
    user: state.auth.user,
})

const mapDispatchToProps = (dispatch, ownProps ) => ({

    uploadFile: ( file, type, additional_id ) => {
        dispatch( uploadFile( 
            ownProps.match.params.id, 
            file, type, additional_id,
        ));
    },

    endActAction: () => {
        dispatch(
            endAct( ownProps.match.params.id, ownProps.history )
        );
    },

    signAct: () => {
        dispatch(
            signAct(  ownProps.match.params.id, ownProps.history )
        );
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(Act)