import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';

import ExpertAllAppItem from './AllAppItem';

import { Layout, Breadcrumbs, Empty, Loader, Table, Row, Cell } from '../../../library';

import { getAllAppForExpert, getRootSeasons } from '../../../../actions'



const ExpertAllApp = () => {

	const dispatch = useDispatch();

	const { data, loading } = useSelector( state => ({
			data: state.expert.all.data,
			loading: state.expert.all.loading
		})
	);
	const seasons = useSelector( state => state.programs.seasons );

	useEffect( () => {
        dispatch( getAllAppForExpert() );
        dispatch( getRootSeasons() );
    }, [])

	

	if( loading ){
		return (
			<Layout
				className="projects-division"
				title="Заявки, які надійшли на оцінку"
				breadcrumbs={<Breadcrumbs items={[{ name: 'Експертна частина ', link: '/expert' }]}/>}
			>
				<Loader/>
			</Layout>
		);
	}


	return (
		<Layout
		className="projects-division"
		title="Заявки, які надійшли на оцінку"
		breadcrumbs={
			<Breadcrumbs items={[
			{ name: 'Експертна частина ', link: '/expert' }
			]}
		/>}
		>
		
			<Table>
				<Row type="head">
					<Cell type="medium">#</Cell>
					<Cell type="large"> Проект </Cell>
					<Cell type="medium"> Бал </Cell>
					<Cell type="large"> Статус </Cell>
				</Row>

				{
					data && data.length ? 
					seasons.loaded && seasons.data.map( season => { 
						
						const reviews = data.filter( ({ review, application }) => {
							if( application.data.season === season.season._id ){
								return true;
							}
						});

						if( reviews.length === 0 ){
							return null;
						}

						return(
							<div key={season.season._id}>
								<h3>{ season.season.title }</h3>
								{
									reviews.map( item => {
										return <ExpertAllAppItem key={ item._id } item={item}/>
									})
								}
							</div>
						)

					})
					:
					<Empty text="Очікуйте, заявки поки не надійшли"/>
				}

			</Table> 
		
		</Layout>
	);

}

export default ExpertAllApp;