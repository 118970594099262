import React from 'react'
import { connect } from 'react-redux'

import { Loader, StatusHandler } from '../../../../library'

import { changeSupervisoryStatus } from '../../../../../actions'
import moment from 'moment'

class AppReportSingle extends React.Component {
	state = {
		message: '',
		type_id: 1
	}

	handleCheckboxChange = (e) => {
		this.setState({
			type_id: e.target.value
		})
	}

	handleText = (e) => {
		this.setState({
			message: e.target.value
		})
	}

	handleSubmit = (e) => {
		const { message, type_id } = this.state;
		this.props.changeDirStatus( type_id, message );
	}

	render() {
		const { data, loading } = this.props
		const { type_id, message } = this.state;
		const { handleSubmit, handleCheckboxChange, handleText } = this;

		console.log(data)
		if (data && !loading) {
		return (
			<div className="expert-solution-form">
				<header className="negotiation__header">
					<h1 className="expert-solution-form-title">
						Рішення наглядової ради
					</h1>
					<StatusHandler type="reporting.supervisory_board" statusId={data.reporting_review.supervisory_board.status}/>
				</header>
				{
					data.reporting_review.supervisory_board.status === 0 ? (
						<>
							<label className="expert-solution-form-label-wrap">
								<input
									className="expert-solution-form-input"
									type="radio"
									value="1"
									name="type_id"
									checked={type_id === "1" ? true : false }
									onChange={handleCheckboxChange}
								/>
								<span className="expert-solution-form-label">Затверджено</span>
							</label>
							<label className="expert-solution-form-label-wrap">
								<input
									className="expert-solution-form-input"
									type="radio"
									value="-1"
									name="type_id"
									onChange={handleCheckboxChange }
									checked={type_id === "-1" ? true : false }
								/>
								<span className="expert-solution-form-label">Не затверджено</span>
							</label>
                            <label className="expert-solution-form-label-wrap">
								<input
									className="expert-solution-form-input"
									type="radio"
									value="2"
									name="type_id"
									onChange={handleCheckboxChange }
									checked={type_id === "2" ? true : false }
								/>
								<span className="expert-solution-form-label">Не потребує рішення</span>
							</label>

							<div className="expert-solution-form-text">Коментар до рішення (Не обов’язково)</div>
							<textarea
								rows="7"
								className="expert-solution-form-textarea"
								name="message"
								onChange={handleText}
								value={message}
							/>
							<button 
								className="expert-solution-form-submit"
								type="submit"
								onClick={handleSubmit}
							>
								Затвердити рішення наглядової ради
							</button>
						</>
					) : (
						<>
								<div className="negotiation__block">
								<header className="negotiation__blockHeader">
									<h3> 
										Рішення вніс { data.reporting_review.supervisory_board.user.name }
										( { moment( data.reporting_review.supervisory_board.updated_at ).format('lll')})
									</h3>
								</header>
								<p className="negotiation__blockMessage">
									{
										data.reporting_review.supervisory_board.message !== undefined ? data.reporting_review.supervisory_board.message : "Коментаря немає"
									}
								</p>
							</div>
						</>
					)
				}

				
		</div>
		)
		} else {
            return <Loader />
		}
	}
}

const mapStateToProps = (state) => ({
	data: state.applications.info.data,
	loading: state.applications.info.loading
})

const mapDispatchToProps = (dispatch, ownProps ) => ({
	changeDirStatus: ( status, message ) => {
		console.log( ownProps.match.params.id, status, message );
		dispatch( changeSupervisoryStatus( ownProps.match.params.id, status, message, ownProps.history ) )
	}
})

export default connect(mapStateToProps, mapDispatchToProps)(AppReportSingle)