import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Sidebar from '../../common/SideBar';
import Notify from '../../common/Notify';

import ProgramsRoot from '../../common/Programs/ProgramsRoot';
import ProgramsChilds from '../../common/Programs/ProgramsChilds';
import ProgramsLots from '../../common/Programs/ProgramsLots';
import SeasonsRoot from '../../common/Programs/SeasonsRoot';

import ReportContest from './ReportContest';
import ReportApp from './ReportingApp';

import Negotiation from './Matching/Negotiation';
import ReportStats from './Stats';

import DivisionContext from '../../../helpers/DivisionContext';

import './styles/stylex.scss';

const ReportingDivision = () => {
    return(
        <>
            <DivisionContext.Provider
            value={{
                type: 'reporting',
                route: 'rp',
                title: 'Погодження та звітність'
            }}>
                <Sidebar />
                <Switch>
                    <Redirect exact from="/rp" to="/rp/seasons"/>
                    <Route exact path="/rp/seasons/:seasonid" component={ ProgramsRoot }/>
                    <Route exaxt path="/rp/seasons" component={SeasonsRoot}/>

                    <Route exact path="/rp/programs/:progid" component={ ProgramsChilds }/>
                    <Route exact path="/rp/lots/:id" component={ ProgramsLots }/>
                    <Route exact path="/rp/contests/:id" component={ ReportContest }/>
                    <Route path="/rp/application/:id" component={ ReportApp }/>

                    <Route path="/rp/negotiation/:negotiation_id" component={ Negotiation } />
                    <Route path="/rp/stats" component={ReportStats} />
                </Switch>
                <Notify />
            </DivisionContext.Provider>
        </>
    )


}

export default ReportingDivision