import React, { Component } from 'react'
import { connect } from 'react-redux'

import Auth from '../../pages/Auth'
import RoleWrapper from '../RoleWrapper/'
import { getCurrentUser } from '../../../actions'

class AuthWrapper extends Component {

    componentDidMount = () => {
        if( this.props.auth.user !== null ){
            this.props.getUser();
        }
    }

    render = () => {
        const { auth } = this.props;
        if( auth.user !== null){
            return <RoleWrapper />
        } else {
            return <Auth />
        }
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth
})

const mapDispathToProps = ( dispatch ) => ({
    getUser: () => {
        dispatch( getCurrentUser() );
    }
})

export default connect(mapStateToProps, mapDispathToProps)(AuthWrapper);