import axios from '../../../helpers/private.axios'

export const STORE_DOCUMENT_REQ = "STORE_DOCUMENT_REQ";

export const storeTemplate  = ( data, history ) => (dispatch) => {


    // dispatch({ type: STORE_DOCUMENT_REQ });

    axios.post(`/api/documents/templates/store`, data )
        .then ( res => {
            history.push(`/documents/templates`);
        })

}