import React from 'react'

import logoutIcon from './icons/exit.svg'

const User = ({name, logout}) => (
  <div className="sidebar-btn">
    {name}
    <span className="icon" onClick={logout}>
      <img src={logoutIcon} alt="logout"/>
    </span>
  </div>
)

export default User