import {
	GET_METHODOLOGY_REQ,
	GET_METHODOLOGY_RES,
    GET_METHODOLOGY_ERR,
    GET_SINGE_METHODOLOGY_REQ,
    GET_SINGE_METHODOLOGY_RES
} from '../../../actions';

const usersInitialState = {
    errors: [],
    loading: false,
    loaded: false,
	data: [],

	create_req: false,
	single: null
}

const groups_reducer = ( state = usersInitialState, action) => {
    switch( action.type ){
		case GET_METHODOLOGY_REQ:
			return {
				...state,
				loaded: false,
				loading: true
			}

		case GET_METHODOLOGY_RES:
			return {
				...state,
				loading: false,
				loaded: true,
				data: action.payload
			}

		case GET_METHODOLOGY_ERR:
			return {
				...state,
				error: action.errors
			}

        case GET_SINGE_METHODOLOGY_REQ:
            return {
                ...state,
                loading: false
            }
        
        case GET_SINGE_METHODOLOGY_RES:
            return {
                ...state,
                loading: false,
                loaded: true,
                single: action.payload
            }

		default:
			return state
		}
}

export default groups_reducer;