import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import { Table, Row, Cell } from '../../library/Table'
import { Layout, Breadcrumbs, Loader } from '../../library'
import DivisionContext from '../../../helpers/DivisionContext'
import { AppliationProjectCount, ApplicationsExpertCount } from './ApplicationsCounters';

import { getLotsData, clearLotsData } from '../../../actions'

import folderIcon from './icons/folder.svg'





class ProgramsLots extends React.Component {
  componentDidMount() {
    this.props.getLots(this.props.match.params.id)
  }
  componentWillUnmount() {
    this.props.clearLots()
  }
  render() {
    const { data, loading } = this.props

    return (
      <DivisionContext.Consumer>
      {
        division => (
          <Layout
          className="projects-division"
          title={
            data && `${data.lot.title}`
          }
          breadcrumbs={
            data && <Breadcrumbs items={[
              {name: division.title, link: `/${division.route}/programs` },
              {name: data.lot.program.title, link: `/${division.route}/programs/${data.lot.program._id}` },
              {name: data.lot.title, link: `/${division.route}/programs` }
            ]}/>
          }
        >
          { console.log('div', division )}
          <Table>
            <Row type="head">
              <Cell type="id">#</Cell>
              <Cell type="large">Назва конкурсу</Cell>
              <Cell type="medium">Опрацьовано заявок</Cell>
              <Cell type="medium">Дедлайни Конкурcу</Cell>
            </Row>
            {
              data && !loading ? data.contests.map(
                ( item, key ) => (
                  <Row
                    key={ key }
                    type="link"
                    to={ division.type !== 'experts' ?
                          `/${division.route}/contests/${item._id}` :
                          `/${division.route}/contests/${item._id}`
                      }
                  >
                    <Cell type="folder">
                      <img src={folderIcon} alt="Категорія з лотами" />
                    </Cell>
                    <Cell type="large">{ key + 1 }.{item.title}</Cell>
                    <Cell type="medium">
                      { division.type === "projects" && <AppliationProjectCount apps={item.apps} /> }
                      { division.type === "experts" && <ApplicationsExpertCount apps={item.apps} /> }
                      
                    </Cell>
                    <Cell type="medium">
                      {
                        moment( item.deadlines.realization.start ).format('ll')
                      } - {
                        moment( item.deadlines.realization.end ).format('ll')
                      }
                    </Cell>
                  </Row>
                )
              ) : <Loader/>
            }
          </Table>
        </Layout>
        )
      }
      </DivisionContext.Consumer>
    )
  }
}

const mapStateToProps = (state) => ({
  data: state.programs.lots.data,
  loading: state.programs.lots.loading
})

const mapDispatchToProps = (dispatch) => ({
  getLots: (id) => {
    dispatch( getLotsData(id) )
  },
  clearLots: () => {
    dispatch( clearLotsData() )
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ProgramsLots)