export const getPath = (role) => {
  switch (role) {
    case('admin'):
      return '/admin'
    case('financier'):
      return '/admin'
    case('expert'):
      return '/expert'
    case('project_manager'):
      return '/pd'
    case('expert_manager'):
      return '/ed'
    default:
      return '/'
  }
}