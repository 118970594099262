import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { getSignerList } from '../../../actions';
import './style.scss';

export const SignerList = ({ sign_id }) => {
    const dispatch = useDispatch();
    const signer = useSelector(state => state.common.signer);

    console.log('signer', signer )

    useEffect( () => {
        dispatch( getSignerList(sign_id) );
    }, [] );


    if( !signer.loaded ){
        return null;
    }

    const selection_unique_key = 'FullName';
    const unique_signers = [...new Map( signer.data.map(item => [ item[ selection_unique_key ], item ]) ).values() ];
    console.log('[unique_signers]', unique_signers );

    return(
        <div className="signerList">
            <header>
                <h3 className="signerList__title"> Хто підписав протокол:</h3> 
            </header>
            <ul className="signerList__list">
                {
                    unique_signers.map( signer => {
                        if( signer.FullName.trim().length !== 0 ){
                            return(
                                <li key={signer.Thumbprint}>
                                    {signer.FullName}
                                    <span className="signerList__date">
                                        ({ moment( signer.SignDateTime ).format('lll') })
                                    </span>
                                </li>
                            )
                        }
                    })
                }
            </ul>
        </div>
    )
}