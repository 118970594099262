import { combineReducers } from 'redux'

import reg_lots from './reg_lots.reducer'
import evaluation from './evaluation';

const admin = combineReducers({
    reg_lots,
    evaluation
})

export default admin;