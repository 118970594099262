import privateAxios from '../../helpers/private.axios'
import config from '../../config'

export const GET_ROOT_PROGRAMS_REQ ='GET_ROOT_PROGRAMS_REQ'
export const GET_ROOT_PROGRAMS_RES ='GET_ROOT_PROGRAMS_RES'
export const GET_ROOT_PROGRAMS_ERR ='GET_ROOT_PROGRAMS_ERR'

export const CLEAR_ROOT_PROGRAMS ='CLEAR_ROOT_PROGRAMS'


export const getAllPrograms = () => dispatch => {

  dispatch({ type: GET_ROOT_PROGRAMS_REQ });

  privateAxios.get(`/api/catalogs/programs`)
    .then( res => {
        dispatch({
          type: GET_ROOT_PROGRAMS_RES,
          payload: res.data.data
        });
    })
    .catch( err => {
      dispatch({
        type: GET_ROOT_PROGRAMS_ERR,
        payload: err
      })
    })

};


export const getRootPrograms = ( season_id ) => (dispatch) => {
  dispatch({ type: GET_ROOT_PROGRAMS_REQ })
  privateAxios(`${config.apiPath}/api/catalogs/seasons/${season_id}`)
  .then( res => {
    dispatch({
      type: GET_ROOT_PROGRAMS_RES,
      payload: res.data.data
    })
  })
  .catch( err => {
    dispatch({
      type: GET_ROOT_PROGRAMS_ERR,
      payload: err
    })
  })
}

export const clearRootPrograms = () => (dispatch) => {
  dispatch({ type: CLEAR_ROOT_PROGRAMS })
}