import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Table, Row, Cell } from '../../../../library/Table'
import { Loader, Breadcrumbs, Empty } from '../../../../library'
import Confirm from '../../../../common/ConfirmAction'

import { getSingleCouncil, deleteCouncil } from '../../../../../actions'

class SingleCouncil extends Component {
  componentDidMount(){
    this.props.getCouncil( this.props.match.params.councilid )
  }

  render(){
    const { loading, data, users } = this.props

    if( loading && !data ){
      return( <Loader/> )
    } else {
      return(
        <div className="innerWrap">
           <Breadcrumbs items={[
            {name: 'Адмiн панель', link: '/admin'},
            {name: 'Користувачі', link: '/admin/users'},
            {name: 'Список рад', link: '/admin/users/council'},
            {name: data.title, link: `/admin/users/council/${data._id}`}
          ]}/>
          <header className="page-header">
            <h1 className="page-title">{data.title}</h1>

            <Confirm
              title="Видалити експертну раду"
              message="Ви впевнені, що хочете видалити цю експертну раду? Усі користувачі які належать до цієї ради будуть від неї відкріплені."
              closeMessage="Повернутись назад"
              redirect="/admin/users/council"
            >
              <button className="button button-decline" onClick={this.props.deleteSingleCouncil(data._id)}>Видалити раду</button>
            </Confirm>

          </header>

          <Table>
            <Row type="head">
              <Cell type="large">Ім'я експерта</Cell>
              <Cell type="large">Кількість заявок на обробці експерта</Cell>
            </Row>
            {
              users && users.length !== 0 ?
                (
                  users.map( user => (
                    <Row key={ user._id }>
                      <Cell type="large">{user.name}</Cell>
                      {/* Кількість заявок на обробці експерта */}
                      <Cell type="large">123</Cell>
                    </Row>
                  ))
                ) :
                (
                  <Empty text="У раді ще немає експертів"/>
                )
            }
          </Table>

        </div>
      )
    }
  }
}

const mapStateToProps = (state) => ({
  data: state.users.singleCouncil.data,
  loading: state.users.singleCouncil.loading
})

const mapDispatchToProps = (dispatch) => ({
  getCouncil: (id) => {
    dispatch( getSingleCouncil(id) )
  },
  deleteSingleCouncil: (id) => (e) => {
    dispatch( deleteCouncil(id) )
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(SingleCouncil)
