import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Row, Cell, Loader } from '../../../library';

import { getAllUnassignedUsers } from '../../../../actions';

const UserRow = React.memo(({ user, handler, selected }) => {

    const checked = selected.some( user_id => user_id === user._id );

    return(
        <Row key={user._id}>
            <label className="direction__row">
                <Cell className="id">
                    <input 
                        type="checkbox"
                        value={user._id}
                        onChange={handler}
                        checked={checked}
                    />
                </Cell>
                <Cell className="large">{ user.name }</Cell>
                <Cell className="small">{ user.vote_rank }</Cell>
            </label>
        </Row>
    );
});


const Users = React.memo(({ data, handler, type, blockName = "Нерозподілені експерти" }) => {
    const dispatch = useDispatch();

    const [ search, setSearch ] = useState('');
    const [ filtredUsers, setFiltred ] = useState([]);


    const selectedUsers = data;
    const setSelected = handler;

    const loaded = useSelector( state => state.users.unassigned.loaded );
    let users = useSelector( state => state.users.unassigned.data );
    const counter = useSelector( state => state.users.unassigned.counter );

    useEffect( () => {
        dispatch( getAllUnassignedUsers( type ) );
    }, []);


    const handleSearch = (e) => {
        let value = e.target.value.toLowerCase();
        const filtred = users.filter( user => {
            return user.name.toLowerCase().indexOf( value ) !== -1;
        });
        setSearch( value );
        setFiltred( filtred );
    }
    
    const checkUser = (e) => {
        let value = e.target.value;
        const isChecked = selectedUsers.some( user_id => user_id === value );   
        console.log( isChecked ); 
        if( isChecked ){
            setSelected( selectedUsers.filter( user_id => user_id !== value ) );
        } else {
            setSelected( [...selectedUsers, value ]);
        }
    } 

    let u_map = users;
    let checked_users = users.filter( user => {
        return selectedUsers.some( user_id => user_id === user._id );
    });

    if( filtredUsers.length !== 0){
        u_map = filtredUsers;
    }

    return(
        <div className="direction__wrapper users">
            <h2 className="direction__subtitle"> {blockName} </h2>
            <input className="direction__search" placeholder="Введіть ім'я для пошуку" value={search} onChange={handleSearch}/>
            
            <div className="direction__container direction__container-inner">
                <div className="direction__block users">
                    {
                        loaded && (
                            <Table>
                                {
                                    u_map.map( user => <UserRow key={user._id} user={user} handler={checkUser} selected={selectedUsers} />)
                                }
                            </Table>
                        )
                    }
                </div>
                {
                    checked_users.length > 0 && (
                        <div className="direction__block users selected">
                            <h2 className="direction__subtitle"> Обрані {selectedUsers.length} </h2>
                            <Table>
                                {
                                    checked_users.map( user => <UserRow key={user._id} user={user} handler={checkUser} selected={selectedUsers} />)
                                }
                            </Table>
                        </div>  
                    )
                }
            </div>
            
        </div>
    )

});


export default Users;