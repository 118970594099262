import {
  GET_EXPERT_CONTESTS_RES,
  GET_EXPERT_CONTESTS_REQ,
  GET_EXPERT_CONTESTS_ERR,

  CLEAR_EXPERT_CONTESTS,
} from '../../actions'

const usersInitialState = {
  error: null,
  loading: false,
  loaded: false,
  data: null
}

const contests = ( state = usersInitialState, action) => {
  switch( action.type ){
    case GET_EXPERT_CONTESTS_REQ:
      return {
        ...state,
        loading: true,
      }
    case GET_EXPERT_CONTESTS_RES:
      return {
        ...state,
        data: action.payload,
        loaded: true,
        loading: false
      }
    case GET_EXPERT_CONTESTS_ERR:
      return {
        ...state,
        error: action.payload
      }
    case CLEAR_EXPERT_CONTESTS:
      return {
        state
      }
    default:
      return state
  }
}

export default contests