import axios from '../../helpers/private.axios';
import { notify } from '../common/notify.actions';

export const GET_APPLICATION_REVIEWS_REQ = "GET_APPLICATION_REVIEWS_REQ";
export const GET_APPLICATION_REVIEWS_RES = "GET_APPLICATION_REVIEWS_RES";


export const getAllApplicationReviews = ( application_id ) => (dispatch, getState) => {
    dispatch({ type: GET_APPLICATION_REVIEWS_REQ })

    axios.get(`/api/application/${application_id}/reviews`)
    .then(
        res => {
            dispatch({
                type: GET_APPLICATION_REVIEWS_RES,
                payload: res.data.data.reviews
            });
        }
    )

}
