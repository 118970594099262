import React, { Component } from 'react'
import { connect } from 'react-redux'

import ExpertInfoItem from '../../ExpertsDivision/ExpertsApp/ExpertInfoItem';
import { Loader, StatusHandler } from '../../../library';
import { Collapse, Panel } from '../../../library/Collapse';

import { getApplicationInfo, clearApplicationInfo, getFeedbacks, sendReviewToRework, approveValidation, removeExpertFromApp } from '../../../../actions'


class ExpertInfo extends Component {

	state = {
		reviewerMessage: '',
		loaded: false
	}

	static getDerivedStateFromProps = ( props, state ) => {
		console.log('s', state, 'p', props);
		if( props.feedbacks.data !== null && state.loaded === false){
			const message = props.feedbacks.data.review.reviewer_message;
			if( message !== ''){
				return {
					...state,
					reviewerMessage: message,
					loaded: true
				}
			}
		}
		return null;
	}


	componentDidMount = () => {
		this.props.getFeedbacksReq();
	}

	handleChange = (e) => {
		this.setState({
			reviewerMessage: e.target.value
		});
	}

	sendToRework = () => {
		this.props.sendToRework( this.state.reviewerMessage );
	}

	approveAppReq = () => {
		this.props.approveValidation( this.state.reviewerMessage );
	}

	removeExpert = () => {
		this.props.removeExpertFromApp();
	}


	render = () => {
		const { reviewerMessage } = this.state;
		const { data, loading, feedbacks } = this.props;
		if (data && !loading && feedbacks.loaded ) {
			return (
				<>
					<header className="expert_info_header">
						<h1> Відгук експерта: { feedbacks.data.review.expert.name} ( { feedbacks.data.review.expert.email} ) <StatusHandler type="expert_review" statusId={feedbacks.data.review.status.id} /></h1>
					</header>
					<div className="text">
						{feedbacks.data.review.expert_message}
					</div>
                    <br/>
					<b className="text" >Коментар експертного відділу:</b>
                    <p className="text" >
                        {reviewerMessage}
                    </p>

					{
						feedbacks.data.feedbacks.map( feedback => {
							const maxPerItem = Number( (feedback.max_score / feedback.questions.length).toFixed() );
							const score = feedback.questions.reduce( ( sum, item ) => {
								return sum + item.score;
							}, 0 );

							return (
							<Collapse className="collapse-experts-main" key={feedback._id}>
								<Panel
									title={ feedback.title }
									opened={ true }
								>
									{ 
										feedback.questions.map( (question, key) => (
											<ExpertInfoItem
												key={key}
												question={question}
												title={ `${question.title} ( ${score}/${feedback.max_score} )`}
												rate={question.score}
												max={maxPerItem}
											/>
										))
									}
								</Panel>
							</Collapse>
							)}
						)
					}
				</>
			)
		} else {
			return <Loader/>
		}
	}
}

const mapStateToProps = (state) => ({
	data: state.applications.info.data,
	loading: state.applications.info.loading,
	feedbacks: state.expert.feedbacks
})

const mapDispatchToProps = (dispatch, ownProps ) => ({
	getApplication: (id) => {
		dispatch(getApplicationInfo(id))
	},
	clearApplication: () => {
		dispatch(clearApplicationInfo())
	},
	getFeedbacksReq: () => {
		dispatch( getFeedbacks( ownProps.match.params.reviewid ) );
	},
	sendToRework: (message) => {
		dispatch( sendReviewToRework( message, ownProps.match.params.reviewid ) )	
	},
	approveValidation: (message) => {
		dispatch( approveValidation( message, ownProps.match.params.reviewid ) )	
	},
	removeExpertFromApp: () => {
		dispatch( removeExpertFromApp( ownProps.match.params.reviewid ) );
	}
})

export default connect(mapStateToProps, mapDispatchToProps)(ExpertInfo)