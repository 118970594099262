import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import { Table, Row, Cell } from '../../library/Table'
import { Layout, Breadcrumbs, Loader } from '../../library'
import DivisionContext from '../../../helpers/DivisionContext'

import { getRootPrograms, clearRootPrograms } from '../../../actions'
import folderIcon from './icons/folder.svg'

class ProgramsRoot extends Component {
  componentDidMount() {
    const { seasonid } = this.props.match.params;

    this.props.getPrograms( seasonid );
  }

  componentWillUnmount() {
    this.props.clearPrograms()
  }

  render() {
    const { loading, data } = this.props

    return (
      <DivisionContext.Consumer>
        {
          division => (
            <Layout
              className="projects-division"
              title={division.title}
              breadcrumbs={
                <Breadcrumbs items={[
                  {name: 'Сезони', link: `/${division.route}/seasons` },
                  {name: 'Програми', link: `/${division.route}/programs` }
                ]}/>
              }
            >
              <Table>
                <Row type="head">
                  <Cell type="id">#</Cell>
                  <Cell type="large">{ division.title }</Cell>
                  <Cell type="small">Префiкс</Cell>
                  <Cell type="medium">Дедлайни програми</Cell>
                </Row>
                {
                  data && !loading ? data.map(( item, key ) => (
                    <Row
                      key={key}
                      type="link"
                      to={`/${division.route}/programs/${item._id}`}
                    >
                      <Cell type="folder">
                        <img src={folderIcon} alt="Категорія з лотами" />
                      </Cell>
                      <Cell type="large">{ key + 1 }. {item.title}</Cell>
                      <Cell type="small">{item.prefix}</Cell>
                      <Cell type="medium">
                        {
                          moment(item.title.program_start).format('ll')
                        } - {
                          moment(item.title.program_end).format('ll')
                        }
                        </Cell>
                    </Row>
                  )) : <Loader/>
                }
              </Table>
            </Layout>
          )
        }
      </DivisionContext.Consumer>
    )
  }
}

const mapStateToProps = (state) => ({
  data: state.programs.root.data,
  loading: state.programs.root.loading
})

const mapDispatchToProps = (dispatch) => ({
  getPrograms: ( seasonid ) => {
    dispatch(getRootPrograms( seasonid ))
  },
  clearPrograms: () => {
    dispatch(clearRootPrograms())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ProgramsRoot)