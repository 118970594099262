import {
  GET_LOTS_REGISTER_RES,
  GET_LOTS_REGISTER_REQ,
  GET_LOTS_REGISTER_ERR,

  SWITCH_LOTS_REGISTER_RES,
  SWITCH_LOTS_REGISTER_REQ,
  SWITCH_LOTS_REGISTER_ERR,

  CLEAR_LOTS_REGISTER
} from '../../actions'

const usersInitialState = {
  error: null,
  loading: false,
  switching: false,
  loaded: false,
  data: null,
  toggleData: null
}

const reg_lots = ( state = usersInitialState, action) => {
  switch( action.type ){
    case GET_LOTS_REGISTER_REQ:
      return {
        ...state,
        loading: true,
      }
    case GET_LOTS_REGISTER_RES:
      return {
        ...state,
        data: action.payload,
        loaded: true,
        loading: false
      }
    case GET_LOTS_REGISTER_ERR:
      return {
        ...state,
        error: action.payload
      }
    case SWITCH_LOTS_REGISTER_REQ:
      return {
        ...state,
        switching: true,
      }
    case SWITCH_LOTS_REGISTER_RES:
      return {
        ...state,
        toggleData: action.payload,
        switching: false
      }
    case SWITCH_LOTS_REGISTER_ERR:
      return {
        ...state,
        error: action.payload
      }
    case CLEAR_LOTS_REGISTER:
      return {
        state
      }
    default:
      return state
  }
}

export default reg_lots