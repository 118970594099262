import React from 'react'
import { connect } from 'react-redux'

import { Loader, DataSort, DocumentLink, ErrorBoundary } from '../../../library'
import DocumentsList from '../../../common/DocumentList';
import { Collapse, Panel } from '../../../library/Collapse';

import { getApplicationInfo, clearApplicationInfo } from '../../../../actions'

const AppInfo = ({ data, documents, loading, breadcrumbs }) => {
	if( !loading && data ){
		return (
			<>
			<Collapse>
				<Panel title="Документи" opened="true">
					<DocumentsList documents={documents} />
					{
					data.tech_review.appeal_file && (
						<DocumentLink 
							url={data.tech_review.appeal_file}
							icon="pdf"
							text="Звернення заявника"
						/>
					)
					}
				</Panel>
				{
					data.data.fields.map( (group, key) => {
					if( group !== null ){
						return(
							<Panel
							key={key}
							title={group.title}
							opened={ key === 0 }
							>
							{
								group.fields.map( (subfield, subkey) => {
								return (
									<ErrorBoundary key={subkey} >
									<DataSort subfields={group.subfields} {...subfield}/>
									</ErrorBoundary>
								)
								})
							}
							</Panel>
						)
						} else { return null }
					})
				}
			</Collapse>
			</>
		)
	}

	return(
		<Loader />
	);
}


const mapStateToProps = (state) => ({
	data: state.applications.info.data,
	documents: state.applications.info.documents,
	loading: state.applications.info.loading,
	breadcrumbs: state.applications.info.breadcrumbs,
})

const mapDispatchToProps = (dispatch) => ({
	getApplication: (id) => {
		dispatch(getApplicationInfo(id))
	},
	clearApplication: () => {
		dispatch(clearApplicationInfo())
	}
})

export default connect(mapStateToProps, mapDispatchToProps)(AppInfo)