import {
    QUESTION_REQ,
    QUESTION_RES,
	QUESTION_ERR,
	CLEAR_QUESTION
} from '../../../actions';

const usersInitialState = {
    errors: [],
    loading: false,
    loaded: false,
	data: null,
	sending: false
}

const single_reducer = ( state = usersInitialState, action) => {
    switch( action.type ){

		case CLEAR_QUESTION: 
			return usersInitialState

		case QUESTION_REQ:
			return {
				...state,
				loaded: false,
				loading: true
			}

		case QUESTION_RES:
			return {
				...state,
				loading: false,
				loaded: true,
				data: action.payload
			}

		case QUESTION_ERR:
			return {
				...state,
				error: action.errors
			}

		default:
			return state
		}
}

export default single_reducer;