import React, { Component } from 'react'

import { connect } from 'react-redux';
import { Breadcrumbs, Loader } from '../../../../library';
import { getAllFields, getSingleGroup, createQuestion, getQuestion, updateQuestion, clearQuestion } from '../../../../../actions';


class CreateQuestion extends Component{

    state = {
        data :{
            title: "",
            order: 1,
            name: "",
            min: 1,
            max: 5,
            question_group: '',
            application_questions: []
        },
        search_query: '',
        search_res: [],
        limit: 20,
        loaded: false
    }

    static getDerivedStateFromProps = ( props, state ) => {
        if( props.match.params.questionid !== undefined && props.question.loaded ){
            // load data to state

            if( state.loaded === false){
                const { title, order, name, min, max, question_group, application_questions} = props.question.data;
                return {
                    data: {
                        title,
                        order,
                        name,
                        min,
                        max,
                        question_group,
                        application_questions
                    },
                    loaded: true
                }
            } else {
                return null;
            }

        } else {
            return null;
        }
    }

    componentWillUnmount = () => {
        this.props.clearQustion();
    }
    componentDidMount = () => {
        if( this.props.match.params.questionid !== undefined){
            this.props.getQuestionReq();
        }

        this.props.getGroup();
        this.props.getFields();
    }

    handleChange = (e) => {
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                [e.target.name]: e.target.value
            }
        });
    }

    sendRequest = (e) => {
        e.preventDefault();

        if( this.props.match.params.questionid !== undefined){
            this.props.updateQuestionReq( this.state.data );
        } else {
            this.props.createQuestionReq( this.state.data );
        }



    }

    handleSearch = (e) => {
        const rawQuery = e.target.value;
        const query = rawQuery.toLowerCase();


        const search_res = this.props.fields.all.fields.filter( field => {
            
            console.log('[search]', query, field.label.toLowerCase(), field.label.toLowerCase().indexOf( query ) !== -1 )
            return field.label.toLowerCase().indexOf( query ) !== -1 
        });


        this.setState({
            search_query: rawQuery,
            search_res
        })
    }

    changeFieldHandler = ( status, field ) => e => {
        const { application_questions } = this.state.data;
        let newSelected;

        if( status ){
            newSelected = [...application_questions, field._id ];
        } else {
            newSelected = application_questions.filter( old_selected => old_selected !== field._id );
        }


        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                application_questions: newSelected
            }
        })
    }


    clearSearch = () => {
        this.setState({
            search_res: []
        })
    }

    showAllFields = () => {
        let limit;

        if( this.state.limit === 20){
            limit = this.props.fields.all.count
        } else {
            limit = 20;
        }
        this.setState({ limit });

    }


    render = () => {
        const { sendRequest, handleChange, handleSearch, clearSearch, changeFieldHandler, showAllFields } = this;
        const { search_query, search_res, limit } = this.state;
        const { title, order, name, min, max, application_questions } = this.state.data;
        const { fields, group } = this.props;

        if( !group.loaded && !fields.all_loaded){
            return <Loader />;
        }


        const selectedFields = fields.all.fields.filter( field => application_questions.some( selected_id => selected_id === field._id ));

        let renderFields = [];
        if( search_res.length !== 0){
            renderFields = search_res;
        } else {
            renderFields = fields.all.fields;
        }


        return(

            <div className="innerWrap">
                <Breadcrumbs items={[
                    {name:'Адмiнiстративна панель', link: '/admin'},
                    {name:'Методика експертного оцінювання', link: '/admin/evaluation/groups'},
                    {name: group.data.group.title, link: `/admin/evaluation/groups/${group.data.group._id}`},
                    {name: "Нове питання", link: `/admin/evaluation/groups/${group.data.group._id}/create`}
                ]}/>
                <h1 className="page-title"> Нове питання </h1>
                <form className="form-container" onSubmit={sendRequest}>
                    <label className="form-label">
                        <div className="form-label-text">
                            <span>Питання експерту</span>
                        </div>
                        <div className="form-label-input">
                            <textarea
                                className="form-input"
                                name="title"
                                onChange={handleChange}
                                value={title}
                                placeholder=""
                                rows="10"
                                required
                            />
                        </div>
                    </label>
                    <label className="form-label">
                        <div className="form-label-text">
                            <span>Порядок</span>
                        </div>
                        <div className="form-label-input">
                            <input
                                type="number"
                                className="form-input"
                                name="order"
                                onChange={handleChange}
                                value={order}
                                placeholder=""
                                required
                            />
                        </div>
                    </label>

                    <label className="form-label">
                        <div className="form-label-text">
                            <span>Коротка назва (Латинкою без пробілів)</span>
                        </div>
                        <div className="form-label-input">
                            <input
                                type="text"
                                className="form-input"
                                name="name"
                                onChange={handleChange}
                                value={name}
                                placeholder=""
                                required
                            />
                        </div>
                    </label>

                    <div className="form-row double">
                        <label className="form-label">
                            <div className="form-label-text">
                                <span>Мінімальний бал</span>
                            </div>
                            <div className="form-label-input">
                                <input
                                    type="text"
                                    className="form-input"
                                    name="min"
                                    onChange={handleChange}
                                    value={min}
                                    placeholder=""
                                    required
                                />
                            </div>
                        </label>
                        <label className="form-label">
                            <div className="form-label-text">
                                <span>Максимальний бал</span>
                            </div>
                            <div className="form-label-input">
                                <input
                                    type="text"
                                    className="form-input"
                                    name="max"
                                    onChange={handleChange}
                                    value={max}
                                    placeholder=""
                                    required
                                />
                            </div>
                        </label>
                    </div>

                    <div className="form-label">
                            <div className="form-label-text">
                                <span>Питання з заявки</span>
                            </div>
                            <div className="form-label-input multi">
                                <div className="tags__container">
                                    {
                                        selectedFields.map( selected => (
                                            <label key={selected._id} className="tags__item" htmlFor="">
                                                {selected.label}
                                            </label>
                                        ))
                                    }
                                </div>

                                <div className="search__container">
                                    <input
                                        type="text"
                                        className="form-input"
                                        onChange={handleSearch}
                                        value={search_query}
                                        placeholder="Введіть назву питання для пошуку"
                                    />
                                    <button className="clear__search" onClick={clearSearch} type="button"> </button>
                                </div>

                                <div className={ limit === 20 ? "fields__container" : "fields__container full"}>
                                    {
                                        renderFields.map( (field, key) => {
                                            const isChecked = application_questions.some( selected => selected === field._id );
                                            if( key > limit ){
                                                return null;
                                            } else {
                                                return (
                                                    <label className="fields__item" key={field._id}>
                                                        <input
                                                            className="fields__input"
                                                            type="checkbox"
                                                            value={ field.name }
                                                            checked={isChecked}
                                                            name="application_questions"
                                                            onChange={ changeFieldHandler( !isChecked, field ) }
                                                        />
                                                        <span>
                                                            { field.label } 
                                                            {
                                                                field.admin_comment && (<span style={{ paddingLeft: '4px'}} className="text-gray">({field.admin_comment})</span>)
                                                            }
                                                        </span>
                                                    </label>
                                                    );
                                            }
                                        })
                                    }

                                </div>
                                <button onClick={showAllFields} type="button" className="fields__showAll">
                                    {
                                        limit === 20 ? `Показати усі поля (${fields.all.count})` : 'Сховати усі поля'
                                    }
                                </button>
                            </div>

                    </div>
                    <div className="form-label">
                        <div className="form-label-text"></div>
                        <div className="form-label-input">
                            <button
                                className={ group.create_req ? "form-button unactive" : "form-button" }
                                type="submit"
                            >
                                {
                                    // group.create_req ? 'Створюється' : 'Створити!'
                                }

                                { this.props.match.params.questionid !== undefined ? 'Оновити' : 'Створити'}

                                {
                                    group.create_req &&
                                    (
                                    <svg className="spinner" viewBox="0 0 50 50">
                                        <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                                    </svg>
                                    )
                                }
                            </button>
                        </div>
                    </div>
                </form>


            </div>
        )

    }
}

const mapStateToProps = (state) => ({
    group: state.admin.evaluation.single,
    fields: state.common.fields,
    question: state.admin.evaluation.question
})



const mapDispatchToProps = (dispatch, ownProps) => ({
    getQuestionReq: () => {
        dispatch( getQuestion( ownProps.match.params.questionid ) );
    },
    getFields: () => {
        dispatch( getAllFields() );
    },
    getGroup: () => {
        dispatch( getSingleGroup( ownProps.match.params.id ) );
    },
    createQuestionReq: ( data ) => {
        dispatch( createQuestion(data, ownProps.match.params.id, ownProps.history ) );
    },
    updateQuestionReq: (data) => {
        dispatch( updateQuestion(data, ownProps.match.params.questionid, ownProps.history  ))
    },
    clearQustion: () => {
        dispatch( clearQuestion() );
    }
})




export default connect(mapStateToProps, mapDispatchToProps)(CreateQuestion);