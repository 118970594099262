import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Loader, Breadcrumbs, Table, Row, Cell } from '../../../../library';

import { getSingleGroup }  from '../../../../../actions';

class ShowGroup extends Component {

    componentDidMount = () => {
        this.props.getGroup();
    }

    render = () => {
        console.log( this.props );
        const { loaded, data } = this.props.single;
        
        if( !loaded ){
            return <Loader />;
        }
        const { group, questions } = data;


        return(
            <div className="innerWrap">
                <Breadcrumbs items={[
                    {name:'Адмiнiстративна панель', link: '/admin'},
                    {name:'Методика експертного оцінювання', link: '/admin/evaluation/groups'},
                    {name: group.title, link: `/admin/evaluation/groups/${group._id}`}
                ]}/>
                <h1 className="page-title"> {group.title} </h1>
                <Link className="catalog__button" to={`/admin/evaluation/groups/${group._id}/create`}> Додати питання </Link>
                <Table>
                    <Row type="head">
                        <Cell type="id">#</Cell>
                        <Cell type="large">Група питань</Cell>
                        <Cell type="large">Загальна кільість балів</Cell>

                    </Row>
                    {
                        questions.map( question => (
                            <Row 
                                type="link" 
                                to={ `/admin/evaluation/groups/${group._id}/question/${question._id}`}
                                key={question._id} 
                                className="table__row-withLinks"
                            >
                                <Cell type="id">{question.order}</Cell>
                                <Cell type="large">
                                    <span title={question.title}>
                                        {
                                            question.title.length > 150 ? `${question.title.substr(0, 150)}...` :  question.title
                                        }
                                    </span>
                                </Cell>
                                <Cell type="large">{question.max}</Cell>
                            </Row>
                        )) 
                    }
                </Table>
            </div>
        )
    }

}

const mapStateToProps = (state) => ({
    single: state.admin.evaluation.single
})

const mapDispatchToProps = ( dispatch, ownProps ) => ({
    getGroup: () => {
        dispatch( getSingleGroup( ownProps.match.params.id ) );
    }
})


export default connect(mapStateToProps, mapDispatchToProps)(ShowGroup);