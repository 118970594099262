import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Breadcrumbs, Signer, DocumentLink } from '../../library';

import { generateExpertFinProtocol, signExpertProtocol } from '../../../actions';


class ExpertSingleProtocol extends Component {

  generateExpertProtocol = () => {
    console.log('click');
    this.props.generateProtocolReq(
      this.props.data.review._id
    );
  }

  signProtocol = () => {
    this.props.signProtocolReq(
      this.props.data.review._id
    )
  }


  render = () => {
    const { data } = this.props;
    const { generateExpertProtocol } = this;

    const avialibleStatuses = [3,5];
    const isProtocolAvialible = avialibleStatuses.some( status => status === data.review.status.id );


    if( !isProtocolAvialible ){
      return(
        <div className="rate-solution">
          <Breadcrumbs
              margin="15px 0"
              items={[
                { name: 'Експертна частина', link: '/expert' },
                { name: `${data.application.spec_id}`, link: `/expert/${data.application._id}/info` },
                { name: `Протокол заявки`, link: `/expert/${data.application._id}/protocol` }
            ]}/>
            <div className="expert-title" style={{marginBottom: "20px"}}> 
              Ви ще не можете згенерувати протокол для цієї заявки
            </div>
        </div>
      );
    }


    return(
      <div className="rate-solution">
        <Breadcrumbs
            margin="15px 0"
            items={[
              { name: 'Експертна частина', link: '/expert' },
              { name: `${data.application.spec_id}`, link: `/expert/${data.application._id}/info` },
              { name: `Протокол заявки`, link: `/expert/${data.application._id}/protocol` }
          ]}/>
          <div className="expert-title" style={{marginBottom: "20px"}}> 
            Протокол заявки {data.application.spec_id}
          </div>

          {
            data.review.status.id !== 5 ?
            (
              <>
                {
                  data.review.protocol_id !== undefined ? 
                  (
                    <div>
                      <DocumentLink
                          text={`Протокол заявки ${data.application.spec_id}`}
                          format="pdf"
                          url={data.review.protocol_id.fileUrl}
                      />
                      <Signer
                        ids={[ data.review.protocol_id.sign_id ]}
                        statusChangerCallback={this.signProtocol}
                      />
                    </div>
                  ) : (
                    <button onClick={generateExpertProtocol} className="expert-solution-form-submit">
                      Згенерувати проткол заявки
                    </button>
                  )
                }
              </>
            ):
            (
              <div className="expert_protocol">
                  {/* <a className="application__documents-protocol" href={data.review.protocol_id.fileUrl} target="_blank" rel="noopener noreferrer"> Завантажити протокол </a> */}
                  <DocumentLink
                        text={`Протокол заявки ${data.application.spec_id}`}
                        format="pdf"
                        url={data.review.protocol_id.fileUrl}
                    />
                  
                  <div>

                  Підписано: 
                    <i>
                      {data.protocol.user.name}

                    </i>
                    </div>
              </div>
            )
          }



         
      </div>
    )
  }
}


const mapStateToProps = (state) => ({
  data: state.expert.single.data,
  loading: state.applications.info.loading
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  generateProtocolReq: ( review_id ) => {
      dispatch( generateExpertFinProtocol( review_id ) );
  },
  signProtocolReq: (review_id) => {
    dispatch( signExpertProtocol(review_id, 5) )
  }
})


export default connect(mapStateToProps, mapDispatchToProps)(ExpertSingleProtocol);
