import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import { Table, Row, Cell } from '../../library/Table'
import { Layout, Breadcrumbs, Loader } from '../../library'
import DivisionContext from '../../../helpers/DivisionContext'

import { getRootSeasons, clearRootSeasons } from '../../../actions'
import folderIcon from './icons/folder.svg'

class SeasonsRoot extends Component {
    componentDidMount() {
        this.props.getSeasons()
    }

    componentWillUnmount() {
        this.props.clearSeasons()
    }

    render() {
        const { loading, data } = this.props

        return (
        <DivisionContext.Consumer>
            {
            division => (
                <Layout
                className="projects-division"
                title={division.title}
                breadcrumbs={
                    <Breadcrumbs items={[
                        {name: 'Сезони', link: `/${division.route}/seasons` }
                    ]}/>
                }
                >
                <Table>
                    <Row type="head">
                        <Cell type="id">#</Cell>
                        <Cell type="large"> Сезон </Cell>
                        <Cell type="medium">Дедлайни програми</Cell>
                    </Row>

                    {
                    data && !loading ? data.map(( { season }, key ) => (
                        <Row
                        key={key}
                        type="link"
                        to={`/${division.route}/seasons/${season._id}`}
                        >
                        <Cell type="folder">
                            <img src={folderIcon} alt="Категорія з лотами" />
                        </Cell>
                        <Cell type="large">{season.title}</Cell>
                        <Cell type="medium">
                            {
                            moment(season.start_date).format('ll')
                            } - {
                            moment(season.end_date).format('ll')
                            }
                            </Cell>
                        </Row>
                    )) : <Loader/>
                    }
                </Table>
                </Layout>
            )
            }
        </DivisionContext.Consumer>
        )
    }
}

const mapStateToProps = (state) => ({
    data: state.programs.seasons.data,
    loading: state.programs.seasons.loading
})

const mapDispatchToProps = (dispatch) => ({
    getSeasons: () => {
        dispatch(getRootSeasons())
    },
    clearSeasons: () => {
        dispatch(clearRootSeasons())
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(SeasonsRoot)