import axios from '../../helpers/public.axios';


export const MAKE_ERROR_LOG_REQ = "MAKE_ERROR_LOG_REQ";
export const MAKE_ERROR_LOG_RES = "MAKE_ERROR_LOG_RES";
export const CLEAR_ERROR_LOG = "CLEAR_ERROR_LOG";


export const makeCriticalErrorLog = ( error, errorInfo, location ) => ( dispatch, getState ) => {
    dispatch({ type: MAKE_ERROR_LOG_REQ });
    const state = JSON.stringify( getState() );
    axios.post('/logger', {
        "url": location,
        "message" : error,
        "state": state,
        "error": JSON.stringify(errorInfo)
    })
    .then( res => {
        dispatch({ type: MAKE_ERROR_LOG_RES, payload: res.data.data.log });
    });
}


export const clearErrorLog = () => dispatch => {
    dispatch({ type: CLEAR_ERROR_LOG });
};

