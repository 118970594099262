import React from 'react'
import PropTypes from 'prop-types';


export const RoleChecker = ({ allowedRoles, user, message, children }) => {
    console.log('props', allowedRoles, user.role, message )
    const isAllow = allowedRoles.some( role => role === user.role );


    if( isAllow ){
        return(
            <>
                {children}
            </>
        )
    } else {

        return(
            <div className="additional_info text">
                { message }
            </div>
        )
    }


}


RoleChecker.propTypes = {
    // allowedRoles: PropTypes.oneOf([
    //     'admin',
    //     'chief',
    //     'project_manager',
    //     'expert_manager',
    //     'financier',
    //     'lawyer',
    //     'project_head',
    //     'expert'
    // ])
    allowedRoles: PropTypes.array
}