import ApplicationSchema from './application';
import ReviewSchema from './review';
import NegotiationSchema from './negotiation';

const schemas = [
    {
        type: "application",
        template: ApplicationSchema
    },
    {
        type: "expert_review",
        template: ReviewSchema
    },
    {
        type: "negotiation_procedure",
        template: NegotiationSchema
    }
];


export const getSchemaByType = ( type ) => {
    
    const isTypeAvail = schemas.some(a_type => a_type.type === type.toLowerCase());
    if (!isTypeAvail) {
		throw `You send unavailable type. You can use only: [${schemas.map(t => t.type).join(',')}]`;
	}
    const schema = schemas.find(item => item.type === type.toLowerCase());
    if ( schema !== null) {
		return schema.template;
	} else {
		throw 'Mail template not found';
	}
}