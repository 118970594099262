import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom';
import { getApplicationTitle } from '../../../../helpers/getApplicationTitle';

const CheckboxBlock = ({ name, value, label, app_status = false, changeRadio, application, disabled }) => {
    return(
        <label className="d-single__label">
            <span>{label}</span>
            <input 
                type="radio" 
                name={ name }
                checked={ app_status.status == value }
                onChange={changeRadio( application._id )}
                value={value}
                required
                disabled={disabled}
            />
        </label>
    )
}


const SingleAppRow = ({ application, index, direction_apps, changeRadio, disabled }) => {
    let app_status = direction_apps.find( d_app => d_app._id === application._id );
    return(
        <div className="d-single">
            <div className="d-single__top">
                <div className="d-single__item id">{index}</div>
                <div className="d-single__item spec_id">
                    <Link to={`/pd/application/${application._id}/info`} className="text-link">
                        { `${application.spec_id} `}
                    </Link>
                </div>
                <div className="d-single__item spec_id">
                    { application.tech_review.status === -2 && (<span style={{ color: "#d16053"}}>Відхилено</span>) }
                    { application.tech_review.status === -3 && (<span style={{ color: "#ffcc2d"}}>Не можу визначитись</span>) }
                    { application.tech_review.status === -4 && (<span style={{ color: "#ffcc2d"}}>Подано апеляцію</span>) }
                </div>
                <div className="d-single__item actions">
                    {
                        app_status && (
                            <>
                                <CheckboxBlock
                                    name={`app[${application.spec_id}]`}
                                    app_status={app_status}
                                    changeRadio={changeRadio}
                                    application={application}
                                    value="2"
                                    label="Так"
                                    disabled={disabled}
                                />
                                <CheckboxBlock
                                    name={`app[${application.spec_id}]`}
                                    app_status={app_status}
                                    changeRadio={changeRadio}
                                    application={application}
                                    value="-2"
                                    label="Ні"
                                    disabled={disabled}
                                />
                                <CheckboxBlock
                                    name={`app[${application.spec_id}]`}
                                    app_status={app_status}
                                    changeRadio={changeRadio}
                                    application={application}
                                    value="-3"
                                    label="Немає рішення"
                                    disabled={disabled}
                                />
                            </>
                        )
                    }
                    

                </div>
            
                <div className="d-single__item large">
                    { getApplicationTitle(application) }
                </div>
                <div className="d-single__item large">
                { application.data.fields.name_organization }
                </div>
            </div>
            <div className="d-single__bottom">
                <div className="d-single__item id"></div>
                {
                    application.tech_review.status === -3 && (
                        <div className="d-single__item description full">
                            {
                                application.tech_review.direction_message === -3 && (
                                    <>
                                        <b>Коментар менеджера:</b>
                                        <span>{ application.tech_review.direction_message }</span>
                                    </>
                                )
                            }
                        </div>
                    )
                }
                {
                    application.tech_review.status === -2 && (
                        <div className="d-single__item description full">
                            {
                                application.tech_review.validate_message &&  (
                                    <div>
                                        <b>Коментар менеджера:</b>
                                        <span>{ application.tech_review.validate_message }</span>
                                    </div>
                                )
                            }
                            {
                                application.tech_review.appeal_file && (
                                    <div>
                                        {
                                            application.tech_review.appeal_date && (
                                                <>
                                                    <b> Звернення подано:</ b>
                                                    <span>  
                                                        { moment( application.tech_review.appeal_date ).format('lll') }
                                                    </span>
                                                </>
                                            )
                                        }
                                        
                                        <a href={application.tech_review.appeal_file} target="_blank" className="text-link"> Файл звернення </a>
                                    </div>  
                                )
                            }
                        </div>
                    )
                }   
                
            </div>
        </div>
    )


}

export default SingleAppRow;