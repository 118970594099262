import React from 'react'

import { Row, Cell, StatusHandler } from '../../../library';

const RateItem = ({ item, order, application_id }) => {
	const reduceQuestions = item.questions.reduce( (sum, current) => sum + current.score, 0);
    return(
        <Row
			type="link"
			to={`/expert/${application_id}/rate/${item.question_group_id}`}
		>
			<Cell type="id">{order}</Cell>
			<Cell type="large">
				{item.title}
			</Cell>
			<Cell type="medium">
				{`${reduceQuestions} / ${item.max_score}`}
			</Cell>
			<Cell
			type="large"
			>
			<StatusHandler
				statusId={item.status_id}
				type="expert_feedback"
			/>
			</Cell>
		</Row>
    )
};

export default RateItem;