import React from 'react'

import { StatusHandler } from '../../../library';
import getFieldValue from '../../../../helpers/getFieldValue';

const AppliedFilters = ({ filters, fields }) => {

    const { spec_id, application_status, review_status, expert_name, sector} = filters;
    const filters_array = [spec_id, application_status, review_status, expert_name, sector ];

    const isApplied = filters_array.some( item => item !== "");

    if( isApplied ){
        return(
            <div className="ed-stats__applied-filters">
                Застосовані фільтри:  

                {
                    filters.spec_id !== '' && (
                        <div className="ed-stats__applied-item">
                            Номер заявки: { filters.spec_id }
                        </div>
                    )
                }
                {
                    filters.application_status !== '' && (
                        <div className="ed-stats__applied-item">
                            Статус заявки: <StatusHandler type="expert_division_application" statusId={filters.application_status}/>
                        </div>
                    )
                }

                {
                    filters.review_status !== '' && (
                        <div className="ed-stats__applied-item">
                            Статус відгуку: <StatusHandler type="expert_review" statusId={filters.review_status}/>
                        </div>
                    )
                }
                
                {
                    filters.expert_name !== '' && (
                        <div className="ed-stats__applied-item">
                            Ім'я експерта: { filters.expert_name }
                        </div>
                    )
                }

                {
                    filters.sector !== '' && fields.culture_loaded && (
                        <div className="ed-stats__applied-item">
                            Сектор заявки: { getFieldValue(filters.sector, fields.culture.options.options) }
                        </div>
                    )
                }
            </div>
        );

    } else {
        return null;
    }
}

export default AppliedFilters;