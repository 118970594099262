import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { getDocumentsFeed } from '../../../actions';

import { Breadcrumbs, Loader, Table, Row, Cell } from '../../library';

export const Feed = () => {

    const [
        { documents, season, loading },
        setDocuments
    ] = useState({
        documents: [],
        season: null,
        loading: false
    });


    useEffect(() => {
        setDocuments({ documents, loading: true });
        getDocumentsFeed()
            .then( res => {
                console.log('[RES]', res.data.data.documents );
                setDocuments({ documents: res.data.data.documents, season: res.data.data.season, loading: false });
            })

    }, [])
    

    return(
        <>
            <Breadcrumbs items={[
                {name: 'Документи', link: `/documents` },
            ]}/>

            <header className="documents__header">
                <h1>
                    Фід створених документів
                    {   
                        season && (
                            <span style={{ margin: "0px 10px"}}>
                                (
                                { moment( season.start_date ).format('D-M-Y') }
                                <span style={{ margin: "0px 2px"}}>–</span>
                                { moment( season.end_date ).format('D-M-Y') }
                                )
                            </span>
                        )
                    }
                    
                </h1>
                <Link className="documents__btn" to="/documents/templates"> Шаблони </Link>
            </header>

            <Table>
                <Row type="head">
                    <Cell type="medium"> Назва </Cell>
                    <Cell type="medium"> Хто згенерував </Cell>
                    <Cell type="medium"> Статус </Cell>
                    <Cell type="medium"> Реквізити </Cell>
                    <Cell type="medium"> Дата </Cell>
                </Row>

                { loading && <Loader />}
                {
                    documents.map( document => {
                        return (
                            <Row 
                                type="link" 
                                key={document._id}
                                to={`/documents/${document._id}`}    
                            >
                                <Cell type="medium">{ document.title }</Cell>
                                <Cell type="medium">{ document.user.name }</Cell>
                                <Cell type="medium"> 
                                    { document.status === -2 && "Видалений документ ❌"}
                                    { document.status === -1 && "Архівний документ 📁"}
                                    { document.status === 0 && "Створена угода"}
                                    { document.status === 1 && "Відправлено фінансовому менеджеру"}
                                    { document.status === 2 && "Новий документ (Проєкт ґрантової угоди)"}
                                    { document.status === 3 && "Відправлений заявнику"}
                                    { document.status === 4 && "Ґрантова угода (Підписана заявником)"}
                                    { document.status === 5 && "Підписання угоди (Директор)"}
                                    { document.status === 6 && "Завершена угода"}
                                </Cell>
                                <Cell type="medium"> 
                                    { document.fin_details_status === 0 && <span className="text-gray">⬤ Не оброблено</span> }
                                    { document.fin_details_status === -1 && <span className="text-red">⬤ Відправлено на доопрацювання </span> }
                                    { document.fin_details_status === 2 && <span className="text-green">⬤ Валідовано </span> }
                                    { document.fin_details_status === 1 && <span className="text-yellow">⬤ Надіслано на перевірку </span> }
                                </Cell>

                                <Cell type="medium">{ moment( document.created_at ).format('lll') }</Cell>
                            </Row>
                        );
                    })
                }


            </Table>

        </>
    );

}
