import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import { Loader, Layout, Breadcrumbs, Empty, StatusHandler } from '../../library'
import { Table, Row, Cell } from '../../library/Table'


import { getApplicationTitle } from '../../../helpers/getApplicationTitle';

import { getProjectContests, clearProjectContests } from '../../../actions'

class ProjectContests extends React.Component {
  componentDidMount () {
    this.props.getContestsList(this.props.match.params.id)
  }
  componentWillUnmount () {
    this.props.clearContestsList()
  }
  render() {
    const { data, loaded } = this.props

    if ( !loaded ) { return <Loader/> }

    return (
      <>
        <Layout
          className="projects-division"
          title={data && data.contest.title}
          breadcrumbs={
            data && <Breadcrumbs items={[
              { name: 'Проектний відділ', link: '/pd/' },
              { name: `${data.contest.program.title}`, link: `/pd/programs/${data.contest.program._id}` },
              data.contest.lot !== undefined ? { name: `${data.contest.lot.title}`, link: `/pd/lots/${data.contest.lot._id}` } : null,
              { name: `${data.contest.title}`, link: `/pd/programs` }
            ]}
          />}
        >
          <Table>
            <Row type="head">
              <Cell type="medium">Номер</Cell>
              <Cell type="large">Назва проекту</Cell>
              <Cell type="medium">Статус</Cell>
              <Cell type="large">Змiнено</Cell>
            </Row>
            {
              data && data.applications.length ?
                data.applications.map((item, key) => {
                  let title = getApplicationTitle( item );
                  return (
                    <Row
                      key={ key }
                      type="link"

                      to={`/pd/application/${item._id}`}
                    >
                      <Cell type="medium">
                        {key + 1 }.
                        <span className="text-sub" style={{marginLeft: '5px'}}>
                          {item.spec_id}
                        </span>
                      </Cell>
                      <Cell type="large">
                        { title.length > 75 ? `${title.substr(0, 75)}...` :  title }
                      </Cell>

                      <Cell type="medium">
                          <StatusHandler statusId={item.tech_review.status} type="tech_review"/>
                      </Cell>
                      <Cell type="large">
                          {
                            item.tech_review.user !== undefined &&
                            (
                              <>
                                <span>{ item.tech_review.user.name }</span>
                                <span> { moment( item.tech_review.user.updated_at ).format('llll') }</span>
                              </>
                            )
                          }
                      </Cell>
                    </Row>
                  )
                })
                : <Empty/>
            }
          </Table>
        </Layout>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  data: state.contests.projects.data,
  loaded: state.contests.projects.loaded
})

const mapDispatchToProps = (dispatch) => ({
  getContestsList: (id) => {
    dispatch( getProjectContests(id) )
  },
  clearContestsList: () => {
    dispatch( clearProjectContests() )
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ProjectContests)