import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from "react-router-dom";
import { getAllDocumentTemplates, validateTemplate, generateDocument } from '../../../../../../actions';

import { InlineSpinner,  Button, ButtonWrap, DocumentLink } from '../../../../../library';
import { AgreementFields } from './agreement_fields';

export const GenerateNewAgreement = () => {
    const [ templates, setTemplates ] = useState([]);
    const history = useHistory();
    const { id } = useParams();
    const [ isValidating, setValidity ]= useState(false);
    const [ isGenerating, setIsGenerating ]= useState(false);
    const [ isReady, setIsReady ] = useState( false );
    const [ selectedTemplate, setSelectedTemplate ]= useState( null );
    const [ additionalFields, setFields ] = useState([]);
    const [ templateData, setTemplateData ] = useState({});
    const [ bankDetails, setBankDetails ] = useState(null);

    const handleTemplateData = ( name, value ) => {
        setTemplateData({
            ...templateData,
            [ name ]: value 
        });
    }

    useEffect(() => {
        getAllDocumentTemplates()
            .then( res => {
                setTemplates( res.data.data.templates );
            });
    }, [])

    const submitDocument = (e) => {
        e.preventDefault();
        setIsGenerating( true );

        generateDocument( id, selectedTemplate, templateData, bankDetails )
            .then( res => {
                setIsGenerating( false );
                if( res.data.data.source ){
                    setIsReady( true );
                    history.push(`/documents/${res.data.data.document._id}`);
                    
                }
            });
    }

    const checkFieldsValidity = ( e ) => {
        console.log('[template_id]', e.target.value );

        if( e.target.value === "" ){
            setValidity( false );
            setFields([]);
            setSelectedTemplate(null)
        } else {
            setValidity( true );
            validateTemplate( id, e.target.value )
                .then( res => {
                    setValidity( false );
                    setFields( res.data.data.generateFields );
                    setSelectedTemplate( res.data.data.template._id );

                    let dataObj = {}
                    res.data.data.generateFields.map( item => {
                        dataObj[ item.name ] = "";
                    });
                    setTemplateData( dataObj );
                });
        }
    }

    const handleBankDetailsTemplate = (e) => {
        setBankDetails( e.target.value )
    }

    return(
        <div className="documents">
            <h1> Згенерувати нову угоду </h1>

            <label className="">
                <div className=""></div>
                <select
                    onChange={ checkFieldsValidity }
                    className="documents__select" 
                >
                    <option value=""> Оберіть шаблон </option>
                    { 
                        templates.map( template => {
                            return(
                                <option key={template._id} value={template._id}>
                                    { template.title }
                                    { template.comment && `(${ template.comment })`}
                                </option>
                            )
                        }) 
                    }
                </select>
            </label>

            { 
                isValidating && (
                    <div className="documents__loader">
                        Перевіряємо шаблон
                        <InlineSpinner />
                    </div>
                )
            }

            <form onSubmit={submitDocument}>
            {
                additionalFields.length > 0 && (
                    <AgreementFields 
                        fields={additionalFields}
                        templateData={templateData}
                        handleTemplateData={handleTemplateData}
                    />
                )
            }

            {
                selectedTemplate && (
                    <>
                        <hr/>
                        <label className="">
                            <div className="">Шаблон для реквізитів</div>
                            <select
                                onChange={ handleBankDetailsTemplate }
                                value={bankDetails}
                                className="documents__select" 
                            >
                                <option value=""> Оберіть шаблон </option>
                                <option value="natural_person"> Фізична особа (Стипендії) </option>
                                <option value="legal_entity"> Юридична особа </option>
                                <option value="legal_entity_fop"> Юридична особа (ФОП) </option>
                            </select>
                        </label>
                    </>
                )
            }

            {
                selectedTemplate && (
                    <ButtonWrap>
                        <Button size="medium"  text="Згенерувати документ" loading={isGenerating}/>
                    </ButtonWrap>
                )
            }
            </form>

        </div>
    )

}

export default GenerateNewAgreement;