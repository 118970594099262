import React from 'react'
import { NavLink } from 'react-router-dom'

import './styles/breadcrumbs.scss'

export const Breadcrumbs = ({ items, margin }) => {
  return(
    <div style={{ margin: margin }} className="breadcrumbs">
      {
        items.map( (item, key) => {
          if( item ){
            return(
              <span key={key}>
              {
                (key !== items.length-1) ? (
                  <>
                    <NavLink
                      to={item.link}
                      className="breadcrumbs__item"
                    >
                      {item.name}
                    </NavLink>
                    <span className="breadcrumbs__divider">/</span>
                  </>
                ) : (
                  <NavLink
                    to={item.link}
                    className="text-disabled"
                    onClick={e => e.preventDefault()}
                  >
                    {item.name}
                  </NavLink>
                )
              }
              </span>
            )
          } else { return null }
        })
      }
    </div>
  )
}
