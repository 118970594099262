import React from 'react';
import { StatusHandler,  Row, Cell  } from '../../../library';

import SpecidDecoder from '../../../common/SpecidDecoder';

import { getApplicationTitle } from '../../../../helpers/getApplicationTitle';

const ExpertAllAppItem = ({item}) => {
  
  let title = getApplicationTitle(item.application);

  return (
    <Row
      type="link"
      to={`/expert/${item.application._id}`}
    >
      <Cell type="medium">
        <span className="text-sub">
          <SpecidDecoder application={item.application}/>
        </span>
      </Cell>
      <Cell type="large">
        { title.length > 75 ? `${title.substr(0, 75)}...` :  title }
      </Cell>
      <Cell type="medium">
        { item.score }
      </Cell>
      <Cell
        type="large"
      >
        <StatusHandler
          statusId={ item.review.status.id }
          type="expert_review"
        />
      </Cell>
    </Row>
  )
}

export default ExpertAllAppItem