import React, { useState } from 'react'

import closeLock from './icons/close-lock.svg'
import openLock from './icons/open-lock.svg'
import searchIcon from './icons/search.svg'

export const Input = (
  {
    type,
    placeholder,
    onChange,
    className,
    wrapClass,
    name,
    required,
    pass,
    error,
    search,
    passState
  }
) => {
  const [showPass, showPassword] = useState(passState)

  return (
    <div className={`input-wrap login-label-input ${wrapClass}`}>
      {type === 'undefined' &&
        <input
          type={'text'}
          className={`input ${className} ${!error ? 'login-input' : 'login-input input-error'}`}
          onChange={onChange}
          placeholder={placeholder ? placeholder : 'Введiть значення'}
          name={name}
          required={required ? required : false}
        />
      }
      {
        type === "password" &&
        <>
          <input
            type={!showPass ? 'password' : 'text'}
            className={`input ${className} ${!error ? 'login-input' : 'login-input input-error'}`}
            onChange={onChange}
            placeholder={placeholder ? placeholder : 'Введiть значення'}
            name={name}
            required={required ? required : false}
          />
          <button
            type="button"
            className="login-showPassword"
            onClick={() => showPassword(passState => !passState)}
          >
            <img
              src={!showPass ? closeLock : openLock}
              alt={!showPass ? 'Показати пароль' : 'Сховати пароль'}
              title={!showPass ? 'Показати пароль' : 'Сховати пароль'}
            />
          </button>
        </>
      }
      {
        type === "search" && <>
          <input
            type={'text'}
            className={`input ${className} ${!error ? 'login-input' : 'login-input input-error'}`}
            onChange={onChange}
            placeholder={placeholder ? placeholder : 'Введiть значення'}
            name={name}
            required={required ? required : false}
          />
          <button
            className="login-showPassword"
            type="button"
          >
            <img
              src={searchIcon}
              alt='Пошук'
              title='Пошук'
              style={{ maxHeight: '50%' }}
            />
          </button>
        </>
      }
    </div>
  )
}

Input.defaultProps = {
  type: 'text',
  required: false,
  passState: false
}