import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'

import close from './icons/close.svg'
import './styles/confirm.scss'

class ConfirmAction extends Component{
  state = {
    open: false,
    redirect_to: false
  }

  changePopupStatus = ( status, redirect ) => (e) => {
    console.log('change status: event:', e, 'status', status, redirect)

    if( status === false ){

      if( this.props.textareaError === null){

        if( redirect !== undefined ){
          this.setState({
            open: false,
            redirect_to: redirect
          })
        } else {
          this.setState({
            open: false,
            redirect_to: false
          })
        }

      } else {
        console.log('Show Error!')
      }

    } else {
      this.setState({
        open: true,
      })
    }


  }

  render(){
    const { children, type, title, message, closeMessage, disabled, redirect, textarea, textareaCounter, textareaLengthValue, textareaError,  textareaHandler, confirmButtonClass } = this.props
    const { changePopupStatus } = this
    const { open, redirect_to } = this.state

    if( redirect_to ){
      return( <Redirect to={redirect} /> )
    }

    return(
      <div className="confirm__container">
        {
          open && (
            <div className="confirm__popup">
              <div className="confirm__blackwrap" onClick={changePopupStatus(false)}></div>
              <div className={
                type === 'decline' ? "confirm__window remove" : "confirm__window accept"
              }>
                  <header className="confirm__header">
                    <div className="confirm__title">
                      {title}
                    </div>
                    <button className="confirm__close-icon" onClick={changePopupStatus(false)}>
                      <img src={close} alt="Закрити вікно підтвердження"/>
                    </button>
                  </header>
                  <p className="confirm__message">
                    {message}
                  </p>
                  {
                    textareaError !== null && (
                      <div className="notification-error confirm__error">
                          {textareaError}
                      </div>
                    )
                  }
                  {
                    textarea && (
                      <>
                        {
                          textareaCounter && (
                            <div className="confirm__textareaCounter">
                                  {textareaLengthValue}/100
                            </div>
                          )
                        }
                        <textarea className="confirm__textarea" rows="5" onChange={textareaHandler}></textarea>
                      </>
                    )
                  }

                  <footer className="confirm__footer">
                    <div onClick={changePopupStatus(false, redirect)}>
                      {children}
                    </div>
                    <button className="direction__btn gray" onClick={changePopupStatus(false)}> {closeMessage} </button>
                  </footer>
              </div>
            </div>
          )
        }
        <button
          disabled={ disabled !== undefined ? disabled : false}
          className={ `${disabled ? 'disabled' : ''} ${ confirmButtonClass ?  confirmButtonClass : `button button-${type}` }`}
          onClick={changePopupStatus(true)}>{title}</button>
      </div>
    )
  }
}

ConfirmAction.defaultProps = {
  textareaError: null,
  type: 'decline'
}

export default ConfirmAction
