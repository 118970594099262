import axios from '../../helpers/private.axios';

import { 
    notify,
    getApplicationInfo
} from '../../actions'

export const FINALIZE_EXPERT_REQ = "FINALIZE_EXPERT_REQ";
export const FINALIZE_EXPERT_RES = "FINALIZE_EXPERT_RES";
export const FINALIZE_EXPERT_ERR = "FINALIZE_EXPERT_ERR";

export const finalize_expert_part = (id, status) => dispatch => {

    dispatch({ type: FINALIZE_EXPERT_REQ });

    axios.post(`/api/application/${id}/finish_experts`, {
        status
    })
    .then( res => {
        dispatch({ type: FINALIZE_EXPERT_RES, payload: res.data.data  });
        dispatch( getApplicationInfo( res.data.data._id ) );
    })
    .catch( error => {
        dispatch( notify( error.response.data.message,  "error") )
    })

}

export const SAVE_FIN_EXPERT_MESSAGE_REQ = "SAVE_FIN_EXPERT_MESSAGE_REQ";
export const SAVE_FIN_EXPERT_MESSAGE_RES = "SAVE_FIN_EXPERT_MESSAGE_RES";

export const save_final_comment  = ( id, message ) => dispatch => {
    dispatch({ type: SAVE_FIN_EXPERT_MESSAGE_REQ });

    axios.post(`/api/expert/application/${id}/save-final-comment`, {
        message
    })
    .then( res => {
        dispatch({ type: SAVE_FIN_EXPERT_MESSAGE_RES, payload: res.data.data  });
        dispatch( notify( res.data.data.message,  "success") )
    })
    .catch( error => {
        dispatch( notify( error.response.data.message,  "error") )
    })

}