import React from 'react'
import { Switch, Route, NavLink, Redirect } from 'react-router-dom'

import Login from './Login'
import Registration from './Registration'
import Restore from './Restore'
import RestoreSuccess from './RestoreSuccess'
import RestoreHash from './RestoreHash'
import RestoreDone from './RestoreDone'

import logo from './icons/sup_icons_SUP_LOGO.svg'
import altLogo from './icons/sup_icons_EA.svg'
import './styles/login.scss'

const Nav = () => (
	<header className="login-header">
		<NavLink className="login-link" exact to="/">Вхiд в систему</NavLink>
		<NavLink className="login-link" exact to="/registration">Реєстрація</NavLink>
	</header>
)

const PublicPage = () => {
	return (
		<div className="wrap login">
		<div className="login-logo">
			<img src={logo} alt="Система управління проєктами Українського Культурного Фонду"/>
		</div>
		<div className="login-wrapper">
			<Switch>
				<Route exact path="/" component={Nav} />
				<Route exact path="/registration" component={Nav} />
			</Switch>
			<Switch>
				<Route exact path="/" component={Login}/>
				<Route exact path="/registration" component={Registration}/>
				<Route exact path="/restore" component={Restore}/>
				<Route exact path="/restore/success" component={RestoreSuccess}/>
				<Route exact path="/restore/done" component={RestoreDone}/>
				<Route exact path="/restore/:hash" component={RestoreHash}/>
				<Redirect to="/"/>
			</Switch>
		</div>
		<div className="login-agency">
			<a href="https://elt.agency/" className="cw_link">
				<span>Система розроблена </span>
				<img className="copywrite" src={altLogo} alt="Element Agency"/>
			</a>
		</div>
		</div>
	)
}

export default PublicPage
