import React from 'react'
import { Breadcrumbs } from '../../../library'

const AppHead=({ spec_id, breadcrumbs, reviews }) => (
  <>
    <Breadcrumbs items={[
        { name: 'Експертний відділ', link: '/ed/programs' },
        { name: `${breadcrumbs.program.title}`, link: `/ed/programs/${breadcrumbs.program._id}` },
        breadcrumbs.lot !== undefined && breadcrumbs.lot !== null ?
          { name: `${breadcrumbs.lot.title}`, link: `/ed/lots/${breadcrumbs.lot._id}` } :
          null,
        { name: `${breadcrumbs.contest.title}`, link: `/ed/contests/${breadcrumbs.contest._id}` },
        { name: `${spec_id}`, link: `/ed/programs` }
      ]}
    />
    <header className="application__header">
      <div className="header-item">
        <h1 className="page-title">{ spec_id }</h1>
      </div>
      <div className="header-item">
        <div className="header-item__name">
          Загальний бал заявки
        </div>
        <div className="header-item__value">
          <span className="text-gray">
              {
                reviews.active.reduce( (sum, current) => {
                  return sum + current.score;
                }, 0 )
              }
          </span>
        </div>
      </div>
    </header>
  </>
);

export default AppHead