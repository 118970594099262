import React from 'react'
import { NavLink } from 'react-router-dom'

export const TabsNav = ({ tabs, location, route }) => {
  return (
    <>
      <header className="tabs__container">
        {
          tabs.map((item, key) => {

            if( item === null){
              return null;
            }

            let to_address = `${route}/${location}/${item.slug}`;
            if( location === undefined ){
              to_address = `${route}/${item.slug}`
            }


            return item.disabled ?
              (
              <div className="tabs__item disabled" key={key}>{item.title}</div>
              ) :
              (
                <NavLink
                  key={key}
                  exact
                  className="tabs__item"
                  to={to_address}
                > {item.title} </NavLink>
              )
            }
          )
        }
      </header>
    </>
  )
}