import { combineReducers } from 'redux'

import projects from './projects.reducer'
import experts from './experts.reducer'

const contests = combineReducers({
    projects,
    experts
})

export default contests