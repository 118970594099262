import React from 'react'

export const Cell = ({children, type, className = ''}) => {
  let classStirng

  if( type !== undefined){
    classStirng = `table_cell ${type} ${className}`
  } else {
    classStirng = `table_cell medium ${className}`
  }

  return(
    <div className={classStirng}>
      { children }
    </div>
  )
}

export default Cell
