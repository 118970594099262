export const roles = [
  { title: "admin", name: "Адміністратор" },
  { title: "chief", name: "Керівник (Директор)"},
  { title: "supervisory", name: "Наглядова рада"},
  { title: "project_head", name: "Керівний проектного відділу" },
  { title: "project_manager", name: "Працівник проектного відділу" },
  { title: "financier", name: "Фінансист" },
  { title: "lawyer", name: "Юрист" },
  { title: "expert_manager", name: "Працівник експертного відділу" },
  { title: "expert", name: "Експерт" },
  { title: "unassigned", name: "Не розподілено" },
  { title: "unassigned_expert", name: "Не розподілений експерт" },
]