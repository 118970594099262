import React from 'react'
import { connect } from 'react-redux'

import SearchField from './SearchField'
// import SearchHead from './SearchHead'
import Sidebar from '../../common/SideBar'
import Notify from '../../common/Notify'

import { Layout, Input, Spinner } from '../../library'
import { Table } from '../../library/Table'

import { search } from '../../../actions'

class Search extends React.Component {
  throttlingTimer = null

  trotling = (e) => {
    const query = e.target.value
    clearTimeout(this.throttlingTimer)
    if (query.length > 3) {
      this.throttlingTimer = setTimeout( () => {
        this.props.searchReq(query)
      }, 800 )
    }
  }

  render() {
    const { data, loading, user } = this.props
    return (
      <>
        <Sidebar />
        <Layout
          className="projects-division"
          title={
            <div style={{ display: 'flex'}}>
              <span>Пошук заявки по номеру</span>
              {loading && <Spinner/>}
            </div>
          }>
          <Input
            type="search"
            onChange={this.trotling}
            placeholder="Введiть номер заявки"
          />
          <Table>
            <SearchField data={data} user={user}/>
          </Table>
        </Layout>
        <Notify />
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  data: state.common.search.data,
  loading: state.common.search.loading,
  user: state.auth.user
})

const mapDispatchToProps = (dispatch) => ({
  searchReq: (id) => {
    dispatch(search(id))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Search)